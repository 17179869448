import React, { useState } from 'react';
import styled from 'styled-components';
import { pxToRem, enlargeFontsize } from '@rjp/common/utils';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as SavingsDark } from '@rjp/common/assets/images/savingsDark.svg';
import { ReactComponent as SavingsLight } from '@rjp/common/assets/images/savingsLight.svg';
import { ReactComponent as LifeDark } from '@rjp/common/assets/images/lifeDark.svg';
import { ReactComponent as LifeLight } from '@rjp/common/assets/images/lifeLight.svg';
import { ReactComponent as InvestmentDark } from '@rjp/common/assets/images/investmentDark.svg';
import { ReactComponent as InvestmentLight } from '@rjp/common/assets/images/investmentLight.svg';
import { RootState } from '../../../../../app';

const AssignAnAdvisorDiv = styled.div`
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0 ${pxToRem(5)} ${pxToRem(5)};
  padding: ${pxToRem(35)} 0 0 ${pxToRem(44)};
`;

const TitleDiv = styled.div`
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 28)};
  line-height: ${pxToRem(37)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  white-space: pre-wrap;
  margin-bottom: ${pxToRem(20)};
`;

const SubtitleDiv = styled.div`
  font-weight: normal;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${pxToRem(23)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  white-space: pre-wrap;
  margin-bottom: ${pxToRem(40)};
`;

const CategoryGroupDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: ${pxToRem(87)};
  margin-right: ${pxToRem(131)};
  margin-bottom: ${pxToRem(27)};
`;

const CategoryDiv = styled.div`
  width: ${pxToRem(110)};
  cursor: pointer;
  height: ${pxToRem(100)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  text-align: center;
  color: ${(props) => props.theme.color.sunlife_blue};
  svg {
    width: ${pxToRem(64)};
    height: ${pxToRem(64)};
  }
`;

const CategoryText = styled.div`
  height: ${pxToRem(26)};
  line-height: ${pxToRem(26)};
`;

const CategorySubtitleDiv = styled.div<{ topTriangleOffset: number }>`
  background-color: ${(props) => props.theme.color.sunlife_yellow_medium};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  border-radius: ${pxToRem(5)};
  width: ${pxToRem(599)};
  height: ${pxToRem(152)};
  white-space: pre-wrap;
  padding: ${pxToRem(15)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${pxToRem(22)};
  font-weight: normal;
  position: relative;
  &::after {
    width: 0px;
    height: 0px;
    border-width: ${pxToRem(12)};
    border-style: solid;
    border-color: transparent transparent
      ${(props) => props.theme.color.sunlife_yellow_medium} transparent;
    position: absolute;
    content: ' ';
    left: ${(props) => pxToRem(props.topTriangleOffset)};
    top: ${pxToRem(-24)};
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Highlight = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const AssignAnAdvisor = () => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const Prefix = 'Common.AssignAnAdvisor';
  const [currentCategory, setCurrentCategory] = useState(0);
  const categoryData = [
    {
      title: `${Prefix}.Savings.Title`,
      highlightTitle: `${Prefix}.Savings.HighlightTitle`,
      subtitle: `${Prefix}.Savings.Subtitle`,
      topTriangleOffset: 130,
      darkIcon: <SavingsDark width={pxToRem(64)} />,
      lightIcon: <SavingsLight width={pxToRem(64)} />,
    },
    {
      title: `${Prefix}.UniversalLife.Title`,
      highlightTitle: `${Prefix}.UniversalLife.HighlightTitle`,
      subtitle: `${Prefix}.UniversalLife.Subtitle`,
      topTriangleOffset: 279,
      darkIcon: <LifeDark width={pxToRem(64)} />,
      lightIcon: <LifeLight width={pxToRem(64)} />,
    },
    {
      title: `${Prefix}.Investment.Title`,
      highlightTitle: `${Prefix}.Investment.HighlightTitle`,
      subtitle: `${Prefix}.Investment.Subtitle`,
      topTriangleOffset: 428,
      darkIcon: <InvestmentDark width={pxToRem(64)} />,
      lightIcon: <InvestmentLight width={pxToRem(64)} />,
    },
  ];

  const goSunLife = () => {
    const languagePath = language === 'en' ? language : 'zh-hant';
    window.open(
      `https://sunlife.com.hk/${languagePath}/life-moments/preparing-to-retire/calculate-well-for-a-great-retirement/`
    );
  };

  return (
    <AssignAnAdvisorDiv>
      <TitleDiv>
        <FormattedMessage id={`${Prefix}.Title`} />
      </TitleDiv>
      <SubtitleDiv>
        <FormattedMessage
          id={`${Prefix}.Subtitle`}
          values={{
            highlight: (msg: string) => (
              <Highlight onClick={goSunLife}>{msg}</Highlight>
            ),
          }}
        />
      </SubtitleDiv>
      <CategoryGroupDiv>
        {categoryData.map((d, i) => (
          <CategoryDiv key={d.title} onClick={() => setCurrentCategory(i)}>
            {currentCategory === i ? d.lightIcon : d.darkIcon}
            <CategoryText>
              <FormattedMessage
                id={currentCategory === i ? d.highlightTitle : d.title}
                values={{
                  highlight: (msg: string) => <Highlight>{msg}</Highlight>,
                }}
              />
            </CategoryText>
          </CategoryDiv>
        ))}
      </CategoryGroupDiv>
      <CategorySubtitleDiv
        topTriangleOffset={categoryData[currentCategory].topTriangleOffset}
      >
        <FormattedMessage
          id={categoryData[currentCategory].subtitle}
          values={{
            bold: (msg: string) => <Bold>{msg}</Bold>,
          }}
        />
      </CategorySubtitleDiv>
    </AssignAnAdvisorDiv>
  );
};

export default AssignAnAdvisor;
