import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';

const Content = styled.div`
  font-size: ${pxToRem(16)};
`;

const Bold = styled.span`
  font-weight: 500;
`;

interface IOverwriteRCResultModal extends ModalProps {
  onConfirm: () => void;
}

const prefix = 'PopModal.OverwriteRCResultModal.';

const OverwriteRCResultModal: FC<IOverwriteRCResultModal> = ({
  visible = false,
  onClose,
  onConfirm,
}) => (
  <Modal
    width={588}
    visible={visible}
    onClose={onClose}
    title={`${prefix}Title`}
    textAlign="center"
    footerConfig={[
      {
        message: `${prefix}Cancel`,
        width: 170,
        type: 'secondary',
        onClick: onClose,
      },
      { message: `${prefix}Confirm`, width: 170, onClick: onConfirm },
    ]}
  >
    <Content>
      <FormattedMessage
        id={`${prefix}Paragraph`}
        values={{ bold: (msg: string) => <Bold>{msg}</Bold> }}
      />
    </Content>
  </Modal>
);

export default OverwriteRCResultModal;
