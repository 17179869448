import React, { FC, useMemo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { InfoModal } from '@rjp/common/component';
import { Input } from 'antd';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { useLayoutSize } from '../../../../../hooks';

interface IVerificationCode {
  error?: boolean;
  seconds?: number;
  onResend: () => void;
  onConfirm: (code: string) => void;
  expireHour: number;
  onClose: () => void;
  visible?: boolean;
  onChange: () => void;
}

const prefix = 'PopModal.VerificationCode.';

const codeLength = 6;

const CurInfoModal = styled(InfoModal)`
  user-select: none;
  .ant-modal-body {
    padding-left: ${pxToRem(32)};
    padding-top: ${pxToRem(40)};
    padding-right: ${pxToRem(32)};
    .title {
      margin-bottom: ${pxToRem(10)};
    }
    .main-content-wrapper {
      overflow: inherit;
    }
  }

  .ant-modal-footer {
    padding-top: ${pxToRem(64)};
    button + button {
      margin-left: ${pxToRem(18)};
    }
  }
`;

const CodeWrapper = styled.div`
  display: flex;
  width: ${pxToRem(330)};
  height: ${pxToRem(37)};
  justify-content: space-around;
  margin: 0 auto;
  position: relative;
  margin-top: ${pxToRem(14)};
  cursor: text;
`;
const inMobile =
  navigator.userAgent.match(/(iPhone|iPad|Android|ios)/i) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const CodeItem = styled.div<{ showCursor: boolean; showDelete: boolean }>`
  height: ${pxToRem(37)};
  text-align: center;
  line-height: ${pxToRem(34)};
  font-size: ${pxToRem(28)};
  font-weight: 500;
  width: ${pxToRem(42)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  border-bottom: ${pxToRem(3)} solid ${(props) => props.theme.color.grey_light};
  position: relative;
  &::after {
    content: '';
    display: ${(props) =>
      props.showCursor || props.showDelete ? 'block' : 'none'};
    position: absolute;
    width: 1px;
    background: ${(props) => props.theme.color.sunlife_blue_medium};
    height: ${pxToRem(30)};
    top: 0;
    bottom: 0;
    ${(props) =>
      props.showDelete
        ? 'right: 0; margin: auto 0;'
        : 'right: 0;left: 0;margin: auto;'}
    animation: cursorFlash 1s infinite;
    @keyframes cursorFlash {
      0% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      99% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

const ErrorMessage = styled.div`
  width: ${pxToRem(360)};
  height: ${pxToRem(40)};
  line-height: ${pxToRem(40)};
  background: ${(props) => props.theme.color.extra_red_error};
  position: absolute;
  bottom: ${pxToRem(-50)};
  padding-left: ${pxToRem(10)};
  font-size: ${pxToRem(16)};
  font-weight: 400;
  color: ${(props) => props.theme.color.white};
  transition: all 0.3s;
  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: ${pxToRem(12)} solid transparent;
    border-right: ${pxToRem(12)} solid transparent;
    border-bottom: ${pxToRem(12)} solid
      ${(props) => props.theme.color.extra_red_error};
    top: ${pxToRem(-10)};
    left: ${pxToRem(16)};
  }
`;

const CurInput = styled(Input)`
  position: absolute;
  z-index: -100;
  opacity: 0;
`;

const VerificationCode: FC<IVerificationCode> = ({
  error,
  seconds,
  onResend,
  onConfirm,
  expireHour,
  onClose,
  visible,
  onChange,
}) => {
  const [code, setCode] = useState<string>();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const codeArr = useMemo(() => code?.split('') || [], [code]);

  const inputRef = useRef<any>(null);

  const { isLandscape } = useLayoutSize();

  const changeCode = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    if (!Number.isNaN(Number(value)) || value === '' || value === undefined) {
      onChange();
      setCode(value === undefined ? '' : value?.toString());
    }
  };

  const preventDefault = (e: any) => {
    if (
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.keyCode === 37 ||
      e.keyCode === 39
    ) {
      e.preventDefault();
    }
  };

  const viewInput = () => {
    if (inMobile && isLandscape) {
      setTimeout(() => {
        document.querySelector('.vcode-modal')?.scrollIntoView({
          behavior: /android/.test(navigator.userAgent.toLowerCase())
            ? 'auto'
            : 'smooth',
          block: 'center',
        });
      }, 500);
    }
  };

  const inputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(inputFocus, []);

  const clickConfirm = () => {
    if (codeLength === codeArr.length && code) {
      onConfirm(code);
    }
  };

  return (
    <CurInfoModal
      className="vcode-modal"
      visible={visible}
      width={436}
      onClose={onClose}
      titleConfig={{
        formatMessage: { id: `${prefix}VerificationCode` },
      }}
      contentConfig={{
        formatMessage: {
          id: `${prefix}PleaseEnterTheCode`,
          values: { value: expireHour },
        },
      }}
      footerConfig={[
        {
          width: 170,
          type: seconds ? 'disabled' : 'secondary',
          message: `${prefix}Resend${seconds ? 'S' : ''}`,
          values: { value: seconds },
          onClick: !seconds ? onResend : undefined,
        },
        {
          width: 170,
          type: 'primary',
          message: `${prefix}Confirm`,
          onClick: clickConfirm,
        },
      ]}
    >
      <CodeWrapper id="code-modal" onClick={inputFocus}>
        <CurInput
          autoFocus
          value={code}
          type="text"
          onChange={changeCode}
          maxLength={codeLength}
          onKeyDown={preventDefault}
          ref={inputRef}
          onFocus={() => {
            setIsFocused(true);
            viewInput();
          }}
          onBlur={() => setIsFocused(false)}
        />
        {Array(codeLength)
          .fill('')
          .map((v, index) => (
            <CodeItem
              key={`index ${index + 1}`}
              showCursor={isFocused && index === codeArr.length}
              showDelete={
                isFocused &&
                codeLength === codeArr.length &&
                index === codeLength - 1
              }
            >
              {codeArr[index] || ''}
            </CodeItem>
          ))}
        <CSSTransition
          in={error}
          timeout={300}
          classNames="short-up"
          unmountOnExit
        >
          <ErrorMessage>
            <FormattedMessage id={`${prefix}InvalidVerificationCode`} />
          </ErrorMessage>
        </CSSTransition>
      </CodeWrapper>
    </CurInfoModal>
  );
};

export default VerificationCode;
