import { pxToRem } from '@rjp/common/utils';
import { theme } from '@rjp/common/constants';

const { color } = theme;

const resetPasswordFieldConfig = [
  {
    type: 'password',
    title: 'NewPassword',
    name: 'password',
    marginTop: 50,
    errorMsg: 'PleaseMatchTheRequestedFormat',
  },
  {
    type: 'password',
    title: 'ConfirmPassword',
    name: 'confirmPassword',
    marginTop: 30,
    errorMsg: 'PasswordsDoNotMatch',
  },
];

export const passwordRule = [
  'AMinimumOfEight',
  'OneLowercaseCharacter',
  'OneUppercaseCharacter',
  'OneNumber',
  'OneOfTheFollowingCharacters',
];

export const successfullyModalConfig = {
  titleConfig: {
    showTitleIcon: true,
    formatMessage: {
      id: 'ResetPassword.YourPasswordHasSuccessfullyBeenReset',
    },
    style: {
      color: color.sunlife_blue_medium,
      fontSize: pxToRem(28),
    },
  },
  contentConfig: {
    formatMessage: {
      id: 'ResetPassword.YouCanNowSignInToYourAccount',
    },
    style: {
      color: color.grey_dark,
      marginTop: pxToRem(20),
      marginBottom: pxToRem(20),
      fontSize: pxToRem(18),
      fontWeight: 500,
    },
  },
};

export default resetPasswordFieldConfig;
