import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem, enlargeFontsize } from '@rjp/common/utils';
import { Button } from '@rjp/common/component';
import startBottomImage from '@rjp/common/assets/images/start-bottom.png';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../app';

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 24)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 31)};
  text-align: center;
  color: ${(props) => props.theme.color.extra_red_error};
  margin-top: ${pxToRem(87)};
  margin-bottom: ${pxToRem(60)};
`;

const SubtitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 24)};
  text-align: center;
  color: ${(props) => props.theme.color.grey_dark};
  white-space: pre-wrap;
  margin-bottom: ${pxToRem(59)};
`;

const BottomImage = styled.img`
  height: ${pxToRem(331)};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const ProfileDeleted: FC = () => {
  const FormattedMsg: FC<{ id: string; values?: any }> = ({ id, values }) => (
    <FormattedMessage id={`Profile.DeleteAccountPage.${id}`} values={values} />
  );
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const goToHomePage = () => {
    window.location.href = `https://sunlife.com.hk/${
      language === 'en' ? language : 'zh-hant'
    }/`;
  };
  return (
    <ProfileWrapper>
      <TitleDiv>
        <FormattedMsg id="Title" />
      </TitleDiv>
      <SubtitleDiv>
        <FormattedMsg id="Subtitle" />
      </SubtitleDiv>
      <Button type="primary-yellow" onClick={goToHomePage}>
        <FormattedMsg id="ButtonTitle" />
      </Button>
      <BottomImage src={startBottomImage} />
    </ProfileWrapper>
  );
};
export default ProfileDeleted;
