import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';
import { pxToRem, formatTime, getName } from '@rjp/common/utils';
import { theme } from '@rjp/common/constants';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  CollapseCard,
  InfoModal,
} from '@rjp/common/component';
// import { ReactComponent as ShareSvg } from '@rjp/common/assets/images/share.svg';
import { ReactComponent as ChangeProfileSvg } from '@rjp/common/assets/images/change-profile.svg';
import ChangeProfileModal, { ShareProfileModal } from './modal';
import { IUserInfoState } from '@rjp/main/src/features/userInfo/userInfoSlice';
import { resetRetirementData } from '@rjp/main/src/features/rcData/rcDataSlice';
import { AppDispatch } from '@rjp/main/src/app';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`WorkSurface.Home.ChangeProfile.${id}`} />
);

interface ChangeProfileProps extends Pick<RouteComponentProps, 'history'> {
  clientInfo: IUserInfoState;
}

const CurCollapseCard = styled(CollapseCard)`
  &.collapsible-card-wrapper {
    padding: 0;
  }
  width: ${pxToRem(670)};
  border-radius: 0px 0px ${pxToRem(5)} ${pxToRem(5)};
  margin-bottom: ${pxToRem(20)};
  box-shadow: none;
  .title {
    font-weight: 500;
    align-items: center;
    font-size: ${pxToRem(14)};
    height: ${pxToRem(49)};
    line-height: ${pxToRem(50)};
    border-bottom: none;
  }
  .content {
    padding-top: ${pxToRem(12)};
    padding-bottom: 0;
  }
  svg {
    path {
      fill: ${(props) => props.theme.color.coolblue};
    }
  }
`;

const Content = styled.div`
  height: ${pxToRem(70)};
  font-size: ${pxToRem(16)};
  padding: ${pxToRem(12)} ${pxToRem(16)};
  border-top: 1px solid ${(props) => props.theme.color.cool_blue_light};
`;

const ContentItem = styled.div<{ width: number }>`
  width: ${(props) => pxToRem(props.width)};
  display: inline-block;
  .detail-value {
    color: ${(props) => props.theme.color.sunlife_blue_light};
    font-weight: 500;
    font-size: ${pxToRem(18)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TitleProfile = styled(CurFormattedMessage)`
  width: ${pxToRem(343)};
  font-size: ${pxToRem(24)};
  color: ${(props) => props.theme.color.sunlife_blue_light};
`;

const StyleTitleWrapper = styled.div`
  display: flex;

  .title-right {
    div {
      display: inline-block;
    }
  }

  .share {
    width: ${pxToRem(83)};
    margin-right: ${pxToRem(18)};
    // cursor: pointer;
  }

  .change-profile {
    cursor: pointer;
    width: ${pxToRem(150)};
    height: ${pxToRem(30)};
    line-height: ${pxToRem(30)};
    margin-top: ${pxToRem(10)};
    border-right: 1px solid ${(props) => props.theme.color.cool_blue_light};
    position: relative;
    right: 0;
  }

  svg {
    width: ${pxToRem(25)};
    height: ${pxToRem(22)};
    margin-right: ${pxToRem(8)};
    vertical-align: ${pxToRem(-6)};
    path {
      fill: ${(props) => props.theme.color.coolblue};
    }
  }
`;

const Details = styled(CurFormattedMessage)`
  font-size: ${pxToRem(14)};
`;

const ContentItemLabel = styled(CurFormattedMessage)`
  font-size: ${pxToRem(16)};
`;

const EmailSentDetailDesc = styled.div`
  font-size: ${pxToRem(18)};
  font-weight: 500;
`;

const EmailSentCode = styled.div`
  height: ${pxToRem(50)};
  margin: ${pxToRem(20)} 0;
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(32)};
  letter-spacing: ${pxToRem(15)};
  font-weight: bold;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const EmailSentDetailComponent: FC<{ code: string; validTime: string }> = ({
  code,
  validTime,
}) => (
  <>
    <EmailSentDetailDesc>
      <FormattedMessage id="PopModal.EmailSent.EmailToShareProfile" />
    </EmailSentDetailDesc>
    <EmailSentCode>{code}</EmailSentCode>
    <EmailSentDetailDesc>
      <FormattedMessage
        id="PopModal.EmailSent.PleaseShareTheOneTimePassword"
        values={{ validTime }}
      />
    </EmailSentDetailDesc>
  </>
);

const TitleWrapper: FC<ChangeProfileProps> = ({ clientInfo, history }) => {
  const themeContext = useContext(ThemeContext);

  const [modalVisible, setModalVisible] = useState({
    change: false,
    share: false,
    emailSent: false,
  });
  const [isShared, setIsShared] = useState(false);
  const [sharedTimes, setSharedTimes] = useState(0);
  const [code, setCode] = useState('');
  const [validTime, setValidTime] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const handleModalVisible = (name: string, visible: boolean) => {
    setModalVisible((state) => ({
      ...state,
      [name]: visible,
    }));
  };
  const onShare = (email: string) => {
    handleModalVisible('share', false);
    setCode('107967');
    handleModalVisible('emailSent', true);
    setValidTime(formatTime());
    setIsShared(true);
    setSharedTimes((times) => times + 1);
  };

  const [time, setTime] = useState(60);
  let timerID:NodeJS.Timeout;
  useEffect(() => {
    setTime(60);
    if (isShared && sharedTimes) {
      timerID = setInterval(() => setTime((t) => t - 1), 1000);
    }
  }, [isShared, sharedTimes]);
  useEffect(() => {
    if (time === 0 && isShared) {
      clearInterval(timerID);
    }
  }, [time, isShared]);

  const emailSentModalConfig = useMemo(
    () => ({
      titleConfig: {
        showTitleIcon: true,
        formatMessage: {
          id: `PopModal.EmailSent.${isShared ? 'EmailResent' : 'EmailSent'}`,
        },
        style: {
          color: themeContext.color.sunlife_blue_medium,
          fontSize: pxToRem(28),
        },
      },
    }),
    [isShared, themeContext]
  );

  const onYes = () => {
    handleModalVisible('change', false);
    dispatch(resetRetirementData());
    history.push('/advisor/search');
  };

  const name = useMemo(() => {
    const curName = `${clientInfo.first_name || '-'} ${
      clientInfo.last_name || '-'
    }`;
    return getName(curName);
  }, [clientInfo.first_name, clientInfo.last_name]);

  return (
    <StyleTitleWrapper>
      <TitleProfile id="Profile" values={{ name }} />
      <div className="title-right share" onClick={() => {}}>
        {/*<ShareSvg />*/}
        {/*<Details id="Share" />*/}
      </div>
      <div
        className="title-right change-profile"
        onClick={() => handleModalVisible('change', true)}
      >
        <ChangeProfileSvg />
        <Details id="ChangeProfile" />
      </div>
      <ShareProfileModal
        email={clientInfo.email ?? ''}
        visible={modalVisible.share}
        onClose={() => handleModalVisible('share', false)}
        onShare={onShare}
        isShared={isShared}
        countdown={time}
        code={code}
        validTime={validTime}
      />
      <ChangeProfileModal
        visible={modalVisible.change}
        onClose={() => handleModalVisible('change', false)}
        onYes={onYes}
      />
      <InfoModal
        width={500}
        visible={modalVisible.emailSent}
        onClose={() => handleModalVisible('emailSent', false)}
        {...emailSentModalConfig}
        contentConfig={{
          content: (
            <EmailSentDetailComponent code={code} validTime={validTime} />
          ),
        }}
      />
    </StyleTitleWrapper>
  );
};

const widthMap: Record<string, number> = {
  Mobile: 165,
  Email: 280,
  ReceiveMarketingInfo: 190,
};

const ChangeProfile: FC<ChangeProfileProps> = ({ clientInfo, history }) => {
  const details = useMemo(() => {
    const { area_code, mobile, email, is_opt_in_direct_marketing } = clientInfo;
    return [
      {
        label: 'Mobile',
        value: `+${area_code ?? ''} ${mobile ?? ''}`,
      },
      {
        label: 'Email',
        value: email,
      },
      {
        label: 'ReceiveMarketingInfo',
        value: (
          <CurFormattedMessage id={is_opt_in_direct_marketing ? 'Yes' : 'No'} />
        ),
      },
    ];
  }, [clientInfo]);
  return (
    <CurCollapseCard
      className="collapsible-card-wrapper"
      titleComponent={
        <TitleWrapper clientInfo={clientInfo} history={history} />
      }
      defaultClose
      color={theme.color.sunlife_blue_medium}
    >
      <Content>
        {details.map(({ label, value }) => (
          <ContentItem width={widthMap[label]} key={label}>
            <ContentItemLabel id={label} />
            <div className="detail-value">{value ?? '-'}</div>
          </ContentItem>
        ))}
      </Content>
    </CurCollapseCard>
  );
};

export default ChangeProfile;
