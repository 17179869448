import sunlifeCaller from '@/api/sunlifeCaller';
import { IMember } from '@/api/type';
import {
  inputPFtoInputPX,
  inputPFtoInputVision
} from '@/utils/engineParser';
export type IIllustrationParams = Record<string, any>;
/*
{
  Age: number;
  Sex: string;
  Class: string;
  Currency: string;
  PremTerm: string;
  PremMode: string;
  Withdrawals: {
    withdrawal: number;
    type: string;
    start: number;
    end: number;
    amount: number;
    duration: number;
  }[];
  Inflation: number;
  SavingBudget1: number;
  Story: string;
  Statement: string;
  IsPrepayment: string;
};
*/

export const getIllustration = async (params: IIllustrationParams, retirementProfile:any, svToken: String) =>
  sunlifeCaller<{ members: IMember[] }>({
    method: 'post',
    url: process.env.REACT_APP_SUNVISUAL_URL+'api/v1/sv/products/victory/illustration',
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+ svToken
    },
    data: inputPFtoInputPX(params, retirementProfile.retirement_age)
  });

export const getVisionIllustration = async (params: IIllustrationParams, retirementProfile:any, svToken: String) =>
  sunlifeCaller<any>({
    method: 'post',
    url: process.env.REACT_APP_SUNVISUAL_URL+'api/v1/sv/products/vision/illustration',
    headers: {
      'Content-Type': 'application/json',
      'Authorization':'Bearer '+ svToken
    },
    data: inputPFtoInputVision(params, retirementProfile.retirement_age)
  });


