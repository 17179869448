import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { pxToRem, enlargeFontsize, getName } from '@rjp/common/utils';
// import { Button, CheckBox, InfoModal, BeforeLeave, ApiErrorTip } from '@rjp/common/component';
import {
  Button,
  InfoModal,
  BeforeLeave,
  ApiErrorTip,
} from '@rjp/common/component';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BackSvg } from '@rjp/common/assets/images/back.svg';
import { ReactComponent as EditSvg } from '@rjp/common/assets/images/yellow-edit.svg';
import { ReactComponent as ProfileComputerSvg } from '@rjp/common/assets/images/profileComputer.svg';
import { RouteComponentProps } from 'react-router-dom';
import { theme } from '@rjp/common/constants';
import { logout } from '@rjp/main/src/features/auth/authSlice';
import { updateUserInfo } from '@rjp/main/src/features/userInfo/userInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import ProfileEditInfo from './ProfileEditInfo';
import { AppDispatch, RootState } from '../../../app';
import profileFormConfig from './profileConfig';
import { updateMember, deleteMember } from '../../../api';
import { IProfileFormData } from './types';

const ProfileWrapper = styled.div`
  /* width: 100%; */
  /* height: ${pxToRem(764)}; */
  /* background-color: ${(props) => props.theme.color.cool_blue_light_20}; */
  /* padding-bottom: 300px; */
`;

const Content = styled.div`
  width: ${pxToRem(1132)};
  min-height: ${pxToRem(694)};
  padding-bottom: ${pxToRem(52)};
  margin-left: ${pxToRem(30)};
  background-color: ${(props) => props.theme.color.white};
  box-shadow: 0px ${pxToRem(2)} ${pxToRem(10)} rgba(0, 0, 0, 0.1);
  border-radius: ${pxToRem(5)};
  padding-top: ${pxToRem(20)};
`;

const BackDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 24)};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.sunlife_blue_medium_40};
  margin-left: ${pxToRem(17)};
  cursor: pointer;
`;

const BackImg = styled(BackSvg)`
  width: ${pxToRem(15)};
  height: ${pxToRem(24)};
  margin-right: ${pxToRem(20)};
`;

const ProfileName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 28)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 37)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  padding-left: ${pxToRem(51)};
  margin-top: ${pxToRem(30)};
  margin-bottom: ${pxToRem(20)};
`;

const ProfileContentDiv = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: ${pxToRem(51)};
  width: 100%;
`;

const ProfileInfoContentDiv = styled.div`
  width: ${pxToRem(637)};
  min-height: ${pxToRem(448)};
  padding-bottom: ${pxToRem(32)};
  background-color: ${(props) => props.theme.color.grey_light_20};
  padding-left: ${pxToRem(17)};
  padding-top: ${pxToRem(19)};
  position: relative;
`;

const ProfileInfoTitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 24)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 31)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ProfileInfoSubtitleDiv = styled.div`
  margin-top: ${pxToRem(8)};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 21)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ProfileInfoLineDiv = styled.div`
  margin-top: ${pxToRem(12)};
  margin-bottom: ${pxToRem(25)};
  width: ${pxToRem(602)};
  height: 0px;
  border: 1px solid ${(props) => props.theme.color.coolblue_medium};
`;

const ProfileEditInfoDiv = styled.div`
  width: 100%;
  padding-right: ${pxToRem(33)};
  min-height: ${pxToRem(270)};
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
`;

// const ProfileEditInfoTipsDiv = styled.div`
//   font-weight: normal;
//   font-size: ${props => enlargeFontsize(props.theme.language, 16)};
//   line-height: ${props => enlargeFontsize(props.theme.language, 21)};
//   color: ${props => props.theme.color.rey_dark};
//   display: flex;
//   align-items: center;
//   margin-top: ${pxToRem(10)};
//   .check-box-tips {
//     margin-left: ${pxToRem(10)};
//     user-select: none;
//   }
// `;

const ProfileComputerImg = styled(ProfileComputerSvg)`
  width: ${pxToRem(385)};
`;

const EditImg = styled(EditSvg)`
  width: ${pxToRem(18)};
  position: absolute;
  top: ${pxToRem(26)};
  right: ${pxToRem(19)};
`;

const ButtonGroupDiv = styled.div`
  width: 100%;
  display: flex;
  padding-left: ${pxToRem(51)};
  margin-top: ${pxToRem(25)};
  position: relative;
`;

const DeleteButton = styled(Button)`
  display: flex;
  position: absolute;
  right: ${pxToRem(25)};
  background-color: transparent;
  border-style: none;
  text-decoration: underline;
  color: ${(props) => props.theme.color.red_medium} !important;
`;

const DeleteModalBold = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.color.extra_red_error};
`;

const resetValueValid = {
  first_name: true,
  last_name: true,
  name_chinese: true,
  mobile: true,
  area_code: true,
};

const Profile: FC<RouteComponentProps> = ({ history }) => {
  const FormattedMsg: FC<{ id: string; prefix?: string; values?: any }> = ({
    id,
    values,
    prefix = 'Profile.Profile',
  }) => <FormattedMessage id={`${prefix}.${id}`} values={values} />;

  const dispatch = useDispatch<AppDispatch>();

  const [editing, setEditing] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showError, setShowError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('10002');

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const language = useSelector(
    (state: RootState) => state.translation.language
  );

  const resetData = useMemo<IProfileFormData>(
    () => ({
      area_code: userInfo.area_code || '852',
      email: userInfo.email,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      mobile: userInfo.mobile,
      name_chinese: userInfo.name_chinese,
    }),
    [userInfo]
  );

  const [formValue, setFormValue] = useState(resetData);

  const [formValueValid, setFormValueValid] = useState(resetValueValid);

  const isValidResult = useMemo(() => {
    const testEmptyFormValue = { ...formValue, name_chinese: 'haveValue' };
    const isNotEmpty = Object.values(testEmptyFormValue).every(
      (value) => value
    );
    const isValid = Object.values(formValueValid).every((value) => value);
    return isNotEmpty && isValid;
  }, [formValueValid, formValue]);

  const inputChange = (name: string, value: any, error: boolean) => {
    setFormValue((values) => ({
      ...values,
      [name]: value,
    }));
    setFormValueValid((values) => ({
      ...values,
      [name]: !error,
    }));
  };
  const replaceSpace = (str: string) => str.replace(/\s+/g, ' ').trim();

  const saveResult = async () => {
    if (!isValidResult) return;
    try {
      const curFormValue = { ...formValue };
      curFormValue.last_name = replaceSpace(curFormValue.last_name || '');
      curFormValue.first_name = replaceSpace(curFormValue.first_name || '');
      await updateMember({ ...curFormValue, id: userInfo.id || '', language });
      dispatch(updateUserInfo(curFormValue));
      setEditing(false);
      setShowError(false);
    } catch (error) {
      // TODO: Show error
      setShowError(true);
      setErrorCode(error);
      console.log(error);
    }
  };

  const deleteAccount = async () => {
    await deleteMember({ id: userInfo.id || '' });
    setShowDeleteModal(false);
    history.replace(`/${userInfo.role}/deleted`);
    dispatch(logout());
  };

  const onCancel = () => {
    setEditing(false);
    setShowError(false);
    setFormValueValid(resetValueValid);
    setFormValue(resetData);
  };

  useEffect(() => setFormValue(resetData), [resetData]);

  const name = useMemo(() => {
    const curName =
      language === 'en'
        ? `${userInfo.first_name} ${userInfo.last_name}`
        : userInfo.name_chinese;
    return getName(curName);
  }, [language, userInfo]);

  return (
    <ProfileWrapper>
      <BeforeLeave history={history} intercept={editing} />
      <Content>
        <BackDiv onClick={() => history.goBack()}>
          <BackImg />
          <FormattedMsg id="Back" />
        </BackDiv>
        <ProfileName>
          <FormattedMsg id="Title" values={{ name }} />
        </ProfileName>
        <ProfileContentDiv>
          <ProfileInfoContentDiv>
            {!editing ? (
              <EditImg onClick={() => setEditing((v) => !v)} />
            ) : null}
            <ProfileInfoTitleDiv>
              <FormattedMsg id="Detail" />
            </ProfileInfoTitleDiv>
            <ProfileInfoSubtitleDiv>
              <FormattedMsg id="DetailSubtitle" />
            </ProfileInfoSubtitleDiv>
            <ProfileInfoLineDiv />
            <ProfileEditInfoDiv>
              {profileFormConfig.map(({ editable, ...item }) => (
                <ProfileEditInfo
                  {...item}
                  onChange={inputChange}
                  defaultValue={
                    (formValue as { [key: string]: number | string })[
                      item.keyName
                    ]
                  }
                  formValue={formValue}
                  editing={editable && editing}
                />
              ))}
            </ProfileEditInfoDiv>
            {/*<ProfileEditInfoTipsDiv>*/}
            {/*  <CheckBox*/}
            {/*    name="is_opt_in_direct_marketing"*/}
            {/*    type="primary-blue"*/}
            {/*    width={22}*/}
            {/*    disable*/}
            {/*    defaultChecked={userInfo.is_opt_in_direct_marketing}*/}
            {/*    onChange={() => { }}*/}
            {/*  >*/}
            {/*    <span className="check-box-tips"><FormattedMsg id="Tips" /></span>*/}
            {/*  </CheckBox>*/}
            {/*</ProfileEditInfoTipsDiv>*/}
          </ProfileInfoContentDiv>
          <ProfileComputerImg />
        </ProfileContentDiv>
        <ApiErrorTip
          showError={showError}
          margin={`${pxToRem(15)} 0 ${pxToRem(18)} ${pxToRem(50)}`}
          width={636}
          id={errorCode}
        />
        <ButtonGroupDiv>
          {editing ? (
            <Button type="secondary" width={171} onClick={onCancel}>
              <FormattedMsg id="Cancel" />
            </Button>
          ) : null}
          {editing ? (
            <Button
              type={isValidResult ? 'primary' : 'disabled'}
              width={171}
              style={{ marginLeft: pxToRem(27) }}
              onClick={saveResult}
            >
              <FormattedMsg id="SaveChanges" />
            </Button>
          ) : null}
          <DeleteButton
            type="secondary"
            width={140}
            onClick={() => setShowDeleteModal(true)}
          >
            <FormattedMsg id="DeleteAccount" />
          </DeleteButton>
        </ButtonGroupDiv>
      </Content>
      <InfoModal
        visible={showDeleteModal}
        width={718}
        onClose={() => setShowDeleteModal(false)}
        titleConfig={{
          title: (
            <FormattedMsg prefix="Profile.DeleteAccountModal" id="Title" />
          ),
          style: {
            color: theme.color.extra_red_error,
          },
        }}
        contentConfig={{
          content: (
            <FormattedMsg
              prefix="Profile.DeleteAccountModal"
              id="Subtitle"
              values={{
                bold: (msg: any) => <DeleteModalBold>{msg}</DeleteModalBold>,
              }}
            />
          ),
        }}
        footerConfig={[
          {
            message: 'Profile.DeleteAccountModal.Yes',
            width: 150,
            type: 'secondary',
            style: {
              borderColor: theme.color.extra_red_error,
              color: theme.color.extra_red_error,
            },
            onClick: deleteAccount,
          },
          {
            message: 'Profile.DeleteAccountModal.Cancel',
            width: 150,
            onClick: () => setShowDeleteModal(false),
          },
        ]}
      />
    </ProfileWrapper>
  );
};
export default Profile;
