export const CompoundUpItemConfig = [
  {
    width: 80,
    marginLeft: 13,
    marginTop: 32,
    money: 10000,
    label: 'Investment',
  },
  {
    width: 84,
    marginLeft: 18,
    marginTop: 30,
    money: 10512,
    label: 'Savings',
  },
  {
    width: 95,
    marginLeft: 22,
    marginTop: 31,
    money: 11049,
    label: 'Savings',
  },
  {
    width: 115,
    marginLeft: 30,
    marginTop: 41,
    money: 11615,
    label: 'Savings',
  },
  {
    width: 135,
    marginLeft: 30,
    marginTop: 51,
    money: 12209,
    label: 'Savings',
  },
  {
    width: 46,
    marginLeft: 4,
    marginTop: -20,
    className: 'equal-item',
  },
  {
    width: 155,
    marginLeft: 0,
    marginTop: 61,
    money: 12834,
    label: 'Savings',
    className: 'last-item',
  },
];

export const CompoundBottomItemConfig = [
  {
    width: 80,
    marginLeft: 13,
    money: 512,
    year: 1,
    label: 'InterestEarned',
  },
  {
    width: 84,
    marginLeft: 18,
    money: 538,
    year: 2,
    label: 'InterestEarned',
  },
  {
    width: 95,
    marginLeft: 22,
    money: 565,
    year: 3,
    label: 'InterestEarned',
  },
  {
    width: 115,
    marginLeft: 30,
    money: 594,
    year: 4,
    label: 'InterestEarned',
  },
  {
    width: 135,
    marginLeft: 30,
    money: 12209,
    year: 5,
    label: 'InterestEarned',
  },
  {
    width: 155,
    marginLeft: 50,
    money: 2834,
    year: 5,
    label: 'InterestEarnedAfter',
    className: 'last-bottom-item',
  },
];

export const mixModalConfig = [
  {
    title: 'PostRetirementInvestmentMix',
    name: 'post_retirement_investment_mix',
  },
  {
    title: 'MPFInvestmentMix',
    name: 'mpf_investment_mix',
  },
];

export const mixModalContentConfig = [
  {
    value: ['81%', '100%'],
    label: 'Aggressive',
  },
  {
    value: ['61%', '80%'],
    label: 'Growth',
  },
  {
    value: ['41%', '60%'],
    label: 'Balanced',
  },
  {
    value: ['21%', '40%'],
    label: 'Moderate',
  },
  {
    value: ['1%', '20%'],
    label: 'Conservative',
  },
];
