import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/zh';
import { useSelector } from 'react-redux';

import { RootState } from '@rjp/main/src/app';
import { pxToRem } from '@rjp/common/utils';
import {
  ApiErrorTip,
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
} from '@rjp/common/component';

interface DoYouWishToExtendProps {
  consent_expiry_date?: string;
  showError: boolean;
  errorCode: string;
  hasExpired: boolean;
}

const prefix = 'PopModal.ContactAdvisor.';
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const Wrapper = styled.div`
  text-align: center;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(26)};
  margin: auto;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const bold = (msg: string) => <Bold>{msg}</Bold>;

const DoYouWishToExtend: FC<DoYouWishToExtendProps> = ({
  consent_expiry_date,
  showError,
  errorCode,
  hasExpired,
}) => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const [languageType, setLanguagetype] = useState('en');
  const [formatMsg, setFormatMsg] = useState('D, MMMM, YYYY');

  useEffect(() => {
    if (language === 'en') {
      setLanguagetype('en');
      setFormatMsg('D MMMM, YYYY');
    } else {
      setLanguagetype('zh');
      setFormatMsg('YYYY年MMMDD日');
    }
  }, [language]);

  return (
    <Wrapper>
      {!hasExpired && (
        <CurFormattedMessage
          id="YourAdvisorCanAccess"
          values={{
            bold,
            date: dayjs(consent_expiry_date).format('D MMMM, YYYY'),
          }}
        />
      )}
      <CurFormattedMessage
        id="DoTouWantToExtendYourConsent"
        values={{
          bold,
          date: dayjs().add(29, 'day').locale(languageType).format(formatMsg),
        }}
      />
      <ApiErrorTip
        width={520}
        showError={showError}
        id={errorCode}
        margin={`${pxToRem(10)} 0 0 0`}
      />
    </Wrapper>
  );
};

export default DoYouWishToExtend;
