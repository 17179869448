import React, { FC, CSSProperties } from "react";
import styled from "styled-components";
import { pxToRem, enlargeFontsize } from "@rjp/common/utils";
import { Modal, ModalProps } from "@rjp/common/component";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CheckSvg } from "@rjp/common/assets/images/check.svg";

export interface InfoModalProps extends ModalProps {
  children?: React.ReactNode;
  titleConfig?: {
    // title和formatMessage, 优先formatMessage
    title?: React.ReactNode | string;
    formatMessage?: {
      id: string;
      values?: any;
    };
    style?: CSSProperties;
    showTitleIcon?: boolean;
    // 默认是对勾
    titleIcon?: React.ReactNode;
  };
  contentConfig?: {
    // title和formatMessage, 优先formatMessage
    content?: React.ReactNode | string;
    formatMessage?: {
      id: string;
      values?: any;
    };
    style?: CSSProperties;
  };
}

const IconDiv = styled.div`
  text-align: center;
  margin-bottom: ${pxToRem(5)};
`;

const TitleDiv = styled.div`
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 20)};
  font-weight: bold;
  text-align: center;
  margin-bottom: ${pxToRem(30)};
`;

const DescDiv = styled.div`
  color: ${(props) => props.theme.color.grey_dark};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  font-weight: normal;
  text-align: center;
  white-space: pre-wrap;
`;

const InfoModal: FC<InfoModalProps> = ({
  visible,
  onClose,
  children,
  titleConfig,
  contentConfig,
  width,
  ...props
}) => {
  const TitleComponent = () => {
    if (titleConfig) {
      return (
        <div>
          {titleConfig.showTitleIcon ? (
            <IconDiv>
              {titleConfig.titleIcon || <CheckSvg width={pxToRem(80)} />}
            </IconDiv>
          ) : null}
          <TitleDiv style={titleConfig.style} className="title">
            {titleConfig.formatMessage ? (
              <FormattedMessage
                id={titleConfig.formatMessage.id}
                values={titleConfig.formatMessage.values}
              />
            ) : (
              titleConfig.title
            )}
          </TitleDiv>
        </div>
      );
    }
    return null;
  };

  const ContentComponent = () => {
    if (contentConfig) {
      return (
        <DescDiv style={contentConfig.style} className="content">
          {contentConfig.formatMessage ? (
            <FormattedMessage
              id={contentConfig.formatMessage.id}
              values={contentConfig.formatMessage.values}
            />
          ) : (
            contentConfig.content
          )}
        </DescDiv>
      );
    }
    return null;
  };

  return (
    <Modal
      visible={!!visible}
      onClose={onClose}
      width={width || 436}
      {...props}
    >
      <TitleComponent />
      <ContentComponent />
      {children}
    </Modal>
  );
};

export default InfoModal;
