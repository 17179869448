import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { pxToRem } from '@rjp/common/utils';
import { Tab, ApiErrorTip } from '@rjp/common/component';
import { RootState } from '@rjp/main/src/app';
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';

import Products from './products';
import RetirementGap from './retirementGap';
import RetirementStats from './retirementStats';
import AboutRDetails, { ChangeProfile } from './aboutRDetails';
import ErrorContext from './homeContext';
import Details from './details';

import {
  CompoundPopModal,
  CashSavingModal,
  FuturePaymentsModal,
  MonthlyMpfSavingsModal,
  MixModal,
  OtherInvestmentsModal,
  OnlyProductsSoldByAdvisorCanBeViewedModal,
  ProjectedMonthlyRetirementAllowanceModal,
  PurchasedSunLifeProductsModal,
} from './details/modal';
import { mixModalConfig } from './details/modal/config';
import { IUserInfoState } from '../../../features/userInfo/userInfoSlice';

const HomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${pxToRem(12)};
`;

const HomeLeft = styled.div`
  width: ${pxToRem(670)};
  height: ${pxToRem(690)};
  display: flex;
  flex-direction: column;
`;

const HomeRight = styled.div`
  width: ${pxToRem(478)};
`;

const GapContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${(props) => props.theme.color.white};
  border-radius: ${pxToRem(5)} ${pxToRem(5)} 0 0;
  box-shadow: 0px ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1);
`;

interface IHome extends RouteComponentProps {
  clientInfo?: IUserInfoState;
  memberShowQuotes: boolean;
}

const Home: FC<IHome> = ({
  match,
  location,
  history,
  clientInfo,
  memberShowQuotes,
}) => {
  const role = useSelector((state: RootState) => state.userInfo.role);
  const [showError, setShowError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('10002');

  const tabList: string[] = useMemo(() => {
    const list = ['details', 'products'];
    // if (role !== 'member') {
    //   list.push('report');
    // }
    return list;
  }, []);

  const [modalVisible, setModalVisible] = useState<Record<string, boolean>>({
    retirement_monthly_income_style: false,
    total_cash_savings: false,
    monthly_cash_savings: false,
    retirement_monthly_income: false,
    post_retirement_investment_mix: false,
    mpf_investment_mix: false,
    monthly_mpf_savings: false,
    otherInvestments: false,
    futurePayments: false,
    age: false,
    purchasedSunLifeProducts: false,
    onlyProductsSoldByAdvisorCanBeViewed: false,
  });

  const handleModalVisible = (name: string, visible: boolean) => {
    setModalVisible((modalState) => ({
      ...modalState,
      [name]: visible,
    }));
  };

  const gapContentContents: { [k: string]: React.ReactElement } = {
    details: (
      <Details
        modalVisible={modalVisible}
        setModalVisible={handleModalVisible}
        clientInfo={clientInfo}
      />
    ),
    products: (
      <Products clientInfo={clientInfo} memberShowQuotes={memberShowQuotes} />
    ),
    // report: <div>report</div>,
  };

  const changeTab = (tab: string) => {
    history.push(`${match.path}/${tab}${location.search}`);
  };

  const activeTab = useMemo(
    () => tabList.find((tab) => location.pathname.indexOf(tab) > -1),
    [location, tabList]
  );

  return (
    <HomeWrapper>
      <ErrorContext.Provider
        value={{
          showError,
          errorCode,
          setShowError,
          setErrorCode,
        }}
      >
        <HomeLeft>
          {role === 'advisor' ? (
            <ChangeProfile clientInfo={clientInfo ?? {}} history={history} />
          ) : (
            <AboutRDetails active={activeTab || 'details'} />
          )}
          <ApiErrorTip
            showError={showError}
            id={errorCode}
            margin={`0 0 ${pxToRem(20)} 0`}
            width={670}
          />
          <GapContentWrapper>
            <Tab
              translationPrefix="WorkSurface.Home.Tabs"
              tabArray={tabList}
              active={activeTab || 'details'}
              onChange={changeTab}
            />
            <Switch>
              <Route
                path={`${match.path}/:tab`}
                render={({ match: { params } }) => {
                  if (params.tab && tabList.includes(params.tab)) {
                    return gapContentContents[params.tab];
                  }
                  return (
                    <Redirect
                      to={{
                        pathname: `${match.path}/details`,
                        search: location.search,
                      }}
                    />
                  );
                }}
              />
              <Route
                render={() => (
                  <Redirect
                    to={{
                      pathname: `${match.path}/details`,
                      search: location.search,
                    }}
                  />
                )}
              />
            </Switch>
          </GapContentWrapper>
        </HomeLeft>
      </ErrorContext.Provider>
      <HomeRight>
        <RetirementStats />
        <RetirementGap />
      </HomeRight>
      <CompoundPopModal
        visible={modalVisible.monthly_cash_savings}
        onClose={() => handleModalVisible('monthly_cash_savings', false)}
      />
      <ProjectedMonthlyRetirementAllowanceModal
        visible={modalVisible.retirement_monthly_income_style}
        onClose={() =>
          handleModalVisible('retirement_monthly_income_style', false)
        }
      />
      <CashSavingModal
        visible={modalVisible.total_cash_savings}
        onClose={() => handleModalVisible('total_cash_savings', false)}
      />
      <ProjectedMonthlyRetirementAllowanceModal
        visible={modalVisible.retirement_monthly_income}
        onClose={() => handleModalVisible('retirement_monthly_income', false)}
      />
      <MonthlyMpfSavingsModal
        visible={modalVisible.monthly_mpf_savings}
        onClose={() => handleModalVisible('monthly_mpf_savings', false)}
      />
      <OtherInvestmentsModal
        visible={modalVisible.otherInvestments}
        onClose={() => handleModalVisible('otherInvestments', false)}
      />
      <FuturePaymentsModal
        visible={modalVisible.futurePayments}
        onClose={() => handleModalVisible('futurePayments', false)}
      />
      <PurchasedSunLifeProductsModal
        visible={modalVisible.purchasedSunLifeProducts}
        onClose={() => handleModalVisible('purchasedSunLifeProducts', false)}
      />
      <OnlyProductsSoldByAdvisorCanBeViewedModal
        visible={modalVisible.onlyProductsSoldByAdvisorCanBeViewed}
        onClose={() =>
          handleModalVisible('onlyProductsSoldByAdvisorCanBeViewed', false)
        }
      />
      {mixModalConfig.map(({ title, name }) => (
        <MixModal
          key={title}
          title={title}
          visible={modalVisible[name]}
          onClose={() => handleModalVisible(name, false)}
        />
      ))}
    </HomeWrapper>
  );
};

export default Home;
