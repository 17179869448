import React, { FC, useRef, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import startBottomImage from '@rjp/common/assets/images/start-bottom.png';
import { pxToRem } from '@rjp/common/utils';
import { StaticContext } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '@rjp/main/src/app';
import moment from 'moment';
import { memberVerificationRequest } from '@rjp/main/src/api';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Button,
  InfoModal,
} from '@rjp/common/component';

const prefix = 'Login.AccountNotVerified.';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const AccountNotVerifiedWrapper = styled.div`
  font-size: ${pxToRem(10)};
  overflow: hidden;
  white-space: pre-wrap;
`;

const BottomImage = styled.img`
  height: ${pxToRem(331)};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Title = styled(CurFormattedMessage)`
  width: ${pxToRem(634)};
  margin: 0 auto;
  margin-top: ${pxToRem(86)};
  font-size: ${pxToRem(28)};
  line-height: ${pxToRem(37)};
  font-weight: 500;
  text-align: center;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Desc = styled(CurFormattedMessage)`
  margin: 0 auto;
  margin-top: ${pxToRem(74)};
  width: ${pxToRem(500)};
  height: ${pxToRem(70)};
  font-weight: 500;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  text-align: center;
  color: ${(props) => props.theme.color.grey_dark};
  margin-bottom: ${pxToRem(35)};
`;

const CurInfoModal = styled(InfoModal)`
  .content {
    width: ${pxToRem(277)};
    margin: 0 auto;
    font-size: ${pxToRem(18)};
    color: ${(props) => props.theme.color.grey_dark};
  }
  .title {
    margin-bottom: ${pxToRem(10)};
  }
`;

const CurButton = styled(Button)`
  margin: 0 auto;
`;

const Bold = styled.span`
  font-weight: bold;
`;

type IAccountNotVerified = RouteComponentProps<
  {},
  StaticContext,
  { email?: string }
>;

const AccountNotVerified: FC<IAccountNotVerified> = ({ location }) => {
  const email = location.state ? location.state.email : null;
  const intl = useIntl();
  const [showInviteSent, setShowInviteSent] = useState<boolean>(false);
  const [expireTime, setExpireTime] = useState<number>(+moment());
  const [countDown, setCountDown] = useState<number>(0);
  let interval:NodeJS.Timeout;
  const language = useSelector(
    (state: RootState) => state.translation.language
  );

  const startCountDown = () => {
    const seconds = 60;
    const currentTime = moment();
    const exceedTime = moment(currentTime).add(seconds, 's');
    setCountDown(seconds);
    interval = setInterval(() => {
      const timestamp = +exceedTime - +moment();
      const diff = moment(timestamp).get('s');
      if (diff <= seconds && timestamp > 0) {
        setCountDown(diff);
      } else {
        setCountDown(0);
        clearInterval(interval);
      }
    }, 500);
  };

  const onResendEmail = async () => {
    if (email) {
      await memberVerificationRequest({ email, language });
      setShowInviteSent(true);
      setExpireTime(+moment().add(7, 'd'));
      startCountDown();
    }
  };

  return email ? (
    <AccountNotVerifiedWrapper>
      <Title
        id="LooksLikeYourAccount"
        values={{
          email,
        }}
      />
      <Desc id="IfYouWishToResend" />
      <BottomImage src={startBottomImage} />
      <CurButton
        width={countDown ? 298 : 250}
        type={countDown ? 'disabled' : 'primary-yellow'}
        onClick={onResendEmail}
      >
        <CurFormattedMessage
          id={
            countDown ? 'ResendVerificationEmailS' : 'ResendVerificationEmail'
          }
          values={{
            value: countDown,
          }}
        />
      </CurButton>
      <CurInfoModal
        visible={showInviteSent}
        onClose={() => setShowInviteSent(false)}
        width={443}
        titleConfig={{
          showTitleIcon: true,
          formatMessage: {
            id: `${prefix}InviteSent`,
          },
        }}
        contentConfig={{
          formatMessage: {
            id: `${prefix}PleaseCheckYourEmail`,
            values: {
              bold: (message: string) => <Bold>{message}</Bold>,
              value: intl.formatDate(expireTime, {
                year: 'numeric',
                day: 'numeric',
                month: 'long',
              }),
            },
          },
        }}
      />
    </AccountNotVerifiedWrapper>
  ) : (
    <Redirect to={{ pathname: '/' }} />
  );
};

export default AccountNotVerified;
