import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { pxToRem } from "@rjp/common/utils";
import { ReactComponent as CheckSolid } from "@rjp/common/assets/images/check-solid.svg";
import { ReactComponent as Checked } from "@rjp/common/assets/images/checkbox-checked.svg";
import { ReactComponent as NoneChecked } from "@rjp/common/assets/images/checkbox-none-checked.svg";

export interface CheckBoxProps {
  name?: string;
  color?: string;
  width?: number;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (name: string, value: boolean) => void;
  type?: "primary" | "primary-blue";
  disable?: boolean;
  className?: string;
}
export interface ICheckProps {
  width?: number;
  checked?: boolean;
  disable?: boolean;
  onClick: () => void;
}

const Wrapper = styled.div<Pick<CheckBoxProps, "disable">>`
  display: flex;
  ${(props) => !props.disable && "user-select: none; cursor: pointer;"}
  width: fit-content;
`;

const Check = styled.div<Pick<CheckBoxProps, "width" | "checked" | "disable">>`
  width: ${(props) => pxToRem(props.width || 18)};
  height: ${(props) => pxToRem(props.width || 18)};
  border: ${(props) => (props.checked ? "1px" : "0.5px")} solid
    ${(props) => props.theme.color.extra_dark};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${pxToRem(12)};
    path {
      fill: ${(props) =>
        props.disable ? "rgba(117,117,117,.4)" : props.theme.color.font};
    }
  }
`;

const PrimaryCheckBox: FC<ICheckProps> = ({
  width,
  checked,
  onClick,
  disable,
}) => (
  <Check
    className="check"
    disable={disable}
    width={width}
    checked={checked}
    onClick={onClick}
  >
    {checked && <CheckSolid />}
  </Check>
);

const PrimaryBlueCheckBox = styled<FC<ICheckProps>>(
  ({ checked, onClick, ...props }) =>
    checked ? (
      <Checked className="check" onClick={onClick} {...props} />
    ) : (
      <NoneChecked className="check" onClick={onClick} {...props} />
    )
)<ICheckProps>`
  width: ${(props) => pxToRem(props.width || 21)};
  height: ${(props) => pxToRem(props.width || 21)};
  path {
    fill: ${(props) =>
      props.disable
        ? "rgba(117,117,117,.4)"
        : props.theme.color.coolblue} !important;
  }
`;

const CheckBox: FC<CheckBoxProps> = ({
  name,
  width,
  defaultChecked,
  onChange,
  type = "primary",
  children,
  disable,
  className,
}) => {
  const [currentChecked, setCurrentChecked] = useState(!!defaultChecked);
  const onClick = () => {
    if (!disable) {
      setCurrentChecked(!currentChecked);
      onChange?.(name || "", !currentChecked);
    }
  };

  useEffect(() => setCurrentChecked(!!defaultChecked), [defaultChecked]);

  const CheckBoxMap = {
    primary: (
      <PrimaryCheckBox
        disable={disable}
        width={width}
        checked={currentChecked}
        onClick={() => {}}
      />
    ),
    "primary-blue": (
      <PrimaryBlueCheckBox
        disable={disable}
        width={width}
        checked={currentChecked}
        onClick={() => {}}
      />
    ),
  };

  return (
    <Wrapper
      className={`check-box ${className || ""}`}
      onClick={onClick}
      disable={disable}
    >
      {CheckBoxMap[type]}
      {children}
    </Wrapper>
  );
};

export default CheckBox;
