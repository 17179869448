import { Switch as AntSwitch } from "antd";
import { SwitchProps as AntSwitchProps } from "antd/es/switch";
import styled from "styled-components";

import { pxToRem } from "../../utils";

export interface ButtonProps extends AntSwitchProps {
  width?: number;
  height?: number;
  circle?: number;
  disabled?: boolean;
}

// @ts-ignore
const SwitchButton = styled(AntSwitch)<ButtonProps>`
  min-width: ${(props) => pxToRem(props.width || 44)};
  width: ${(props) => pxToRem(props.width || 44)};
  height: ${(props) => pxToRem(props.height || 22)};

  .ant-switch-handle {
    top: ${pxToRem(2)};
    left: ${pxToRem(2)};
    width: ${(props) => pxToRem(props.circle || 18)};
    height: ${(props) => pxToRem(props.circle || 18)};
  }

  &.ant-switch-checked {
    background-color: ${(props) => props.theme.color.sunlife_blue_light};
    .ant-switch-handle {
      left: ${(props) =>
        pxToRem(props.width ? props.width - (props.circle || 18) - 2 : 24)};
    }
  }
  &.ant-switch-checked:focus {
    box-shadow: none;
  }
`;

export default SwitchButton;
