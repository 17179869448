/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { RootState } from '@rjp/main/src/app';
import { sessionTimeHourLimit } from './config';

const loggedInTimeSelector = (state: RootState) => state.auth.loggedInTime;

export const isLoggedInSelector = createSelector(
  loggedInTimeSelector,
  (loggedInTime) =>
    !!loggedInTime &&
    dayjs(loggedInTime).add(sessionTimeHourLimit, 'hour').diff(dayjs()) >= 0
);
