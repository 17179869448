import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { pxToRem } from '@rjp/common/utils';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Button,
} from '@rjp/common/component';
import { RootState } from '@rjp/main/src/app';
import BikePng from '@rjp/common/assets/images/bike.png';
import { trackingFunc, RJP_LINK } from '@/utils/tracking';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`Search.AdvisorAppDesc.${id}`} />
);

const AdvisorAppDescWrapper = styled.div`
  margin-top: ${pxToRem(20)};
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  width: ${pxToRem(472)};
  height: ${pxToRem(646)};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1);
  border-radius: ${pxToRem(5)};
  padding: ${pxToRem(30)} ${pxToRem(22)} 0 ${pxToRem(20)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  background-image: url(${BikePng});
  background-repeat: no-repeat;
  background-size: ${pxToRem(194)} ${pxToRem(150)};
  background-position: bottom 0px right ${pxToRem(27)};
`;

const Title = styled(CurFormattedMessage)`
  font-weight: 500;
  font-size: ${pxToRem(28)};
  line-height: 1.2;
`;

const Description = styled(CurFormattedMessage)`
  margin-top: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
`;

const Subtitle = styled(CurFormattedMessage)<{ marginTop: number }>`
  font-weight: 500;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  margin-top: ${(props) => pxToRem(props.marginTop)};
`;

const CurButton = styled((props) => <Button width={204} {...props} />)`
  margin-top: ${pxToRem(18)};
`;

const retirementLanguage: { [key: string]: string } = {
  en: 'en',
  'zh-hant': 'zh-tw',
};

const AdvisorAppDesc: FC = () => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );

  const goRetirementCalculator = () => {
    window.open(
      `${process.env.REACT_APP_RETIREMENT_CALCULATOR_URL}/${retirementLanguage[language]}`
    );
  };

  // const getQuote = () => {
  //   window.open(`${process.env.REACT_APP_SUNVISUAL_URL}?from=advisor_app&locale=${retirementLanguage[language]}`);
  // };
  return (
    <AdvisorAppDescWrapper>
      <Title id="TheAdvisorApp" />
      <Description id="HelpYourClientsSeeTheir" />
      {/*<Subtitle id="TheSunVisualTool" marginTop={44} />*/}
      {/*<CurButton onClick={getQuote}>*/}
      {/*  <CurFormattedMessage id="GetQuote" />*/}
      {/*</CurButton>*/}
      <Subtitle id="ShareTheRetirementCalculator" marginTop={48} />
      <CurButton onClick={() => {goRetirementCalculator(); trackingFunc(RJP_LINK, 'other', 'retirement calculator - right rail')}}>
        <CurFormattedMessage id="RetirementCalculator" />
      </CurButton>
    </AdvisorAppDescWrapper>
  );
};

export default AdvisorAppDesc;
