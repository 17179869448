export const formatLanguagePackage = (data: object) => {
  const currentData: any = {};
  const destructionObject = (prefix: string, obj: any) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (typeof value === "string") {
        currentData[`${prefix}${key}`] = value;
      } else if (typeof value === "object")
        destructionObject(`${prefix}${key}.`, value);
    });
  };
  destructionObject("", data);
  return currentData;
};

export interface LanguagesPackage {
  [language: string]: {
    [key: string]: string;
  };
}
