import React, { FC } from "react";
import styled from "styled-components";
import { pxToRem } from "@rjp/common/utils";
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
} from "@rjp/common/component";
import { CSSTransition } from "react-transition-group";

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`ErrorTip.${id}`} />
);

interface IApiErrorTip {
  id: string;
  width?: number;
  showError: boolean;
  margin?: string;
  onClick?: () => void;
}

const Medium = styled.span`
  font-weight: 500;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const ApiErrorTipWrapper = styled(CurFormattedMessage)<{
  width?: number;
  margin?: string;
}>`
  width: ${(props) => pxToRem(props.width || 666)};
  background: ${(props) => props.theme.color.pink_red};
  border-radius: ${pxToRem(5)};
  font-size: ${pxToRem(14)};
  min-height: ${pxToRem(26)};
  padding: ${pxToRem(8)} ${pxToRem(10)} ${pxToRem(8)} ${pxToRem(16)};
  color: ${(props) => props.theme.color.red};
  transition: all 0.5s;
  margin: ${(props) => props.margin || 0};
`;

const ApiErrorTip: FC<IApiErrorTip> = ({
  id,
  width,
  showError,
  onClick,
  margin,
}) => (
  <CSSTransition
    in={showError}
    timeout={showError ? 0 : 500}
    unmountOnExit
    classNames="short-up"
  >
    <ApiErrorTipWrapper
      id={`Code${id}`}
      width={width}
      margin={margin}
      values={{
        bold: (msg: string) => <Bold>{msg}</Bold>,
        medium: (msg: string) => <Medium>{msg}</Medium>,
        underline: (msg: string) => (
          <Underline onClick={onClick}>{msg}</Underline>
        ),
      }}
    />
  </CSSTransition>
);

export default ApiErrorTip;
