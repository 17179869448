import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { theme } from '@rjp/common/constants';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Input,
  InputNumber,
} from '@rjp/common/component';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`Search.Form.${id}`} />
);

const FormLabel = styled(CurFormattedMessage)`
  height: ${pxToRem(20)};
  line-height: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  color: ${(props) => props.theme.color.grey_dark};
  font-weight: 500;
  margin-bottom: ${pxToRem(3)};
`;

const InputGroup = styled.div`
  display: flex;
  width: ${pxToRem(300)};
  justify-content: space-between;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${pxToRem(20)};
`;

const AreaCode = styled.div`
  font-size: ${pxToRem(16)};
  font-weight: bold;
  height: ${pxToRem(44)};
  line-height: ${pxToRem(44)};
`;

const PhoneInputItem = styled(FormItem)`
  width: ${pxToRem(240)};
  margin-top: ${pxToRem(23)};
`;

const BaseFormItem: FC<{
  fieldName: string;
  name: string;
  onChange: (key: string, value?: string | number) => void;
  originData: any;
  type?: string;
  width?: number;
  hasError?: boolean;
  setError?: (name: string, value: boolean) => void;
}> = ({
  fieldName,
  name,
  originData,
  onChange,
  type = 'text',
  width,
  hasError,
  setError,
}) => {
  const formItemEnum: { [key: string]: React.ReactElement } = {
    text: (
      <FormItem>
        <FormLabel id={name} />
        <Input
          name={fieldName || ''}
          type={type}
          width={width || 300}
          onChange={onChange}
          color={theme.color.grey_medium}
          hasError={hasError}
          setError={setError}
          defaultValue={originData[fieldName]}
        />
      </FormItem>
    ),
    age: (
      <FormItem>
        <FormLabel id={name} />
        <InputNumber
          name={fieldName || ''}
          width={width || 300}
          onChange={(value) => onChange(fieldName, value)}
          color={theme.color.grey_medium}
          hasError={hasError}
          value={originData[fieldName]}
        />
      </FormItem>
    ),
    mobile: (
      <InputGroup>
        <FormItem>
          <FormLabel id="Mobile" />
          <AreaCode>+{originData?.area_code}</AreaCode>
        </FormItem>
        <PhoneInputItem>
          <Input
            width={240}
            name="mobile"
            type="phoneNumber"
            onChange={onChange}
            color={theme.color.grey_medium}
            hasError={hasError}
            setError={setError}
            defaultValue={originData[fieldName]}
            countryCode={originData?.area_code}
          />
        </PhoneInputItem>
      </InputGroup>
    ),
  };
  return formItemEnum[type] ? formItemEnum[type] : formItemEnum.text;
};

export default BaseFormItem;
