import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { pxToRem, toLogin } from '@rjp/common/utils';
import {
  WorkSurfaceHeader,
  Tab,
  SignOutModel,
  WaitSignOutModal,
} from '@rjp/common/component';
import { RouteComponentProps } from 'react-router-dom';
import { logout } from '@rjp/main/src/features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import LstGetStarted from '../workSurface/home/aboutRDetails';
import AdvisorAppDesc from './AdvisorAppDesc';
import SearchForm from './SearchForm';
import CreateForm from './CreateForm';
import { AppDispatch, RootState } from '../../app';

const SearchWrapper = styled.div``;

const SearchContentWrapper = styled.div`
  margin: 0 auto;
  width: ${pxToRem(1172)};
  display: flex;
  justify-content: space-between;
`;

const LeftWrapper = styled.div`
  width: ${pxToRem(670)};
`;

const ContentWrapper = styled.div`
  background: ${(props) => props.theme.color.white};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1);
  border-radius: 0 0 ${pxToRem(5)} ${pxToRem(5)};
`;

const Search: FC<RouteComponentProps> = ({ history, match }) => {
  const [tab, setTab] = useState<string>('search');
  const [showSignOutModal, setShowSignOutModal] = useState<boolean>(false);
  const [showWaitSignOut, setShowWaitSignOut] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const role =
    useSelector((state: RootState) => state.userInfo.role) ?? 'member';

  const signOut = async () => {
    setShowSignOutModal(false);
    setShowWaitSignOut(true);
    await dispatch(logout());
    setShowWaitSignOut(false);
    if (role === 'advisor') {
      toLogin('advisor', history)();
    } else {
      history.replace('/');
    }
  };

  return (
    <SearchWrapper>
      <WorkSurfaceHeader
        history={history}
        match={match}
        inAdvisor
        onClickSignOut={() => setShowSignOutModal(true)}
      />
      <SearchContentWrapper>
        <LeftWrapper>
          <LstGetStarted defaultOpen active="AdvisorLetGetStarted" />
          <Tab
            translationPrefix="Search.Tabs"
            tabArray={['search']}
            active={tab}
            onChange={(value) => setTab(value)}
          />
          <ContentWrapper>
            {tab === 'search' ? <SearchForm /> : <CreateForm />}
          </ContentWrapper>
        </LeftWrapper>
        <AdvisorAppDesc />
      </SearchContentWrapper>
      <SignOutModel
        visible={showSignOutModal}
        onClose={() => setShowSignOutModal(false)}
        signOut={signOut}
      />
      <WaitSignOutModal
        visible={showWaitSignOut}
        onClose={() => setShowWaitSignOut(false)}
      />
    </SearchWrapper>
  );
};

export default Search;
