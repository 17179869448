import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { CheckBox } from '@rjp/common/component';
import { labelMap } from '../../config/config';
import { pxToRem } from '@rjp/common/utils';

export interface CheckBoxGroup {
  options: Record<string, any>[];
  onChange: (value: any[]) => void;
  type?: 'primary' | 'primary-blue';
}

const Description = styled.span`
  width: ${pxToRem(415)};
  display: inline-block;
`;

export const CheckBoxGroup: FC<CheckBoxGroup> = ({
  options,
  onChange,
  type,
}) => {
  const [value, setValue] = useState<Record<string, any>[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [result, setResult] = useState<string[]>([]);

  // 初始化
  useEffect(() => {
    const allReturnValue = options.map((item) => item.id);
    let allCheckedValue: Record<string, any>[] = [];
    allReturnValue.forEach((item) => {
      allCheckedValue.push({ id: item, checked: false });
    });
    setValue(allCheckedValue);
  }, [options]);

  const onCheckedChange = (nameValue: string, checked: boolean) => {
    let curValue = value;
    curValue.forEach((item, index) => {
      if (item.id === nameValue) {
        curValue[index]['checked'] = checked;
      }
    });

    const returnValue = curValue
      .filter((item) => item.checked === true)
      .map((item) => item.id);
    setValue(curValue);
    setResult(returnValue);
    onChange(returnValue);
  };

  const selectAll = (changeName: string, checked: boolean) => {
    const allReturnValue = options.map((item) => item.id);
    let allCheckedValue: Record<string, any>[] = [];
    allReturnValue.forEach((item) => {
      allCheckedValue.push({ id: item, checked });
    });
    setResult(!checked ? [] : allReturnValue);
    onChange(!checked ? [] : allReturnValue);
    setValue(allCheckedValue);
    setIsSelectAll(checked);
  };

  // select all 被 value 影响
  useEffect(() => setIsSelectAll(result.length === options.length), [
    options,
    result,
  ]);

  return (
    <div className="group-wrapper">
      <CheckBox
        className="checkbox-list select-all"
        type={type}
        defaultChecked={isSelectAll}
        onChange={selectAll}
      >
        <FormattedMessage id="Common.SelectAll" />
      </CheckBox>
      {options.map((option: any, index) => (
        <CheckBox
          width={20}
          className="checkbox-list"
          key={option.id || ''}
          {...option}
          type={type}
          defaultChecked={!!value[index]?.checked}
          onChange={(changeName, value) =>
            onCheckedChange(option.id || '', value)
          }
        >
          <Description>
            <FormattedMessage
              id={`PopModal.AddSaveQuoteModal.${
                labelMap[option.product_code]
              }`}
            />
            {option.description ? " - " + option.description : ''}
            {": "}
            {dayjs(option.creation_date).format('DD/MM/YY  HH:mm')}
          </Description>
        </CheckBox>
      ))}
    </div>
  );
};

export default CheckBoxGroup;
