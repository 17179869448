import MockAdapter from 'axios-mock-adapter';
import Cookies from 'js-cookie';
import { IResponse } from './type';
import { ILoginResponse, ITokenExchangeResponse } from './auth.type';

const authMock = (mock: MockAdapter) => {
  /**
   * Config values. to be restructured
   */
  const authlist: {
    username: string;
    password: string;
    id?: string;
    role?: string;
    status: string;
  }[] = [
    {
      username: 'member@coherent.com.hk',
      password: 'Member123!',
      id: 'member_id',
      role: 'member',
      status: 'success',
    },
    {
      username: 'client@coherent.com.hk',
      password: 'Client123!',
      id: 'client_id',
      role: 'client',
      status: 'success',
    },
    {
      username: 'ADVISOR',
      password: 'Advisor123!',
      id: 'advisor_id',
      role: 'advisor',
      status: 'success',
    },
    {
      username: 'pending@coherent.com.hk',
      password: 'Pending123!',
      status: 'pending',
    },
  ];

  const checkAuth = (username: string, password: string, status: string) =>
    authlist.find(
      (info) =>
        username === info.username &&
        password === info.password &&
        info.status === status
    );

  // login
  mock.onPost('/auth/login').reply<IResponse<ILoginResponse>>((config) => {
    const { username, password } = JSON.parse(config.data);
    const successAccountInfo = checkAuth(username, password, 'success');
    const pendingAccountInfo = checkAuth(username, password, 'pending');
    const status = 200;
    const payload = successAccountInfo
      ? {
          username: 'M000000001',
          confirmationCode: 'confirmed',
          redirectUrl: `${window.location.origin}${
            process.env.REACT_APP_IS_SUNLIFE_API ? '' : '/rjp'
          }/authorized?code=${successAccountInfo.role}`,
        }
      : {};
    const error_code = successAccountInfo
      ? []
      : pendingAccountInfo
      ? [10009]
      : [10012];
    const responseData = {error_code, payload};

    return [status, responseData];
  });

  // tokenExchange
  mock
    .onPost('/auth/tokenexchange')
    .reply<IResponse<{ metadata: ITokenExchangeResponse }>>((config) => {
      const { authorization_code } = JSON.parse(config.data);
      const authorizedInfo = authlist.find(
        (info) => authorization_code && info.role === authorization_code
      );
      const status = authorizedInfo ? 200 : 400;
      const responseData = authorizedInfo
        ? {
            payload: {
              metadata: {
                id: authorizedInfo.id,
                role: authorizedInfo.role,
              },
            },
            error_code: [],
          }
        : undefined;
      if (authorizedInfo) {
        Cookies.set('rjpAccessToken', 'rjpAccessToken');
        Cookies.set('rjpRefreshToken', 'rjpRefreshToken');
      }
      return [status, responseData];
    });

  // resetPasswordRequest
  mock.onPost('/auth/passwordresetrequest').reply<IResponse<{}>>((config) => {
    const { email, language } = JSON.parse(config.data);
    const status = 200;
    const responseData: IResponse<{}> = {
      payload: {},
      error_code: [],
    };
    if (!(email && (language === 'en' || language === 'zh-hant'))) {
      responseData.error_code.push(10001);
    }
    return [status, responseData];
  });

  // resetPassword
  const validPasswordResetToken = 'validtoken';
  mock.onPost('/auth/passwordreset').reply<IResponse<{}>>((config) => {
    const { password, token } = JSON.parse(config.data);
    const status = 200;
    const responseData: IResponse<{}> = {
      payload: {},
      error_code: [],
    };
    if (!(password && token === validPasswordResetToken)) {
      responseData.error_code.push(10001);
    }
    return [status, responseData];
  });

  // logout
  mock.onPost('/auth/logout').reply<IResponse<{}>>(() => {
    const status = 200;
    const responseData = {
      payload: {},
      error_code: [],
    };
    Cookies.remove('rjpAccessToken');
    Cookies.remove('rjpRefreshToken');
    return [status, responseData];
  });
};

export default authMock;
