import { theme } from '@rjp/common/constants';
import { pxToRem } from '@rjp/common/utils';
import React from 'react';

const { color } = theme;

export const fieldConfig = [
  [
    {
      type: 'name',
      title: 'FirstName',
      name: 'first_name',
      errorMsg: 'InvalidName',
    },
    {
      type: 'name',
      title: 'LastName',
      name: 'last_name',
      errorMsg: 'InvalidName',
    },
  ],
  [
    {
      type: 'email',
      title: 'Email',
      subTitle: 'ThisCannotBeChanged',
      name: 'email',
      errorMsg: 'InvalidEmailAddressFormat',
    },
    {
      type: 'email',
      title: 'ConfirmEmail',
      name: 'confirmEmail',
      errorMsg: '',
    },
  ],
  [
    {
      type: 'password',
      title: 'Password',
      name: 'password',
      errorMsg: 'PleaseMatchTheRequestedFormat',
    },
    {
      type: 'phoneNumber',
      title: 'Mobile',
      name: 'mobile',
      errorMsg: 'InvalidMobileNumber',
    },
  ],
];

export const passwordRule1 = ['AMinimumOfEight', 'OneLowercaseCharacter'];

export const passwordRule2 = [
  'OneUppercaseCharacter',
  'OneNumber',
  'OneOfTheFollowingCharacters',
];

export const createdAccountContent = [
  {
    marginTop: 36,
    text: 'CheckYourEmailInbox',
  },
  {
    marginTop: 125,
    text: 'DidNotReceiveTheVerificationEmail',
  },
];

const prefix = 'Login.CreateAccount.';

export const verificationModalConfig = {
  titleConfig: {
    showTitleIcon: true,
    formatMessage: {
      id: `${prefix}VerificationEmailSent`,
    },
    style: {
      color: color.sunlife_blue_medium,
      fontSize: pxToRem(28),
    },
  },
  contentConfig: {
    formatMessage: {
      id: `${prefix}PleaseCheckYourEmailInbox`,
    },
    style: {
      color: color.grey_dark,
      marginTop: pxToRem(40),
      marginBottom: pxToRem(20),
      fontSize: pxToRem(18),
      fontWeight: 500,
    },
  },
};

export const activatedModalConfig = {
  titleConfig: {
    showTitleIcon: true,
    formatMessage: {
      id: `${prefix}YourAccountHasBeenActivated`,
    },
    style: {
      color: color.sunlife_blue_medium,
      fontSize: pxToRem(28),
    },
  },
  contentConfig: {
    formatMessage: {
      id: `${prefix}YouCanNowSignInToYourAccount`,
    },
    style: {
      color: color.grey_dark,
      marginTop: pxToRem(40),
      marginBottom: pxToRem(20),
      fontSize: pxToRem(18),
      fontWeight: 500,
    },
  },
  width: 550,
  closeText: `${prefix}SignIn`,
};

export const expiredModalConfig = (titleIcon: React.ReactNode) => ({
  titleConfig: {
    showTitleIcon: true,
    titleIcon,
    formatMessage: {
      id: `${prefix}VerificationExpired`,
    },
    style: {
      color: color.sunlife_blue_medium,
      fontSize: pxToRem(28),
      fontWeight: 500,
      marginBottom: pxToRem(11),
    },
  },
  contentConfig: {
    formatMessage: {
      id: `${prefix}WeSuggestYouDo`,
    },
    style: {
      color: color.grey_dark,
      marginBottom: pxToRem(36),
      fontSize: pxToRem(16),
    },
  },
  width: 582,
  closeBtnWidth: 305,
  closeText: `${prefix}DoRetirementCalculator`,
});

/**
 * A minimum of eight (8) characters
 */
const AMinimumOfEight = /^.{8,}$/;

/**
 * One lowercase character (e.g. a-z)
 */
const OneLowercaseCharacter = /(?=.*?[a-z])/;

/**
 * One uppercase character (e.g. A-Z)
 */
const OneUppercaseCharacter = /(?=.*?[A-Z])/;

/**
 * One number (e.g. 0-9)
 */
const OneNumber = /(?=.*?[0-9])/;

/**
 * One of the following characters: !@#$%^&*( )+ -
 */
const OneOfTheFollowingCharacters = /(?=.*?[!@#$%^&*()+-])/;

export const passwordReg: Record<string, any> = {
  AMinimumOfEight,
  OneLowercaseCharacter,
  OneUppercaseCharacter,
  OneNumber,
  OneOfTheFollowingCharacters,
};
