import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

export interface ICurFormattedMessage {
  className?: string;
  id?: string;
  values?: any;
  tagName?: string;
  onClick?: any;
}

const CurFormattedMessage: FC<ICurFormattedMessage> = ({
  className,
  id,
  values,
  tagName = "div",
  onClick,
}) =>
  React.createElement(
    tagName,
    { className, onClick },
    <FormattedMessage id={id || " "} values={values} />
  );

export default CurFormattedMessage;
