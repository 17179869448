import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';

const Content = styled.div`
  font-size: ${pxToRem(16)};
`;

const prefix = 'PopModal.CashSaving.';

const CashSavingModal: FC<ModalProps> = ({ visible = false, onClose }) => (
  <Modal
    width={630}
    visible={visible}
    onClose={onClose}
    title={`${prefix}CashSavingsInTheBank`}
    textAlign="center"
  >
    <Content>
      <FormattedMessage id={`${prefix}ThisRefersToTheLiquid`} />
    </Content>
  </Modal>
);

export default CashSavingModal;
