import React, { FC } from "react";
import { Input } from "antd";
import { PasswordProps as AntPasswordProps } from "antd/es/input";
import styled from "styled-components";

import { pxToRem, enlargeFontsize } from "@rjp/common/utils";
import { reg } from "@rjp/common/config";
import { ReactComponent as EyeInvisible } from "@rjp/common/assets/images/eye-invisible.svg";
import { ReactComponent as EyeVisible } from "@rjp/common/assets/images/eye-visible.svg";

export interface PasswordProps extends Omit<AntPasswordProps, "onChange"> {
  title?: string;
  color?: string;
  subTitle?: string;
  name: string;
  width?: number;
  height?: number;
  marginTop?: number;
  hasError?: boolean;
  prefix?: string;
  suffix?: string;
  textAlign?: "left" | "center" | "right";
  min?: number;
  max?: number;
  className?: string;
  value?: any;
  onChange?: (name: string, value: any) => void;
  setError?: (name: string, value: boolean) => void;
  errorMsg?: string;
}

const StylePassword = styled(Input.Password)<PasswordProps>`
  &.ant-input-password,
  .ant-input-affix-wrapper {
    width: ${(props) => pxToRem(props.width ?? 300)};
    height: ${(props) => pxToRem(props.height ?? 44)};
    border-color: ${(props) =>
      props.hasError
        ? props.theme.color.extra_red_error
        : props.color || props.theme.borderColor} !important;
    background: ${(props) =>
      props.hasError
        ? props.theme.color.extra_invalid_input
        : props.theme.borderColor};
    box-shadow: none !important;

    .ant-input {
      background: ${(props) =>
        props.hasError
          ? props.theme.color.extra_invalid_input
          : props.theme.white};
    }
  }

  .ant-input-suffix {
    width: ${pxToRem(25)};
    svg {
      width: ${pxToRem(25)};
      height: ${pxToRem(25)};

      path {
        fill: ${(prop) => prop.theme.color.sunlife_blue_medium};
      }
    }
  }
  &.ant-input,
  .ant-input-password,
  .ant-input-affix-wrapper {
    width: ${(props) => pxToRem(props.width ?? 300)};
    height: ${(props) => pxToRem(props.height ?? 44)};
    line-height: ${(props) => pxToRem(props.height ?? 44)};
    font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
    border-color: ${(props) =>
      props.hasError
        ? props.theme.color.extra_red_error
        : props.color || props.theme.borderColor};
    background: ${(props) =>
      props.hasError
        ? props.theme.color.extra_invalid_input
        : props.theme.borderColor};
    box-shadow: none !important;

    & .ant-input-input-wrap > input,
    .ant-input-password-input-wrap > input {
      height: ${(props) => pxToRem(props.height ?? 44)};
      line-height: ${(props) => pxToRem(props.height ?? 44)};
      text-align: ${(props) => props.textAlign || "center"};
    }
    &:hover {
      border-color: ${(props) =>
        props.color || props.theme.color.sunlife_yellow};
    }
  }
  .ant-input-handler-wrap,
  .ant-input-password-handler-wrap {
    display: none;
  }
  &.ant-input-focused,
  .ant-input-password-focused {
    border-color: ${(props) => props.color || props.theme.color.sunlife_yellow};
    box-shadow: none;
  }
`;

const Password: FC<PasswordProps> = ({
  name,
  className,
  marginTop,
  value,
  setError,
  onChange,
  onBlur,
  ...props
}) => {
  const change = (e: any) => onChange?.(name, e?.target?.value || "");

  const blur = (e: any) => {
    const currentValue = e?.target?.value || "";
    if (currentValue === "") {
      setError?.(name, false);
    } else {
      setError?.(name, !reg.password.test(currentValue));
    }
    onBlur?.(e);
  };

  return (
    <StylePassword
      name={name}
      className={className}
      iconRender={(visible: boolean) =>
        visible ? <EyeVisible /> : <EyeInvisible />
      }
      onChange={change}
      onBlur={blur}
      {...props}
    />
  );
};

export default Password;
