import { formatLanguagePackage, LanguagesPackage } from '@rjp/common/utils';
import zhCommon from '@rjp/common/locale/zh';
import enCommon from '@rjp/common/locale/en';
import zh from './zh';
import en from './en';

const languagesPackage: LanguagesPackage = {
  en: formatLanguagePackage({ ...enCommon, ...en }),
  'zh-hant': formatLanguagePackage({ ...zhCommon, ...zh }),
  zh: formatLanguagePackage({ ...zhCommon, ...zh }),
};

export default languagesPackage;
