import React, { FC, useState } from "react";
import { reg } from "@rjp/common/config";
// eslint-disable-next-line import/no-cycle
import StyleInput from "./StyledInput";
import { InputProps } from "./config";

const PhoneNumber: FC<InputProps> = ({
  name = "",
  value,
  setError,
  onChange,
  onBlur,
  ...props
}) => {
  const [currentPhone, setCurrentPhone] = useState(value);
  const change = (e: any) => {
    const currentValue = e?.target?.value.replace(reg.trimAll, "") || "";
    if (!Number.isNaN(Number(currentValue))) {
      onChange?.(name, currentValue);
      setCurrentPhone(currentValue);
    }
  };

  const blur = (e: any) => {
    const currentValue = e?.target?.value.replace(reg.trimAll, "") || "";
    if (currentValue === "") {
      setError?.(name, false);
    } else {
      setError?.(name, currentValue.length !== 8);
    }
    onBlur?.(e);
  };

  return (
    <StyleInput
      name={name}
      onChange={change}
      onBlur={blur}
      value={currentPhone}
      {...props}
    />
  );
};

export default PhoneNumber;
