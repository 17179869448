import start from './start.json';
import login from './login.json';
import resetPassword from './resetPassword.json';
import forgotPassword from './forgotPassword.json';
import errorMsg from './errorMsg.json';
import profile from './profile.json';
import product from './product.json';
import workSurface from './workSurface.json';
import popModal from './popModal.json';
import search from './search.json';
import errorTip from './errorTip.json';

const zh: { [s: string]: string | object } = {
  ...start,
  ...login,
  ...resetPassword,
  ...forgotPassword,
  ...errorMsg,
  ...profile,
  ...product,
  ...popModal,
  ...workSurface,
  ...search,
  ...errorTip,
};

export default zh;
