import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';
import { RootState } from '@rjp/main/src/app';

const Content = styled.div`
  font-size: ${pxToRem(16)};
`;

const Bold = styled.span`
  font-weight: 500;
`;

const prefix = 'PopModal.RetirementGap.';

const CashSavingModal: FC<ModalProps> = ({ visible = false, onClose }) => {
  const { language } = useSelector((state: RootState) => state.translation);
  const onClick = () => {
    window.open(`https://www.sunlife.com.hk/${language}/about-us/contact-us/`);
    onClose?.();
  };
  return (
    <Modal
      width={500}
      visible={visible}
      onClose={onClose}
      title={`${prefix}RetirementGapCalculation`}
      textAlign="center"
      footerConfig={[
        {
          message: `${prefix}NotNow`,
          width: 170,
          type: 'secondary',
          onClick: onClose,
        },
        { message: `${prefix}TalkToAdvisor`, width: 170, onClick },
      ]}
    >
      <Content>
        <FormattedMessage
          id={`${prefix}WhenTheCurrentAgeInput`}
          values={{ bold: (msg: string) => <Bold>{msg}</Bold> }}
        />
      </Content>
    </Modal>
  );
};

export default CashSavingModal;
