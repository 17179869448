import axios from 'axios';
import { IRetirementGapSummary } from '../features/rcData/type';
import sunlifeCaller from './sunlifeCaller';
import getRetirementGapCalculation from './retirementgap';

const { REACT_APP_SECRET_KEY, REACT_APP_USERS_DOMAIN } = process.env;

axios.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json';
  return config;
});

const getTokens = () =>
  axios.post(`${REACT_APP_USERS_DOMAIN}/users/api/tokens`, {
    key: REACT_APP_SECRET_KEY,
  });

const getCaseStore = (caseId: string, token: string) =>
  axios.get(`${REACT_APP_USERS_DOMAIN}/casestore/api/pastes/${caseId}`, {
    headers: { Authorization: `bearer ${token}` },
  });

const RCEnum: Record<string, string[]> = {
  client_id: ['client_id'],
  age: ['age'],
  gender: ['gender'],
  total_cash_savings: ['cashbalance'],
  other_investment_rc: ['investmentbalance'],
  other_investment_style_rc: ['investmentstrategy'],
  marital_status: ['maritalstatus'],
  retirement_monthly_income: ['monthlyexpense'],
  monthly_income: ['monthlyincome'],
  total_mpf_savings: ['mpfbalance'],
  mpf_investment_mix: ['mpfstrategy'],
  monthly_mpf_savings: ['mpfvoluntarycontributionamount'],
  post_retirement_investment_mix: ['postretirementinvestmentstrategy'],
  retirement_age: ['decisionretirementage', 'retirementage'],
  retirement_monthly_income_style: [
    'decisionretirementlifestyle',
    'retirementlifestyle',
  ],
  monthly_cash_savings: ['decisionadditionalsaving', 'additionalsaving'],
  smoking_status: ['smokingstatus'],
};

const formatRetirementPayload = (paste_data: any) => {
  const retirementProfile: Record<string, any> = Object.fromEntries(
    Object.entries(RCEnum)
      .map(([rjpKey, rcKeyList]) => [
        rjpKey,
        rcKeyList.reduce(
          (value, rcKey) => value ?? paste_data[rcKey],
          undefined
        ),
      ])
      .filter(
        ([, value]) => value !== '' && value !== undefined && value !== null
      )
  );

  const {
    cashbalance,
    decisioncashforinvestment,
    mpfbalance,
    investmentbalance,
  } = paste_data;

  if (decisioncashforinvestment || decisioncashforinvestment === 0) {
    if (mpfbalance === 0 && investmentbalance === 0) {
      retirementProfile.other_investment_rc =
        cashbalance * decisioncashforinvestment;
    } else {
      const totalBalance = mpfbalance + investmentbalance;
      retirementProfile.other_investment_rc = Math.round(
        investmentbalance +
          ((investmentbalance * decisioncashforinvestment) / totalBalance) *
            cashbalance
      );
      retirementProfile.total_mpf_savings = Math.round(
        mpfbalance +
          ((mpfbalance * decisioncashforinvestment) / totalBalance) *
            cashbalance
      );
    }
  }

  return retirementProfile;
};

export const updateRetirementProfiles = async (
  retirement_profile: Record<string, any>
) => {
  const data = JSON.parse(JSON.stringify(retirement_profile));
  return sunlifeCaller<{ retirement_gap_summary: IRetirementGapSummary }>(
    {
      method: 'post',
      url: '/retirementgapcalculation',
      data,
    },
    2
  );
};

export const updateRCProfiles = async (
  retirement_profile: Record<string, any>
) => {
  const updateRCData = await updateRetirementProfiles(retirement_profile);
  if (updateRCData) {
    return updateRCData;
  }
  return undefined;
};

interface CurRCDataState {
  RCPasteId: string;
  retirementProfile?: Record<string, any>;
  rcRetirementProfile?: Record<string, any>;
  retirementGapSummary?: IRetirementGapSummary;
}

type UseCaseIdGetRCData = (
  id: string,
  isLoggedIn: boolean
) => Promise<CurRCDataState | undefined>;

const useCaseIdGetRCData: UseCaseIdGetRCData = async (
  id: string,
  isLoggedIn: boolean
) => {
  try {
    const tokenData = await getTokens();
    const caseData = await getCaseStore(id, tokenData.data.token);
    const { answer, skipDecisionGame } = caseData.data.paste_data;
    const retirementProfile = formatRetirementPayload({
      ...answer,
      skipDecisionGame,
    });
    const curRCDataState: CurRCDataState = {
      RCPasteId: id,
      rcRetirementProfile: retirementProfile,
    };
    if (!isLoggedIn) {
      curRCDataState.rcRetirementProfile = {
        mpf_investment_mix: 'Conservative',
        monthly_cash_savings: 0,
        monthly_mpf_savings: 0,
        other_investment: [],
        future_payments: [],
        quotes: [],
        ...curRCDataState.rcRetirementProfile,
      };
      const responseRGData = await getRetirementGapCalculation({
        retirement_profile: curRCDataState.rcRetirementProfile,
      });
      if (responseRGData) {
        curRCDataState.retirementGapSummary =
          responseRGData.retirement_gap_summary;
      }
    }
    return curRCDataState;
  } catch (err) {
    console.error(err);
  }
  return undefined;
};

export default useCaseIdGetRCData;
