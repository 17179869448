import React, { FC } from "react";
import {
  Modal,
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
} from "@rjp/common/component";
import styled from "styled-components";
import { pxToRem } from "@rjp/common/utils";

const prefix = "WorkSurface.";

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

interface ISignOutModel {
  visible?: boolean;
  onClose: () => void;
  signOut?: () => any;
}

const StyledSignOutModel = styled(Modal)`
  .ant-modal-body {
    padding-top: ${pxToRem(37)};
    padding-bottom: ${pxToRem(37)};
  }
  .ant-modal-footer {
    padding-top: 0;
  }
  &.no-bottom {
    .ant-modal-footer {
      padding: 0;
    }
  }
`;
const ModelTitle = styled(CurFormattedMessage)`
  height: ${pxToRem(50)};
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(24)};
  text-align: center;
  font-weight: 500;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ModelContent = styled(CurFormattedMessage)`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(21)};
  text-align: center;
  width: ${pxToRem(300)};
  margin: 0 auto;
  margin-top: ${pxToRem(7)};
  padding: ${pxToRem(8)} 0;
  color: ${(props) => props.theme.color.grey_dark};
`;

export const SignOutModel: FC<ISignOutModel> = ({
  visible,
  onClose,
  signOut,
}) => (
  <StyledSignOutModel
    width={434}
    visible={visible}
    onClose={onClose}
    footerConfig={[
      {
        width: 170,
        type: "secondary",
        message: `${prefix}SignOut.Cancel`,
        onClick: onClose,
      },
      {
        width: 170,
        type: "primary",
        message: `${prefix}SignOut.SignOut`,
        style: { marginLeft: pxToRem(20) },
        onClick: signOut,
      },
    ]}
  >
    <ModelTitle id="SignOut.SignOut" />
    <ModelContent id="SignOut.AreYouSure" />
  </StyledSignOutModel>
);

export const WaitSignOutModal: FC<ISignOutModel> = ({ visible, onClose }) => (
  <StyledSignOutModel
    width={434}
    onClose={onClose}
    visible={visible}
    className="no-bottom"
    footerConfig={[]}
  >
    <ModelTitle id="SignOut.SignOut" />
    <ModelContent id="SignOut.PleaseWait" />
  </StyledSignOutModel>
);
