import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { pxToRem, enlargeFontsize, toLogin } from '@rjp/common/utils';
import { Button } from '@rjp/common/component';
import startBottomImage from '@rjp/common/assets/images/start-bottom.png';
import { trackingFunc, RJP_VIEW, RJP_LINK } from '@/utils/tracking';

const FormattedMsg: FC<{ id: string; values?: any }> = ({ id, values }) => (
  <FormattedMessage id={`Login.AccountActivated.${id}`} values={values} />
);

const AccountActivatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 24)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 31)};
  text-align: center;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  margin-top: ${pxToRem(90)};
  margin-bottom: ${pxToRem(65)};
`;

const SubTitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 24)};
  text-align: center;
  margin-bottom: ${pxToRem(65)};
`;

const BottomImage = styled.img`
  height: ${pxToRem(331)};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const AccountActivated: FC = () => {
  const history = useHistory();

  return (
    <AccountActivatedWrapper>
      <TitleDiv>
        <FormattedMsg id="Title" />
      </TitleDiv>
      <SubTitleDiv>
        <FormattedMsg id="SubTitle" />
      </SubTitleDiv>
      <Button
        width={180}
        type="primary-yellow"
        onClick={toLogin('member', history)}
      >
        <FormattedMsg id="SignIn" />
      </Button>
      <BottomImage src={startBottomImage} />
    </AccountActivatedWrapper>
  );
};
export default AccountActivated;
