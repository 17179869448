import React, { FC, useState } from "react";
import { reg } from "@rjp/common/config";
import StyleInput from "./StyledInput";
import { IInputProps } from "./Input";

const PhoneNumber: FC<IInputProps> = ({
  name = "",
  countryCode = "",
  value,
  setError,
  onChange,
  ...props
}) => {
  const { hkTelephone, mobile, telephone, trimAll } = reg;
  const [currentPhone, setCurrentPhone] = useState("");
  const change = (e: any) => {
    const currentValue = e?.target?.value.replace(trimAll, "") || "";
    if (currentValue === "") {
      setError?.(name, false);
    } else {
      setError?.(
        name,
        countryCode === "852"
          ? !hkTelephone.test(currentValue)
          : !(mobile.test(currentValue) || telephone.test(currentValue))
      );
    }
    onChange?.(name, currentValue);
    const phone = currentValue
      .replace(trimAll, "")
      .replace(/(\d{4})(\d{1,4})/, "$1 $2 ")
      .trimEnd();
    setCurrentPhone(phone);
  };

  return (
    <StyleInput
      name={name}
      onChange={change}
      value={currentPhone}
      maxLength={countryCode === "852" ? 9 : 14}
      {...props}
    />
  );
};

export default PhoneNumber;
