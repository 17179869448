import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IContactAdvisor {
  showContactAdvisorModal: boolean;
  activeContactAdvisorModalConfig: string;
  isRequestedAdvisor: boolean;
}

const initialState: IContactAdvisor = {
  showContactAdvisorModal: false,
  activeContactAdvisorModalConfig: 'loading',
  isRequestedAdvisor: false,
};

const contactAdvisor = createSlice({
  name: 'contactAdvisor',
  initialState,
  reducers: {
    setShowContactAdvisorModal(
      state,
      action: PayloadAction<IContactAdvisor['showContactAdvisorModal']>
    ) {
      state.showContactAdvisorModal = action.payload;
    },
    setActiveContactAdvisorModalConfig(
      state,
      action: PayloadAction<IContactAdvisor['activeContactAdvisorModalConfig']>
    ) {
      state.activeContactAdvisorModalConfig = action.payload;
    },
    setIsRequestedAdvisor(
      state,
      action: PayloadAction<IContactAdvisor['isRequestedAdvisor']>
    ) {
      state.isRequestedAdvisor = action.payload;
    },
  },
});

export const {
  setShowContactAdvisorModal,
  setActiveContactAdvisorModalConfig,
  setIsRequestedAdvisor,
} = contactAdvisor.actions;

export default contactAdvisor.reducer;
