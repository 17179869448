import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { ReactComponent as SearchResultRadio } from '@rjp/common/assets/images/searchResultRadio.svg';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Modal,
  Button,
} from '@rjp/common/component';
import { ReactComponent as SortArrow } from '@rjp/common/assets/images/sort-arrow.svg';
import dayjs from 'dayjs';

import { IMember } from '../../api';
import { RJP_LINK, trackingFunc } from '@/utils/tracking';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`Search.SearchResult.${id}`} />
);

interface ISearchResultModal {
  onClose: () => void;
  onView: (selectedID: string) => void;
  visible?: boolean;
  searchData: (IMember & {
    name: string;
    roleName: string;
    date_added_formatted: string;
    mobile_formatted: string;
    consent_expiry_date_formatted: string;
  })[];
}

const SearchResultModalWrapper = styled(Modal)`
  .ant-modal-content {
    background: ${(props) => props.theme.color.white};
    padding: ${pxToRem(47)} ${pxToRem(44)} ${pxToRem(29)} ${pxToRem(44)};
  }
  .ant-modal-body {
    > div {
      max-height: ${pxToRem(700)};
    }
  }
`;

const CurSortArrow = styled(({ onClick, className }) => (
  <SortArrow className={className} onClick={onClick} />
))<{ sort: boolean; order: number }>`
  width: ${pxToRem(15)};
  height: ${pxToRem(9)};
  cursor: pointer;
  transition: all 0.5s;
  transform: rotate(
    ${(props) => (props.sort && props.order === 2 ? 180 : 0)}deg
  );
  path {
    fill: ${(props) =>
      props.sort && props.order > 0
        ? props.theme.color.sunlife_blue_medium
        : props.theme.color.white};
  }
`;

const Title = styled(CurFormattedMessage)`
  font-weight: 500;
  font-size: ${pxToRem(28)};
  height: ${pxToRem(38)};
  line-height: ${pxToRem(38)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Subtitle = styled(CurFormattedMessage)`
  font-size: ${pxToRem(18)};
  margin-top: ${pxToRem(10)};
  line-height: ${pxToRem(30)};
  height: ${pxToRem(30)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const TableWrapper = styled.div`
  margin-top: ${pxToRem(6)};
  width: 100%;
  height: ${pxToRem(310)};
  background: ${(props) => props.theme.color.grey_light_20};
  overflow-x: auto;
`;

const TableHeader = styled.div`
  width: ${pxToRem(1006)};
  height: ${pxToRem(50)};
  background: ${(props) => props.theme.color.sunlife_blue_light};
  color: ${(props) => props.theme.color.white};
  display: flex;
  align-items: center;
  font-size: ${pxToRem(16)};
  padding-left: ${pxToRem(56)};
`;
const TableItemWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const TableItem = styled(CurFormattedMessage)<{
  width: number;
  needSort: boolean;
}>`
  width: ${(props) => pxToRem(props.width)};
  user-select: none;
  cursor: ${(props) => (props.needSort ? 'pointer' : 'auto')};
`;

const TableContent = styled.div`
  width: ${pxToRem(1006)};
`;

const TableContentItem = styled.div<{ width: number }>`
  width: ${(props) => pxToRem(props.width)};
  padding-right: 0.1rem;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableContentLine = styled.div<{ expiry: boolean }>`
  height: ${pxToRem(51)};
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: ${pxToRem(14)};
  color: ${(props) =>
    props.expiry
      ? props.theme.color.grey_medium
      : props.theme.color.sunlife_blue};
`;

const Radio = styled(({ onClick, className }) => (
  <SearchResultRadio className={className} onClick={onClick} />
))<{ select: boolean; expiry: boolean }>`
  width: ${pxToRem(24)};
  cursor: pointer;
  margin-left: ${pxToRem(20)};
  margin-right: ${pxToRem(12)};
  .selected-icon {
    transition: all 0.3s;
    opacity: ${(props) => (props.select ? 1 : 0)};
  }
  ${(props) => (props.expiry ? 'visibility: hidden;cursor: auto;' : '')}
`;

const PageNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${pxToRem(25)};
`;

const PageNumberItem = styled.div<{ active?: boolean }>`
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  line-height: ${pxToRem(30)};
  text-align: center;
  font-weight: 500;
  font-size: ${pxToRem(18)};
  color: ${(props) =>
    props.active ? props.theme.color.white : props.theme.color.coolblue};
  background: ${(props) =>
    props.active ? props.theme.color.sunlife_blue_light : 'none'};
  cursor: pointer;
  margin-right: ${pxToRem(18)};
  transition: all 0.3s;
  &:last-child {
    margin-right: 0;
  }
`;

const BottomBtnWrapper = styled.div`
  display: flex;
  margin-top: ${pxToRem(35)};
  justify-content: space-between;
  width: ${pxToRem(386)};
`;

const tableHeaderArr = [
  { key: 'name', width: 159, id: 'Name', needSort: true },
  { key: 'mobile_formatted', width: 145, id: 'MobileNo', needSort: false },
  { key: 'email', width: 224, id: 'Email', needSort: false },
  { key: 'date_added_formatted', width: 141, id: 'DateAdded', needSort: true },
  { key: 'age', width: 63, id: 'Age', needSort: false },
  { key: 'roleName', width: 80, id: 'Account', needSort: false },
  {
    key: 'consent_expiry_date_formatted',
    width: 120,
    id: 'ValidUntil',
    needSort: true,
  },
] as const;

const rowCount = 5;

const SearchResultModal: FC<ISearchResultModal> = ({
  onClose,
  onView,
  visible,
  searchData,
}) => {
  const [selectedID, setSelectedID] = useState<string>('');
  const [activeSort, setActiveSort] = useState<string>('date_added_formatted');
  const [sortOrder, setSortOrderOrder] = useState<number>(1);
  const [page, setPage] = useState<number>(0);

  const changeSort = (key: string) => {
    setSortOrderOrder((val) => {
      const order = key !== activeSort ? 1 : val + 1;
      setActiveSort(key);
      return order > 2 ? 0 : order;
    });
  };

  useEffect(() => {
    if (searchData.length === 1) {
      setSelectedID(searchData[0].id);
    }
  }, [searchData]);

  const resetState = () => {
    setSortOrderOrder(1);
    setActiveSort('date_added_formatted');
    setSelectedID('');
    setPage(0);
  };

  const curSearchData = useMemo(() => {
    const searchDataArr = [...searchData];
    if (sortOrder > 0) {
      const inverted = sortOrder === 1;
      if (activeSort === 'name') {
        searchDataArr.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return inverted ? 1 : -1;
          if (nameA > nameB) return inverted ? -1 : 1;
          return 0;
        });
      }
      if (
        activeSort === 'date_added_formatted' ||
        activeSort === 'consent_expiry_date_formatted'
      ) {
        searchDataArr.sort((a, b) => {
          const aTimestamp =
            activeSort === 'date_added_formatted'
              ? +dayjs(a.date_added)
              : +dayjs(a.consent_expiry_date || undefined);

          const bTimestamp =
            activeSort === 'date_added_formatted'
              ? +dayjs(b.date_added)
              : +dayjs(b.consent_expiry_date || undefined);

          return inverted ? aTimestamp - bTimestamp : bTimestamp - aTimestamp;
        });
      }
    }
    return searchDataArr;
  }, [searchData, activeSort, sortOrder]);

  return (
    <SearchResultModalWrapper
      width={1072}
      visible={visible}
      afterClose={resetState}
      customize
    >
      <Title id="ProfileSearchResults" />
      <Subtitle
        id={
          searchData.length === 1 ? 'OneMatchingProfileFound' : 'ProfilesFound'
        }
        values={{
          count: searchData.length,
        }}
      />
      <TableWrapper>
        <TableHeader>
          {tableHeaderArr.map((item) => (
            <TableItemWrap>
              <TableItem
                {...item}
                needSort={item.needSort && searchData.length > 1}
                onClick={() =>
                  item.needSort && searchData.length > 1
                    ? changeSort(item.key)
                    : () => {}
                }
                values={{
                  svg:
                    item.needSort && searchData.length > 1 ? (
                      <CurSortArrow
                        sort={activeSort === item.key}
                        order={sortOrder}
                      />
                    ) : (
                      ''
                    ),
                }}
              />
            </TableItemWrap>
          ))}
        </TableHeader>
        <TableContent>
          {curSearchData.map((data, index) => {
            const expiry =
              +dayjs(data.consent_expiry_date || undefined) < +dayjs();
            return (
              index >= page * rowCount &&
              index < page * rowCount + rowCount && (
                <TableContentLine
                  key={`${data.id}${data.name}${index + 1}`}
                  expiry={expiry}
                >
                  <Radio
                    select={data.id === selectedID}
                    expiry={expiry}
                    onClick={!expiry ? () => setSelectedID(data.id) : () => {}}
                  />
                  {tableHeaderArr.map((item) => (
                    <TableContentItem key={item.key} width={item.width}>
                      {expiry &&
                      item.key === 'consent_expiry_date_formatted' ? (
                        <CurFormattedMessage id="Expired" />
                      ) : (
                        data[item.key]
                      )}
                    </TableContentItem>
                  ))}
                </TableContentLine>
              )
            );
          })}
        </TableContent>
      </TableWrapper>
      {searchData.length > rowCount && (
        <PageNumberWrapper>
          {Array(Math.ceil(searchData.length / 5))
            .fill('')
            .map((item, index) => (
              <PageNumberItem
                active={index === page}
                onClick={() => setPage(index)}
                key={`${index + 2}`}
              >
                {index + 1}
              </PageNumberItem>
            ))}
        </PageNumberWrapper>
      )}
      <BottomBtnWrapper>
        <Button type="secondary" width={188} onClick={onClose}>
          <CurFormattedMessage id="Close" />
        </Button>
        <Button
          type={selectedID === '' ? 'disabled' : undefined}
          width={188}
          onClick={() => {onView(selectedID); trackingFunc(RJP_LINK, 'other', 'profile viewed')}}
        >
          <CurFormattedMessage id="View" />
        </Button>
      </BottomBtnWrapper>
    </SearchResultModalWrapper>
  );
};

export default SearchResultModal;
