import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { InfoModal } from '@rjp/common/component';

const prefix = 'PopModal.LastMinute.';

interface ILastMinute {
  visible?: boolean;
  onClose: () => void;
  seconds: number;
}

const CurInfoModal = styled(InfoModal)`
  .ant-modal-body {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
  }
`;

const Bold = styled.span`
  font-size: ${pxToRem(28)};
  font-weight: bold;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const LastMinute: FC<ILastMinute> = ({ visible, onClose, seconds }) => (
  <CurInfoModal
    width={436}
    onClose={onClose}
    closeText={`${prefix}Continue`}
    visible={visible}
    contentConfig={{
      formatMessage: {
        id: `${prefix}ThisSessionIsAbout`,
        values: {
          value: <Bold>{seconds}</Bold>,
        },
      },
    }}
  />
);

export default LastMinute;
