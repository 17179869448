import sunlifeCaller from './sunlifeCaller';
import {
  ICaptchaCheckRequest,
  ICaptchaCheckResponse,
  ICaptchaResponse,
} from './captcha.type';

export const generate = async () =>
  sunlifeCaller<ICaptchaResponse>({
    method: 'post',
    url: '/captcha/generate',
  });

export const verify = async (data: ICaptchaCheckRequest) =>
  sunlifeCaller<ICaptchaCheckResponse>({
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    url: '/captcha/verify',
  });
