import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";

import { pxToRem, enlargeFontsize, getName } from "@rjp/common/utils";
import { RootState } from "@rjp/main/src/app";
import { ReactComponent as WorkSurfaceHeaderSvg } from "@rjp/common/assets/images/workSurfaceHeader.svg";
import { ReactComponent as PhoneSvg } from "@rjp/common/assets/images/phone.svg";

const Header = styled.div`
  width: 100%;
  height: ${pxToRem(70)};
  background-color: ${(props) => props.theme.color.sunlife_blue_medium};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  // width: 50%;
  padding-left: ${pxToRem(30)};
`;

const NameDiv = styled.div`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 30)};
  color: ${(props) => props.theme.color.white};
  margin-left: ${pxToRem(17)};
  .name {
    font-weight: bold;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRightSubDiv = styled.div<{ width: number; active?: boolean }>`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  font-weight: 500;
  text-align: center;
  color: ${(props) =>
    props.active
      ? props.theme.color.sunlife_yellow_medium
      : props.theme.color.cool_blue_light};
  width: ${(props) => pxToRem(props.width)};
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    background: ${(props) => props.theme.color.sunlife_blue_medium_40};
    top: ${pxToRem(-10)};
    bottom: ${pxToRem(-10)};
    left: 0;
    margin: auto 0;
  }
`;

const ContactAdvisorIcon = styled(PhoneSvg)`
  width: ${pxToRem(24)};
  margin-left: ${pxToRem(10)};
`;

const WorkSurfaceHeaderImg = styled(WorkSurfaceHeaderSvg)`
  width: ${pxToRem(34)};
  height: ${pxToRem(34)};
`;

const Italic = styled.span`
  font-style: italic;
`;

const RightSignOut = styled.div`
  font-size: ${pxToRem(16)};
  height: ${pxToRem(30)};
  line-height: ${pxToRem(30)};
  width: ${pxToRem(115)};
  text-align: center;
  margin-right: ${pxToRem(7)};
  font-weight: 500;
  color: ${(props) => props.theme.color.cool_blue_light};
  cursor: pointer;
`;

interface WorkSurfaceHeaderProps
  extends Pick<RouteComponentProps<{ role?: string }>, "history" | "match"> {
  onClickProfile?: () => void;
  onClickSignOut?: () => void;
  onClickContactAdvisor?: () => void;
  inAdvisor?: boolean;
}

const WorkSurfaceHeader: FC<WorkSurfaceHeaderProps> = ({
  onClickProfile,
  onClickSignOut,
  onClickContactAdvisor,
  inAdvisor,
}) => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const data = [
    {
      title: "Common.WorkSurfaceHeader.Profile",
      width: 100,
      action: onClickProfile,
      key: "profile",
    },
    {
      title: "Common.WorkSurfaceHeader.SignOut",
      width: 116,
      action: onClickSignOut,
      key: "none",
    },
    {
      title: "Common.WorkSurfaceHeader.ContactAdvisor",
      width: 262,
      icon: <ContactAdvisorIcon />,
      action: onClickContactAdvisor,
      key: "none",
    },
  ];

  const curName = useMemo(() => {
    if (language === "zh-hant" && userInfo.name_chinese) {
      return userInfo.name_chinese;
    }
    return userInfo.first_name && userInfo.last_name
      ? `${userInfo.first_name?.trim()} ${userInfo.last_name?.trim()}`
      : "";
  }, [language, userInfo]);

  return (
    <Header>
      <HeaderLeft>
        <WorkSurfaceHeaderImg />
        <NameDiv>
          <Italic>
            <FormattedMessage id="Common.WorkSurfaceHeader.Welcome" />
          </Italic>
          {curName && (
            <>
              ,<span> </span>
              <span className="name">{getName(curName)}</span>
            </>
          )}
        </NameDiv>
      </HeaderLeft>
      {inAdvisor ? (
        <RightSignOut onClick={onClickSignOut}>
          <FormattedMessage id="Common.WorkSurfaceHeader.SignOut" />
        </RightSignOut>
      ) : (
        <HeaderRight>
          {data.map((d) => (
            <HeaderRightSubDiv
              width={d.width}
              key={d.title}
              onClick={() => d.action?.()}
              active={window.location.pathname.indexOf(d.key) > -1}
            >
              <FormattedMessage id={d.title} />
              {d.icon}
            </HeaderRightSubDiv>
          ))}
        </HeaderRight>
      )}
    </Header>
  );
};

export default WorkSurfaceHeader;
