import React, { FC } from "react";
import styled, { css } from "styled-components";
import { pxToRem } from "@rjp/common/utils";
import { FormattedMessage } from "react-intl";
import Button from "@rjp/common/component/button";
import { useSelector } from "react-redux";
import { RootState } from "@rjp/main/src/app";
import BikePng from "@rjp/common/assets/images/bike.png";

const primary = css`
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  width: ${pxToRem(472)};
  height: ${pxToRem(654)};
  border-radius: ${pxToRem(5)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  padding: ${pxToRem(28)} ${pxToRem(28)} ${pxToRem(0)};
  background-image: url(${BikePng});
  background-repeat: no-repeat;
  background-size: ${pxToRem(389 / 2.2)} ${pxToRem(301 / 2.2)};
  background-position: bottom 0px right ${pxToRem(26)};
`;

const TitleDiv = styled.div`
  font-size: ${pxToRem(28)};
  font-weight: medium;
  margin-bottom: ${pxToRem(28)};
  white-space: pre-wrap;
  line-height: ${pxToRem(34)};
`;

const DescContainer = styled.div`
  display: flex;
  margin-bottom: ${(props) => pxToRem(props.theme.language === "en" ? 15 : 50)};
`;

const DescIndexDiv = styled.div`
  background-color: white;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  line-height: ${pxToRem(40)};
  border-radius: ${pxToRem(20)};
  text-align: center;
  font-weight: bold;
  font-size: ${pxToRem(28)};
  margin-right: ${pxToRem(17)};
`;

const NormalDesc = styled.div`
  font-size: ${pxToRem(16)};
  font-weight: normal;
  white-space: pre-wrap;
  line-height: ${pxToRem(22)};
  font-weight: medium;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  flex: 1;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Highlight = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const SideCardComponent: FC<{ className?: string }> = ({ className }) => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );

  const descArr = [
    {
      index: 1,
      descId: "Common.Start.SideCard.desc1",
      buttonTitleId: "Common.Start.SideCard.buttonTitle",
      action: () => {
        const retirementLanguage: { [key: string]: string } = {
          en: "en",
          "zh-hant": "zh-tw",
        };
        window.open(
          `${process.env.REACT_APP_RETIREMENT_CALCULATOR_URL}/${retirementLanguage[language]}`
        );
      },
    },
    {
      index: 2,
      descId: "Common.Start.SideCard.desc2",
    },
    {
      index: 3,
      descId: "Common.Start.SideCard.desc3",
      highlightAction: () => {
        const allowJump = ["zh-hant", "en"].indexOf(language) !== -1;
        if (allowJump) {
          window.open(
            language === "en"
              ? "https://www.sunlife.com.hk/en/about-us/contact-us/"
              : "https://www.sunlife.com.hk/zh-hant/about-us/contact-us/"
          );
        }
      },
    },
  ];

  return (
    <div className={className}>
      <TitleDiv>
        <FormattedMessage id="Common.Start.SideCard.title" />
      </TitleDiv>
      {descArr.map((data) => (
        <DescContainer key={data.index}>
          <DescIndexDiv>{data.index}</DescIndexDiv>
          <NormalDesc>
            <FormattedMessage
              id={data.descId}
              values={{
                bold: (msg: string) => <Bold>{msg}</Bold>,
                highlight: (msg: string) => (
                  <Highlight onClick={() => data.highlightAction?.()}>
                    {msg}
                  </Highlight>
                ),
              }}
            />
            {data.action && (
              <Button
                type="primary"
                style={{ width: "90%", marginTop: "10px" }}
                onClick={data.action}
              >
                <FormattedMessage id={data.buttonTitleId} />
              </Button>
            )}
          </NormalDesc>
        </DescContainer>
      ))}
    </div>
  );
};

const SideCard = styled(SideCardComponent)`
  ${primary}
`;

export default SideCard;
