import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAPIConfig } from '@rjp/main/src/app/store';
import useCaseIdGetRCData, { updateRCProfiles } from '../../api/rcApiFetch';
import { RCDataState } from './type';

const initialState: RCDataState = {
  RCPasteId: '',
  retirementGapSummary: {
    retirement_age: 0,
    life_expectancy: 0,
    retirement_years: 0,
    years_to_retirement: 0,
    present_retirement_projected_savings: 0,
    present_retirement_goal: 0,
    present_projected_monthly_retirement_income: 0,
    present_retirement_gap: 0,
    future_retirement_projected_savings: 0,
    future_retirement_goal: 0,
    future_projected_monthly_retirement_income: 0,
    future_retirement_gap: 0,
    retirement_shortfall_percentage: 0,
    projected_savings_percentage: 0,
  },
  includedQuote: [],
  addedQuotes: [],
};

type IApiActionGeneratorResponse = Pick<
  RCDataState,
  'retirementGapSummary' | 'rcRetirementProfile' | 'RCPasteId'
>;

const apiActionGenerator = (name: string) =>
  createAsyncThunk<
    IApiActionGeneratorResponse,
    {
      caseId: string;
      isLoggedIn: boolean;
    },
    ThunkAPIConfig
  >(
    `RCData/${name}`,
    async ({ caseId, isLoggedIn }, { rejectWithValue, getState }) => {
      try {
        const data =
          (await useCaseIdGetRCData(caseId, isLoggedIn)) || initialState;
        const { RCData } = getState();
        const curRCData: IApiActionGeneratorResponse = {
          retirementGapSummary:
            data.retirementGapSummary || RCData.retirementGapSummary,
          rcRetirementProfile: {
            ...RCData.rcRetirementProfile,
            ...data.rcRetirementProfile,
          },
          RCPasteId: data.RCPasteId,
        };
        return curRCData;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  );

const apiActionUpdateRcData = (name: string) =>
  createAsyncThunk<
    RCDataState['retirementProfile'],
    Record<string, any>,
    ThunkAPIConfig
  >(`RCData/${name}`, async (rcProfile, { dispatch }) => {
    try {
      const data =
        (await updateRCProfiles(rcProfile)) || initialState.retirementProfile;
      return data;
    } catch (err) {
      return err;
      // return rejectWithValue(err);
    }
  });

export const RCDataApiCall = apiActionGenerator('RetirementCall');
export const UpdateRCDataApiCall = apiActionUpdateRcData(
  'UpdateRetirementCall'
);

const RCData = createSlice({
  name: 'RCData',
  initialState,
  reducers: {
    changeRCPasteId(state, action: PayloadAction<string>) {
      state.RCPasteId = action.payload;
    },
    updateRetirementProfile(state, action: PayloadAction<Record<string, any>>) {
      state.retirementProfile = {
        ...state.retirementProfile,
        ...action.payload,
      };
    },
    setRetirementGapSummary(
      state,
      action: PayloadAction<RCDataState['retirementGapSummary']>
    ) {
      state.retirementGapSummary = action.payload;
    },
    setIncludedQuote(
      state,
      action: PayloadAction<RCDataState['includedQuote']>
    ) {
      state.includedQuote = action.payload;
    },
    setAddedQuotes(state, action: PayloadAction<RCDataState['addedQuotes']>) {
      state.addedQuotes = action.payload;
    },
    resetRetirementData() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      RCDataApiCall.fulfilled,
      (state, action: PayloadAction<IApiActionGeneratorResponse>) => ({
        ...state,
        ...action.payload,
      })
    );
    builder.addCase(
      UpdateRCDataApiCall.fulfilled,
      (state, action: PayloadAction<Record<string, any> | undefined>) => {
        state.retirementProfile = action.payload;
      }
    );
  },
});

export const {
  changeRCPasteId,
  updateRetirementProfile,
  setRetirementGapSummary,
  setIncludedQuote,
  setAddedQuotes,
  resetRetirementData,
} = RCData.actions;

export default RCData.reducer;
