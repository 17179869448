import React from "react";
import Button, { ButtonProps } from "../../component/button";

interface IMain {
  title?: string;
  onButtonClick?: ButtonProps["onClick"];
}

const Main: React.FC<IMain> = ({ title = "Main", onButtonClick }) => (
  <div>
    <h1>{title}</h1>
    <Button onClick={onButtonClick} type="primary">
      Main Button
    </Button>
  </div>
);

export default Main;
