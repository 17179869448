import { Input as AntInput } from "antd";
import styled from "styled-components";
import { pxToRem, enlargeFontsize } from "@rjp/common/utils";
import { InputProps } from "./config";
import { PasswordProps } from "./Password";

export interface IInputProps extends InputProps, PasswordProps {
  notCheck?: boolean;
}
const StyleInput = styled(AntInput)<IInputProps>`
  &.ant-input {
    width: ${(props) => pxToRem(props.width ?? 300)};
    height: ${(props) => pxToRem(props.height ?? 44)};
    line-height: ${(props) => pxToRem(props.height ?? 44)};
    font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.hasError
        ? props.theme.color.extra_red_error
        : props.color || props.theme.color.borderColor};
    background: ${(props) =>
      props.hasError
        ? props.theme.color.extra_invalid_input
        : props.theme.borderColor};
    box-shadow: none !important;
    appearance: none;

    & .ant-input-input-wrap > input {
      height: ${(props) => pxToRem(props.height ?? 44)};
      line-height: ${(props) => pxToRem(props.height ?? 44)};
      text-align: ${(props) => props.textAlign || "center"};
    }
    &:hover {
      border-color: ${(props) =>
        props.color || props.theme.color.sunlife_yellow};
    }
  }
  &.ant-input-handler-wrap {
    display: none;
  }
  &.ant-input-focused {
    border-color: ${(props) => props.color || props.theme.color.sunlife_yellow};
    box-shadow: none;
  }
`;
export default StyleInput;
