export const getStrLength = (str: any) => {
  let curStr = str;
  if (str == null) return 0;
  if (typeof str != "string") {
    curStr += "";
  }
  return curStr.replace(/[^\x00-\xff]/g, "01").length;
};

const getName = (str: any) => {
  const zhPattern = new RegExp("[\u4E00-\u9FA5]+");
  const length = getStrLength(str);
  if (zhPattern.test(str)) {
    if (length > 10) {
      return str.substring(0, 10) + "...";
    }
  } else {
    if (length > 15) {
      return str.substring(0, 15) + "...";
    }
  }
  return str;
};

export default getName;
