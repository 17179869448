import React, { FC, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Select as AntSelect } from "antd";

import styled from "styled-components";
import { pxToRem } from "@rjp/common/utils";
import ErrorMessage from "../errorMessage";
import { ErrorMessageProps } from "../errorMessage/ErrorMessage";

const { Option } = AntSelect;

export interface MenuOverplay {
  className?: string;
  width?: number;
  height?: number;
  name?: string;
  onChange?: (name: string, value: any) => void;
  value?: string;
  hasError?: boolean;
  errorMsg?: string;
  prefix?: string;
  option?: {
    label: string;
    value: string;
    noTranslate?: boolean;
  }[];
  optionValue?: Record<string, any>;
  suffixIcon?: JSX.Element;
  direction?: ErrorMessageProps["direction"];
  placeHolder?: string;
  showSearch?: boolean;
  showArrow?: boolean;
}

const StyleSelect = styled(AntSelect)<Pick<MenuOverplay, "width" | "height">>`
  &.ant-select,
  &.ant-select-selector,
  &.ant-select-focused,
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: ${(props) => pxToRem(props.width || 104)};
    height: ${(props) => pxToRem(props.height || 44)};
    line-height: ${(props) => pxToRem(props.height || 44)};
    font-size: ${pxToRem(16)};
    border-color: ${(props) => props.theme.color.grey_medium} !important;
    box-shadow: none !important;

    .ant-select-selection-item {
      font-weight: 400;
      line-height: ${(props) => pxToRem(props.height || 44)};
    }

    .ant-select-selection-search {
      input {
        height: ${(props) => pxToRem(props.height || 44)};
        line-height: ${(props) => pxToRem(props.height || 44)};
      }
    }
  }

  &.ant-select-item-option {
    font-size: ${pxToRem(16)};
  }

  &.ant-select-arrow {
    width: ${pxToRem(18)};
    svg {
      width: ${pxToRem(18)};
      height: ${pxToRem(10)};

      path {
        fill: ${(props) => props.theme.color.coolblue};
      }
    }
  }
`;

const Select: FC<MenuOverplay> = ({
  name = "",
  onChange,
  value = "",
  option = [],
  optionValue = [],
  suffixIcon,
  width,
  height,
  hasError,
  errorMsg,
  direction,
  showSearch,
  className,
  placeHolder,
  ...props
}) => {
  const [selectedIndex, setSelectedIndex] = useState(value);
  useEffect(() => {
    setSelectedIndex(value);
  }, [value]);
  const selectChange = (currentValue: any) => {
    setSelectedIndex(currentValue);
    if (onChange) onChange(name, currentValue);
  };
  const onSearch = (currentValue: any) => {
    if (currentValue) setSelectedIndex(currentValue);
  };
  const onBlur = () => {
    if (showSearch) {
      selectChange(selectedIndex);
    }
  };
  return (
    <>
      <StyleSelect
        className={className}
        width={width}
        height={height}
        showSearch={showSearch}
        onChange={(currentValue) => selectChange(currentValue)}
        value={selectedIndex}
        suffixIcon={suffixIcon}
        onSearch={onSearch}
        onBlur={onBlur}
        placeholder={placeHolder}
        {...props}
      >
        {option.map((item) => (
          <Option
            value={item.value}
            key={item.label}
            className={
              item.value === selectedIndex ? "select-option-selected" : ""
            }
          >
            {item.noTranslate ? (
              item.label
            ) : (
              <FormattedMessage
                id={item.label}
                values={{ value: optionValue[item.label] }}
              />
            )}
          </Option>
        ))}
      </StyleSelect>
      {hasError && <ErrorMessage errorMsg={errorMsg} direction={direction} />}
    </>
  );
};

export default Select;
