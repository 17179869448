import axios from 'axios';

// const formatCommitmentPayload = (objectData: Record<string, any>) => {
//   const parametersData: Record<string, any> = Object.fromEntries(
//     Object.entries(COMMEnum)
//       .map(([key, value]) => [value, objectData[key]])
//       .map(([key, value]) => (typeof value === 'number' ? [key, Math.round(value)] : [key, value]))
//       .filter(([, value]) => value !== undefined && value !== null)
//   );
//   return parametersData;
// };

export interface IProductFactoryInput {
  xInput: string;
  Value: string | number | boolean;
}

const formatProductFactoryData = (objectData: Record<string, any>) => {
  const formattedData: IProductFactoryInput[] = Object.entries(objectData)
    .filter(
      ([, Value]) => Value !== '' && Value !== undefined && Value !== null
    )
    .map(([xInput, Value]) => ({ xInput, Value }));
  return { Inputs: formattedData };
};

const productFactoryCaller = (engineName: string) => async (
  data: Record<string, any>
) => {
  const response = await axios.post<{ Outputs: Record<string, any> }>(
    `${process.env.REACT_APP_EXCELENGINE_DOMAIN}/api/ApiTester/RunRichJsonAPICalc/${engineName}`,
    formatProductFactoryData(data),
    { headers: { SecretKey: process.env.REACT_APP_SECRET_KEY } }
  );
  return response.data.Outputs;
};

const productfactoryAPI: Record<
  string,
  (data: Record<string, any>) => Promise<Record<string, any>>
> = {
  COMM: productFactoryCaller('Commitment_Illustration_v6'),
};

export default productfactoryAPI;
