import sunlifeCaller from './sunlifeCaller';
import { IQuote } from './type';
import { IGetQuotes, IQuoteIDs, IDeleteQuoteIDs } from './quote.type';

export const getAddedQuotes = async ({ customer_id }: IGetQuotes) =>
  process.env.REACT_APP_USE_POST_API_QUOTES_ADDED === 'true'
    ? sunlifeCaller<{ quotes: IQuote[] }>({
        method: 'post',
        url: `/quotes/added`,
        data: {
          customer_id,
        },
        params: { version: 1 },
      })
    : sunlifeCaller<{ quotes: IQuote[] }>({
        method: 'get',
        url: `/quotes/${customer_id}/addedquotes`,
        params: { version: 1 },
      });

export const getDraftQuotes = async ({ customer_id }: IGetQuotes) =>
  process.env.REACT_APP_USE_POST_API_QUOTES_DRAFTS === 'true'
    ? sunlifeCaller<{ quotes: IQuote[] }>({
        method: 'post',
        url: `/quotes/drafts`,
        data: {
          customer_id,
        },
        params: { version: 1 },
      })
    : sunlifeCaller<{ quotes: IQuote[] }>({
        method: 'get',
        url: `/quotes/${customer_id}/drafts`,
        params: { version: 1 },
      });

export const addQuotes = async (data: IQuoteIDs) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/quotes/addquotes',
    data,
    params: { version: 1 },
  });

export const deleteQuotes = async (data: IDeleteQuoteIDs) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/quotes/delete',
    data,
    params: { version: 1 },
  });
