import React, { FC, useContext, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { get } from "lodash";
import styled, { ThemeContext } from "styled-components";

import {
  Slider,
  Select,
  RadioGroupWrap,
  InputNumber,
  Input,
  CheckBox,
} from "@rjp/common/component";
import {
  fieldMap,
  sliderFieldsName,
  sliderFieldsValueMap,
} from "@rjp/main/src/pages/workSurface/home/details/config";
import { CollapseCardProps } from "@rjp/common/component/collapseCard/CollapseCard";
import { pxToRem } from "@rjp/common/utils";
import { RootState } from "@rjp/main/src/app";
import { IFormField, isInputNumber } from "./types";
import { ReactComponent as ChevronLeftSvg } from "@rjp/common/assets/images/chevron-down.svg";

export interface ShowValueItemProps
  extends Omit<CollapseCardProps, "titleConfig"> {
  name?: string;
  index: number;
  type?: string;
  formData: any;
  config: IFormField;
  setShowHelp?: (name: string, value: boolean) => void;
  updateValue?: (
    value: any,
    name: string,
    cardName?: string,
    index?: number,
    type?: string
  ) => void;
  errorTipValue?: number;
  itemConfigLength?: number;
  prefix: string;
  errorState?: Record<string, boolean>;
  minMaxStep?: Record<string, any>;
  onRemove?: (name: string, cardName?: string, index?: number) => void;
  onBlur?: (name: string, value: number | string, cardName?: string) => void;
  handleErrorState?: (name: string, state: boolean) => void;
  handleMaxMinErrorState?: (name: string, state: boolean) => void;
  activeTopError?: string;
}

const Remove = styled.div`
  margin-left: ${pxToRem(492)};
  margin-top: ${pxToRem(5)};
  font-size: ${pxToRem(14)};
  color: ${(props) => props.theme.color.coolblue};
  text-decoration: underline;
  text-align: right;
  display: inline-block;
  cursor: pointer;
  user-select: none;

  &.inline-remove {
    margin-left: 0;
  }
`;

const Note = styled.span`
  margin-left: ${pxToRem(11)};
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(28)};
  font-weight: normal;
`;

const Bold = styled.span`
  font-weight: 500;
`;

const bold = (msg: string) => <Bold>{msg}</Bold>;
const inputNumberMarginRight: Record<string, any> = {
  age: { en: 104, "zh-hant": 131 },
  no_of_children: { en: 94, "zh-hant": 141 },
  retirement_age: { en: 363, "zh-hant": 418 },
};

const Fields: FC<ShowValueItemProps> = ({
  name,
  config,
  formData,
  updateValue,
  errorTipValue,
  prefix,
  errorState,
  minMaxStep,
  onRemove,
  onBlur,
  handleErrorState,
  handleMaxMinErrorState,
}) => {
  const intl = useIntl();
  const themeContext = useContext(ThemeContext);
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const handleMonthlyExpenseStep = (value: number) => {
    const step =
      value < 10000 ? 100 : value >= 10000 && value < 300000 ? 1000 : 5000;
    return step;
  };

  const inputNumberValue = useMemo(
    () =>
      get(
        formData,
        `${fieldMap[name || ""]}.${config.index || 0}.${config.inputName}`
      ),
    [config, name, formData]
  );

  const sliderValue = useMemo(() => {
    const value = get(
      formData,
      !config.isAddCard
        ? config.name
        : `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
    );
    let currentValue = value;
    let surffix = minMaxStep?.[config.name]?.suffix || config.suffix;
    if (config.isAddCard) {
      currentValue = value - 1;
    }
    if (sliderFieldsName.includes(config.name)) {
      currentValue = sliderFieldsValueMap[config.name][value || "Conservative"];
      surffix = intl.formatMessage({
        id: `${prefix}.${value || "Conservative"}`,
      });
    }
    return { value: currentValue, surffix };
  }, [config, name, formData, language]);

  switch (config.type) {
    case "radioGroup":
      return (
        <RadioGroupWrap
          prefix={prefix}
          config={config}
          onRadioButtonClick={updateValue}
          errorMsg={config.errorMsg}
          hasError={!!errorState?.[config.name]}
          value={get(
            formData,
            !config.noLabel
              ? config.name
              : `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
          )}
        />
      );
    case "inputNumber":
      return (
        <InputNumber
          name={config.name}
          className={`${isInputNumber(config) ? "number-inline" : ""} ${
            config.name === "no_of_children" ? "no-of-children-input" : ""
          }`}
          width={config.width}
          prefix={config.prefix}
          height={36}
          marginRight={
            isInputNumber(config)
              ? inputNumberMarginRight[config.name][language]
              : 0
          }
          onBlur={(e) => onBlur?.(config.name, +e?.target?.value || "", name)}
          hasError={!!errorState?.[config.name]}
          value={get(
            formData,
            !config.noLabel
              ? config.name
              : `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
          )}
          min={minMaxStep?.[config.name]?.min || config.min}
          max={minMaxStep?.[config.name]?.max || config.max}
          direction="right"
          errorMsg={minMaxStep?.[config.name]?.errorMsg || config.errorMsg}
          onChange={(value) =>
            updateValue?.(
              typeof value === "number" ? value : "",
              config.name,
              name,
              config.index
            )
          }
          handleErrorState={(name, state) => {
            handleMaxMinErrorState?.(name, state);
            handleErrorState?.(name, state);
          }}
        />
      );
    case "slider":
      return (
        <div className={config.isAddCard ? "checkbox-wrapper" : ""}>
          <Slider
            name={config.name}
            hasInput={!!config.hasInput}
            direction={config.hasInput ? "right" : "left"}
            min={minMaxStep?.[config.name]?.min || config.min}
            max={
              minMaxStep?.[config.name]?.max ||
              minMaxStep?.[config.name]?.max === 0
                ? minMaxStep?.[config.name]?.max
                : config.max
            }
            toolTip={!!config.toolTip}
            type="medium"
            inputPrefix={config.inputPrefix ?? ""}
            prefix={
              config.isAddCard
                ? intl.formatMessage({ id: config.prefix })
                : config.prefix
            }
            suffix={
              config.isAddCard
                ? intl.formatMessage({ id: config?.suffix })
                : sliderValue.surffix
            }
            inputTextAlign="right"
            errorType={config.errorType}
            disabled={config.disabled}
            width={config.width || 400}
            errorMsg={minMaxStep?.[config.name]?.errorMsg || config.errorMsg}
            hasError={!!errorState?.[config.name]}
            value={sliderValue.value}
            step={
              config.name === "retirement_monthly_income"
                ? handleMonthlyExpenseStep(sliderValue.value)
                : 1
            }
            onChange={(value) =>
              updateValue?.(value, config.name, name, config.index)
            }
            inputWidth={config.inputPrefix ? 108 : 50}
            inputHeight={36}
            errorTipValue={errorTipValue}
            canOutOfRange
            tooltipValue={minMaxStep?.[config.name]?.tooltipValue}
            handleErrorState={handleMaxMinErrorState}
            needStepLogic={!sliderFieldsName.includes(config.name)}
          />
          {!!config.isAddCard && (
            <Remove
              className="inline-remove"
              onClick={() =>
                onRemove?.(config.name, "otherInvestments", config.index)
              }
            >
              <FormattedMessage id={`${prefix}.Remove`} />
            </Remove>
          )}
        </div>
      );
    case "inputInputNumber":
      return (
        <>
          <Input
            className="number-inline input-input"
            width={config.inputWidth}
            height={36}
            value={inputNumberValue}
            name={config.inputName}
            errorMsg={config.inputErrorMsg}
            hasError={!!errorState?.[`${config.inputName}${config.index}`]}
            placeholder={intl.formatMessage({
              id: `${prefix}.${config.placeHolder}`,
            })}
            color={themeContext.color.coolblue_medium}
            onChange={(currentName, value) =>
              updateValue?.(value, currentName, name, config.index)
            }
            onBlur={(e: any) => {
              handleErrorState?.(
                `${config.inputName}${config.index}`,
                !e?.target?.value
              );
            }}
          />
          <InputNumber
            className={`number-inline input-input-number ${
              errorState?.[`${config.inputName}${config.index}`]
                ? "margin-top-number"
                : ""
            }`}
            width={config.width}
            prefix="$"
            height={36}
            name={config.name}
            value={get(
              formData,
              `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
            )}
            min={config.min}
            max={config.max}
            direction="right"
            errorMsg={config.errorMsg}
            hasError={!!errorState?.[`${name}${config.name}${config.index}`]}
            textAlign="right"
            color={themeContext.color.coolblue_medium}
            onChange={(value) =>
              updateValue?.(
                typeof value === "number" ? value : 0,
                config.name,
                name,
                config.index
              )
            }
            handleErrorState={(curName, state) =>
              handleMaxMinErrorState?.(
                `${name}${curName}${config.index}`,
                state
              )
            }
          />
        </>
      );
    case "selectInputNumber":
      return (
        <>
          <Select
            className="number-inline input-input"
            width={config.inputWidth}
            height={36}
            value={inputNumberValue}
            name={config.inputName}
            errorMsg={config.inputErrorMsg}
            hasError={!!errorState?.[`${config.inputName}${config.index}`]}
            onChange={(currentName, value) =>
              updateValue?.(value, currentName, name, config.index)
            }
            placeHolder={intl.formatMessage({
              id: `${prefix}.${config.placeHolder}`,
            })}
            option={config.option}
            showSearch
            showArrow
            suffixIcon={<ChevronLeftSvg />}
          />
          <InputNumber
            className={`number-inline input-input-number ${
              errorState?.[`${config.inputName}${config.index}`]
                ? "margin-top-number"
                : ""
            }`}
            width={config.width}
            prefix="$"
            height={36}
            name={config.name}
            value={get(
              formData,
              `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
            )}
            min={config.min}
            max={config.max}
            direction="right"
            errorMsg={config.errorMsg}
            hasError={!!errorState?.[`${name}${config.name}${config.index}`]}
            textAlign="right"
            color={themeContext.color.coolblue_medium}
            onChange={(value) =>
              updateValue?.(
                typeof value === "number" ? value : 0,
                config.name,
                name,
                config.index
              )
            }
            handleErrorState={(curName, state) => {
              handleMaxMinErrorState?.(
                `${name}${curName}${config.index}`,
                state
              );
              handleErrorState?.(`${name}${curName}${config.index}`, state);
            }}
          />
        </>
      );
    case "checkBox":
      return (
        <>
          <CheckBox
            name={config.name}
            type={config.checkBoxType}
            width={config.width}
            defaultChecked={get(
              formData,
              `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
            )}
            onChange={(checkBoxName, value) =>
              updateValue?.(value, config.name, name, config.index)
            }
          >
            <Note>
              <FormattedMessage
                id={`${prefix}.${config.note}`}
                values={{ bold }}
              />
            </Note>
          </CheckBox>
          {
            !!get(
              formData,
              `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
            )
          }
          {!!get(
            formData,
            `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
          ) === true && (
            <InputNumber
              className="number-inline checkbox-input-number"
              width={50}
              height={36}
              name={config.inputName}
              value={inputNumberValue}
              min={minMaxStep?.[config.inputName]?.min || 41}
              max={120}
              color={themeContext.color.coolblue_medium}
              hasError={!!errorState?.[`${config.inputName}${config.index}`]}
              errorMsg={minMaxStep?.[config.inputName]?.errorMsg}
              direction="right"
              marginRight={0}
              prefix={intl.formatMessage({ id: `${prefix}.ReceiveAtAge` })}
              onChange={(value) =>
                updateValue?.(
                  typeof value === "number" ? value : "",
                  config.inputName,
                  name,
                  config.index
                )
              }
              onBlur={(e: any) => {
                const val = e?.target?.value;
                handleMaxMinErrorState?.(
                  `${config.inputName}${config.index}`,
                  val < minMaxStep?.[config.inputName]?.min || val > 120 || !val
                );
              }}
            />
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default memo(Fields);
