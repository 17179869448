import React from 'react';

interface IErrorContext {
  showError: boolean;
  errorCode: string;
  setShowError?: (flag: boolean) => void;
  setErrorCode?: (code: string) => void;
}

const originData: IErrorContext = {
  showError: false,
  errorCode: '10002',
};

const ErrorContext = React.createContext(originData);

export default ErrorContext;
