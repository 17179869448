import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { initApm, initSentry } from '@rjp/common/utils';
import { createBrowserHistory } from 'history';
import App from './app/App';
import { store, persistor } from './app';

import '@rjp/common/index.css';
import 'swiper/swiper.scss';

if (process.env.REACT_APP_HIDE_CONSOLE_LOG === 'true') {
  console.log = function () {};
}

const routerHistory = createBrowserHistory({
  basename: process.env.REACT_APP_IS_SUNLIFE_API === 'true' ? '/' : '/rjp',
});
initApm();
initSentry();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={routerHistory}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
