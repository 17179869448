const main = {
  sunlife_yellow: "#EAAB00",
  sunlife_yellow_medium: "#FFCB05",
  sunlife_yellow_light: "#FFDD00",
  sunlife_blue: "#003946",
  sunlife_blue_medium: "#004C6C",
  sunlife_blue_light: "#00B4BD",
  sunlife_blue_medium_40: "#4A7283",
  black: "#000",
  white: "#FFF",
};

const accent = {
  coolblue: "#4A7283",
  coolblue_medium: "#86A7AD",
  cool_blue_light: "#C6D8D4",
  cool_blue_light_20: "#F4F7F6",
  blue_light_5: "#E6E9EB",
  orange: "#DF7227",
  orange_medium: "#EB892C",
  orange_light: "#FAA61F",
  orange_light_40: "#FDDBA5",
  orange_light_20: "#FEEDD2",
  red: "#851534",
  red_medium: "#B81F41",
  red_light: "#DA1F45",
  green: "#074A29",
  green_medium: "#00713A",
  green_light: "#58B947",
  mint_green_light: "#C6D8D4",
  green_light_grey: "#A7B1B2",
  teal: "#014747",
  teal_grey: "#004E52",
  teal_medium: "#138179",
  teal_light: "#48BFAD",
  blue_light: "#DFF6FF",
  purple: "#511E44",
  purple_medium: "#79346D",
  purple_light: "#B24399",
  pink_light: "#C2B1BF",
  pink_light_10: "#C7B2C2",
  pink_red: "#DBA5A9",
  skyblue: "#193767",
  skyblue_medium: "#00548B",
  skyblue_light: "#00A8E5",
  taupe: "#776C62",
  taupe_medium: "#9E938A",
  taupe_light: "#E1DCD2",
};

const greyScale = {
  grey_dark_0: "#333",
  grey_dark: "#424242",
  grey_medium_0: "#828282",
  grey_medium: "#9A9A9A",
  grey_light: "#D7D7D7",
  grey_light_0: "#BDBDBD",
  grey_light_5: "#DDD",
  grey_light_10: "#E0E0E0",
  grey_light_15: "#F2F2F2",
  grey_light_20: "#F7F7F7",
  grey_96: "#606060",
  grey_119: "#777777",
  grey_172: "#ACACAC",
  grey_180: "#B4B4B4",
  grey_185: "#B9B9B9",
  grey_195: "#C3C3C3",
  grey_light_25: "#CCC",
  grey_dark_5: "#E6E9EB",
};

const extra = {
  extra_invalid_input: "#F8E8EC",
  extra_sharp_green: "#3F92A8",
  extra_skyblue: "#E4F1F9",
  extra_lightyellow: "#FFF6E9",
  extra_pale_purple: "#B974A9",
  extra_mid_red: "#DE5048",
  extra_red_error: "#A4383E",
  extra_red_failed: "#F2DEDE",
  extra_red_failed_border: "#EBCCCC",
  extra_red_failed_font_color: "#984041",
  extra_pink: "#FEB8CA",
  extra_dark: "#4F4F4F",
  extra_yellow_70: "#FFD949",
  extra_yellow_40: "#FEEDAC",
};

const opacity = {
  extra_dark_0: "rgba(0, 0, 0, 0)",
  extra_dark_10: "rgba(0, 0, 0, .1)",
  extra_dark_15: "rgba(0, 0, 0, .15)",
  extra_dark_20: "rgba(0, 0, 0, .2)",
  extra_dark_25: "rgba(0, 0, 0, .25)",
  extra_dark_40: "rgba(0, 0, 0, .4)",
  extra_dark_50: "rgba(0, 0, 0, .5)",
  extra_white_20: "rgba(255, 255, 255, .2)",
  extra_grey_50: "rgba(66, 66, 66, 0.5)",
  extra_grey_light_10_50: "rgba(224, 224, 224, 0.5)",
  extra_green_50: "rgba(198, 216, 212, 0.5)",
  extra_yellow_20: "rgba(234, 171, 0, 0.2)",
  extra_yellow_light_50: "rgba(255, 203, 5, 0.5)",
  extra_sunlife_blue_medium_60: "rgba(0, 76, 108, 0.6)",
  extra_sunlife_yellow_30: "rgba(234, 171, 0, 0.3)",
  extra_grey_light_25_40: "rgba(204, 204, 204, 0.4)",
  extra_cool_blue_light_30: "rgba(198, 216, 212, 0.3)",
};

const linearGradient = {
  linear_gradient_sunlife_yellow: `linear-gradient(180deg, ${main.sunlife_yellow} 0%, ${main.sunlife_yellow_light} 100%)`,
  linear_gradient_bar: `linear-gradient(180deg, ${main.sunlife_yellow} 0%, ${extra.extra_yellow_70} 53.65%, ${extra.extra_yellow_40} 100%);`,
};

const colorBase = {
  ...main,
  ...accent,
  ...greyScale,
  ...extra,
  ...opacity,
  ...linearGradient,
};

const color = {
  ...colorBase,
  font: colorBase.grey_dark,
  shadow: colorBase.grey_light,
};

export default color;
