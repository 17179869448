import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ModalProps as AntModalProps } from "antd/es/modal";

import Button, { ButtonProps } from "../button";

export interface DefaultFooterProps extends AntModalProps {
  onClose?: ButtonProps["onClick"];
  closeText?: string;
  width?: number;
}

export interface FooterButtonProps extends ButtonProps {
  message: string;
  values?: Record<string, any>;
}

export interface ButtonsFooterProps extends AntModalProps {
  config: FooterButtonProps[];
}

export const DefaultFooter: FC<DefaultFooterProps> = ({
  onClose,
  closeText,
  width,
}) => (
  <Button type="primary" width={width || 170} onClick={onClose}>
    <FormattedMessage id={closeText || "Common.Close"} />
  </Button>
);

export const ButtonsFooter: FC<ButtonsFooterProps> = ({ config }) => (
  <>
    {config.length !== 0 &&
      config.map(({ message, type, width, onClick, style, values }) => (
        <Button
          key={message}
          type={type}
          width={width}
          onClick={onClick}
          style={style}
        >
          <FormattedMessage id={message} values={values} />
        </Button>
      ))}
  </>
);
