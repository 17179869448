import { combineReducers } from '@reduxjs/toolkit';
import translationReducer from '../features/translation/translationSlice';
import userInfoReducer from '../features/userInfo/userInfoSlice';
import RCDataReducer from '../features/rcData/rcDataSlice';
import authReducer from '../features/auth/authSlice';
import advisorEditReducer from '../features/advisorEdit/advisorEditSlice';
import contactAdvisorReducer from '../features/contactAdvisor/contactAdvisorSlice';
import productsDataReducer from '../features/productsData/productsSlice';
import loadingReducer from '../features/loading/loadingSlice';

const rootReducer = combineReducers({
  translation: translationReducer,
  RCData: RCDataReducer,
  userInfo: userInfoReducer,
  auth: authReducer,
  advisorEdit: advisorEditReducer,
  contactAdvisor: contactAdvisorReducer,
  productsData: productsDataReducer,
  loading: loadingReducer,
});

export default rootReducer;
