import React, { FC, useState, useRef } from "react";
import styled from "styled-components";
import { pxToRem } from "@rjp/common/utils";
import { Prompt, RouteComponentProps } from "react-router-dom";
import { PromptProps } from "react-router";
import * as H from "history";
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Modal,
} from "@rjp/common/component";

const prefix = "Common.BeforeLeave.";
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const ModelTitle = styled(CurFormattedMessage)`
  height: ${pxToRem(50)};
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(24)};
  text-align: center;
  font-weight: 500;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ModelContent = styled(CurFormattedMessage)`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(21)};
  text-align: center;
  width: ${pxToRem(300)};
  margin: 0 auto;
  margin-top: ${pxToRem(7)};
  padding: ${pxToRem(8)} 0;
  color: ${(props) => props.theme.color.grey_dark};
`;

interface IBeforeLeave extends Pick<RouteComponentProps, "history"> {
  intercept?: boolean;
}

const BeforeLeave: FC<IBeforeLeave> = ({ intercept = false, history }) => {
  const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);

  const agreeLeave = useRef<boolean>(false);

  const beforeLeaveLocation = useRef<H.Location>();

  const promptMessage: PromptProps["message"] = (location) => {
    if (agreeLeave.current) return agreeLeave.current;
    if (intercept) {
      setShowLeaveModal(true);
      beforeLeaveLocation.current = location;
    }
    return !intercept;
  };

  const leave = () => {
    agreeLeave.current = true;
    if (beforeLeaveLocation.current) {
      history.push(beforeLeaveLocation.current);
    }
    setShowLeaveModal(false);
  };

  return (
    <>
      <Modal
        visible={showLeaveModal}
        width={500}
        onClose={() => setShowLeaveModal(false)}
        footerConfig={[
          {
            width: 170,
            type: "secondary",
            message: `${prefix}Stay`,
            onClick: () => setShowLeaveModal(false),
          },
          {
            width: 170,
            type: "primary",
            message: `${prefix}Leave`,
            style: { marginLeft: pxToRem(20) },
            onClick: leave,
          },
        ]}
      >
        <ModelTitle id="AreYouSure" />
        <ModelContent id="LeavingThisPage" />
      </Modal>
      <Prompt message={promptMessage} />
    </>
  );
};

export default BeforeLeave;
