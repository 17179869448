import React, { FC, useEffect, useRef, useState, memo, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

import { AppDispatch, RootState } from '@rjp/main/src/app';
import { pxToRem } from '@rjp/common/utils';
import { setIncludedQuote } from '@rjp/main/src/features/rcData/rcDataSlice';
import { filteredAddedQuotesSelector } from '@/features/rcData/rcDataSelector';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  InfoButton,
  Label,
  // Select,
  SwitchButton,
} from '@rjp/common/component';
import { ReactComponent as ProductsDotSvg } from '@rjp/common/assets/images/products-dot.svg';
import { ReactComponent as ChevronLeftSvg } from '@rjp/common/assets/images/chevron-down.svg';
// import { selectOption } from './config';
import { labelMap } from '../config/config';
import ProductsModal from '../modal';
import {useIntl} from "react-intl";

Swiper.use([Navigation, Pagination]);

interface ProductsContainerProps {
  productConfig: {
    title: string;
    noDataLabel: string;
    swiperName: string;
  };
  isPurchasedProducts: boolean;
  onDelete?: (id: string) => void;
}

interface ProductContentProps {
  config: Record<string, any>;
  handleSwitchState?: (checked: boolean) => void;
  onClickProduct?: () => void;
}

const prefix = 'WorkSurface.Home.Products.';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const ProductWrapper = styled.div`
  min-height: ${pxToRem(168)};
  font-size: ${pxToRem(16)};
  font-weight: 500;
  white-space: pre-wrap;

  .title {
    width: ${pxToRem(620)};
    height: ${pxToRem(32)};
    line-height: ${pxToRem(32)};
    margin-left: ${pxToRem(20)};
    border-bottom: 1px solid ${(props) => props.theme.color.coolblue_medium};
    font-size: ${pxToRem(20)};
    color: ${(props) => props.theme.color.sunlife_blue_medium};
    display: flex;
    justify-content: space-between;

    .ant-select {
      margin-top: ${pxToRem(-6)};
    }
    .ant-select,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${(props) => props.theme.color.grey_180} !important;
    }
  }

  .no-data-content {
    width: ${pxToRem(620)};
    margin-top: ${pxToRem(20)};
    margin-left: ${pxToRem(20)};
    line-height: ${pxToRem(23)};
    font-size: ${pxToRem(18)};
    font-style: italic;
    font-weight: 500;
    color: ${(props) => props.theme.color.grey_medium};
  }
`;

const ProductsWrapper = styled.div`
  position: relative;

  .swiper-container {
    width: ${pxToRem(605)};
    padding-top: ${pxToRem(20)};
    padding-bottom: ${pxToRem(10)};
    margin-left: ${pxToRem(30)};

    .swiper-wrapper {
      .swiper-slide {
        width: ${pxToRem(280)} !important;
        margin-right: ${pxToRem(12)} !important;
      }
    }
  }

  .chevron.purchased-products-chevron {
    top: ${pxToRem(75)};
  }

  .chevron.product-quotes-chevron {
    top: ${pxToRem(90)};
  }

  .chevron {
    width: ${pxToRem(24)};
    height: ${pxToRem(15)};
    cursor: pointer;

    path {
      fill: ${(props) => props.theme.color.grey_medium_0};
    }
  }

  .swiper-button-prev {
    transform: rotate(90deg);
    left: ${pxToRem(5)};
  }

  .swiper-button-next {
    transform: rotate(-90deg);
    right: ${pxToRem(8)};
  }
`;

const ProductsItem = styled.div`
  width: ${pxToRem(280)};
  padding: ${pxToRem(12)};
  margin-right: ${pxToRem(12)};
  border-radius: ${pxToRem(5)};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)}
    ${(props) => props.theme.color.extra_dark_10};
  position: relative;
  font-size: ${pxToRem(14)};
  font-weight: normal;
  color: ${(props) => props.theme.color.sunlife_blue_medium};

  &.purchased-products {
    height: ${pxToRem(70)};
    color: ${(props) => props.theme.color.sunlife_blue_medium};
    background: ${(props) => props.theme.color.sunlife_yellow_medium};
    cursor: pointer;
  }

  &.purchased-quotes {
    height: ${pxToRem(130)};
    padding: 0;
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.color.sunlife_blue_light};
  }

  .product-name {
    font-size: ${pxToRem(20)};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ProductsDot = styled(ProductsDotSvg)`
  width: ${pxToRem(24)};
  height: ${pxToRem(8)};
  position: absolute;
  top: ${pxToRem(6.5)};
  right: ${pxToRem(12)};

  path {
    fill: ${(props) => props.theme.color.white};
  }
`;

// const CurChevronDown = styled(ChevronLeftSvg)`
//   width: ${pxToRem(12)};
//   fill: ${props => props.theme.color.sunlife_blue_medium_40};
// `;

const Status = styled(CurFormattedMessage)`
  min-width: ${pxToRem(90)};
  height: ${pxToRem(24)};
  padding: 0 ${pxToRem(7)};
  line-height: ${pxToRem(24)};
  font-size: ${pxToRem(14)};
  font-weight: 500;
  position: absolute;
  top: ${pxToRem(-5)};
  left: ${pxToRem(12)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  cursor: pointer;

  svg {
    vertical-align: ${pxToRem(-2)};
  }
`;

const ProductQuotesTop = styled.div`
  height: ${pxToRem(95)};
  padding: 0 ${pxToRem(12)};
  padding-top: ${pxToRem(2)};
  cursor: pointer;
  line-height: 1.5;
`;

const ProductQuotesBottom = styled.div`
  height: ${pxToRem(35)};
  padding-left: ${pxToRem(8)};
  padding-right: ${pxToRem(3)};
  line-height: ${pxToRem(35)};
  border-radius: 0 0 ${pxToRem(5)} ${pxToRem(5)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  background: ${(props) => props.theme.color.cool_blue_light_20};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Bold = styled.span`
  font-size: ${pxToRem(18)};
  font-weight: bold;
  color: ${(props) => props.theme.color.sunlife_blue_light};
`;

const bold = (msg: string) => <Bold>{msg}</Bold>;

const Description = styled.p`
  font-weight: bold;
  font-size: ${pxToRem(14)};
  color: ${(props) => props.theme.color.white};
`
const PurchasedProducts: FC<ProductContentProps> = ({ config }) => (
  <>
    <div className="product-name">
      <CurFormattedMessage id={labelMap[config.product_code]} />
    </div>
    <CurFormattedMessage
      id="PolicyNo"
      values={{ policyNo: config.policyNo || '-' }}
    />
  </>
);

const ProductQuotes: FC<ProductContentProps> = ({
  config,
  handleSwitchState,
  onClickProduct,
}) => {
  const intl = useIntl();
  const modalPremium = config.illustration?.plan_summary?.premium || config.illustration?.policy?.vectors?.find((v:any) => v.name === "Premium")?.value;
  return (
    <>
      <ProductQuotesTop onClick={onClickProduct}>
        <div className="product-name">
          <CurFormattedMessage id={labelMap[config.product_code]}/>
          <Description>{config.description || "\u00A0"}</Description>
        </div>
        <CurFormattedMessage
          id="DateTime"
          values={{
            date: dayjs(config.creation_date).format('DD/MM/YY'),
            time: dayjs(config.creation_date).format('HH:mm'),
          }}
        />
        <Label>
          <CurFormattedMessage
            id="ModalPremium"
            values={{
              currency: intl.formatMessage({ id: `PopModal.ProductSummary.Value.${config.parameters.Currency}` }),
              value: Number(modalPremium || 0)
            }}
          />
        </Label>
      </ProductQuotesTop>
      <ProductQuotesBottom>
        <CurFormattedMessage
          id="ImpactOnRetirementGap"
          values={{
            value: config.impact,
            bold,
          }}
        />
        <SwitchButton
          onChange={handleSwitchState}
          checked={config.isIncludedImpact}
        />
      </ProductQuotesBottom>
    </>
  )
};

const ProductsContainer: FC<ProductsContainerProps> = ({
  productConfig,
  isPurchasedProducts,
  onDelete,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const role =
    useSelector((state: RootState) => state.userInfo.role) ?? 'member';
  const { includedQuote, retirementGapSummary } = useSelector(
    (state: RootState) => state.RCData,
    shallowEqual
  );
  const filteredAddedQuotes = useSelector(filteredAddedQuotesSelector);
  const productsData = useSelector((state: RootState) => state.productsData);

  const [productsConfig, setProductsQuoteConfig] = useState({
    visible: false,
    name: 'Commitment',
    isPurchasedProducts: false,
    config: {},
  });
  // const [advisor, setAdvisor] = useState('AllAdvisors');

  const curMemberId = useRef<string>('');

  useEffect(() => {
    const paramsObject = new URLSearchParams(location.search);
    if (role === 'advisor') {
      const member_id = paramsObject.get('member_id');
      curMemberId.current = member_id || '';
    }
  }, [role, location]);

  const onClickItem = (
    name: string,
    isPurchasedProduct: boolean,
    config: Record<string, any>
  ) => {
    setProductsQuoteConfig((prevState) => ({
      ...prevState,
      visible: true,
      name,
      isPurchasedProducts: isPurchasedProduct,
      config,
    }));
  };

  const onClose = () => {
    setProductsQuoteConfig((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleSwitchState = async (
    name: string,
    id: string,
    checked: boolean
  ) => {
    const newIncludedQuote = [...includedQuote];
    const position = newIncludedQuote.findIndex(
      ({ quote_id }) => quote_id === id
    );
    if (position >= 0) {
      newIncludedQuote[position] = {
        ...newIncludedQuote[position],
        is_included: checked,
      };
    }

    dispatch(setIncludedQuote(newIncludedQuote));
  };

  // const onAdvisorChange = (name: string, value: string) => {
  //   if (advisor !== value) {
  //     setAdvisor(value);
  //   }
  // };
  const { swiperName, noDataLabel, title } = productConfig;

  const navigationClassName = useMemo(
    () => ({
      prev: `${swiperName}-prev`,
      next: `${swiperName}-next`,
    }),
    [swiperName]
  );

  const mySwiper = useRef<any>(null);

  useEffect(() => {
    if (filteredAddedQuotes.length > 0 && swiperName) {
      const { next, prev } = navigationClassName;
      mySwiper.current?.slideTo(0, 200);
      mySwiper.current?.navigation.update();
      // eslint-disable-next-line no-new
      mySwiper.current = new Swiper(`.${swiperName}.swiper-container`, {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: 'auto',
        shortSwipes: false,
        updateOnWindowResize: true,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,

        // Navigation arrows
        navigation: {
          nextEl: `.${next}`,
          prevEl: `.${prev}`,
        },
      });
      mySwiper.current?.navigation.update();
      mySwiper.current?.slideTo(filteredAddedQuotes.length - 1, 0);
      setTimeout(() => mySwiper.current?.slideTo(0, 200), 0);
    }
  }, [filteredAddedQuotes, swiperName, navigationClassName]);

  return (
    <>
      <ProductWrapper key={title}>
        <div className="title">
          <CurFormattedMessage
            id={title}
            values={{ value: filteredAddedQuotes.length }}
          />
          {/* {
            !isPurchasedProducts && role !== 'advisor' && (
              <Select
                value={advisor}
                option={selectOption}
                width={214}
                height={32}
                onChange={onAdvisorChange}
                suffixIcon={<CurChevronDown />}
              />
            )
          } */}
        </div>
        {filteredAddedQuotes.length ? (
          <ProductsWrapper className="out-swiper-container">
            <div className={`swiper-container ${productConfig.swiperName}`}>
              <div className="swiper-wrapper">
                {filteredAddedQuotes.map((item: Record<string, any>, index) => {
                  const curCommItemData = {
                    ...item,
                    ...productsData[item.product_code]?.[item.id],
                  };
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                      className="swiper-slide"
                      key={`${productConfig.swiperName}${item.product_code}${index}`}
                    >
                      <ProductsItem
                        className={`swiper-slide ${
                          isPurchasedProducts
                            ? 'purchased-products'
                            : 'purchased-quotes'
                        }`}
                        onClick={() =>
                          isPurchasedProducts
                            ? onClickItem(
                                labelMap[item.product_code],
                                isPurchasedProducts,
                                curCommItemData
                              )
                            : {}
                        }
                      >
                        {!isPurchasedProducts &&
                          dayjs(item.creation_date).valueOf() <
                            dayjs(item.product_last_update_date).valueOf() &&
                          item.product_status !== 'Valid' && (
                            <Status
                              id={item.product_status}
                              values={{
                                info: (
                                  <InfoButton
                                    width={14}
                                    type="primary-medium-blue"
                                  />
                                ),
                              }}
                              onClick={() =>
                                onClickItem(
                                  labelMap[item.product_code],
                                  isPurchasedProducts,
                                  curCommItemData
                                )
                              }
                            />
                          )}
                        <ProductsDot />
                        {isPurchasedProducts ? (
                          <PurchasedProducts config={curCommItemData} />
                        ) : (
                          <ProductQuotes
                            config={{
                              ...curCommItemData,
                              impact:
                                retirementGapSummary?.quote_impact?.find(
                                  (quote) => quote.quote_id === item.id
                                )?.value ?? 0,
                              isIncludedImpact:
                                includedQuote.find(
                                  (quote) => quote.quote_id === item.id
                                )?.is_included ?? false,
                            }}
                            handleSwitchState={(checked) =>
                              handleSwitchState(
                                item.product_code,
                                item.id,
                                checked
                              )
                            }
                            onClickProduct={() =>
                              onClickItem(
                                labelMap[item.product_code],
                                isPurchasedProducts,
                                curCommItemData
                              )
                            }
                          />
                        )}
                      </ProductsItem>
                    </div>
                  );
                })}
              </div>
            </div>
            {filteredAddedQuotes.length > 2 && (
              <>
                <ChevronLeftSvg
                  className={`chevron swiper-button-prev ${navigationClassName.prev} ${swiperName}-chevron`}
                />
                <ChevronLeftSvg
                  className={`chevron swiper-button-next ${navigationClassName.next} ${swiperName}-chevron`}
                />
              </>
            )}
          </ProductsWrapper>
        ) : (
          <div className="no-data-content">
            <CurFormattedMessage id={noDataLabel} />
          </div>
        )}
      </ProductWrapper>
      <ProductsModal
        visible={productsConfig.visible}
        productName={productsConfig.name}
        isPurchasedProducts={productsConfig.isPurchasedProducts}
        config={productsConfig.config}
        onClose={onClose}
        onDelete={onDelete}
        role={role}
      />
    </>
  );
};

export default memo(ProductsContainer);
