export const inputPFtoInputPX = (inputPF: any, retirementAge:number) => {
	var inputPX;
	return inputPX = illustrationInputParse(inputPF, retirementAge);
};


export const inputPFtoInputVision = (inputPF: any, retirementAge:number) => {
	return {
		"product_name": "vision",
		"service_name": "vision illustration",
		"round": true,
		"precision": 0,
		"insured": [
			{
				"gender": inputPF.Sex,
				"age": inputPF.Age,
				"smokerCode": inputPF.Class === "Smoker" ? "Smoker" : "NonSmoker",
				"typeCode": "Insured",
			}
		],
		"policy": {
			"currency": "USD",
			"prepayment": false,
			"jointInsured": false,
			"paymentFrequency": visionFrequencies[inputPF.PremMode],
			"requestedCalculations": [
				"Illustration",
				"Premium",
				"Quote"
			],
			"withdrawalOption": 0,
			"withdrawals": [],
			"benefitPeriodMax": 120,
			"prepaymentYear": 0,
			"faceAmount": 20000,
			"premiumPayingPeriod": parseInt(inputPF.PremTerm, 10),
			"planCode": visionPlanCodes[parseInt(inputPF.PremTerm, 10)],
		},
		"journey": {
			"story": "Retirement",
			"statement": "Projected Savings",
			"savingAmount": inputPF.SavingBudget1,
			"inflation": inputPF.Inflation,
			"education": {
			},
			"retirement": {
				"retirementAge": retirementAge,
				"duration": 20,
				"retirementAllowance": 0
			},
			"addwithdrawalDetails": []
		}
	}
};

const visionPlanCodes: {[key: number]: string} = {
	2: "C02U0A",
	5: "C05U0A",
	10: "C10U0A",
}

const visionFrequencies: {[key: string]: number} = {
	Monthly: 12,
	'Semi-Annual': 2,
	'Semi-annually': 2,
	Annually: 1,
	Annual: 1,
}

const premiumMode: any = {
	Monthly: 'Monthly',
	'Semi-annually': 'Semi-Annual',
	Annually: 'Annual'
}

const illustrationInputParse = (inputPF: any, retirementAge:number) => {
	const statement = inputPF.Statement
	let withdrawals = withdrawalsArrInputMapper(inputPF)
	let inputPX = {
		insured_1: [{
			age: inputPF.Age,
			start_year: 1,
			gender: inputPF.Sex,
			smoker: inputPF.Class
		}],
		insured_2: [],
		is_prepayment: inputPF.IsPrepayment === 'Y' ? true: false,
		product: {
			prem_term: parseInt(inputPF.PremTerm),
			prem_mode: inputPF.PremMode,
		},
		withdrawals,
		withdrawal_selection: withdrawals.length,
		inflation: inputPF.Inflation,
		retirement_age: retirementAge,
		saving_budget_1: inputPF.SavingBudget1,
		story: inputPF.Story,
		years_of_retirement: retirementAge - inputPF.Age,
		statement: statement
	}

	return inputPX;
}

const withdrawalsArrInputMapper = (inputPF: any) => {

	const victoryWithdrawals = inputPF.Withdrawals
	const victoryWithdrawalsSummary = inputPF.victory_withdrawal_summary ? inputPF.victory_withdrawal_summary:undefined;
	const statement = inputPF.Statement
	const type = inputPF.victory_method === 'One' ? 'Lump sum' : 'Regular'

	let withdrawals: any[] = []
	let addlWithdrawals: any[] = []
	let withdrawalSummary: any[] = []

	// Additional Withdrawal Details
	if (victoryWithdrawals !== undefined && victoryWithdrawals.length > 0) {
		addlWithdrawals = victoryWithdrawals.map((obj: any) => {
			return {
				withdrawal: obj['withdrawal'],
				type,
				start: obj['start'],
				end: obj['start'] + obj['duration'] - 1,
				amount: obj['amount'],
				duration: obj['duration']
			}
		})
	}

	//Withdrawal Summary
	if (victoryWithdrawalsSummary !== undefined && victoryWithdrawalsSummary.length > 0) {
		withdrawalSummary = victoryWithdrawalsSummary.map((obj: any) => {
			return {
				withdrawal: obj.withdrawal,
				type: obj.type,
				start: obj.start,
				end: obj.end,
				amount: obj.Amount,
				duration: obj.Duration
			}
		})
	}

	console.log(`Statement: ${statement}`)
	// Update withdrawals depending on the flow
	if (statement === 'Projected Savings') {
		if (addlWithdrawals.length === 0 ) {
			const projectedSavingsWithdrawal =  withdrawalsArrMapperFromProjectedSavingsFlow(inputPF.victory_retirement_age, inputPF.victory_insured_1[0]["age"], type, inputPF.victory_retirement_year)
			console.log('Projected Savings 9B', projectedSavingsWithdrawal)
			withdrawals = projectedSavingsWithdrawal
		} else {
			/* addlWithdrawals[0] = projectedSavingsWithdrawal[0] // Replace the first withdawal item for projected savings flow
			withdrawals = addlWithdrawals */

			// Get the first withdrawal summary if present and concat with additional withdrawal
			if (withdrawalSummary.length > 0) {
				withdrawals.push(withdrawalSummary[0])
			}
			withdrawals = withdrawals.concat(addlWithdrawals)
			console.log('Projected Savings Wdraw Summary', withdrawalSummary)
		}
	} else {
		const expectedSavingsWithdrawal = withdrawalsArrMapperFromExpectedSavingsFlow(inputPF.victory_withdrawal_summary, inputPF.victory_goal_amount_2, inputPF.victory_retirement_age, inputPF.victory_retirement_year, inputPF.victory_insured_1[0]["age"], type)
		withdrawals = expectedSavingsWithdrawal.concat(addlWithdrawals)
		console.log('Expected Withdrawals', withdrawals)
	}

	// Update withdrawals number based on index to avoid duplicates
	withdrawals = withdrawals.map((obj: any, index: number) => {
		obj.withdrawal = index + 1
		return obj
	})

	return withdrawals
}

const withdrawalsArrMapperFromExpectedSavingsFlow = (victoryWithdrawalSummary: any, victoryGoalAmount2: number, retirementAge: number, retirementYears: number, age: number, type: string) => {
	let withdrawalArr = [{
		withdrawal: 1,
		type,
		start: retirementAge - age,
		end: retirementAge - age + retirementYears - 1,
		amount: victoryGoalAmount2,
		duration: retirementYears
	}]

	if(victoryGoalAmount2 !== 0){
		withdrawalArr[0].amount = victoryGoalAmount2
	} else {
		// Use the amount from victory withdrawal summary
		withdrawalArr[0].amount = victoryWithdrawalSummary[0].Amount
	}
	return withdrawalArr
}

const withdrawalsArrMapperFromProjectedSavingsFlow = (retirementAge: number, age: number, type: string, retirementYear: number) => {
	return [{
		withdrawal: 1,
		type,
		start: retirementAge - age,
		end: retirementAge - age + retirementYear - 1,
		amount: 9999999999,
		duration: retirementYear
	}]
}
