const getCurrentTime = (time: number) => (time < 10 ? `0${time}` : time);

const formatTime = () => {
  const curTime = new Date();
  const addHour = curTime.setHours(curTime.getHours() + 1);
  const date = new Date(addHour);
  return `${date.getFullYear()}-${getCurrentTime(
    date.getMonth() + 1
  )}-${getCurrentTime(date.getDate())} ${getCurrentTime(
    date.getHours()
  )}:${getCurrentTime(date.getMinutes())}:${getCurrentTime(date.getSeconds())}`;
};

export default formatTime;
