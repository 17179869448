import sunlifeCaller from './sunlifeCaller';
import {
  ILogin,
  ILoginResponse,
  ITokenExchange,
  ITokenExchangeResponse,
  IResetPasswordRequest,
  IResetPassword,
  logoutRequest
} from './auth.type';

export const login = async ({ role, ...data }: ILogin) =>
  sunlifeCaller<ILoginResponse>({
    method: 'post',
    url: '/auth/login',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { role, version:1 },
  });

export const tokenExchange = async (data: ITokenExchange) =>
  sunlifeCaller<{ metadata: ITokenExchangeResponse }>({
    method: 'post',
    url: '/auth/tokenexchange',
    data,
    withCredentials: true,
  });

export const resetPasswordRequest = async (data: IResetPasswordRequest) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/auth/passwordresetrequest',
    data,
  });

export const resetPassword = async (data: IResetPassword) =>
sunlifeCaller<{}>({
  method: 'post',
  url: '/auth/passwordreset',
  data,
});

export const logout = async () =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/auth/logout',
    withCredentials: true
  });
