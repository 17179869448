import React, {
  FC,
  useRef,
  useState,
  useEffect,
  memo,
  useCallback,
  useMemo,
} from 'react';
import IdleTimer, { EVENTS } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

import { TimeoutModal } from '@rjp/common/component';
import { AppDispatch, RootState } from '@rjp/main/src/app';
import { isLoggedInSelector } from './authSelector';
import { logout, setLastActiveTime } from './authSlice';
import { idleTimerCountdownSecond, noActionTimeHourLimit } from './config';

const Timeout: FC = () => {
  let idleTimer = useRef<IdleTimer>(null);
  const [visible, setVisible] = useState(false);

  const [expiredCountdown, setExpiredCountdown] = useState<number>(10);
  let expiredTimeInterval:NodeJS.Timeout;
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const eventData: EVENTS[] = [
    'mousemove',
    'keydown',
    'wheel',
    'mousedown',
    'touchstart',
    'touchmove',
  ];
  const isLoggedIn = useSelector(isLoggedInSelector);
  const lastActiveTime = useSelector(
    (state: RootState) => state.auth.lastActiveTime
  );
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const lastActiveTimeDayObject = useMemo(() => dayjs(lastActiveTime), [
    lastActiveTime,
  ]);
  const afterClose = useCallback(
    () => setExpiredCountdown(idleTimerCountdownSecond),
    []
  );
  const hasClosed = useRef<boolean>(false);
  const role = useSelector((state: RootState) => state.userInfo.role);

  const onIdle = () => {};

  const onAction = useCallback(() => {
    if(idleTimer && idleTimer.current)
        idleTimer.current?.reset();
    if (!visible && !hasClosed.current) {
      clearTimeout(expiredTimeInterval);
      hasClosed.current = false;
      dispatch(setLastActiveTime());
    }
  }, [visible, dispatch]);

  const fetchLogout = useCallback(async () => {
    await dispatch(logout());
    history.push(`/${role}/prelanding?locale=${language}`);
  }, [dispatch, history, role, language]);

  const sleep = useCallback(
    (ms: number) =>
      new Promise((res) => {
        expiredTimeInterval = setTimeout(res, ms);
      }),
    []
  );

  useEffect(() => {
    if (isLoggedIn) {
      let isNotExpired = true;
      const run = async () => {
        do {
          const expiredMillisecond = lastActiveTimeDayObject
            .add(noActionTimeHourLimit, 'h')
            .diff(dayjs());
          const expiredSecond = Math.floor(expiredMillisecond / 1000) + 1;
          const countDownMillisecond =
            expiredMillisecond - (expiredSecond - 1) * 1000;
          if (expiredSecond <= 0) {
            clearTimeout(expiredTimeInterval);
            isNotExpired = false;
            setExpiredCountdown(0);
            setVisible(false);
            fetchLogout();
          } else if (expiredSecond > 0 && expiredSecond <= 60) {
            if (!hasClosed.current) {
              setVisible(true);
              hasClosed.current = true;
            }
            setExpiredCountdown(expiredSecond);
          }
          // eslint-disable-next-line no-await-in-loop
          await sleep(countDownMillisecond);
        } while (isNotExpired);
      };
      run();
    } else {
      clearTimeout(expiredTimeInterval);
      hasClosed.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActiveTimeDayObject, isLoggedIn]);

  useEffect(() => {
    hasClosed.current = false;
    setVisible(false);
    if (isLoggedIn) {
      dispatch(setLastActiveTime());
    }
    clearTimeout(expiredTimeInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoggedIn]);

  const onContinue = () => {
    clearTimeout(expiredTimeInterval);
    setVisible(false);
    hasClosed.current = false;
    if(idleTimer && idleTimer.current)
        idleTimer.current?.reset();
    dispatch(setLastActiveTime());
  };

  return (
    <>
      <IdleTimer
        ref={(element:any) => { idleTimer = element }}
        element={document}
        events={eventData}
        onIdle={onIdle}
        onAction={onAction}
        debounce={0}
        // stopOnIdle
      />
      <TimeoutModal
        visible={visible}
        onClose={onContinue}
        expiredSecond={expiredCountdown}
        onContinue={onContinue}
        afterClose={afterClose}
      />
    </>
  );
};

export default memo(Timeout);
