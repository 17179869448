import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { InfoModal } from '@rjp/common/component';

const prefix = 'PopModal.SessionExpired.';

interface ISessionExpired {
  visible?: boolean;
  onClose: () => void;
  onResendRequest: () => void;
}

const CurInfoModal = styled(InfoModal)`
  .ant-modal-body {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
  }
`;

const SessionExpired: FC<ISessionExpired> = ({
  visible,
  onClose,
  onResendRequest,
}) => (
  <CurInfoModal
    width={436}
    onClose={onClose}
    footerOnClose={onResendRequest}
    closeText={`${prefix}ResendRequest`}
    visible={visible}
    closeBtnWidth={198}
    titleConfig={{
      formatMessage: {
        id: `${prefix}SessionExpired`,
      },
    }}
    contentConfig={{
      formatMessage: {
        id: `${prefix}YourSessionIsEnded`,
      },
    }}
  />
);

export default SessionExpired;
