import { SVGProps } from "react";
import styled, { css } from "styled-components";
import { pxToRem } from "@rjp/common/utils";

import { ReactComponent as InfoSvg } from "@rjp/common/assets/images/info.svg";

export interface InfoButtonProps
  extends Pick<SVGProps<SVGSVGElement>, "onClick"> {
  /**
   * Width of svgButton
   */
  width?: number;
  /**
   * Type of svgButton. primary, primary-yellow and primary-medium-blue
   */
  type?: "primary" | "primary-yellow" | "primary-medium-blue";
}

const primaryMixin = css`
  path {
    fill: ${(props) => props.theme.color.font};
  }
`;

const primaryYellowMixin = css`
  path {
    fill: ${(props) => props.theme.color.sunlife_yellow};
  }
`;

const primaryMediumBlueMixin = css`
  path {
    fill: ${(props) => props.theme.color.sunlife_blue_medium};
  }
`;

const StyledInfoSvg = styled(InfoSvg)<InfoButtonProps>`
  width: ${(props) => pxToRem(props.width || 24)};
  height: ${(props) => pxToRem(props.width || 24)};
  cursor: pointer;

  ${(props) => {
    switch (props.type) {
      case "primary-medium-blue":
        return primaryMediumBlueMixin;
      case "primary-yellow":
        return primaryYellowMixin;
      default:
        return primaryMixin;
    }
  }}
`;

export default StyledInfoSvg;
