import React from "react";
import styled, { css } from "styled-components";
import { pxToRem } from "@rjp/common/utils";
import { FormattedMessage } from "react-intl";
import BikePng from "@rjp/common/assets/images/bike.png";
import WalkPng from "@rjp/common/assets/images/walk.png";
import { ReactComponent as HomeSvg } from "@rjp/common/assets/images/home.svg";
import { ReactComponent as EditSvg } from "@rjp/common/assets/images/edit.svg";
// import { ReactComponent as GraphSvg } from '@rjp/common/assets/images/graph.svg';

const primary = css`
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  width: ${pxToRem(472)};
  height: ${pxToRem(654)};
  border-radius: ${pxToRem(5)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  padding: ${pxToRem(30)} ${pxToRem(10)} 0 ${pxToRem(28)};
  background-image: url(${BikePng}), url(${WalkPng});
  background-repeat: no-repeat, no-repeat;
  background-size: ${pxToRem(389 / 2.5)} ${pxToRem(301 / 2.5)},
    ${pxToRem(472 / 2)} ${pxToRem(263 / 2)};
  background-position: bottom 0px right ${pxToRem(26)},
    bottom 0px left ${pxToRem(0)};
`;

const TitleDiv = styled.div`
  font-size: ${pxToRem(28)};
  font-weight: medium;
  white-space: pre-wrap;
  line-height: ${pxToRem(34)};
  margin-bottom: ${pxToRem(34)};
`;

const SubTitleDiv = styled.div`
  font-size: ${pxToRem(16)};
  white-space: pre-wrap;
  line-height: ${pxToRem(20)};
  margin-bottom: ${pxToRem(34)};
`;

const DescContainer = styled.div`
  display: flex;
  margin-bottom: ${pxToRem(36)};
`;

const NormalDesc = styled.div`
  margin-left: ${pxToRem(12)};
  font-size: ${pxToRem(16)};
  font-weight: normal;
  white-space: pre-wrap;
  line-height: ${pxToRem(22)};
  font-weight: medium;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Highlight = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

const SideCard2 = styled(({ ...props }) => {
  const descArr = [
    {
      index: 1,
      descId: "Common.Start.SideCard2.desc1",
      svg: <EditSvg width={pxToRem(54)} />,
    },
    {
      index: 2,
      descId: "Common.Start.SideCard2.desc2",
      svg: <HomeSvg width={pxToRem(54)} />,
    },
    // {
    //   index: 3,
    //   descId: 'Common.Start.SideCard2.desc3',
    //   svg: <GraphSvg width={pxToRem(54)} />
    // },
  ];

  return (
    <div {...props}>
      <TitleDiv>
        <FormattedMessage id="Common.Start.SideCard2.title" />
      </TitleDiv>
      <SubTitleDiv>
        <FormattedMessage id="Common.Start.SideCard2.desc" />
      </SubTitleDiv>
      {descArr.map((data) => (
        <DescContainer key={data.index}>
          {data.svg}
          <NormalDesc>
            <FormattedMessage
              id={data.descId}
              values={{
                bold: (msg: string) => <Bold>{msg}</Bold>,
                highlight: (msg: string) => <Highlight>{msg}</Highlight>,
              }}
            />
          </NormalDesc>
        </DescContainer>
      ))}
    </div>
  );
})`
  ${primary}
`;

export default SideCard2;
