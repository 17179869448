import color from "./color";
import mediaQueries from "./mediaQueries";
import unit from "./unit";
import style from "./style";

const theme = {
  color,
  unit,
  style,
  mediaQueries,
};

export default theme;
