import {
  SliderProps,
  RadioButtonProps,
  InputNumberProps,
  MenuOverplay,
} from "@rjp/common/component";
import { CheckBoxProps } from "../../component/checkBox/CheckBox";

interface IFormCommon {
  type: string;
  title: string;
  subTitle?: string;
  name: string;
  errorType?: string;
  error?: boolean;
  width?: number;
  maxWidth?: number;
  canEdit?: boolean;
  required?: boolean;
  errorMsg?: string;
  index?: number;
  wrapperWidth?: number;
  noLabel: boolean;
  inVisible?: boolean;
  isAddCard?: boolean;
  className?: string;
}

export interface IFormSlider
  extends IFormCommon,
    Pick<SliderProps, "min" | "max" | "hasInput" | "toolTip"> {
  type: "slider";
  inputPrefix?: SliderProps["inputPrefix"];
  prefix?: SliderProps["prefix"];
  suffix?: SliderProps["suffix"];
  help?: string;
  step?: number;
  guaranteed?: string;
  disabled?: boolean;
  mathRound?: boolean;
  isDelay?: boolean;
}

export interface IFormRadioGroup
  extends IFormCommon,
    Pick<RadioButtonProps, "width"> {
  type: "radioGroup";
  options: {
    label: string;
    value: RadioButtonProps["value"];
    disabled?: boolean;
  }[];
}

export interface IInputNumber
  extends IFormCommon,
    Pick<InputNumberProps, "min" | "max" | "width" | "prefix"> {
  type: "inputNumber";
  help?: string;
  step?: number;
  helpIcon?: string;
  mathRound?: boolean;
  disabled?: boolean;
}

export interface IInputInputNumber
  extends IFormCommon,
    Pick<InputNumberProps, "min" | "max" | "width" | "prefix"> {
  type: "inputInputNumber";
  inputName: string;
  inputWidth?: number;
  help?: string;
  step?: number;
  helpIcon?: string;
  mathRound?: boolean;
  disabled?: boolean;
  placeHolder?: string;
  inputErrorMsg?: string;
}

export interface ISelectInputNumber
  extends IFormCommon,
    Pick<MenuOverplay, "option" | "width"> {
  inputName: string;
  inputWidth?: number;
  type: "selectInputNumber";
  placeHolder?: string;
  inputErrorMsg?: string;
  help?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
}

export interface ICheckBox extends IFormCommon, Pick<CheckBoxProps, "width"> {
  type: "checkBox";
  note?: string;
  checkBoxType: CheckBoxProps["type"];
  inputName: string;
  min?: number;
  max?: number;
}

export type IFormField =
  | IFormSlider
  | IFormRadioGroup
  | IInputNumber
  | IInputInputNumber
  | ISelectInputNumber
  | ICheckBox;

export const isSlider = (config: IFormField): config is IFormSlider =>
  (config as IFormSlider).type === "slider";
export const isRadioGroup = (config: IFormField): config is IFormRadioGroup =>
  (config as IFormRadioGroup).type === "radioGroup";
export const isInputNumber = (config: IFormField): config is IInputNumber =>
  (config as IInputNumber).type === "inputNumber";
export const isInputInputNumber = (
  config: IFormField
): config is IInputInputNumber =>
  (config as IInputInputNumber).type === "InputInputNumber";
export const isCheckBox = (config: IFormField): config is ICheckBox =>
  (config as ICheckBox).type === "CheckBox";
