import sunlifeCaller from './sunlifeCaller';
import { IRetirementProfile } from './type';
import {
  IGetRetirementProfile,
  IUpdateRetirementProfile,
  IEditRequest,
  IEditRequestVerify,
  IEditSessionStatus,
  IEditSessionStatusResponse,
} from './retirementprofile.type';

export const createRetirementProfile = async (data: IRetirementProfile) =>
  sunlifeCaller<{ retirement_profile: IRetirementProfile }>({
    method: 'post',
    url: '/retirementprofiles',
    data: Object.fromEntries(
      Object.entries(data).filter(
        ([, value]) => value !== '' && value !== undefined && value !== null
      )
    ),
    withCredentials: true,
  });

export const getRetirementProfile = async ({
  id,
  role,
}: IGetRetirementProfile) =>
  process.env.REACT_APP_USE_POST_API_RETIREMENT_PROFILE_SEARCH === 'true'
    ? sunlifeCaller<{ retirement_profile: IRetirementProfile }>({
        method: 'post',
        url: '/retirementprofiles/profilesearch',
        params: { role },
        data: { client_id: id },
        withCredentials: true,
      })
    : sunlifeCaller<{ retirement_profile: IRetirementProfile }>({
        method: 'get',
        url: `/retirementprofiles/${id}`,
        params: { role },
        withCredentials: true,
      });

export const updateRetirementProfile = async ({
  id,
  role,
  ...data
}: IUpdateRetirementProfile) =>
  sunlifeCaller<{ retirement_profile: IRetirementProfile }>({
    method: 'post',
    url: `/retirementprofiles/${id}/update`,
    params: { role },
    data: Object.fromEntries(
      Object.entries(data).filter(
        ([, value]) => value !== '' && value !== undefined && value !== null
      )
    ),
    withCredentials: true,
  });

export const editRequest = async ({ clientId, ...data }: IEditRequest) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: `/retirementprofiles/${clientId}/editrequest`,
    data,
  });

export const editRequestVerify = async ({
  clientId,
  ...data
}: IEditRequestVerify) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: `/retirementprofiles/${clientId}/editrequestverify`,
    data,
  });

export const editSessionStatus = async ({ clientId }: IEditSessionStatus) =>
  process.env.REACT_APP_USE_POST_API_RETIREMENT_PROFILE_EDIT_SESSION_STATUS ===
  'true'
    ? sunlifeCaller<IEditSessionStatusResponse>({
        method: 'post',
        url: `/retirementprofiles/editsessionstatus`,
        data: {
          client_id: clientId,
        },
      })
    : sunlifeCaller<IEditSessionStatusResponse>({
        method: 'get',
        url: `/retirementprofiles/${clientId}/editsessionstatus`,
      });
