import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import AdvisorCoverPic from '@rjp/common/assets/images/advisor-cover-pic.png';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
} from '@rjp/common/component';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`Login.Login.Advisor.${id}`} />
);

const AdvisorCoverWrapper = styled.div`
  position: absolute;
  width: ${pxToRem(472)};
  height: ${pxToRem(708)};
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  overflow: hidden;
  box-shadow: 0px ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1);
  border-radius: ${pxToRem(5)};
  text-align: center;
  right: ${pxToRem(10)};
  top: ${pxToRem(-180)};
`;

const Cover = styled.img`
  filter: drop-shadow(0px ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1));
  display: block;
  width: 100%;
`;

const Title = styled(CurFormattedMessage)`
  font-weight: 500;
  height: ${pxToRem(26)};
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(26)};
  margin-top: ${pxToRem(70)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Description = styled(CurFormattedMessage)`
  font-size: ${pxToRem(20)};
  font-weight: 400;
  width: ${pxToRem(424)};
  margin: 0 auto;
  margin-top: ${pxToRem(29)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const AdvisorCover: FC = () => (
  <AdvisorCoverWrapper>
    <Cover src={AdvisorCoverPic} />
    <Title id="AdvisorApp" />
    <Description id="ASimpleToolToHelpYou" />
  </AdvisorCoverWrapper>
);

export default AdvisorCover;
