import React, { FC, useMemo } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import sunLifeLogo from '@rjp/main/public/img/sunlife-logo.png';
import { FormattedMessage } from 'react-intl';
import { pxToRem } from '@rjp/common/utils';
import { useLocation } from 'react-router';
import LanguageSwitch from '../features/translation/LanguageSwitch';

const StyledHeader = styled(Layout.Header)`
  &.ant-layout-header {
    justify-content: space-between;
    height: ${pxToRem(70)};
    align-items: center;
    display: flex;
    padding: 0;
    background-color: ${(props) => props.theme.color.white};
    box-shadow: 0px ${pxToRem(2)} ${pxToRem(6)} rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
`;

const Logo = styled.img`
  width: ${pxToRem(120)};
  margin-left: ${pxToRem(25)};
`;

const ProjectName = styled.div`
  font-weight: 500;
  font-size: ${pxToRem(26)};
  height: ${pxToRem(70)};
  line-height: ${pxToRem(70)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const LanguageSwitchWrapper = styled.div`
  margin-right: ${pxToRem(27)};
  display: flex;

  .ant-radio-group.ant-radio-group-outline {
    display: flex;
    align-items: center;
  }
`;

const Header: FC = () => {
  const curLocation = useLocation();
  const inAdvisor = useMemo(() => /advisor/.test(curLocation.pathname), [
    curLocation,
  ]);

  return (
    <StyledHeader>
      <Logo src={sunLifeLogo} />
      <ProjectName>
        <FormattedMessage id={`Start.${inAdvisor ? 'AdvisorApp' : 'Header'}`} />
      </ProjectName>
      <LanguageSwitchWrapper>
        <LanguageSwitch />
      </LanguageSwitchWrapper>
    </StyledHeader>
  );
};

export default Header;
