import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { pxToRem, enlargeFontsize } from "@rjp/common/utils";
import { reg } from "@rjp/common/config";
import StyleInput from "./StyledInput";
import Label from "../label";
import ErrorMessage from "../errorMessage";
import Password, { PasswordProps } from "./Password";
// eslint-disable-next-line import/no-cycle
import PhoneNumber from "./Phone";
import { InputProps } from "./config";

export interface IInputProps extends InputProps, PasswordProps {
  notCheck?: boolean;
}

const FlexDiv = styled.div<Pick<InputProps, "marginTop">>`
  margin-top: ${(props) => pxToRem(props.marginTop || 0)};
  display: flex;
  flex-direction: column;
`;

const FieldTitle = styled((props) => <Label {...props} />)`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  height: ${pxToRem(21)};
  line-height: ${pxToRem(21)};
`;

const FieldTitleNote = styled.span`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
  font-style: italic;
  font-weight: normal;
`;

interface IInputDefault extends InputProps {
  notCheck?: boolean;
}

export const InputDefault: FC<IInputDefault> = ({
  name,
  onChange,
  setError,
  type,
  notCheck,
  onBlur,
  ...props
}) => {
  const change = (e: any) => onChange?.(name, e?.target?.value || "");

  const blur = (e: any) => {
    const currentValue = e?.target?.value || "";
    if (!notCheck) {
      setError?.(name, !reg[type || "name"].test(currentValue));
      if (currentValue === "") {
        setError?.(name, false);
      }
    }
    onBlur?.(e);
  };

  return <StyleInput name={name} onChange={change} onBlur={blur} {...props} />;
};

const InputConfig: FC<IInputProps> = ({ countryCode, notCheck, ...props }) => {
  switch (props.type) {
    case "password":
      return <Password {...props} />;
    case "phoneNumber":
      return <PhoneNumber countryCode={countryCode} {...props} />;
    default:
      return <InputDefault notCheck={notCheck} {...props} />;
  }
};

const Input: FC<IInputProps> = ({
  title,
  subTitle,
  className,
  hasError,
  marginTop,
  value,
  type,
  errorMsg,
  failed,
  notCheck,
  ...props
}) => (
  <>
    <FlexDiv marginTop={marginTop} className={className}>
      {title && (
        <FieldTitle className="field-title">
          <FormattedMessage id={title} />
          {subTitle && (
            <FieldTitleNote>
              <FormattedMessage id={subTitle} />
            </FieldTitleNote>
          )}
        </FieldTitle>
      )}
      <InputConfig
        hasError={hasError || failed}
        type={type}
        notCheck={notCheck}
        value={value}
        {...props}
      />
    </FlexDiv>
    {hasError && <ErrorMessage errorMsg={errorMsg} />}
  </>
);

export default Input;
