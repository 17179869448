import React, { FC, useMemo, useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import {
  enlargeFontsize,
  isEmptyField,
  pxToRem,
  checkParams,
  toLogin,
} from '@rjp/common/utils';
import {
  Input,
  Button,
  SideCard3,
  SideCard2,
  Header,
  CheckBox,
  ApiErrorTip,
} from '@rjp/common/component';
import { CSSTransition } from 'react-transition-group';
import AdvisorCover from './AdvisorCover';
import { loginFieldConfig, advisorLoginFieldConfig } from './config';
import { RootState, AppDispatch } from '../../app';
import { login } from '../../api';
import { setLoggedIn, updateRememberMe } from '../../features/auth/authSlice';
import { setUserInfo } from '@/features/userInfo/userInfoSlice';
import { generate, verify } from '@/api/captcha';

const Wrapper = styled.div`
  font-size: ${pxToRem(40)};
  white-space: pre-wrap;
`;

const Content = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  width: ${pxToRem(550)};
  margin-left: ${pxToRem(30)};
  text-align: left;
`;

const PasswordNote = styled.div`
  margin-top: ${pxToRem(13)};
  margin-bottom: ${pxToRem(18)};
  font-weight: normal;
  line-height: ${pxToRem(20)};
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.color.extra_dark};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
`;

const RememberMe = styled.span`
  margin-left: ${pxToRem(13)};
  line-height: ${pxToRem(20)};
`;

const ForgotPassword = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.color.coolblue};
  cursor: pointer;
`;

const CurRCSideCard = styled(SideCard3)`
  position: absolute;
  right: ${pxToRem(10)};
  top: ${pxToRem(-180)};
`;

const WelcomeSideCard = styled(SideCard2)`
  position: absolute;
  right: ${pxToRem(10)};
  top: ${pxToRem(-180)};
`;

const FailedWrapper = styled.div`
  background: ${(props) => props.theme.color.extra_red_failed};
  border: 1px solid ${(props) => props.theme.color.extra_red_failed_border};
  box-sizing: border-box;
  border-radius: ${pxToRem(3)};
  width: ${pxToRem(550)};
  height: ${pxToRem(32)};
  padding-left: ${pxToRem(13)};
  line-height: ${pxToRem(32)};
  color: ${(props) => props.theme.color.extra_red_failed_font_color};
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(63)};
  transition: all 0.5s;
  opacity: 0;
  transform: translateY(${pxToRem(30)});
`;

const Bold = styled.span`
  font-weight: bold;
`;

const CaptchaContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
`;

const Login: FC = () => {
  const history = useHistory();
  const { role = 'member' } = useParams<{
    role: 'member' | 'client' | 'advisor';
  }>();
  const themeContext = useContext(ThemeContext);
  const { retirementGapSummary, RCPasteId } = useSelector(
    (state: RootState) => state.RCData
  );
  const [showError, setShowError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('10002');
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const auth = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch<AppDispatch>();
  
  const [formValue, setFormValue] = useState(
    role === 'advisor'
      ? {
          email: auth[role].email,
          password: '',
          isRememberMe: auth[role].isRememberMe,
          captcha: '',
        }
      : {
          email: auth[role].email,
          password: '',
          isRememberMe: auth[role].isRememberMe,
        }
  );
  const [hasError, setHasError] = useState<Record<string, boolean>>({
    email: false,
    confirmEmail: false,
  });

  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const signInState = useMemo(() => {
    const hasErrorValue = isEmptyField(hasError, 'boolean');
    const formValues = isEmptyField(
      Object.fromEntries(
        Object.entries(formValue).filter(([key]) => key !== 'isRememberMe')
      )
    );
    return !hasErrorValue && !formValues;
  }, [hasError, formValue]);

  const handleSetError = (name: string, value: boolean) => {
    if (name !== 'password') {
      setHasError((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleChange = (name: string, value: any) => {
    setFormValue((values) => ({
      ...values,
      [name]: value,
    }));
    setLoginFailed(false);
  };

  const handleCheckBoxChange = (_: string, value: boolean) => {
    setFormValue((prev) => ({ ...prev, isRememberMe: value }));
  };

  const openUrl = (errorCode: string, role: string) => {
    if(errorCode == '10023' || errorCode == '10012') {
      window.open(`${process.env.REACT_APP_PLANNER_URL}/${role}/forgot?locale=${language}`);
    }
  };

  const [captchaFailed, setCaptchaFailed] = useState(false);
  const signIn = async () => {
    let captchaVerified = false;
    if (role === 'advisor') {
      const checkCaptcha = await verify({ ans: formValue.captcha || '' });
      if (checkCaptcha.verified === '1') {
        captchaVerified = true;
        setCaptchaFailed(false);
      } else {
        captchaVerified = false;
        setCaptchaFailed(true);
      }
    } else {
      captchaVerified = true;
    }

    const authDetail = {
      email: formValue.isRememberMe ? formValue.email : '',
      isRememberMe: formValue.isRememberMe,
    };
    dispatch(updateRememberMe({ [role]: authDetail }));
    try {
      const curUsername =
        role === 'advisor' ? formValue.email.toUpperCase() : formValue.email;
      if (captchaVerified || role !== 'advisor') {
        const responseData = await login({
          role,
          username: curUsername,
          password: formValue.password
        });
        
        if (responseData) {
          const userinfo = responseData.userinfo;
          dispatch(setLoggedIn());
          dispatch(setUserInfo(userinfo));
          Cookies.set('rjpAccessToken', responseData.accessToken);
          Cookies.set('rjpRefreshToken', responseData.refreshToken);
          history.push({
            pathname: `/${role}/${role === 'advisor' ? 'search' : 'home'}`,
            search: `locale=${language || 'en'}`,
          });
        }
      }

      setShowError(false);
      // window.location.assign(`${responseData.redirectUrl}&locale=${language}`);
      // console.log(window.location.href,"post login-res url");
    } catch (error:any) {
      if (error) {
        if (error === 10009) {
          setShowError(false);
          history.push('/member/accountnotverified', {
            email: formValue.email,
          });
        } else {
          setShowError(true);
          setErrorCode(error);
          // TODO: Show error
          // TODO: Does 5 times failed login attempt has its own error_code?
        }
      }
    }
  };

  const [captcha, setCaptcha] = useState('');
  useEffect(() => {
    const generateCaptcha = async () => {
      const captchaResponse = await generate();
      console.log(captchaResponse);
      setCaptcha(captchaResponse.captcha);
    };
    generateCaptcha();
  }, []);

// no use
  // useEffect(() => {
  //   if (!checkParams()) {
  //     if (role === 'member') {
  //       history.replace({ pathname: '/start', search: window.location.search });
  //     }
  //   }
  // }, [role, history]);

  return (
    <Wrapper>
      <Header
        leftHeader={{
          title: `Login.Login.${
            role === 'advisor' ? 'AdvisorSignIn' : 'MemberSignIn'
          }`,
          detail: `Login.Login.${
            role === 'advisor'
              ? 'SignInToTheSunLifeAdvisor'
              : 'SignInToYourAccount'
          }`,
        }}
      />
      <Content>
        <ContentWrapper>
          {(role === 'advisor'
            ? advisorLoginFieldConfig
            : loginFieldConfig
          ).map(({ title, name, type, marginTop, errorMsg, notCheck }) => (
            <Input
              width={550}
              marginTop={marginTop}
              key={title}
              name={name}
              defaultValue={formValue[name]}
              type={type}
              notCheck={notCheck}
              title={`Login.Login.${title}`}
              hasError={hasError[name]}
              onChange={handleChange}
              setError={!notCheck ? handleSetError : undefined}
              color={themeContext.color.grey_medium}
              errorMsg={errorMsg ? `ErrorMsg.${errorMsg}` : errorMsg}
              failed={loginFailed}
              onPressEnter={name === 'password' ? signIn : undefined}
            />
          ))}
          <PasswordNote>
            <CheckBox
              defaultChecked={formValue.isRememberMe}
              onChange={handleCheckBoxChange}
            >
              <RememberMe>
                <FormattedMessage id="Login.Login.RememberMe" />
              </RememberMe>
            </CheckBox>
            <ForgotPassword onClick={() => history.push(`/${role}/forgot`)}>
              <FormattedMessage id="Login.Login.ForgotPassword" />
            </ForgotPassword>
          </PasswordNote>

          {role === 'advisor' &&
            <CaptchaContainer>
              {captcha !== '' && (
                <img src={`data:captcha/png;base64,${captcha}`} />
              )}

              <Input
                width={220}
                height={32}
                textAlign="center"
                marginTop={5}
                key="captcha"
                name="captcha"
                type="text"
                title={`Login.Login.Captcha.CaptchaTitle`}
                color={themeContext.color.grey_medium}
                onChange={handleChange}
                onPressEnter={signIn}
              />
              <CSSTransition
                in={captchaFailed}
                timeout={0}
                classNames="side-in"
                unmountOnExit
              >
                <FailedWrapper>
                  <FormattedMessage
                    id="Login.Login.Captcha.CaptchaErrorMessage"
                    values={{
                      bold: (text: string) => <Bold>{text}</Bold>,
                    }}
                  />
                </FailedWrapper>
              </CSSTransition>
            </CaptchaContainer>
          }

          <CSSTransition
            in={loginFailed}
            timeout={0}
            classNames="side-in"
            unmountOnExit
          >
            <FailedWrapper>
              <FormattedMessage
                id="Login.Login.LoginFailed"
                values={{
                  bold: (text: string) => <Bold>{text}</Bold>,
                }}
              />
            </FailedWrapper>
          </CSSTransition>
          <ApiErrorTip
            showError={showError}
            id={errorCode}
            width={550}
            margin={`0 0 ${pxToRem(28)} 0`}
            onClick={() => openUrl(errorCode, role)}
          />
          <Button
            type={signInState ? 'primary-yellow' : 'disabled'}
            width={180}
            onClick={signIn}
          >
            <FormattedMessage id="Login.Login.SignIn" />
          </Button>
        </ContentWrapper>
        {role === 'advisor' ? (
          <AdvisorCover />
        ) : (
          <>
            {RCPasteId && retirementGapSummary.retirement_age ? (
              <CurRCSideCard />
            ) : (
              <WelcomeSideCard />
            )}
          </>
        )}
      </Content>
    </Wrapper>
  );
};
export default Login;
