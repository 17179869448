import React, { FC, useMemo, useState, useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import { enlargeFontsize, pxToRem } from '@rjp/common/utils';
import {
  Input,
  Button,
  Header,
  Label,
  InfoModal,
  ApiErrorTip,
} from '@rjp/common/component';
import { RootState } from '../../app';
import { resetPasswordRequest } from '../../api';

interface IForgetPassword extends Pick<RouteComponentProps, 'history'> {}

const Wrapper = styled.div`
  font-size: ${pxToRem(40)};
  text-align: center;
  white-space: pre-wrap;
`;

const ContentWrapper = styled.div`
  width: ${pxToRem(550)};
  margin-left: ${pxToRem(30)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  line-height: ${pxToRem(21)};
  text-align: left;
`;

const Title = styled(Label)`
  margin-top: ${pxToRem(58)};
  margin-bottom: ${pxToRem(5)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${pxToRem(30)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ButtonWrapper = styled.div<{ noMargin: boolean }>`
  margin-top: ${(props) => pxToRem(props.noMargin ? 0 : 50)};
  margin-bottom: ${pxToRem(28)};
  display: flex;
`;

const VerifyButton = styled(({ marginLeft, ...props }) => (
  <Button {...props} />
))`
  margin-left: ${(props) => pxToRem(props.marginLeft)};
`;

const Normal = styled.span`
  font-weight: normal;
`;

const normal = (msg: string) => <Normal>{msg}</Normal>;

const ForgotPassword: FC<IForgetPassword> = ({ history }) => {
  const intl = useIntl();
  const themeContext = useContext(ThemeContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [hasError, setHasError] = useState(false);
  const [successfullyVisible, setSuccessfullyVisible] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [sendLabel, setSendLabel] = useState('Send');
  const [sendBefore, setSendBefore] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('10002');
  const language = useSelector(
    (state: RootState) => state.translation.language
  );

  let timer:NodeJS.Timeout;

  const onSend = async () => {
    try {
      await resetPasswordRequest({
        email: emailAddress,
        language,
      });
      setSuccessfullyVisible(true);
      timer = setInterval(() => {
        setCountdown((value) => value - 1);
      }, 1000);
      setSendLabel('Resend');
      setSendBefore(emailAddress);
      setShowError(false);
    } catch (error) {
      if (error) {
        setShowError(true);
        setErrorCode(error);
        // TODO: Show error
        console.log(error);
      }
    }
  };

  const onClose = () => setSuccessfullyVisible(false);

  const verifyState = useMemo(() => {
    if (countdown === 0 && timer) {
      window.clearInterval(timer);
      if (sendBefore !== '') {
        setSendLabel(sendBefore !== emailAddress ? 'Send' : 'CurrentResend');
      }
      setCountdown(60);
    }
    return (
      !!emailAddress &&
      !hasError &&
      (sendLabel === 'Send' || sendLabel === 'CurrentResend') &&
      countdown === 60
    );
  }, [emailAddress, hasError, countdown, sendLabel, sendBefore]);

  const handleChange = (name: string, value: any) => {
    setEmailAddress(value);
    if (sendBefore !== '' && countdown !== 60) {
      setSendLabel(sendBefore !== value ? 'OtherSend' : 'Resend');
    }
    if (sendBefore !== '' && countdown === 60) {
      setSendLabel(sendBefore !== value ? 'Send' : 'CurrentResend');
    }
  };

  const modalConfig = useMemo(
    () => ({
      titleConfig: {
        showTitleIcon: true,
        formatMessage: {
          id: 'ForgotPassword.VerificationEmailSent',
        },
        style: {
          color: themeContext.color.sunlife_blue_medium,
          fontSize: pxToRem(28),
        },
      },
      contentConfig: {
        formatMessage: {
          id: 'ForgotPassword.PleaseCheckYourEmailInbox',
          values: {
            value: emailAddress,
            bold: (msg: string) => <Label>{msg}</Label>,
            normal,
          },
        },
        style: {
          color: themeContext.color.grey_dark,
          marginTop: pxToRem(20),
          marginBottom: pxToRem(20),
          fontSize: pxToRem(18),
          fontWeight: 500,
        },
      },
    }),
    [emailAddress, themeContext]
  );

  return (
    <Wrapper>
      <Header
        leftHeader={{
          title: 'ForgotPassword.ForgotYourPassword',
          detail: 'ForgotPassword.WellGetYouBackInto',
        }}
      />
      <ContentWrapper>
        <Title>
          <FormattedMessage id="ForgotPassword.EnterTheEmailAddress" />
        </Title>
        <FormattedMessage id="ForgotPassword.ThenWeWillSendYou" />
        <Input
          style={{ fontStyle: emailAddress ? 'normal' : 'italic' }}
          width={550}
          marginTop={40}
          name="emailAddress"
          type="email"
          title="ForgotPassword.EmailAddress"
          placeholder={intl.formatMessage({
            id: 'ForgotPassword.AddEmailHere',
          })}
          hasError={hasError}
          onChange={handleChange}
          setError={(name: string, value: boolean) => setHasError(value)}
          color={themeContext.color.coolblue_medium}
          errorMsg="ErrorMsg.InvalidEmailAddressFormat"
        />
        <ApiErrorTip
          showError={showError}
          margin={`${pxToRem(20)} 0 ${pxToRem(24)} 0`}
          id={errorCode}
          width={550}
        />
        <ButtonWrapper noMargin={showError}>
          <Button type="secondary" width={170} onClick={() => history.goBack()}>
            <FormattedMessage id="ForgotPassword.Cancel" />
          </Button>
          <VerifyButton
            marginLeft={20}
            type={verifyState ? 'primary-yellow' : 'disabled'}
            width={170}
            onClick={onSend}
          >
            <FormattedMessage
              id={`ForgotPassword.${sendLabel}`}
              values={{ value: countdown }}
            />
          </VerifyButton>
        </ButtonWrapper>
      </ContentWrapper>
      <InfoModal
        visible={successfullyVisible}
        onClose={onClose}
        {...modalConfig}
      />
    </Wrapper>
  );
};
export default ForgotPassword;
