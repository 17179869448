import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';
import { mixModalContentConfig } from './config';

interface IModalProps extends ModalProps {
  title: string;
}

const Content = styled.div`
  font-size: ${pxToRem(16)};
  margin-left: ${pxToRem(15)};
`;

const Detail = styled.div`
  height: ${pxToRem(146)};
  margin-top: ${pxToRem(26)};
  font-size: ${pxToRem(16)};
  display: flex;
`;

const DetailLeft = styled.div`
  width: ${pxToRem(89)};
  height: ${pxToRem(146)};
  font-size: ${pxToRem(14)};
  font-weight: 500;
  text-align: right;
  font-weight: 500;

  .left-bottom {
    margin-top: ${pxToRem(92)};
  }
`;

const DetailMiddle = styled.div`
  height: ${pxToRem(141)};
  width: ${pxToRem(12)};
  background: ${(props) => props.theme.color.linear_gradient_bar};
  border-radius: ${pxToRem(50)};
  margin-left: ${pxToRem(11)};
`;

const Stocks = styled.span`
  display: inline-block;
  min-width: ${pxToRem(155)};
`;

const DetailRight = styled.div`
  font-size: ${pxToRem(18)};
  margin-left: ${pxToRem(21)};
`;

const Bold = styled.span`
  font-weight: 500;
`;

const stocks = (msg: string) => <Stocks>{msg}</Stocks>;

const prefix = 'PopModal.MixModal.';

const MixModal: FC<IModalProps> = ({ visible = false, title, onClose }) => (
  <Modal
    width={630}
    visible={visible}
    onClose={onClose}
    title={`${prefix}${title}`}
  >
    <Content>
      <FormattedMessage id={`${prefix}Paragraph`} />
      <Detail>
        <DetailLeft>
          <div className="left-top">
            <FormattedMessage id={`${prefix}HighRisk`} />
          </div>
          <div className="left-bottom">
            <FormattedMessage id={`${prefix}LowRisk`} />
          </div>
        </DetailLeft>
        <DetailMiddle />
        <DetailRight>
          {mixModalContentConfig.map(({ value, label }) => (
            <div key={label}>
              <FormattedMessage
                id={`${prefix}Stocks`}
                values={{ v1: value[0], v2: value[1], stocks }}
              />
              <Bold>
                <FormattedMessage id={`${prefix}${label}`} />
              </Bold>
            </div>
          ))}
        </DetailRight>
      </Detail>
    </Content>
  </Modal>
);

export default MixModal;
