// @ts-nocheck
import validator from 'validator';

export default function deserializeObject(data) {
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      if (data[key] !== null && typeof data[key] === 'object') {
        data[key] = deserializeObject(data[key]);
      } else {
        if (typeof data[key] === 'string') {
          const value = data[key];
          data[key] = validator.unescape(value);
          if(data[key] === "true"){
            data[key] = true;
          }else if(data[key] === "false"){
            data[key] = false;
          } 
        }
      }
    }
  }
  return data;
}
