import React, { FC } from 'react';
import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/es/radio';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { pxToRem } from '@rjp/common/utils';
import { languages } from '@rjp/common/constants';
import { changeLanguage } from './translationSlice';
import { AppDispatch, RootState } from '../../app';

const RadioButton = styled(Radio.Button)`
  &.ant-radio-button-wrapper {
    user-select: none;
    font-weight: bold;
    border: none;
    height: ${pxToRem(16)};
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(16)};
    background: none;
    padding: 0 ${pxToRem(12)};

    :first-child {
      border-left: none;
    }

    :not(:first-child) {
      ::before {
        left: 0;
        width: ${pxToRem(3)};
        margin-top: ${pxToRem(1)};
        background-color: ${(props) => props.theme.color.font};
      }
    }

    :last-child {
      padding-right: 0;
    }

    :focus-within {
      box-shadow: none;
    }

    :not(.ant-radio-button-wrapper-disabled) {
      :hover {
        color: ${(props) => props.theme.color.grey_dark};
      }
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: none;
    background: none;
    color: ${(props) => props.theme.color.sunlife_yellow};

    ::before {
      background-color: ${(props) => props.theme.color.font} !important;
      opacity: inherit;
    }
    :hover {
      box-shadow: none;
      color: ${(props) => props.theme.color.sunlife_yellow};
    }
  }
`;

const LanguageSwitch: FC = () => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const dispatch = useDispatch<AppDispatch>();

  const onLanguageChange: RadioGroupProps['onChange'] = (e) => {
    dispatch(changeLanguage(e.target.value));
  };

  return (
    <Radio.Group
      className="language"
      name="language-group"
      onChange={onLanguageChange}
      value={language}
    >
      {languages.map(({ label, value }) => (
        <RadioButton key={value} value={value}>
          {label}
        </RadioButton>
      ))}
    </Radio.Group>
  );
};

export default LanguageSwitch;
