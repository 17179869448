const unit = {
  basesize: `${window.innerWidth / 11.94}px`,
  space_xs: "0.25rem",
  space_sm: "0.5rem",
  space_md: "1rem",
  space_lg: "1.5rem",
  space_xl: "2rem",
  corner_sm: "0.25rem",
  corner_md: "0.5rem",
  app_height: "100%",
  page_height: "100%",
};

export default unit;
