import React, { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { InputNumber as AntInputNumber } from "antd";
import { InputNumberProps as AntInputNumberProps } from "antd/es/input-number";
import styled from "styled-components";

import { pxToRem, enlargeFontsize } from "@rjp/common/utils";
import ErrorMessage from "../errorMessage";
import { ErrorMessageProps } from "../errorMessage/ErrorMessage";

export interface InputNumberProps
  extends Omit<AntInputNumberProps, "min" | "max"> {
  width?: number;
  height?: number;
  marginRight?: number;
  hasError?: boolean;
  errorMsg?: string;
  prefix?: string;
  suffix?: string;
  textAlign?: "left" | "center" | "right";
  min?: number;
  max?: number;
  className?: string;
  value: number;
  mathRound?: boolean;
  handleErrorState?: (name: string, state: boolean) => void;
  direction?: ErrorMessageProps["direction"];
}

const StyleInputNumber = styled(AntInputNumber)<
  Pick<
    InputNumberProps,
    "width" | "height" | "hasError" | "textAlign" | "marginRight"
  >
>`
  &.ant-input-number {
    width: ${(props) => pxToRem(props.width ?? 158)};
    height: ${(props) => pxToRem(props.height ?? 48)};
    margin-right: ${(props) => pxToRem(props.marginRight || 0)};
    line-height: ${(props) => pxToRem(props.height ?? 48)};
    font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
    border-color: ${(props) =>
      props.hasError
        ? props.theme.color.extra_red_error
        : props.color || props.theme.color.borderColor};
    background: ${(props) =>
      props.hasError
        ? props.theme.color.extra_invalid_input
        : props.theme.color.white};

    & .ant-input-number-input-wrap > input {
      padding: 0 ${pxToRem(5)};
      height: ${(props) => pxToRem(props.height ?? 48)};
      line-height: ${(props) => pxToRem(props.height ?? 48)};
      text-align: ${(props) => props.textAlign ?? "center"};
    }
    &:hover {
      border-color: ${(props) =>
        props.hasError
          ? props.theme.color.red
          : props.color || props.theme.color.sunlife_yellow};
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  &.ant-input-number-focused {
    border-color: ${(props) =>
      props.hasError
        ? props.theme.color.red
        : props.color || props.theme.color.sunlife_yellow};
    box-shadow: none;
  }
`;

const FlexDiv = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  position: relative;

  & span {
    margin-right: ${pxToRem(8)};
  }
`;

const Prefix = styled.span`
  margin-right: ${pxToRem(8)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  font-weight: bold;
`;

const Suffix = styled.div`
  max-width: ${pxToRem(80)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  margin-left: ${pxToRem(8)};
`;

const InputNumber: FC<InputNumberProps> = ({
  name,
  prefix,
  suffix = "",
  className,
  errorMsg,
  max = 100,
  min = 0,
  value,
  mathRound,
  hasError,
  handleErrorState,
  direction,
  marginRight = 0,
  width = 158,
  onBlur,
  ...props
}) => {
  const intl = useIntl();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(!!hasError);
  }, [hasError]);

  const blur = (e: any) => {
    // @ts-ignore
    const error = (value < min || value > max) && value !== "";
    setShowError(error);
    handleErrorState?.(name || "", error);
    onBlur?.(e);
  };

  const format = useMemo(() => {
    const parser: InputNumberProps["parser"] = (displayValue) => {
      if (displayValue === undefined || displayValue === "") {
        return 0;
      }
      const parsedValue = displayValue.replace(/,/g, "");
      const parsedNumber = +parsedValue;
      if (Number.isNaN(parsedNumber)) {
        return value;
      }
      return parsedNumber;
    };

    const formatter: InputNumberProps["formatter"] = (formatterValue) => {
      if (prefix === "$") {
        return intl.formatNumber(
          formatterValue === undefined || formatterValue === ""
            ? 0
            : Math.round(+formatterValue)
        );
      }
      return (formatterValue || "").toString().replace(/,/g, "");
    };
    return { parser, formatter };
  }, [prefix, intl, value]);

  return (
    <>
      <FlexDiv className={className}>
        {!!prefix && <Prefix className="input__prefix">{prefix}</Prefix>}
        <StyleInputNumber
          hasError={(showError && !!value && value !== 0) || !!hasError}
          type="text"
          inputMode="numeric"
          width={width}
          value={value}
          precision={0}
          parser={format.parser}
          formatter={format.formatter}
          // marginRight={marginRight}
          onBlur={blur}
          {...props}
        />
        {!!suffix && <Suffix className="input__suffix">{suffix}</Suffix>}
      </FlexDiv>
      {(showError || !!hasError) && (
        <ErrorMessage
          errorMsg={errorMsg}
          values={{ min, max }}
          direction={direction}
          pixel={width - 34 + marginRight}
        />
      )}
    </>
  );
};

export default InputNumber;
