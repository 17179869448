export const isEmptyField = (
  object: Record<string, any>,
  type?: "object" | "boolean"
) => {
  const arr: string[] = [];
  Object.keys(object).filter((key) => {
    const value = object[key];
    const valueState = value?.state || value;
    if (type === "boolean" && arr.length === 0) {
      if (valueState === true) {
        arr.push(value);
      }
    } else if ((value === "" || value === undefined) && arr.length === 0) {
      arr.push(key);
    }
    return true;
  });
  return arr.length !== 0;
};

/**
 * 过滤为非传输值的key，并将其重新赋值为所传值
 * @param obj    要过滤的对象
 * @param value  想要的值的状态
 */
export const filterObj = (obj: Record<string, boolean>, value: boolean) => {
  let result: Record<string, boolean> = {};
  Object.keys(obj).filter((key) => {
    if (obj[key] === !value) {
      result[key] = obj[key];
      result = {
        ...result,
        [key]: value,
      };
    }
    return true;
  });
  return result;
};

/**
 *
 * @param object
 * @param type
 */
export const isEmptyFields = (
  object: Record<string, any>,
  type?: "object" | "boolean"
) => {
  const arr: string[] = [];
  Object.keys(object).filter((key) => {
    const value = object[key];
    const valueState = value?.state || value;
    if (type === "boolean" && arr.length === 0) {
      if (valueState === true) {
        arr.push(value);
      }
    } else if (value === "" || value === undefined) {
      arr.push(key);
    }
    return true;
  });
  return arr;
};
