import React, { FC } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { pxToRem, enlargeFontsize, toLogin } from '@rjp/common/utils';
import { Button, SideCard } from '@rjp/common/component';
import { FormattedMessage } from 'react-intl';

import startBottomImage from '@rjp/common/assets/images/start-bottom.png';
import { trackingFunc, RJP_VIEW, RJP_LINK } from '@/utils/tracking';

interface IStart extends Pick<RouteComponentProps, 'history'> {}

const StartWrapper = styled.div`
  position: relative;
  height: ${pxToRem(764)};
`;

const CurSideCard = styled(SideCard)`
  position: absolute;
  top: ${pxToRem(19)};
  right: ${pxToRem(10)};
  z-index: 100;
`;

const StartHeader = styled.div`
  height: ${pxToRem(150)};
  line-height: ${pxToRem(150)};
  font-weight: bold;
  background: ${(props) => props.theme.color.sunlife_blue_medium};
  color: ${(props) => props.theme.color.white};
  padding-left: ${pxToRem(30)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 32)};
`;

const BottomImage = styled.img`
  height: ${pxToRem(251)};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const StartTitle = styled.div`
  font-weight: 500;
  width: ${pxToRem(547)};
  height: ${pxToRem(35)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 28)};
  line-height: ${pxToRem(37)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  margin-top: ${pxToRem(49)};
  display: flex;
  align-items: center;
  margin-left: ${pxToRem(32)};
`;

const EntranceBtnWrapper = styled.div`
  display: flex;
  margin-top: ${pxToRem(60)};
  padding-left: ${pxToRem(30)};
`;

const AccountTip = styled.div`
  margin-top: ${pxToRem(90)};
  margin-left: ${pxToRem(30)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  line-height: ${pxToRem(21)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  white-space: pre-wrap;
  position: relative;
  z-index: 2;
`;

const UnderLine = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const EntranceBtnContent = styled.div`
  height: ${pxToRem(89)};
  min-width: ${pxToRem(252)};
`;

const EntranceBtnTip = styled.div`
  font-weight: bold;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  height: ${pxToRem(24)};
  color: ${(props) => props.theme.color.grey_dark};
`;

const EntranceBtn = styled((props) => (
  <Button {...props} width={170} type="primary-yellow" />
))`
  margin-top: ${pxToRem(19)};
`;

const btnContent = [
  // {
  //   role: 'client',
  //   tip: 'ImSunLifeClient',
  //   btnContent: 'ClientSignIn'
  // },
  {
    role: 'member',
    tip: 'IAmConsideringTo',
    btnContent: 'MemberSignIn',
  },
];

const Start: FC<IStart> = ({ history }) => {
  const goCreateAccount = () => {
    history.push('/member/create');
  };

  return (
    <StartWrapper>
      <StartHeader>
        <FormattedMessage id="Start.Welcome" />
      </StartHeader>
      <StartTitle>
        <FormattedMessage id="Start.TellUsWhoYouAre" />
      </StartTitle>
      <EntranceBtnWrapper>
        {btnContent.map((item) => (
          <EntranceBtnContent key={item.role}>
            <EntranceBtnTip>
              <FormattedMessage id={`Start.${item.tip}`} />
            </EntranceBtnTip>
            <EntranceBtn onClick={toLogin(item.role, history, 'memBtn')}>
              <FormattedMessage id={`Start.${item.btnContent}`} />
            </EntranceBtn>
          </EntranceBtnContent>
        ))}
      </EntranceBtnWrapper>
      <AccountTip>
        <FormattedMessage
          id="Start.DonTHaveAnAccount"
          values={{
            underLine: (text: string) => (
              <UnderLine onClick={goCreateAccount}>{text}</UnderLine>
            ),
          }}
        />
      </AccountTip>
      <BottomImage src={startBottomImage} />
      <CurSideCard />
    </StartWrapper>
  );
};

export default Start;
