import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import {
  ApiErrorTip,
  CheckBox,
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Button,
} from '@rjp/common/component';
import { FooterButtonProps } from '@rjp/common/component/modal/FooterLayout';

interface IFooterButtonProps extends FooterButtonProps {
  message: string;
  title: string;
  values?: Record<string, any>;
}

interface LetOurAdvisorHelpProps {
  setContactAdvisorCheck: (value: boolean) => void;
  showError: boolean;
  errorCode: string;
  config: IFooterButtonProps[];
}

const prefix = 'PopModal.ContactAdvisor.';
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const Wrapper = styled.div`
  width: ${pxToRem(568)};
  font-size: ${pxToRem(16)};
  margin: auto;
  line-height: ${pxToRem(21)};
`;

const Note = styled(CurFormattedMessage)`
  margin-left: ${pxToRem(20)};
  margin-bottom: ${pxToRem(30)};
`;

const ContentWrapper = styled.div`
  height: ${pxToRem(270)};
  padding: ${pxToRem(27)} ${pxToRem(10)} ${pxToRem(27)} ${pxToRem(20)};
  background: ${(props) => props.theme.color.cool_blue_light_20};

  .check-box {
    .check {
      margin-right: ${pxToRem(14)};
    }
  }
`;

const ContentList = styled(CurFormattedMessage)`
  margin-left: ${pxToRem(34)};
  margin-top: ${pxToRem(20)};
`;

const Or = styled(CurFormattedMessage)`
  margin: 0 ${pxToRem(25)};
  ::before {
    content: '';
    width: ${pxToRem(1)};
    height: ${pxToRem(32)};
    margin-left: ${pxToRem(10)};
    margin-bottom: ${pxToRem(7.5)};
    display: block;
    border-left: ${pxToRem(1)} solid ${(props) => props.theme.color.grey_medium};
  }
  ::after {
    content: '';
    width: ${pxToRem(1)};
    height: ${pxToRem(32)};
    margin-left: ${pxToRem(10)};
    margin-top: ${pxToRem(7.5)};
    display: block;
    border-left: ${pxToRem(1)} solid ${(props) => props.theme.color.grey_medium};
  }
`;

const ButtonTitle = styled(CurFormattedMessage)`
  margin-bottom: ${pxToRem(15)};
  font-weight: 500;
`;

const ButtonMsg = styled(CurFormattedMessage)`
  ${(props) =>
    props.theme.language === 'zh-hant' && `letter-spacing: ${pxToRem(1)};`}
`;

const Buttons = styled.div`
  width: ${pxToRem(242)};
  text-align: center;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const bold = (msg: string) => <Bold>{msg}</Bold>;

const ButtonsFooter: FC<IFooterButtonProps> = ({
  message,
  type,
  width,
  onClick,
  style,
  values,
  title,
}) => (
  <Buttons>
    <ButtonTitle id={title} />
    <Button
      key={message}
      type={type}
      width={width}
      onClick={onClick}
      style={style}
    >
      <ButtonMsg id={message} values={values} />
    </Button>
  </Buttons>
);

const LetOurAdvisorHelp: FC<LetOurAdvisorHelpProps> = ({
  setContactAdvisorCheck,
  showError,
  errorCode,
  config,
}) => {
  const onChange = (name: string, value: boolean) => {
    setContactAdvisorCheck(value);
  };
  return (
    <Wrapper>
      <Note id="YourRetirementInformation" />
      <ContentWrapper>
        <CheckBox width={20} onChange={onChange}>
          <CurFormattedMessage
            id="IGiveConsentForMyAssignedAdvisor"
            values={{ bold }}
          />
        </CheckBox>
        <ContentList id="List" />
      </ContentWrapper>
      <ApiErrorTip
        width={568}
        showError={showError}
        id={errorCode}
        margin={`${pxToRem(10)} 0 0 0`}
      />
      <div className="ant-modal-footer">
        <ButtonsFooter {...config[0]} />
        <Or id="Or" />
        <ButtonsFooter {...config[1]} />
      </div>
    </Wrapper>
  );
};

export default LetOurAdvisorHelp;
