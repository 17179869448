import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { logout as logoutAPI } from '@rjp/main/src/api';
import { ThunkAPIConfig } from '@rjp/main/src/app/store';
import { resetUserInfo } from '@rjp/main/src/features/userInfo/userInfoSlice';
import { resetAdvisorEdit } from '@rjp/main/src/features/advisorEdit/advisorEditSlice';

export interface IAuthDetail {
  email: string;
  isRememberMe: boolean;
}

export type IRememberMe = {
  [role in 'member' | 'client' | 'advisor']: IAuthDetail;
};

type authState = IRememberMe & {
  loggedInTime: string;
  lastActiveTime: string;
};

const initialState: authState = {
  loggedInTime: '',
  lastActiveTime: '',
  member: {
    isRememberMe: false,
    email: '',
  },
  client: {
    isRememberMe: false,
    email: '',
  },
  advisor: {
    isRememberMe: false,
    email: '',
  },
};

export const logout = createAsyncThunk<void, void, ThunkAPIConfig>(
  'auth/logout',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await logoutAPI();
    } catch (err) {
      return rejectWithValue(err);
    } finally {
      dispatch(resetUserInfo());
      dispatch(resetAdvisorEdit());
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateRememberMe(state, action: PayloadAction<Partial<IRememberMe>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setLoggedIn(state) {
      state.loggedInTime = dayjs().utc().format();
    },
    setLastActiveTime(state) {
      state.lastActiveTime = dayjs().utc().format();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.loggedInTime = '';
    });
  },
});

export const {
  updateRememberMe,
  setLoggedIn,
  setLastActiveTime,
} = auth.actions;

export default auth.reducer;
