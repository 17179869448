import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import { enlargeFontsize, pxToRem, toLogin } from '@rjp/common/utils';
import { Button, Header, Label } from '@rjp/common/component';

interface PrimaryYellowButtonProps {
  message: string;
  onClick: () => void;
}

interface ICreateAccountHeader extends Pick<RouteComponentProps, 'history'> {
  noSaveResult?: boolean;
  isCreated: boolean;
}

const HeaderLeft = styled(Label)`
  width: ${pxToRem(670)};
  padding: ${pxToRem(35)} 0 0 ${pxToRem(30)};
  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 32)};
  box-sizing: border-box;
  text-align: left;
`;

const HeaderRight = styled.div`
  display: flex;
  padding: ${pxToRem(20)} ${pxToRem(30)} ${pxToRem(20)} 0;
  color: ${(props) => props.theme.color.white};
  box-sizing: border-box;
  text-align: left;
`;

// const OtherRight = styled.div`
//   display: flex;
//   margin-top: ${pxToRem(29)};
//   width: ${props => pxToRem(props.theme.language === 'en' ? 466 : 486)};
//   height: ${pxToRem(46)};
//   margin-left: ${props => pxToRem(props.theme.language === 'en' ? 86 : 110)};
//   margin-right: ${pxToRem(10)};
// `;
//
// const ExistingClientsTip = styled.div`
//   width: ${props => pxToRem(props.theme.language === 'en' ? 270 : 263)};
//   line-height: ${props => props.theme.language === 'en' ? 1.4 : pxToRem(46)};
//   font-size: ${props => enlargeFontsize(props.theme.language, 18)};
//   color: ${props => props.theme.color.sunlife_yellow_medium};
// `;

const SignIn = styled.div`
  width: ${pxToRem(250)};
  color: ${(props) => props.theme.color.sunlife_yellow_medium};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 20)};
  line-height: ${pxToRem(26)};
  text-align: center;
`;

const SignInLabel = styled(Label)`
  height: ${pxToRem(46)};
  margin-bottom: ${pxToRem(20)};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const PrimaryButton = styled((props) => (
  <Button type="primary-yellow" width={190} {...props} />
))`
  margin: auto;
`;

const PrimaryYellowButton: FC<PrimaryYellowButtonProps> = ({
  message,
  onClick,
}) => (
  <PrimaryButton onClick={onClick}>
    <FormattedMessage id={`Login.CreateAccount.${message}`} />
  </PrimaryButton>
);

const CommonRightHeader: FC<Pick<ICreateAccountHeader, 'history'>> = ({
  history,
}) => (
  <HeaderRight>
    <SignIn>
      <SignInLabel>
        {/*<FormattedMessage id="Login.CreateAccount.IAmASunLifeClient" />*/}
      </SignInLabel>
      {/*<PrimaryYellowButton message="ClientSignIn" onClick={toLogin('client', history)} />*/}
    </SignIn>
    <SignIn>
      <SignInLabel>
        <FormattedMessage id="Login.CreateAccount.IAmConsideringToPurchaseAPolicy" />
      </SignInLabel>
      <PrimaryYellowButton
        message="MemberSignIn"
        onClick={toLogin('member', history)}
      />
    </SignIn>
  </HeaderRight>
);

const CreateAccountHeader: FC<ICreateAccountHeader> = ({
  isCreated,
  history,
  noSaveResult,
}) => (
  <>
    {isCreated ? (
      <Header>
        <HeaderLeft>
          <FormattedMessage id="Login.CreateAccount.NearlyThere" />
        </HeaderLeft>
        <CommonRightHeader history={history} />
      </Header>
    ) : (
      <Header
        leftHeader={{
          title: `Login.CreateAccount.${
            noSaveResult ? 'CreateAnAccount' : 'SaveYourResults'
          }`,
          detail: `Login.CreateAccount.${
            noSaveResult
              ? 'WeRecommedYouDoTheRetirement'
              : 'ToSaveYourRetirementGapInformation'
          }`,
        }}
        titleMarginBottom={noSaveResult ? 15 : 30}
      >
        {!noSaveResult && <CommonRightHeader history={history} />}
      </Header>
    )}
  </>
);

export default CreateAccountHeader;
