import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { pxToRem } from "@rjp/common/utils";
import { Modal, ModalProps } from "@rjp/common/component";

interface TimeoutModalProps extends ModalProps {
  expiredSecond: number;
  onContinue: () => void;
}

const ModalDesc = styled.div`
  margin-top: ${pxToRem(30)};
  margin-bottom: ${pxToRem(30)};
  font-size: ${pxToRem(18)};
  font-weight: 500;
  line-height: ${pxToRem(24)};
`;

const StyleDecoration = styled.span`
  font-size: ${pxToRem(28)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const count = (count: number) => <StyleDecoration>{count}</StyleDecoration>;

const TimeoutModal: FC<TimeoutModalProps> = ({
  visible,
  onClose,
  children,
  expiredSecond,
  onContinue,
  ...props
}) => (
  <Modal
    {...props}
    onClose={onClose}
    visible={visible}
    width={718}
    textAlign="center"
    footerConfig={[
      {
        message: "Common.Continue",
        type: "primary",
        onClick: onContinue,
        width: 250,
      },
    ]}
  >
    <ModalDesc>
      <FormattedMessage
        id={`Common.Timeout.ThisSessionIsAboutToExpire`}
        values={{ count, time: expiredSecond }}
      />
    </ModalDesc>
    {children}
  </Modal>
);

export default TimeoutModal;
