import { trackingFunc, RJP_VIEW, RJP_LINK } from '../../main/src/utils/tracking';

const toLogin = (role: string, history: any, opt?: string) => () => {
  if (opt == 'memBtn'){
    trackingFunc(RJP_LINK, 'other', 'member sign in');
  }
  if (opt == 'activation') {
    trackingFunc(RJP_LINK, 'other', 'account activated - sign in');
  }
  
  const params = new URLSearchParams(window.location.search);
  const locale = params.get("locale") || "";
  if (process.env.REACT_APP_IS_SUNLIFE_API === "true") {
    // const urlObject = new URL(`${window.location.origin}`);
    // urlObject.searchParams.append("response_type", "code");
    // urlObject.searchParams.append("scope", "scope_anonymous");
    // urlObject.searchParams.append(
    //   "client_id",
    //   process.env.REACT_APP_SUNLIFE_CLIENT_ID ?? ""
    // );
    // urlObject.searchParams.append("role", role);
    //urlObject.searchParams.append("original-url", "https%3A%2F%2Flocalhost");
    // window.location.assign(
    //   `${urlObject.toString()}&redirect_uri=${
    //     window.location.origin
    //   }/rjp/authorized`
    // );
    // urlObject.searchParams.append("original-url", window.location.origin);
    window.location.assign(`${window.location.origin}/${role}/login?original-url=https%3A%2F%2Fldsewf###`)
  } else {
    const query = {
      locale: locale!,
      "original-url": "https://local.host/###",
    };
    const qs = new URLSearchParams(query);
    history.push({
      pathname: `/${role}/login`,
      search: qs.toString(),
    });
  }
};

export default toLogin;
