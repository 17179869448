import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAPIConfig } from '@rjp/main/src/app/store';
import { editSessionStatus as editSessionStatusAPI } from '@rjp/main/src/api';

export interface IAdvisorEdit {
  [id: string]: string;
}

const initialState: IAdvisorEdit = {};

interface IEditSessionStatusThunkResponse {
  clientId: string;
  edit_session_expiry: string;
}

export const editSessionStatus = createAsyncThunk<
  IEditSessionStatusThunkResponse,
  string,
  ThunkAPIConfig
>('advisorEdit/editSessionStatus', async (clientId, { rejectWithValue }) => {
  try {
    if (clientId) {
      const { edit_session_expiry } = await editSessionStatusAPI({ clientId });
      return { clientId, edit_session_expiry };
    }
    throw new Error('miss client id !');
  } catch (err) {
    return rejectWithValue(err);
  }
});

const advisorEdit = createSlice({
  name: 'advisorEdit',
  initialState,
  reducers: {
    resetAdvisorEdit() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      editSessionStatus.fulfilled,
      (state, action: PayloadAction<IEditSessionStatusThunkResponse>) => {
        const { clientId, edit_session_expiry } = action.payload;
        return edit_session_expiry
          ? {
              ...state,
              [clientId]: edit_session_expiry,
            }
          : state;
      }
    );
  },
});

export const { resetAdvisorEdit } = advisorEdit.actions;

export default advisorEdit.reducer;
