import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { theme } from '@rjp/common/constants';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  CollapseCard,
} from '@rjp/common/component';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`WorkSurface.Home.${id}`} />
);

const CurCollapseCard = styled(CollapseCard)`
  width: ${pxToRem(670)};
  border-radius: 0px 0px ${pxToRem(5)} ${pxToRem(5)};
  margin-bottom: ${pxToRem(20)};
  box-shadow: none;
  .title {
    font-weight: 500;
    font-size: ${pxToRem(18)};
    height: ${pxToRem(16)};
    line-height: ${pxToRem(16)};
  }
  .content {
    padding-top: ${pxToRem(12)};
    padding-bottom: 0;
  }
  svg {
    path {
      fill: ${(props) => props.theme.color.coolblue};
    }
  }
`;

const Details = styled(CurFormattedMessage)`
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(21)};
  padding: ${pxToRem(15)};
  padding-bottom: 0;
`;

const detailEnum: { [k: string]: string } = {
  details: 'AboutRetirementDetails',
  products: 'AboutProducts',
  report: 'AboutTrueReport',
};

const AboutRDetails: FC<{ active: string; defaultOpen?: boolean }> = ({
  active,
  defaultOpen,
}) => (
  <CurCollapseCard
    titleConfig={{
      title: `WorkSurface.Home.${detailEnum[active] || active}.Title`,
    }}
    defaultClose={!defaultOpen}
    backgroundColor={theme.color.cool_blue_light}
    color={theme.color.sunlife_blue_medium}
  >
    <Details id={`${detailEnum[active] || active}.Desc`} />
  </CurCollapseCard>
);

export default AboutRDetails;
