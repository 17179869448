import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { Modal, ModalProps, Label } from '@rjp/common/component';
// import { ReactComponent as UpArrow } from '@rjp/common/assets/images/product-modal-up-arrow.svg';
import { ReactComponent as AlertCircle } from '@rjp/common/assets/images/alert-circle.svg';
import {currencyNoPrefixAndDecimalFormat, currencyNoPrefixFormat, pxToRem} from '@rjp/common/utils';
import {
  configs,
  currencyValue,
  lastPremiumField,
  needTranslation,
  needValueTranslation,
} from './config';
import { trimAllReg } from '@rjp/common/config';
import {useSelector} from "react-redux";
import {RootState} from "@/app";

interface ProductsModalProps extends ModalProps {
  isPurchasedProducts?: boolean;
  productName: string;
  role: string;
  config: Record<string, any>;
  onDelete?: (id: string) => void;
}

const StyleModal = styled((props) => <Modal {...props} />)`
  .ant-modal-body {
    padding: 0;

    .modal-content {
      max-height: ${pxToRem(700)};
    }
  }
`;

const Content = styled.div`
  width: ${pxToRem(550)};
  padding: ${pxToRem(50)};
  box-sizing: content-box;
`;

const ContentTop = styled.div`
  width: ${pxToRem(550)};
  // height: ${pxToRem(91)};
  margin-bottom: ${pxToRem(6)};
  font-size: ${pxToRem(16)};
  display: flex;
  justify-content: space-between;
  position: relative;

  .content-left {
    color: ${(props) => props.theme.color.sunlife_blue_medium};
  }

  .content-right {
    text-align: right;
  }
`;

const Top = styled.div`
  height: ${pxToRem(22)};
  padding: 0 ${pxToRem(10)};
  line-height: ${pxToRem(26)};
  font-size: ${pxToRem(14)};
  position: absolute;
  top: ${pxToRem(20)};
  border-radius: ${pxToRem(5)};
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
`;

const StyleLabel = styled((props) => <Label {...props} />)<{
  lineHeight: number;
}>`
  line-height: ${(props) => pxToRem(props.lineHeight)};
`;

// const PercentValue = styled(({ isPurchasedProducts, ...props }) => <Label {...props} />)<Pick<ProductsModalProps, 'isPurchasedProducts'>>`
//   margin-top: ${pxToRem(6)};
//   line-height: ${pxToRem(42)};
//   color: ${props => (props.isPurchasedProducts ? props.theme.color.sunlife_blue_medium : props.theme.color.sunlife_blue_light)};
//
//   svg {
//     width: ${pxToRem(25)};
//     height: ${pxToRem(25)};
//     margin-right: ${pxToRem(4)};
//     path {
//       fill: ${props => props.theme.color.sunlife_blue_light};
//     }
//   }
// `;

const Note = styled.div`
  width: ${pxToRem(550)};
  height: ${pxToRem(56)};
  line-height: ${pxToRem(20)};
  padding: ${pxToRem(8)} ${pxToRem(12)};
  margin-bottom: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  border-radius: ${pxToRem(4)};
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  color: ${(props) => props.theme.color.sunlife_blue};

  .note-right {
    display: inline-block;
  }

  svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    margin-right: ${pxToRem(10)};
    vertical-align: ${pxToRem(10)};
    path {
      fill: ${(props) => props.theme.color.sunlife_blue};
    }
  }
`;

const Bold = styled.span<{
  fontSize: number;
  lineHeight: number;
  color?: string;
}>`
  font-size: ${(props) => pxToRem(props.fontSize)};
  font-weight: 500;
  line-height: ${(props) => pxToRem(props.lineHeight)};
  ${(props) => props.color && `color: ${props.color};`}
`;

const SwitchWrapper = styled.div`
  width: ${pxToRem(550)};
  height: ${pxToRem(32)};
  padding: ${pxToRem(1)};
  background: ${(props) => props.theme.color.sunlife_blue};
`;

const SwitchItem = styled.div<{ isActiveTab: boolean; isSingle: boolean }>`
  width: ${(props) => pxToRem(props.isSingle ? 547 : 273.5)};
  height: ${pxToRem(27)};
  line-height: ${pxToRem(27)};
  font-weight: 500;
  text-align: center;
  display: inline-block;
  user-select: none;
  ${(props) => !props.isSingle && 'cursor: pointer;'}
  ${(props) =>
    !props.isActiveTab
      ? `background: ${props.theme.color.white};
  color: ${props.theme.color.sunlife_blue};`
      : `color: ${props.theme.color.white};`}
`;

const SwitchContent = styled.div<{ isSummary: boolean }>`
  width: ${pxToRem(550)};
  height: ${pxToRem(360)};
  overflow: auto;
  font-size: ${(props) => pxToRem(props.isSummary ? 14 : 16)};
  padding: ${(props) =>
    props.isSummary ? `${pxToRem(15)} ${pxToRem(12)}` : 0};
  background: ${(props) =>
    props.isSummary
      ? props.theme.color.cool_blue_light_20
      : props.theme.color.white};
`;

const RiderWrapper = styled.div`
  padding: ${pxToRem(15)} ${pxToRem(19)};
  background: ${(props) => props.theme.color.cool_blue_light_20};
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: ${pxToRem(33)};

  .item-value {
    text-align: right;
  }
`;

const RidersItem = styled.li`
  margin-left: ${pxToRem(20)};
  line-height: ${pxToRem(28)};
  font-size: ${pxToRem(14)};
`;

const bold = (msg: string) => (
  <Bold fontSize={16} lineHeight={21}>
    {msg}
  </Bold>
);
const formatMsg = (id: string) => <FormattedMessage id={id} />;

const prefix = 'PopModal.ProductSummary.';

const ProductsModal: FC<ProductsModalProps> = ({
  visible = false,
  onClose,
  onDelete,
  isPurchasedProducts = true,
  productName,
  config,
  role,
}) => {
  // const themeContext = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState('summary');

  const modalConfig = useMemo(() => configs[productName], [productName]);
  const retirementProfile = useSelector(
    (state: RootState) => state.RCData.retirementProfile
  );
  const { parameters } = config;

  const close = useCallback(() => {
    setActiveTab('summary');
    onClose?.();
  }, [onClose]);

  const deleteQuotes = () => {
    onDelete?.(config.id);
    close();
  };

  const hasSavingBooster = useMemo(
    () => !!config.GCV_SB && config.GCV_SB !== '0',
    [config.GCV_SB]
  );
  const hasProtectionBooster = useMemo(
    () =>
      !!parameters?.ProtectionBooster && parameters?.ProtectionBooster !== '0',
    [parameters]
  );
  const tabList = useMemo(() => {
    if (hasSavingBooster || hasProtectionBooster) {
      return ['summary', 'riders'];
    }
    return ['summary'];
  }, [hasSavingBooster, hasProtectionBooster]);

  const riderList = useMemo(() => {
    const listValue = [
      { label: 'SavingBooster', value: config.GCV_SB },
      {
        label: 'SumAssuredProtectionBooster',
        value: parameters?.ProtectionBooster,
      },
    ];
    const list = listValue
      .filter((item) => !!item.value)
      .map((item) => item.label);
    return list;
  }, [config.GCV_SB, parameters]);

  const premiumLabel = useMemo(() => {
    if (parameters?.PremTerm === 'Single Pay') {
      return 'SinglePremium';
    }
    return 'InitialPaymentModePremium';
  }, [parameters]);

  const currentValue = (label: string, name: string) => {
    let value = parameters?.[name] || '';
    if (name === "ModalPremium") {
      value = config.illustration?.plan_summary?.premium || config.illustration?.policy?.vectors?.find((v:any) => v.name === "Premium")?.value;
    } else if (name === "NotionalAmount") {
      // const i = retirementProfile?.retirement_age - (config.parameters.Age as number) - 2;
      value = config.illustration?.plan_explanations[0].notional_amount;
    } else if (name === "FaceAmount") {
      value = config.illustration?.policy?.vectors?.find((v:any) => v.name === "GSA")?.value;
    }
    if (needTranslation.includes(label)) {
      return (
        <FormattedMessage
          id={`${prefix}Value.${value}`}
          values={{ age: parameters?.bt, year: parameters?.AccPeriod }}
        />
      );
    }
    if (needValueTranslation.includes(label)) {
      return (
        <FormattedMessage
          id={`${prefix}Value.${
            typeof value === 'number' || label === 'AccumulationTerm'
              ? label
              : value.toString().replace(trimAllReg, '')
          }`}
          values={{
            age: parameters?.bt,
            year: parameters?.AccPeriod,
            couponStrategy: parameters?.MonthlyCouponStrategy
          }}
        />
      );
    }
    if (currencyValue.includes(label)) {
      let currencyValue = value;
      if (label === 'SavingBooster') {
        currencyValue = config.GCV_SB;
      }
      if (label === 'BasicPlan') {
        currencyValue = config.GCV_Comm;
      }
      if (label === premiumLabel) {
        currencyValue = config.TotalPrem;
      }
      return currencyNoPrefixAndDecimalFormat(Number(currencyValue || 0));
    }
    return value;
  };

  const isValidStatus = useMemo(
    () =>
      !isPurchasedProducts &&
      dayjs(config.creation_date).valueOf() <
        dayjs(config.product_last_update_date).valueOf() &&
      config.product_status !== 'Valid',
    [isPurchasedProducts, config]
  );

  return (
    <StyleModal
      width={652}
      visible={visible}
      onClose={close}
      footerConfig={
        !isPurchasedProducts && role === 'advisor'
          ? [
              {
                message: `${prefix}Delete`,
                width: 170,
                type: 'secondary',
                onClick: deleteQuotes,
              },
              { message: `${prefix}Close`, width: 170, onClick: close },
            ]
          : undefined
      }
    >
      <Content>
        {isValidStatus && (
          <Top>
            <FormattedMessage id={`${prefix}${config.product_status}`} />
          </Top>
        )}
        <ContentTop>
          <div className="content-left">
            <StyleLabel fontSize={28} lineHeight={37}>
              <FormattedMessage id={`${prefix}${productName}`} />{config.description ? " - " + config.description: ""}
            </StyleLabel>
            {isPurchasedProducts && (
              <>
                <FormattedMessage
                  id={`${prefix}PolicyDate`}
                  values={{
                    bold,
                    date: dayjs(config.creation_date).format('DD/MM/YY'),
                  }}
                  tagName="div"
                />
                <FormattedMessage
                  id={`${prefix}PolicyNo`}
                  values={{ bold, value: config.policyNo }}
                  tagName="div"
                />
              </>
            )}
          </div>
        </ContentTop>
        {isValidStatus && (
          <Note>
            <AlertCircle />
            <div className="note-right">
              <FormattedMessage
                id={`${prefix}${config.product_status}Note`}
                values={{
                  bold,
                  date: dayjs(config.creation_date).format('DD/MM/YY'),
                }}
              />
            </div>
          </Note>
        )}
        <SwitchWrapper>
          {tabList.map((item: string) => (
            <SwitchItem
              key={item}
              isSingle={tabList.length === 1}
              isActiveTab={activeTab === item}
              onClick={() => setActiveTab(item)}
            >
              <FormattedMessage id={`${prefix}Tab.${item}`} />
            </SwitchItem>
          ))}
        </SwitchWrapper>
        <SwitchContent isSummary={activeTab === 'summary'}>
          {activeTab === 'summary' ? (
            <>
              {modalConfig.summary.map(
                (item: { label: string; name: string }) => {
                  const currentLabel = lastPremiumField.includes(item.label)
                    ? premiumLabel
                    : item.label;
                  return (item.label === 'SavingBooster' &&
                    !hasSavingBooster) ||
                    (item.label === 'SumAssuredProtectionBooster' &&
                      !hasProtectionBooster) ? (
                    <></>
                  ) : (
                    <SummaryItem key={currentLabel}>
                      <Label>
                        <FormattedMessage
                          id={`${prefix}Summary.${currentLabel}`}
                          values={{
                            paymentMode: formatMsg(
                              `${prefix}Value.${
                                parameters?.PremMode || 'Annually'
                              }`
                            ),
                          }}
                        />
                      </Label>
                      <div className="item-value">
                        {currentValue(currentLabel, item.name)}
                      </div>
                    </SummaryItem>
                  );
                }
              )}
            </>
          ) : (
            <RiderWrapper>
              <ul>
                {riderList.map((item: string) => (
                  <RidersItem key={item}>
                    <FormattedMessage id={`${prefix}Riders.${item}`} />
                  </RidersItem>
                ))}
              </ul>
            </RiderWrapper>
          )}
        </SwitchContent>
      </Content>
    </StyleModal>
  );
};

export default ProductsModal;
