import React, { FC, useEffect, useMemo, useRef, useState, memo } from "react";
import { Slider as AntSlider } from "antd";
import { SliderSingleProps as AntSliderProps } from "antd/es/slider";
import styled from "styled-components";
import { useIntl } from "react-intl";

import {
  pxToRem,
  enlargeFontsize,
  clamp,
  currencyFormat,
  valueToStep,
  stepToValue,
} from "@rjp/common/utils";
import {
  largeStepLogic,
  smallStepFields,
  smallStepLogic,
} from "@rjp/main/src/pages/workSurface/home/details/config";
import InputNumber, { InputNumberProps } from "../input/InputNumber";
import { typeSliderMap } from "./sliderConfigs";
import ErrorMessage from "../errorMessage";
import { ErrorMessageProps } from "../errorMessage/ErrorMessage";

export interface SliderProps
  extends Omit<AntSliderProps, "onChange">,
    Pick<InputNumberProps, "hasError"> {
  name?: string;
  type?: "small" | "medium" | "large";
  hasInput?: boolean;
  toolTip?: boolean;
  value: number;
  step?: number;
  tooltipValue?: number | string;
  width?: number;
  inputWidth?: InputNumberProps["width"];
  inputHeight?: InputNumberProps["height"];
  inputPrefix?: InputNumberProps["prefix"];
  inputSuffix?: InputNumberProps["suffix"];
  prefix?: string;
  suffix?: string;
  inputTextAlign?: InputNumberProps["textAlign"];
  margin?: string;
  hasButton?: boolean;
  errorType?: string;
  onChange?: (value: number) => void;
  onBlur?: (value: number) => void;
  align?: string;
  errorTipValue?: number;
  mathRound?: boolean;
  canOutOfRange?: boolean;
  moneyType?: JSX.Element | string;
  assured?: boolean;
  translateX?: number;
  sliderType?: "green" | "yellow";
  showError?: boolean;
  currency?: string;
  backgroundColor?: string;
  errorMsg?: string;
  isDelay?: boolean;
  handleErrorState?: (name: string, state: boolean) => void;
  direction?: ErrorMessageProps["direction"];
  needStepLogic?: boolean;
}

type StyledSliderProps =
  | "width"
  | "type"
  | "sliderType"
  | "disabled"
  | "backgroundColor";

const StyledSlider = styled(AntSlider)<Pick<SliderProps, StyledSliderProps>>`
  &.ant-slider {
    width: ${(props) => pxToRem(props.width || 200)};
    height: ${(props) => pxToRem(typeSliderMap[props.type ?? "large"])};
    margin: ${pxToRem(10)} ${pxToRem(12)} ${pxToRem(10)};
    padding: ${pxToRem(4)} 0;

    & .ant-slider-rail {
      height: ${(props) => pxToRem(typeSliderMap[props.type ?? "large"])};
      top: 0;
      background: ${(props) =>
        props.backgroundColor || props.theme.color.grey_light_0};
      border-radius: ${pxToRem(16)};
    }

    & .ant-slider-track {
      top: 0;
      height: ${pxToRem(8)};
      height: ${(props) => pxToRem(typeSliderMap[props.type ?? "large"])};
      border-radius: ${pxToRem(16)};
      background: ${(props) =>
        props.backgroundColor
          ? "none"
          : props.sliderType === "green"
          ? props.theme.color.extra_sharp_green
          : props.theme.color.sunlife_yellow};
    }

    & .ant-slider-step {
      top: 0;
      height: ${(props) => pxToRem(typeSliderMap[props.type ?? "large"])};
      border-radius: ${pxToRem(16)};
      position: absolute;

      & .ant-slider-dot {
        width: ${pxToRem(18)};
        height: ${pxToRem(18)};
        border-radius: 0;
        transform: rotate(45deg);
        margin-left: ${pxToRem(-9)};
        top: ${pxToRem(-5)};
        background: ${(props) => props.theme.color.mint_green_light};
        border: ${pxToRem(2)} solid
          ${(props) => props.theme.color.coolblue_medium};
      }
    }

    & .ant-slider-handle {
      width: ${pxToRem(25)};
      height: ${pxToRem(25)};
      margin-top: -${pxToRem(13)};
      border-width: ${pxToRem(5)};
      border-color: ${(props) =>
        props.sliderType === "green"
          ? props.theme.color.extra_sharp_green
          : props.theme.color.sunlife_yellow};

      &:after {
        content: "";
        background: ${(props) => props.theme.color.extra_dark_0};
        width: ${pxToRem(35)};
        height: ${pxToRem(35)};
        display: block;
        margin-left: ${pxToRem(-9.5)};
        margin-top: ${pxToRem(-9.5)};
        border-radius: ${pxToRem(17.5)};
      }
    }

    & .ant-slider-mark {
      display: none;
    }

    & .ant-slider-handle:focus {
      box-shadow: none;
    }
  }
  &.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${(props) =>
      props.sliderType === "green"
        ? props.theme.color.extra_sharp_green
        : props.theme.color.sunlife_yellow};
  }
`;

const ComponentWrapper = styled.div<Pick<SliderProps, "toolTip">>`
  margin-top: ${(props) => (props.toolTip ? pxToRem(32) : 0)};
  position: relative;
`;

const ComponentContentWrapper = styled.div<Pick<SliderProps, "toolTip">>`
  display: flex;
  justify-content: space-between;
`;

const SliderWrapper = styled.div<Pick<SliderProps, "margin">>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};

  .slider-prefix {
    margin-right: ${pxToRem(15)};
  }
  .slider-suffix {
    margin-left: ${pxToRem(15)};
  }

  & .anticon-minus-circle > svg,
  & .anticon-plus-circle > svg {
    width: ${pxToRem(31)};
    height: auto;
    color: ${(props) => props.theme.color.coolblue};
  }
  & .anticon-minus-circle > svg {
    margin-right: ${pxToRem(10)};
  }
  & .anticon-plus-circle > svg {
    margin-left: ${pxToRem(10)};
  }
`;

const ToolTipWrapper = styled.div<Pick<SliderProps, "width">>`
  top: ${pxToRem(-10)};
  display: flex;
  align-items: center;
  position: relative;
  margin-left: ${pxToRem(20)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 12.5)};
  font-weight: bold;

  & .slider-value {
    width: auto;
    min-width: ${pxToRem(40)};
    height: ${pxToRem(30)};
    line-height: ${pxToRem(30)};
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0 ${pxToRem(8)};
    box-sizing: border-box;
  }
`;

const needStepFields = [
  "monthly_income",
  "total_cash_savings",
  "retirement_monthly_income",
  "post_retirement_investment_mix",
  "total_mpf_savings",
  "monthly_mpf_savings",
  "mpf_investment_mix",
];

const Slider: FC<SliderProps> = ({
  name = "",
  value: fieldValue,
  hasInput = false,
  hasError,
  prefix,
  suffix,
  inputPrefix,
  inputSuffix,
  inputTextAlign = "center",
  inputWidth,
  inputHeight,
  toolTip = false,
  hasButton = false,
  min = 0,
  max = 100,
  width = 400,
  margin,
  step = 1,
  onChange,
  onBlur,
  errorType,
  align,
  disabled,
  errorTipValue,
  canOutOfRange,
  moneyType,
  assured,
  currency,
  backgroundColor,
  errorMsg,
  tooltipValue,
  handleErrorState,
  direction,
  needStepLogic,
  ...props
}) => {
  const intl = useIntl();
  const [showError, setShowError] = useState(false);
  const sliderRef = useRef<any>(null);
  const [inputMax, setInputMax] = useState(max);
  let sliderMax = useMemo(() => (fieldValue > max ? fieldValue : max), [
    fieldValue,
    max,
  ]);

  useEffect(() => handleErrorState?.(name || "", showError), [name, showError]);

  // const increment = () => {
  //   const newValue = boundedValue(Math.floor(fieldValue / step + 1) * step, min, max);
  //   if (newValue <= max) {
  //     onChange?.(newValue);
  //   }
  // };

  // const decrement = () => {
  //   const newValue = boundedValue(Math.ceil(fieldValue / step - 1) * step, min, max);
  //   if (newValue >= min) {
  //     onChange?.(newValue);
  //   }
  // };

  const onSliderChange: AntSliderProps["onChange"] = (value) => {
    let curValue = value;
    if (!!needStepLogic && needStepFields.includes(name)) {
      const errMaxCurrentValue =
        curValue > max
          ? Number(((value / inputMax) * (max - min) + min).toFixed(0))
          : curValue;
      const stepLogic = smallStepFields.includes(name)
        ? smallStepLogic
        : largeStepLogic;
      const currentStep = valueToStep(min, errMaxCurrentValue, stepLogic);
      const setStep = currentStep + 1;
      const maxStep = valueToStep(min, max, stepLogic);
      const stepValue = stepToValue(setStep, stepLogic, maxStep, min, max);
      const currentValue =
        stepValue <= min ? stepValue + min : stepValue > max ? max : stepValue;
      curValue =
        currentStep === maxStep ? max : currentStep === 0 ? min : currentValue;
    }
    onChange?.(curValue);
  };

  const onInputChange: InputNumberProps["onChange"] = (value) => {
    if (typeof value === "number") {
      onChange?.(value);
      if (value >= max) setInputMax(value);
    }
  };

  const parser: InputNumberProps["parser"] = (displayValue) => {
    if (displayValue === undefined || displayValue === "") {
      return "";
    }
    const parsedValue = displayValue.replace(/,/g, "");
    const parsedNumber = +parsedValue;
    if (Number.isNaN(parsedNumber)) {
      return fieldValue;
    }
    return parsedNumber;
  };

  const formatter: InputNumberProps["formatter"] = (value) => {
    if (inputPrefix === "$") {
      return intl.formatNumber(
        value === undefined || value === "" ? 0 : Math.round(+value)
      );
    }
    return (value || "").toString().replace(/,/g, "");
  };

  const onSliderAfterChange: AntSliderProps["onAfterChange"] = (value) => {
    const error = (fieldValue < min || fieldValue > max) && !!fieldValue;
    setShowError(error);
    handleErrorState?.(name || "", error);
    sliderRef?.current.blur();
    onBlur?.(fieldValue);
  };

  const onInputBlur: InputNumberProps["onBlur"] = () => {
    const error = (fieldValue < min || fieldValue > max) && !!fieldValue;
    setShowError(error);
    handleErrorState?.(name || "", error);
    sliderRef?.current.blur();
    onChange?.(fieldValue);
    onBlur?.(fieldValue);
  };

  const left = useMemo(
    () => (width * (clamp(fieldValue, min, max) - min)) / (max - min) - 30,
    [width, fieldValue, min, max]
  );

  return (
    <ComponentWrapper toolTip={toolTip}>
      <ComponentContentWrapper>
        <SliderWrapper margin={margin}>
          {prefix && <span className="slider-prefix">{prefix}</span>}
          <div>
            {toolTip && (
              <ToolTipWrapper width={width}>
                <div className="slider-value" style={{ left: pxToRem(left) }}>
                  {tooltipValue}
                </div>
              </ToolTipWrapper>
            )}
            <StyledSlider
              value={
                canOutOfRange ? clamp(fieldValue, min, sliderMax) : fieldValue
              }
              min={min}
              max={sliderMax}
              step={step}
              width={width}
              onChange={onSliderChange}
              onAfterChange={onSliderAfterChange}
              tipFormatter={null}
              disabled={disabled}
              backgroundColor={backgroundColor}
              ref={sliderRef}
              {...props}
            />
          </div>
          {suffix && <span className="slider-suffix">{suffix}</span>}
        </SliderWrapper>
        {hasInput && (
          <InputNumber
            width={inputWidth}
            height={inputHeight}
            value={fieldValue}
            onChange={onInputChange}
            onBlur={onInputBlur}
            min={min}
            max={max}
            formatter={formatter}
            parser={parser}
            prefix={inputPrefix}
            suffix={inputSuffix}
            textAlign={inputTextAlign}
            disabled={disabled}
            hasError={showError}
            maxLength={11}
          />
        )}
      </ComponentContentWrapper>
      {(hasError || showError) && !!errorMsg && (
        <ErrorMessage
          errorMsg={errorMsg}
          direction={direction}
          pixel={inputWidth ? inputWidth - 34 : 0}
          values={{
            min: min || 0,
            max: max || 100,
            value: currencyFormat(errorTipValue ?? 0, "zh", currency || "USD"),
            moneyType,
          }}
        />
      )}
    </ComponentWrapper>
  );
};

export default memo(Slider);
