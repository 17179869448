import styled from "styled-components";

import { pxToRem } from "../../utils";

export interface LabelProps {
  counterVariable?: string;
  paddingForCounter?: number;
  fontSize?: number;
}

/**
 * Counter variable is used for number bullet
 * NOTE FOR PARENT ELEMENT
 * 1. A string counter variable and padding-left must be set, and then pass into this component
 * https://www.w3schools.com/css/css_counters.asp
 */

const Label = styled.div<LabelProps>`
  font-weight: 500;
  ${(props) => props.fontSize && `font-size: ${pxToRem(props.fontSize)};`}
  ${(props) =>
    props.counterVariable &&
    props.paddingForCounter &&
    `&::before {
      counter-increment: ${props.counterVariable};
      content: "${props.counterVariable}.";
      margin-left: -${pxToRem(props.paddingForCounter)};
      position: absolute;
    }`}
`;

export default Label;
