import { createSlice } from '@reduxjs/toolkit';

interface loadingState {
  loadingCount: number;
}

const initialState: loadingState = {
  loadingCount: 0,
};

const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading(state) {
      state.loadingCount += 1;
    },
    hideLoading(state) {
      state.loadingCount -= 1;
    },
  },
});

export const { showLoading, hideLoading } = loading.actions;

export default loading.reducer;
