import sunlifeCaller from './sunlifeCaller';
import { IMember } from './type';
import {
  IGetMemberList,
  ICreateMember,
  IGetMember,
  IUpdateMember,
  IDeleteMember,
  IMemberVerificationRequest,
  IMemberVerificationRequestTemp,
  IMemberVerification,
  IContactAdvisor,
  IContactAdvisorResponse,
  IContactAdvisorExtendExpiry,
  IContactAdvisorExtendExpiryResponse,
} from './member.type';

export const getMemberList = async (params: IGetMemberList) =>
  process.env.REACT_APP_USE_POST_API_MEMBER_LIST_SEARCH === 'true'
    ? sunlifeCaller<{ members: IMember[] }>({
        method: 'post',
        // url: '/memberlistsearch',
        url: '/memberlistsearch',
        data: params,
      })
    : sunlifeCaller<{ members: IMember[] }>({
        method: 'get',
        url: '/members',
        params,
      });

export const createMember = async (data: ICreateMember) =>
  sunlifeCaller<{ member: IMember }>({
    method: 'post',
    params: { version: 0 },
    url: '/members',
    data,
  });

export const getMember = async ({ member_id, language }: IGetMember) =>
  process.env.REACT_APP_USE_POST_API_MEMBERS_PROFILE_SEARCH === 'true'
    ? sunlifeCaller<{ member: IMember }>({
        method: 'post',
        url: '/members/profilesearch',
        params: { language },
        data: {
          member_id,
        },
      })
    : sunlifeCaller<{ member: IMember }>({
        method: 'get',
        url: `/members/${member_id}`,
        params: { language },
      });

export const updateMember = async ({ id, ...data }: IUpdateMember) =>
  sunlifeCaller<{ member: IMember }>({
    method: 'post',
    url: `/members/${id}/update`,
    data,
  });

export const deleteMember = async ({ id }: IDeleteMember) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: `/members/${id}/delete`,
  });

export const memberVerification = async (data: IMemberVerification) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/members/verification',
    data,
  });

export const memberVerificationRequest = async (
  data: IMemberVerificationRequest
) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/members/verificationrequest',
    data,
  });

export const memberVerificationRequestTemp = async (
  data: IMemberVerificationRequestTemp
) =>
  sunlifeCaller<{}>({
    method: 'post',
    url: '/members/verificationrequest',
    data,
  });

export const contactAdvisor = async ({ id, ...data }: IContactAdvisor) =>
  sunlifeCaller<IContactAdvisorResponse>({
    method: 'post',
    url: `/members/${id}/contactadvisor`,
    data,
  });

export const contactAdvisorExtendExpiry = async ({
  id,
  ...data
}: IContactAdvisorExtendExpiry) =>
  sunlifeCaller<IContactAdvisorExtendExpiryResponse>({
    method: 'post',
    url: `/members/${id}/contactadvisor/extendexpiry`,
    data,
  });
