import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Button,
  ApiErrorTip,
} from '@rjp/common/component';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { RootState } from '@rjp/main/src/app';
import FormItem from './BaseFormItem';
import SearchResultModal from './SearchResultModal';
import { getMemberList, IMember } from '../../api';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`Search.Form.${id}`} />
);

const SearchFormWrapper = styled.div`
  padding: ${pxToRem(27)} ${pxToRem(14)} ${pxToRem(125)} ${pxToRem(14)};
`;

const Title = styled(CurFormattedMessage)`
  height: ${pxToRem(40)};
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(26)};
  margin-bottom: ${pxToRem(11)};
  font-weight: 500;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
`;

const SearchButton = styled(Button)`
  margin-top: ${pxToRem(19)};
`;

const formConfig = [
  {
    fieldName: 'first_name',
    name: 'FirstName',
    type: 'text',
  },
  {
    fieldName: 'last_name',
    name: 'LastName',
    type: 'text',
  },
  {
    fieldName: 'mobile',
    name: 'Mobile',
    type: 'mobile',
  },
  {
    fieldName: 'email',
    name: 'Email',
    type: 'text',
  },
];

const SearchForm: FC = () => {
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const history = useHistory();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('10002');
  // const language = useSelector((state: RootState) => state.translation.language);
  const agent_code = useSelector((state: RootState) => state.userInfo.id) ?? '';
  const [searchData, setSearchData] = useState<
    (IMember & {
      name: string;
      roleName: string;
      date_added_formatted: string;
      mobile_formatted: string;
      consent_expiry_date_formatted: string;
    })[]
  >([]);
  // , useRouteMatch

  const [formData, setFormData] = useState<{
    [key: string]: string | number | null | undefined;
  }>({
    first_name: '',
    last_name: '',
    email: '',
    area_code: '852',
    mobile: null,
  });

  const onChange = (key: string, value?: string | number) =>
    setFormData((data) => ({
      ...data,
      [key]: value,
    }));

  const searchResultView = (selectedID: string) => {
    setShowSearchResult(false);
    history.push(`/advisor/home?member_id=${selectedID}`);
  };

  const onSearch = async () => {
    const curFormData = { ...formData };
    Object.keys(curFormData).forEach((key) => {
      curFormData[key] = curFormData[key]
        ? curFormData[key]?.toString().toLocaleLowerCase()
        : curFormData[key];
    });

    try {
      const responseData = await getMemberList({
        ...curFormData,
        agent_code,
        // language,
      });
      setSearchData(
        responseData.members.map((info) => ({
          ...info,
          name: `${info.first_name} ${info.last_name}`,
          roleName: 'Member',
          date_added_formatted: dayjs(info.date_added).format('DD/MM/YYYY'),
          mobile_formatted: `+${info.area_code ?? ''} ${info.mobile ?? ''}`,
          consent_expiry_date_formatted: dayjs(
            info.consent_expiry_date || undefined
          ).format('DD/MM/YYYY'),
        }))
      );
      setShowSearchResult(true);
      setShowError(false);
    } catch (error:any) {
      setShowError(true);
      setErrorCode(error);
      // TODO: Show error
      console.log(error);
    }
  };

  return (
    <SearchFormWrapper>
      <Title id="SearchTitle" />
      <FormWrapper>
        {formConfig.map((item) => (
          <FormItem
            key={item.fieldName}
            {...item}
            originData={formData}
            onChange={onChange}
          />
        ))}
      </FormWrapper>
      {/* miss error message */}
      <ApiErrorTip showError={showError} id={errorCode} />
      {/* miss error message */}
      <SearchButton type="primary-yellow" width={204} onClick={onSearch}>
        <CurFormattedMessage id="Search" />
      </SearchButton>
      <SearchResultModal
        searchData={searchData}
        visible={showSearchResult}
        onClose={() => setShowSearchResult(false)}
        onView={searchResultView}
      />
    </SearchFormWrapper>
  );
};

export default SearchForm;
