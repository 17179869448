import React, { FC, useMemo, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';

import {
  enlargeFontsize,
  isEmptyField,
  pxToRem,
  toLogin,
} from '@rjp/common/utils';
import {
  Input,
  Button,
  Header,
  Label,
  InfoModal,
  ApiErrorTip,
} from '@rjp/common/component';
import { reg } from '@rjp/common/config';
import resetPasswordFieldConfig, {
  passwordRule,
  successfullyModalConfig,
} from './config';
import { resetPassword } from '../../api';

interface IResetPassword extends Pick<RouteComponentProps, 'history'> {
  token: string;
}

const Wrapper = styled.div`
  font-size: ${pxToRem(40)};
  text-align: center;
  white-space: pre-wrap;
`;

const Content = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  width: ${pxToRem(550)};
  margin-left: ${pxToRem(30)};
  text-align: left;
`;

const StyledButton = styled((props) => <Button {...props} />)`
  margin-top: ${pxToRem(95)};
`;

const PasswordNote = styled.div`
  width: ${pxToRem(467)};
  height: ${pxToRem(171)};
  top: ${pxToRem(10)};
  right: ${pxToRem(75)};
  line-height: ${pxToRem(21)};
  padding: ${pxToRem(24)} ${pxToRem(32)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  background: ${(props) => props.theme.color.extra_cool_blue_light_30};
  color: ${(props) => props.theme.color.coolblue};
  text-align: left;
  position: absolute;

  ul {
    margin-left: ${pxToRem(20)};
  }
`;

const ForgotPassword: FC<IResetPassword> = ({ history, token }) => {
  const themeContext = useContext(ThemeContext);
  const [showError, setShowError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('10002');
  const [formValue, setFormValue] = useState<Record<string, string>>({
    password: '',
    confirmPassword: '',
  });
  const [hasError, setHasError] = useState<Record<string, boolean>>({
    password: false,
    confirmPassword: false,
  });
  const [successfullyVisible, setSuccessfullyVisible] = useState(false);

  const signInState = useMemo(() => {
    const hasErrorValue = isEmptyField(hasError, 'boolean');
    const formValues = isEmptyField(formValue);
    return !hasErrorValue && !formValues;
  }, [hasError, formValue]);

  const handleSetError = (name: string, value: boolean) => {
    setHasError((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleChange = (name: string, value: any) => {
    setFormValue((values) => ({
      ...values,
      [name]: value,
    }));
    if (name === 'confirmPassword') {
      handleSetError(
        name,
        !reg.password.test(value) || value !== formValue.password
      );
    }
    if (name === 'password' && formValue.confirmPassword !== '') {
      handleSetError(
        'confirmPassword',
        !reg.password.test(value) || value !== formValue.confirmPassword
      );
    }
    if (value === '') {
      handleSetError(name, false);
    }
  };

  const onClick = async () => {
    try {
      await resetPassword({
        password: formValue.password,
        token,
      });
      setShowError(false);
      setSuccessfullyVisible(true);
    } catch (error) {
      if (error) {
        setShowError(true);
        setErrorCode(error);
        // TODO: Show error
        console.log(error);
      }
    }
  };

  const goLogin = () => {
    setSuccessfullyVisible(false);
    toLogin('member', history)();
  };

  return (
    <Wrapper>
      <Header
        leftHeader={{
          title: 'ResetPassword.ResetYourPassword',
          detail: 'ResetPassword.EnterYourNewPassword',
        }}
      />
      <Content>
        <ContentWrapper>
          {resetPasswordFieldConfig.map(
            ({ title, name, type, marginTop, errorMsg }) => (
              <Input
                width={550}
                marginTop={marginTop}
                key={title}
                name={name}
                defaultValue={formValue[name]}
                type={type}
                title={`ResetPassword.${title}`}
                errorMsg={`ErrorMsg.${errorMsg}`}
                hasError={hasError[name]}
                onChange={handleChange}
                setError={name === 'password' ? handleSetError : undefined}
                color={themeContext.color.grey_medium}
              />
            )
          )}
          {/* miss error message */}
          <ApiErrorTip width={550} showError={showError} id={errorCode} />
          <StyledButton
            type={signInState ? 'primary-yellow' : 'disabled'}
            width={180}
            onClick={onClick}
          >
            <FormattedMessage id="ResetPassword.SavePassword" />
          </StyledButton>
        </ContentWrapper>
        <PasswordNote>
          <Label>
            <FormattedMessage id="Login.CreateAccount.PasswordMustContain" />
          </Label>
          {passwordRule.map((message) => (
            <div key={message}>
              <FormattedMessage
                id={`Login.CreateAccount.${message}`}
                values={{ checkDot: '•' }}
              />
            </div>
          ))}
        </PasswordNote>
      </Content>
      <InfoModal
        visible={successfullyVisible}
        onClose={goLogin}
        footerOnClose={goLogin}
        {...successfullyModalConfig}
      />
    </Wrapper>
  );
};

export default ForgotPassword;
