import { numberDecimalsFormat } from "./numberFormat";

const maxTotalValue = (monthlyIncome: number, savings: number) => {
  let max = 100;
  if (monthlyIncome >= 7100 && 0.05 * monthlyIncome >= 1500) {
    max = numberDecimalsFormat(1 - (savings + 1500) / monthlyIncome);
  } else if (monthlyIncome >= 7100 && 0.05 * monthlyIncome < 1500) {
    max = numberDecimalsFormat(
      1 - (savings + 0.05 * monthlyIncome) / monthlyIncome
    );
  } else if (monthlyIncome >= 100 && monthlyIncome < 7100) {
    max = numberDecimalsFormat(1 - savings / monthlyIncome);
  } else {
    max = 0;
  }
  return Math.round(max * 100);
};

export default maxTotalValue;
