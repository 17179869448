export type ILoginFieldConfigProps = {
  type: string;
  title: string;
  name: 'email' | 'password';
  marginTop: number;
  errorMsg?: string;
  notCheck?: boolean;
};

export const loginFieldConfig: ILoginFieldConfigProps[] = [
  {
    type: 'email',
    title: 'Email',
    name: 'email',
    marginTop: 50,
    errorMsg: 'InvalidEmailAddressFormat',
  },
  {
    type: 'password',
    title: 'Password',
    name: 'password',
    marginTop: 28,
    errorMsg: 'PleaseMatchTheRequestedFormat',
  },
];

export const advisorLoginFieldConfig: ILoginFieldConfigProps[] = [
  // {
  //   type: 'email',
  //   title: 'AdvisorEmail',
  //   name: 'advisoremail',
  //   marginTop: 50,
  //   errorMsg: 'InvalidEmailAddressFormat',
  // },
  {
    type: 'text',
    title: 'AdvisorID',
    name: 'email',
    marginTop: 50,
    notCheck:true
  },
  {
    type: 'password',
    title: 'Password',
    name: 'password',
    marginTop: 28,
    errorMsg: 'PleaseMatchTheRequestedFormat',
  },
];
