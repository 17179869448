import React from "react";
import { Button as AntButton } from "antd";
import { ButtonProps as AntButtonProps } from "antd/es/button";
import styled, { css } from "styled-components";

import { enlargeFontsize, pxToRem } from "../../utils";

export interface ButtonProps
  extends Omit<AntButtonProps, "type" | "disabled" | "danger"> {
  /**
   * Width of button
   */
  width?: number;
  /**
   * Type of button. primary, secondary, disabled, danger and primary-yellow
   */
  type?: "primary" | "secondary" | "disabled" | "danger" | "primary-yellow";
}

const primaryMixin = css`
  &.ant-btn-primary {
    background: ${(props) => props.theme.color.sunlife_blue_medium};
    color: ${(props) => props.theme.color.white};
    border-color: ${(props) => props.theme.color.sunlife_blue_medium};
    font-weight: bold;
  }
`;

const primaryYellowMixin = css`
  &.ant-btn-primary {
    background: ${(props) => props.theme.color.sunlife_yellow_medium};
    color: ${(props) => props.theme.color.sunlife_blue_medium};
    border-color: ${(props) => props.theme.color.sunlife_yellow_medium};
    font-weight: bold;
  }
`;

const secondaryMixin = css`
  &.ant-btn {
    color: ${(props) => props.theme.color.sunlife_blue_medium};
    border-color: ${(props) => props.theme.color.sunlife_blue_medium};
  }
`;

const disabledMixin = css`
  &.ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active,
  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: ${(props) => props.theme.color.font};
    background: ${(props) => props.theme.color.grey_light};
    border-color: ${(props) => props.theme.color.grey_light};
    font-weight: bold;
  }
`;

const dangerMixin = css`
  &.ant-btn-dangerous {
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.color.extra_red_error};
    border-color: ${(props) => props.theme.color.extra_red_error};
    font-weight: bold;
  }
`;

const Button = styled<React.FC<ButtonProps>>(
  ({ type = "primary", ...props }) => (
    <AntButton
      type={
        type === "primary" || type === "primary-yellow" ? "primary" : "default"
      }
      disabled={type === "disabled"}
      danger={type === "danger"}
      {...props}
    />
  )
)<ButtonProps>`
  &.ant-btn {
    width: ${(props) => pxToRem(props.width ?? 257)};
    height: ${pxToRem(46)};
    border-radius: ${pxToRem(4)};
    border-width: 1px;
    box-shadow: none;
    text-shadow: none;
    font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &[ant-click-animating-without-extra-node="true"]:after,
  .ant-click-animating-node {
    animation: none;
  }

  ${(props) => {
    switch (props.type) {
      case "disabled":
        return disabledMixin;
      case "danger":
        return dangerMixin;
      case "secondary":
        return secondaryMixin;
      case "primary-yellow":
        return primaryYellowMixin;
      default:
        return primaryMixin;
    }
  }}
`;

export default Button;
