/**
 * Breakpoints
 * xs < 576px
 * 576px < sm < 768px
 * 768px < md < 992px
 * 992px < lg < 1200px
 * 1200px < xl < 1600px
 * xxl < 1600px
 */
const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

/* Media queries */
const mediaQueries = {
  mobile: `only screen and (max-width: ${breakpoints.sm - 1}px)`,
  tablet: `only screen and (min-width: ${breakpoints.sm}px) and (max-width: ${
    breakpoints.md - 1
  }px)`,
  desktop: `only screen and (min-width: ${breakpoints.md}px)`,
  largerScreen: `only screen and (min-width: ${breakpoints.lg}px)`,
  extraLargeScreen: `only screen and (min-width: ${breakpoints.xl}px)`,
  tabletDesktop: `only screen and (min-width: ${breakpoints.sm}px)`,
  mobileTablet: `only screen and (max-width: ${breakpoints.md - 1}px)`,
};

export default mediaQueries;
