import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Button,
  CheckBox,
} from '@rjp/common/component';
import { useHistory } from 'react-router';
import FormItem from './BaseFormItem';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`Search.Form.${id}`} />
);

const SearchFormWrapper = styled.div`
  padding: ${pxToRem(27)} ${pxToRem(15)} ${pxToRem(28)} ${pxToRem(18)};
`;

const Title = styled(CurFormattedMessage)`
  width: ${pxToRem(637)};
  height: ${pxToRem(40)};
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(26)};
  margin-bottom: ${pxToRem(11)};
  font-weight: 500;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
`;

const SearchButton = styled(Button)`
  margin-top: ${pxToRem(23)};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${pxToRem(6)};
`;

const CheckBoxTip = styled(CurFormattedMessage)`
  font-size: ${pxToRem(16)};
  height: ${pxToRem(21)};
  line-height: ${pxToRem(21)};
  color: ${(props) => props.theme.color.grey_dark};
  margin-left: ${pxToRem(10)};
`;

const CurCheckBox = styled(CheckBox)`
  > div {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    border: 1px solid ${(props) => props.theme.color.sunlife_blue_medium};
    box-sizing: border-box;
    border-radius: ${pxToRem(5)};
    svg {
      width: ${pxToRem(14)};
    }
  }
`;

const formConfig = [
  {
    fieldName: 'first_name',
    name: 'FirstName',
    type: 'name',
  },
  {
    fieldName: 'last_name',
    name: 'LastName',
    type: 'name',
  },
  {
    fieldName: 'mobile',
    name: 'Mobile',
    type: 'mobile',
  },
  {
    fieldName: 'email',
    name: 'Email',
    type: 'email',
  },
  {
    fieldName: 'age',
    name: 'Age',
    type: 'age',
    width: 58,
  },
];

const SearchForm: FC = () => {
  const [formData, setFormData] = useState<{
    [key: string]: string | number | null | undefined;
  }>({
    first_name: '',
    last_name: '',
    email: '',
    area_code: 852,
    mobile: null,
    age: null,
  });

  const [validation, setValidation] = useState<{ [key: string]: boolean }>({
    first_name: false,
    last_name: false,
    email: false,
    mobile: false,
    age: false,
  });

  const [notWantReceive, setNotWantReceive] = useState<boolean>(false);

  const history = useHistory();

  const onChange = (key: string, value?: string | number) =>
    setFormData((data) => ({
      ...data,
      [key]: value,
    }));

  const isEmpty = useMemo(
    () => !Object.keys(formData).every((key) => formData[key]),
    [formData]
  );

  const isValidationSuccessful = useMemo(
    () => !Object.keys(validation).every((key) => validation[key]),
    [validation]
  );

  const createProfile = () => {
    sessionStorage.setItem('create_profile', JSON.stringify(formData));
    history.push('/advisor/home/?create_profile=true');
  };

  const validationError = (name: string, value: boolean) => {
    setValidation((data) => {
      const curData = { ...data };
      curData[name] = value;
      return curData;
    });
  };

  return (
    <SearchFormWrapper>
      <Title id="CreateTitle" />
      <FormWrapper>
        {formConfig.map((item) => (
          <FormItem
            key={item.fieldName}
            hasError={validation[item.fieldName]}
            setError={validationError}
            {...item}
            originData={formData}
            onChange={onChange}
          />
        ))}
      </FormWrapper>
      <CheckBoxWrapper>
        <CurCheckBox
          defaultChecked={notWantReceive}
          onChange={() => setNotWantReceive(!notWantReceive)}
        />
        <CheckBoxTip id="IDoNotWantToReceive" />
      </CheckBoxWrapper>
      <SearchButton
        type={
          isEmpty && !isValidationSuccessful ? 'disabled' : 'primary-yellow'
        }
        width={204}
        onClick={createProfile}
      >
        <CurFormattedMessage id="CreateProfile" />
      </SearchButton>
    </SearchFormWrapper>
  );
};

export default SearchForm;
