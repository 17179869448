import React, { FC } from "react";
import styled from "styled-components";
import { theme } from "@rjp/common/constants";
import { pxToRem } from "@rjp/common/utils";

const StyledLoading = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  transition: all 0.3s;
  top: 0;
  left: 0;
  background: ${theme.color.extra_dark_50};

  & .loading-svg-wrap {
    width: ${pxToRem(144)};
    display: flex;
    justify-content: space-between;
    height: ${pxToRem(36)};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    svg {
      width: ${pxToRem(32)};
      height: ${pxToRem(32)};
      transition: all 0.5s;
      animation: loading-flash 1.2s infinite;

      &:nth-child(2) {
        opacity: 0.8;
        animation: loading-flash-2 1.2s infinite;
      }

      &:nth-child(3) {
        opacity: 0.4;
        animation: loading-flash-3 1.2s infinite;
      }
    }

    @keyframes loading-flash {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes loading-flash-2 {
      0% {
        opacity: 0.8;
      }
      16% {
        opacity: 1;
      }
      66% {
        opacity: 0.4;
      }
      100% {
        opacity: 0.8;
      }
    }

    @keyframes loading-flash-3 {
      0% {
        opacity: 0.4;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.4;
      }
    }
  }
`;

const Loading: FC = () => (
  <StyledLoading>
    <div className="loading-svg-wrap">
      <svg viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="16" fill="white" />
      </svg>
      <svg viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="16" fill="white" />
      </svg>
      <svg viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="16" fill="white" />
      </svg>
    </div>
  </StyledLoading>
);

export default Loading;
