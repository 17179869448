import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import {
  Start,
  CreateAccount,
  Login,
  VCode,
  ForgotPassword,
  ResetPassword,
  WorkSurface,
  ProfileDeleted,
  OtherForgot,
  Authorized,
  Search,
  ToAdvisorLogin,
  AccountNotVerified,
  PreLanding,
  AccountActivated,
  Verify,
} from '../pages';

const verifyRole = (role?: string, search?: string, ProfileDeleted?: boolean) => {
  const roleArr = ProfileDeleted ? ['member', 'client'] : ['member', 'advisor'];
  if (!roleArr.find((key) => role === key)) {
    return <Redirect to={{ pathname: '/start', search }} />;
  }
  return null;
};

const isVerifiedRedirectURL = () => {
  return true;
  // const urlParams = new URLSearchParams(window.location.search);
  // const originalURLObject = new URL(urlParams.get('original-url') ?? '');
  // const redirect_uri = originalURLObject.searchParams.get('redirect_uri');
  // const redirectURIObject = new URL(redirect_uri ?? '');
  // return redirectURIObject.origin === window.location.origin;
};

export const unauthorizedRoutes: RouteProps[] = [
  {
    path: '/start',
    component: Start,
    exact: true,
  },
  {
    path: '/authorized',
    component: Authorized,
    exact: true,
  },
  {
    path: '/member/create',
    component: CreateAccount,
    exact: true,
  },
  {
    path: '/member/accountactivated',
    exact: true,
    component: AccountActivated,
  },
  {
    path: '/member/verify',
    exact: true,
    render: ({ history, location }) => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      return token ? (
        <Verify history={history} token={token} />
      ) : (
        <Redirect to={{ pathname: '/start', search: location.search }} />
      );
    },
  },
  {
    path: '/login',
    render: ({ location }) => {
      const urlParams = new URLSearchParams(window.location.search);
      const originalURLObject = new URL(urlParams.get('original-url') ?? '');
      const role = originalURLObject.searchParams.get('role') ?? 'member';
      return isVerifiedRedirectURL() ? (
        <Redirect
          to={{ pathname: `/${role}/login`, search: location.search }}
        />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      );
    },
    exact: true,
  },
  {
    path: '/:role/login',
    exact: true,
    render: ({ location, match }) =>
      isVerifiedRedirectURL() ? (
        verifyRole(match.params.role, location.search) || <Login />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      ),
  },
  {
    path: '/:role/prelanding',
    exact: true,
    render: ({ location, match }) =>
      verifyRole(match.params.role, location.search) || <PreLanding />,
  },
  {
    path: '/member/accountnotverified',
    component: AccountNotVerified,
    exact: true,
  },
  {
    path: '/member/code',
    component: VCode,
    exact: true,
  },
  {
    path: '/member/forgot',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/:role/forgot',
    render: ({ history, location, match }) =>
      verifyRole(match.params.role, location.search) || (
        <OtherForgot match={match} history={history} location={location} />
      ),
    exact: true,
  },
  {
    path: '/member/reset',
    render: ({ history, location }) => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      return token ? (
        <ResetPassword history={history} token={token} />
      ) : (
        <Redirect to={{ pathname: '/start', search: location.search }} />
      );
    },
    exact: true,
  },
  {
    path: '/advisor/tologin',
    component: ToAdvisorLogin,
    exact: true,
  },
  {
    path: '/member/deleted',
    exact: true,
    component: ProfileDeleted,
  },
  {
    path: '/:role/deleted',
    exact: true,
    render: ({ location, match }) =>
      verifyRole(match.params.role, location.search) || <ProfileDeleted />,
  },
  {
    render: ({ location }) => (
      <Redirect to={{ pathname: '/start', search: location.search }} />
    ),
  },
];

export const authorizedRoutes = (role: string) => {
  const routes: RouteProps[] = [
    {
      path: `/${role}/home`,
      component: WorkSurface,
    },
  ];

  if (role === 'member' || role === 'client') {
    routes.push({
      path: `/${role}/deleted`,
      component: ProfileDeleted,
      exact: true,
    });
  }

  if (role === 'advisor') {
    routes.push({
      path: '/advisor/search',
      component: Search,
      exact: true,
    });
  }

  routes.push({
    render: ({ location }) => (
      <Redirect
        to={{
          pathname: `/${role}/${role === 'advisor' ? 'search' : 'home'}`,
          search: location.search,
        }}
      />
    ),
  });
  return routes;
};
