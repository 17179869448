import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { pxToRem } from '@rjp/common/utils';
import { theme } from '@rjp/common/constants';
import { ReactComponent as ErrorIcon } from '@rjp/common/assets/images/error-tip-icon.svg';
import { ReactComponent as ErrorClose } from '@rjp/common/assets/images/error-tip-close.svg';
import { CSSTransition } from 'react-transition-group';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
} from '@rjp/common/component';
import { IntlProvider } from 'react-intl';
import languagePackage from '../../locale';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`ErrorTip.${id}`} />
);

const ErrorTipWrapper = styled.div`
  width: ${pxToRem(425)};
  height: ${pxToRem(60)};
  overflow: hidden;
  background: ${theme.color.pink_red};
  border-radius: ${pxToRem(5)};
  filter: drop-shadow(0px 0px ${pxToRem(20)} rgba(0, 0, 0, 0.6));
  font-size: ${pxToRem(14)};
  color: ${theme.color.red};
  display: flex;
  align-items: center;
  position: fixed;
  top: ${pxToRem(80)};
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.5s;
`;

const CurErrorIcon = styled(ErrorIcon)`
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  margin-left: ${pxToRem(14)};
  margin-right: ${pxToRem(11)};
`;

const CurErrorClose = styled(ErrorClose)`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${pxToRem(14)};
  margin: auto 0;
  cursor: pointer;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ErrorTip: FC<{ msgKey: string; delays: number; unmount: () => void }> = ({
  msgKey,
  delays,
  unmount,
}) => {
  const [language, setLanguage] = useState<string>('en');
  const [show, setShow] = useState<boolean>(false);

  let timeout:NodeJS.Timeout;

  const onLeave = useCallback(() => {
    clearTimeout(timeout);
    setShow(false);
    setTimeout(unmount, 500);
  }, [unmount]);

  useEffect(() => {
    timeout = setTimeout(()=>{onLeave();}, delays);
    return () => clearTimeout(timeout);
  }, [delays, onLeave]);

  useEffect(() => {
    const persistString = window.localStorage.getItem('persist:root');
    const paramsLanguage = new URLSearchParams(window.location.search).get(
      'locale'
    );
    let curLang = 'en';
    try {
      if (persistString) {
        curLang = JSON.parse(JSON.parse(persistString).translation).language;
      }
    } catch (err) {
      console.error(err);
    }
    if (paramsLanguage) curLang = paramsLanguage;
    setLanguage(curLang);
    setTimeout(() => setShow(true), 200);
  }, []);

  return (
    <IntlProvider locale={language} messages={languagePackage[language]}>
      <CSSTransition
        in={show}
        timeout={show ? 0 : 500}
        classNames="slow-down"
        unmountOnExit
      >
        <ErrorTipWrapper className="opo">
          <CurErrorIcon />
          <CurFormattedMessage
            id={`Code${msgKey}`}
            values={{ bold: (msg: string) => <Bold>{msg}</Bold> }}
          />
          <CurErrorClose onClick={onLeave} />
        </ErrorTipWrapper>
      </CSSTransition>
    </IntlProvider>
  );
};

const errorWrapper = document.createElement('div');

const hideErrorTip = () => {
  ReactDOM.unmountComponentAtNode(errorWrapper);
  errorWrapper.remove();
};

const showErrorTip = (key: string, delays = 2000) => {
  ReactDOM.render(
    <ErrorTip msgKey={key} delays={delays} unmount={hideErrorTip} />,
    errorWrapper
  );
  document.getElementById('root')?.append(errorWrapper);
};

export default showErrorTip;
