import MockAdapter from "axios-mock-adapter";
import {IResponse} from "@/api/type";

const getSVtokenMock = (mock: MockAdapter) => {
  mock
    .onPost('/agents/getsvtoken')
    .reply<IResponse<string>>((config) => {
      const responseData = {
        status: 200,
        payload: "fdslfjsklfjsfkjksjfku02jklfjsf",
        error_code: [],
      };
      return [200, responseData];
    });
};

export default getSVtokenMock;
