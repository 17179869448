import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import yellow from "@rjp/common/assets/images/yellow-item.png";
import red from "@rjp/common/assets/images/red-item.png";
import styled from "styled-components";
import { pxToRem } from "@rjp/common/utils";

const DuotoneDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: ${pxToRem(73.39)};
  height: ${pxToRem(120.33)};
  overflow: hidden;
  position: relative;
  .chart-item {
    width: ${pxToRem(69.39)};
    position: absolute;
    bottom: 0;
    transition: all 0.3s;
  }
`;

const Duotone = ({ value = [56, 44], donTHalf = false, className = "" }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const bottom = donTHalf && window.outerWidth < 800 ? 2.8 : 6.3;
  const itemPositionLeft = [
    1,
    1,
    1,
    0,
    1,
    1,
    1,
    3,
    1,
    1,
    0,
    1,
    1,
    1,
    0,
    1,
    1,
    3,
    1,
    1,
  ];

  useEffect(() => {
    let cValue = currentValue;
    if (cValue === 0) {
      let interval = null;
      interval = setInterval(() => {
        if (Number(cValue) >= 100) clearInterval(interval);
        else {
          cValue += 5;
          setCurrentValue(cValue);
        }
      }, 80);
    }
  }, [value]);

  return (
    <DuotoneDiv className={className}>
      {itemPositionLeft.map((left, index) => (
        <CSSTransition
          in={currentValue >= index * 5 || index === 0}
          timeout={300}
          classNames="down"
          unmountOnExit
          key={index}
        >
          <img
            alt=""
            style={{
              bottom: `${(index * bottom) / 100}rem`,
              left: `${left / 100}rem`,
              opacity: currentValue === 0 ? 0.3 : 1,
            }}
            className="chart-item"
            src={index * 5 < value[0] ? yellow : red}
          />
        </CSSTransition>
      ))}
    </DuotoneDiv>
  );
};

export default Duotone;
