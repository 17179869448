import React, { FC } from 'react';
import styled from 'styled-components';

import { pxToRem } from '@rjp/common/utils';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Input,
} from '@rjp/common/component';
import { theme } from '@rjp/common/constants';

interface HelpWithRetirementProps {
  phoneError: boolean;
  showError: boolean;
  errorCode: string;
  onChange: (mobile: string) => void;
  onBlur: (mobile: string) => void;
  errorMsg: string;
}

const prefix = 'PopModal.ContactAdvisor.';
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const Wrapper = styled.div`
  margin-left: ${pxToRem(52)};
  margin-right: ${pxToRem(52)};
  line-height: ${pxToRem(26)};
  font-size: ${pxToRem(16)};
`;

const Title = styled(CurFormattedMessage)`
  height: ${pxToRem(50)};
  margin-bottom: ${pxToRem(40)};
  line-height: ${pxToRem(31)};
  font-size: ${pxToRem(24)};
  font-weight: 500;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Note = styled(CurFormattedMessage)`
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  font-weight: 500;
`;

const AdvisorConnectNumber = styled.div`
  min-height: ${pxToRem(112)};
  margin-top: ${pxToRem(20)};
  padding: ${pxToRem(11)} ${pxToRem(26)} ${pxToRem(16)} ${pxToRem(22)};
  font-size: ${pxToRem(18)};
  background: ${(props) => props.theme.color.cool_blue_light_20};
  overflow-x: auto;
`;

const PhoneNumber = styled.div`
  margin-top: ${pxToRem(15)};
  display: flex;
`;

const PhoneNumberInput = styled.div`
  width: ${pxToRem(406)};

  .error-msg {
    width: ${pxToRem(473)};
    left: ${pxToRem(-65)};
    ::before {
      left: ${pxToRem(77)};
    }
  }
`;

const AreaCode = styled.div`
  width: ${pxToRem(60)};
  line-height: ${pxToRem(36)};
  margin-left: ${pxToRem(7)};
  margin-right: ${pxToRem(20)};
  font-weight: 500;
`;

const HelpWithRetirement: FC<HelpWithRetirementProps> = ({
  phoneError,
  onChange,
  onBlur,
  errorMsg,
}) => (
  <Wrapper>
    <Title id="WhoWouldYouLikeToHelpWithYourRetirement" />
    <Note id="ThisProfileAndTheRetirementGapInformation" />
    <AdvisorConnectNumber>
      <CurFormattedMessage id="AdvisorsContactNumber" />
      <PhoneNumber>
        <AreaCode>+852</AreaCode>
        <PhoneNumberInput>
          <Input
            width={406}
            height={36}
            name="mobile"
            type="phoneNumber"
            onChange={(name, value) => onChange(value)}
            onBlur={(e) => onBlur(e.target.value)}
            color={theme.color.grey_medium}
            errorMsg={`ErrorMsg.${errorMsg}`}
            hasError={phoneError}
          />
        </PhoneNumberInput>
      </PhoneNumber>
    </AdvisorConnectNumber>
  </Wrapper>
);

export default HelpWithRetirement;
