/**
 * 数字格式化
 * @param num // 需要格式化的数字
 * @param decimal // 保留小数位数
 */

export const formatDecimal = (num, decimal) => {
  num = num.toString();
  let index = num.indexOf(".");
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1);
  } else {
    num = num.substring(0);
  }
  return parseFloat(num);
};

export type IStepLogic = {
  cuttingPoint: number;
  stepSize: number;
}[];

/**
 * [slider專用] 尋找slider上的數字，在切割定義中對應的位置
 * @param num slider上的數字
 * @param stepLogic slider上的step size切割定義
 */
const checkPos = (num: number, stepLogic: IStepLogic) => {
  for (let i = 0; i < stepLogic.length; i++) {
    const { cuttingPoint } = stepLogic[i];
    if (num < cuttingPoint) return i;
  }
  return stepLogic.length - 1;
};

/**
 * [slider專用] 數值轉step
 * @param min slider的最小值
 * @param value 需要轉化的數值
 * @param stepLogic slider上的step size切割定義
 */
export const valueToStep = (
  min: number,
  value: number,
  stepLogic: IStepLogic
) => {
  // return 0 for wrong configurations
  if (stepLogic.length === 0 || min > value) return 0;

  // Calculate the position of min and value based on stepLogic array
  const minPos = checkPos(min, stepLogic);
  const valuePos = checkPos(value, stepLogic);

  if (minPos === valuePos) {
    // if min and value are within same step range
    return Math.floor((value - min) / stepLogic[minPos].stepSize);
  } else {
    let total = 0;
    // if min and value are not in the same step range
    // 1. Calculate the steps from min to the next cuttingPoint
    total += Math.floor(
      (stepLogic[minPos].cuttingPoint - min) / stepLogic[minPos].stepSize
    );
    // 2. Calculate all intermediate steps which are within min and value, as well as between cuttingPoints
    for (let i = minPos + 1; i < valuePos; i++) {
      total +=
        (stepLogic[i].cuttingPoint - stepLogic[i - 1].cuttingPoint) /
        stepLogic[i].stepSize;
    }
    // 3. Calculate the steps from previous cuttingPoint to value
    if (valuePos > 0) {
      total += Math.floor(
        (value - stepLogic[valuePos - 1].cuttingPoint) /
          stepLogic[valuePos].stepSize
      );
    }
    return total;
  }
};

/**
 * [slider專用] 數值轉step
 * @param min slider的最小值
 * @param value 需要轉化的數值
 * @param stepLogic slider上的step size切割定義
 */
/**
 * [slider專用] step轉數值
 * @param step
 * @param stepLogic slider上的step size切割定義
 * @param maxStep slider的最大step
 * @param min slider的最小值
 * @param max slider的最大值
 */
export const stepToValue = (
  step: number,
  stepLogic: IStepLogic,
  maxStep: number,
  min: number,
  max: number
) => {
  // return 0 for wrong configurations
  if (stepLogic.length === 0 || min > max) return 0;

  if (step < 0) {
    return min;
  } else {
    const minPos = checkPos(min, stepLogic);
    let value = 0;
    for (let i = minPos; i < stepLogic.length; i++) {
      const { cuttingPoint, stepSize } = stepLogic[i];
      if (i < stepLogic.length - 1) {
        const prevCuttingPoint =
          i === minPos ? min : stepLogic[i - 1].cuttingPoint;
        const stepsInRegion = (cuttingPoint - prevCuttingPoint) / stepSize;
        if (step < stepsInRegion) {
          value += step * stepSize;
          break;
        } else {
          value = cuttingPoint;
          step -= stepsInRegion;
        }
      } else {
        value += step * stepSize;
      }
    }
    return value;
  }
};
