import MockAdapter from 'axios-mock-adapter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IResponse, IRetirementProfile } from './type';
import { IEditSessionStatusResponse } from './retirementprofile.type';
import mockdata from './mockdata';

dayjs.extend(utc);

const mandatoryFieldValidation = (data: IRetirementProfile) =>
  [
    'age',
    'marital_status',
    'gender',
    'smoking_status',
    'retirement_age',
    'monthly_income',
    'retirement_monthly_income',
    'post_retirement_investment_mix',
  ].every((value) => value in data);

const nonNegativeNumberValidation = (data: IRetirementProfile) =>
  Object.values(data)
    .filter((value) => typeof value === 'number')
    .every((value) => value >= 0);

const ageValidation = (data: IRetirementProfile) =>
  'age' in data ? data.age >= 18 && data.age <= 69 : true;

const retirementAgeValidation = (data: IRetirementProfile) => {
  if ('age' in data && 'retirement_age' in data) {
    const { age, retirement_age } = data;
    return retirement_age >= 40 + (age < 40 ? 0 : 1) && retirement_age <= 70;
  }
  return true;
};

const monthlyIncomeValidation = (data: IRetirementProfile) =>
  'monthly_income' in data
    ? data.monthly_income >= 0 && data.monthly_income <= 300000
    : true;

const retirementMonthlyIncomeValidation = (data: IRetirementProfile) => {
  if ('monthly_income' in data && 'retirement_monthly_income' in data) {
    const { monthly_income, retirement_monthly_income } = data;
    return monthly_income < 6000
      ? retirement_monthly_income >= 3000 && retirement_monthly_income <= 6000
      : retirement_monthly_income >= 0.5 * monthly_income &&
          retirement_monthly_income <= monthly_income;
  }
  return true;
};

const totalCashSavingsValidation = (data: IRetirementProfile) =>
  'total_cash_savings' in data
    ? data.total_cash_savings >= 0 && data.total_cash_savings <= 50000000
    : true;

const totalMpfSavingsValidation = (data: IRetirementProfile) =>
  'total_mpf_savings' in data
    ? data.total_mpf_savings >= 0 && data.total_mpf_savings <= 50000000
    : true;

const otherInvestmentSavingsValidation = (data: IRetirementProfile) =>
  'total_mpf_savings' in data
    ? data.total_mpf_savings >= 0 && data.total_mpf_savings <= 50000000
    : true;

export const validationList = [
  mandatoryFieldValidation,
  nonNegativeNumberValidation,
  ageValidation,
  retirementAgeValidation,
  monthlyIncomeValidation,
  retirementMonthlyIncomeValidation,
  totalCashSavingsValidation,
  totalMpfSavingsValidation,
  otherInvestmentSavingsValidation,
];

export const retirementProfileList = [
  {
    client_id: 'member_id',
    age: 28,
    gender: 'Male',
    no_of_children: 1,
    marital_status: 'Married',
    smoking_status: 'Non Smoker',
    monthly_income: 100000,
    total_cash_savings: 3500000,
    monthly_cash_savings: 0.2,
    retirement_age: 65,
    retirement_monthly_income: 80000,
    retirement_monthly_income_style: 'Comfortable',
    post_retirement_investment_mix: 'Moderate',
    total_mpf_savings: 245569,
    monthly_mpf_savings: 4000,
    other_investment_rc: 5000,
    mpf_investment_mix: 'Conservative',
    other_investment: [
      {
        investment_description: 'Stocks',
        amount: 300000,
        other_investment_style: 3,
      },
      {
        investment_description: 'Mutual Funds',
        amount: 100000,
        other_investment_style: 1,
      },
    ],
    future_payments: [
      {
        payment_description: 'Retirement fund from company',
        amount: 300000,
        is_after_retirement: false,
      },
    ],
  },
  {
    client_id:
      '8360316E1BBEDD9D3C3AF7042190C73755ED2793003719C06B96F974DBA00346',
    age: 28,
    gender: 'Male',
    no_of_children: 1,
    marital_status: 'Married',
    smoking_status: 'Non Smoker',
    monthly_income: 100000,
    total_cash_savings: 3500000,
    monthly_cash_savings: 0.2,
    retirement_age: 65,
    retirement_monthly_income: 80000,
    retirement_monthly_income_style: 'Comfortable',
    post_retirement_investment_mix: 'Balanced',
    total_mpf_savings: 245569,
    monthly_mpf_savings: 4000,
    mpf_investment_mix: 'Conservative',
    other_investment_rc: null,
    other_investment_style_rc: null,
    other_investment: [
      {
        investment_description: 'Stocks',
        amount: 300000,
        other_investment_style: 3,
      },
      {
        investment_description: 'Mutual Funds',
        amount: 100000,
        other_investment_style: 1,
      },
    ],
    future_payments: [
      {
        payment_description: 'Retirement fund from company',
        amount: 300000,
        is_after_retirement: false,
      },
    ],
  },
  {
    client_id:
      'C27DC4530EB681BCEAA37379037C17F4289C80910F387D555D049BDB6A5A4D94',
    age: 45,
    gender: 'Female',
    no_of_children: 3,
    marital_status: 'Married',
    smoking_status: 'Smoker',
    monthly_income: 30000,
    total_cash_savings: 8800000,
    monthly_cash_savings: 0.1,
    retirement_age: 68,
    retirement_monthly_income: 20000,
    retirement_monthly_income_style: 'Comfortable',
    post_retirement_investment_mix: 'Conservative',
    total_mpf_savings: 300000,
    monthly_mpf_savings: 5000,
    mpf_investment_mix: 'Conservative',
    other_investment_rc: null,
    other_investment_style_rc: null,
    other_investment: [
      {
        investment_description: 'Stocks',
        amount: 300000,
        other_investment_style: 3,
      },
      {
        investment_description: 'Mutual Funds',
        amount: 100000,
        other_investment_style: 1,
      },
    ],
    future_payments: [
      {
        payment_description: 'Retirement fund from company',
        amount: 300000,
        is_after_retirement: true,
      },
    ],
  },
];

const editSessionStatusList = [
  {
    id: 'C27DC4530EB681BCEAA37379037C17F4289C80910F387D555D049BDB6A5A4D94',
    edit_session_expiry: '',
  },
  {
    id: '8360316E1BBEDD9D3C3AF7042190C73755ED2793003719C06B96F974DBA00346',
    edit_session_expiry: '',
  },
  {
    id: 'member_id',
    edit_session_expiry: dayjs().add(4, 'hour').utc().format(),
  },
];

const retirementProfileMock = (mock: MockAdapter) => {
  // createRetirementProfile
  const createRetirementProfileRegex = /\/retirementprofiles\/?$/;
  mock
    .onPost(createRetirementProfileRegex)
    .reply<IResponse<{ retirement_profile: IRetirementProfile }>>((config) => {
      const data = JSON.parse(config.data);
      const validMemberInfo = retirementProfileList.find(
        (info) => info.client_id === data.client_id
      );
      const isValidData = validationList.every((fn) =>
        fn(data.retirement_profile)
      );
      const status = validMemberInfo ? 200 : 400;
      const responseData = {
        payload:
          validMemberInfo && isValidData
            ? {
                retirement_profile: data,
              }
            : {},
        error_code: isValidData ? [] : [10002],
      };
      return [status, responseData];
    });

  // getRetirementProfile
  const getRetirementProfileRegex = /\/retirementprofiles\/(?<id>[^/]+)\/?$/;
  mock
    .onGet(getRetirementProfileRegex)
    .reply<IResponse<{ retirement_profile: IRetirementProfile }>>((config) => {
      const matchObj = getRetirementProfileRegex.exec(config.url ?? '');
      const id = matchObj?.groups?.id;
      const validMemberInfo = mockdata.memberList.find(
        (info) => info.id === id
      );
      const status = validMemberInfo ? 200 : 400;
      const responseData = {
        payload: validMemberInfo
          ? {
              retirement_profile:
                retirementProfileList.find((info) => info.client_id === id) ??
                [],
            }
          : {},
        error_code: [],
      };
      return [status, responseData];
    });

  mock
    .onPost('/retirementprofiles/profilesearch')
    .reply<IResponse<{ retirement_profile: IRetirementProfile }>>((config) => {
      const id = config.data.client_id;
      const validMemberInfo = mockdata.memberList.find(
        (info) => info.id === id
      );
      const status = validMemberInfo ? 200 : 400;
      const responseData = {
        payload: validMemberInfo
          ? {
              retirement_profile:
                retirementProfileList.find((info) => info.client_id === id) ??
                [],
            }
          : {},
        error_code: [],
      };
      return [status, responseData];
    });

  // updateRetirementProfile
  const updateRetirementProfileRegex = /\/retirementprofiles\/(?<id>[^/]+)\/update\/?$/;
  mock
    .onPost(updateRetirementProfileRegex)
    .reply<IResponse<{ retirement_profile: IRetirementProfile }>>((config) => {
      const data = JSON.parse(config.data);
      const matchObj = updateRetirementProfileRegex.exec(config.url ?? '');
      const id = matchObj?.groups?.id;
      const validMemberInfo = retirementProfileList.find(
        (info) => info.client_id === id
      );
      const isValidData = validationList.every((fn) => fn(data));
      const status = validMemberInfo ? 200 : 400;
      const responseData = {
        payload:
          validMemberInfo && isValidData
            ? {
                retirement_profile: {
                  ...validMemberInfo,
                  ...data,
                },
              }
            : {},
        error_code: isValidData ? [] : [10002],
      };
      return [status, responseData];
    });

  const optList = ['111111', '212121', '473847', '029302'];

  // editRequestVerify
  const editRequestVerifyRegex = /\/retirementprofiles\/(?<id>[^/]+)\/editrequestverify\/?$/;
  mock.onPost(editRequestVerifyRegex).reply<IResponse<{}>>((config) => {
    const { otp } = JSON.parse(config.data);
    const matchObj = editRequestVerifyRegex.exec(config.url ?? '');
    const id = matchObj?.groups?.id;
    const validMemberInfo = mockdata.memberList.find((info) => info.id === id);
    const validOtp = optList.find((code) => code === otp);
    const status = validMemberInfo && validOtp ? 200 : 400;
    const responseData = {
      payload: {},
      error_code: [],
    };
    return [status, responseData];
  });

  // editRequest
  const editRequestRegex = /\/retirementprofiles\/(?<id>[^/]+)\/editrequest\/?$/;
  mock.onPost(editRequestRegex).reply<IResponse<{}>>((config) => {
    const matchObj = editRequestRegex.exec(config.url ?? '');
    const id = matchObj?.groups?.id;
    const validMemberInfo = mockdata.memberList.find((info) => info.id === id);
    const status = 200;
    const responseData = {
      payload: {},
      error_code: validMemberInfo ? [] : [10002],
    };
    return [status, responseData];
  });

  // editSessionStatus
  const editSessionStatusRegex = /\/retirementprofiles\/(?<id>[^/]+)\/editsessionstatus\/?$/;
  mock
    .onGet(editSessionStatusRegex)
    .reply<IResponse<IEditSessionStatusResponse>>((config) => {
      const matchObj = editSessionStatusRegex.exec(config.url ?? '');
      const id = matchObj?.groups?.id;
      const editSessionExpiryObject = editSessionStatusList.find(
        (info) => info.id === id
      );
      const edit_session_expiry =
        editSessionExpiryObject?.edit_session_expiry ?? '';
      if (
        editSessionExpiryObject &&
        !editSessionExpiryObject.edit_session_expiry
      ) {
        editSessionExpiryObject.edit_session_expiry = dayjs()
          .add(4, 'hour')
          .utc()
          .format();
      }
      const status = 200;
      const responseData = {
        payload: { edit_session_expiry },
        error_code: [],
      };
      return [status, responseData];
    });

  mock
    .onPost('/retirementprofiles/editsessionstatus')
    .reply<IResponse<IEditSessionStatusResponse>>((config) => {
      const matchObj = editSessionStatusRegex.exec(config.url ?? '');
      const id = matchObj?.groups?.id;
      const editSessionExpiryObject = editSessionStatusList.find(
        (info) => info.id === id
      );
      const edit_session_expiry =
        editSessionExpiryObject?.edit_session_expiry ?? '';
      if (
        editSessionExpiryObject &&
        !editSessionExpiryObject.edit_session_expiry
      ) {
        editSessionExpiryObject.edit_session_expiry = dayjs()
          .add(4, 'hour')
          .utc()
          .format();
      }
      const status = 200;
      const responseData = {
        payload: { edit_session_expiry },
        error_code: [],
      };
      return [status, responseData];
    });
};

export default retirementProfileMock;
