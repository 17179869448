import axios, { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { showErrorTip } from '../globalKit';
import deserializeObject from '@/utils/deserializeObjects';

interface ISunlifeResponseDataSuccess<T> {
  payload: T;
  error_code: number[];
}

interface ISunlifeResponseDataFail {
  payload: {};
  error_code: number[];
}

type ISunlifeResponseData<T> =
  | ISunlifeResponseDataSuccess<T>
  | ISunlifeResponseDataFail;

const isSunlifeResponseDataSuccess = <T>(
  responseData: ISunlifeResponseData<T>
): responseData is ISunlifeResponseDataSuccess<T> =>
  responseData?.error_code?.length === 0 ||
  typeof responseData?.error_code === 'undefined';

// export const sunlifeCallerInstance = axios.create({
  //   baseURL: '/api',
// });

// sunlifeCallerInstance.interceptors.request.use(config => ({
//   ...config,
//   headers: {
// ...(config.headers ?? {}),
//     'x-correlation-id': uuidv4(),
//   },
// }));

const sunlifeCaller = <T = any>(config: AxiosRequestConfig, version = 1) =>
  new Promise<T>((resolve, reject) => {
    console.log('API----------', process.env.REACT_APP_IS_SUNLIFE_API);
    let fetchAPI;
    let useAxios: boolean;
    if (process.env.REACT_APP_IS_SUNLIFE_API) {
      useAxios = true;
      fetchAPI = axios.request<ISunlifeResponseData<T>>({
        ...config,
        params: {
          ...(config.params ?? {}),
          version,
        },
        baseURL: '/api',
        headers: {
          ...(config.headers ?? {}),
          'x-correlation-id': uuidv4(),
        },
      });
    } else {
      useAxios = false;
      fetchAPI = fetch(
        `/rjp/api${config.url}?` +
          new URLSearchParams({...config.params, version:1}).toString(),
        {
          method: config.method,
          headers: {
            ...(config.headers ?? {}),
            'Content-Type': 'application/json',
            'x-correlation-id': uuidv4(),
          },
          body: JSON.stringify(config.data),
        }
      ).then((response) => {
        return response.json();
      });
    }

    // do the api call
    fetchAPI
      .then((response) => {
        console.log('sulifeCaller response:::::', response, config);
        const data = deserializeObject(useAxios ? response.data : response);
        if (isSunlifeResponseDataSuccess(data)) {
          if (data && data.payload) {
            resolve(data.payload as any);
          } else {
            resolve(data as any);
          }
        } else {
          // Assume only 1 error code returned
          reject(data.error_code[0]);
        }
      })
      .catch((error) => {
        console.log('sulifeCaller error:::::', error);
        if (error.response) {
          const { status } = error.response;
          console.log('Show global error message');
          if (status === 401) {
            console.log('Logout by removing cookies and go to main page');
          }
          showErrorTip(status.toString());
        }
        reject(error);
      });
  });

export default sunlifeCaller;