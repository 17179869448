import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';

import { TimeoutModal } from '@rjp/common/component';
import { sleep } from '@rjp/common/utils';
import { AppDispatch, RootState } from '@rjp/main/src/app';
import { isLoggedInSelector } from './authSelector';
import { logout } from './authSlice';
import { sessionTimeHourLimit, absoluteTimerCountdownSecond } from './config';

const AbsoluteTimeout: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch<AppDispatch>();

  const hasClosed = useRef<boolean>(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [expiredCountdown, setExpiredCountdown] = useState<number>(
    absoluteTimerCountdownSecond
  );

  const loggedInTime = useSelector(
    (state: RootState) => state.auth.loggedInTime
  );

  const loggedInTimeDayObject = useMemo(() => dayjs(loggedInTime), [
    loggedInTime,
  ]);

  const isLoggedIn = useSelector(isLoggedInSelector);

  const onClose = useCallback(() => setModalVisible(false), []);

  const afterClose = useCallback(
    () => setExpiredCountdown(absoluteTimerCountdownSecond),
    []
  );

  const role = useSelector((state: RootState) => state.userInfo.role);

  const language = useSelector(
    (state: RootState) => state.translation.language
  );

  const fetchLogout = useCallback(async () => {
    await dispatch(logout());
    history.push(`/${role}/prelanding?locale=${language}`);
  }, [dispatch, history, role, language]);

  useEffect(() => {
    if (isLoggedIn) {
      let isNotExpired = true;
      const run = async () => {
        do {
          const expiredMillisecond = loggedInTimeDayObject
            .add(sessionTimeHourLimit, 'hour')
            .diff(dayjs());
          const expiredSecond = Math.floor(expiredMillisecond / 1000) + 1;
          const countDownMillisecond =
            expiredMillisecond - (expiredSecond - 1) * 1000;
          if (expiredSecond <= 0) {
            isNotExpired = false;
            setExpiredCountdown(0);
            setModalVisible(false);
            fetchLogout();
          } else if (expiredSecond > 0 && expiredSecond <= 60) {
            if (!hasClosed.current) {
              setModalVisible(true);
              hasClosed.current = true;
            }
            setExpiredCountdown(expiredSecond);
          }
          // eslint-disable-next-line no-await-in-loop
          await sleep(countDownMillisecond);
        } while (isNotExpired);
      };
      run();
    } else {
      hasClosed.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <TimeoutModal
      visible={modalVisible}
      onClose={onClose}
      expiredSecond={expiredCountdown}
      onContinue={onClose}
      afterClose={afterClose}
    />
  );
};

export default memo(AbsoluteTimeout);
