import React, {
  FC,
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import { pxToRem, enlargeFontsize } from '@rjp/common/utils';
import { Input } from '@rjp/common/component';
import { FormattedMessage } from 'react-intl';
import { reg } from '@rjp/common/config';
import { IProfileFormData } from './types';

const ProfileEditInfoWrapper = styled.div`
  width: ${pxToRem(280)};
  margin-bottom: ${pxToRem(19)};
`;

const Title = styled.div`
  width: ${pxToRem(280)};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 21)};
  color: ${(props) => props.theme.color.grey_dark};
  margin-bottom: ${pxToRem(5)};
`;

const ValueDiv = styled.div`
  width: ${pxToRem(280)};
  height: ${pxToRem(30)};
  margin-top: ${pxToRem(14)};
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 24)};
  color: ${(props) => props.theme.color.sunlife_blue_medium_40};
`;

const InputGroup = styled.div`
  display: flex;
  width: ${pxToRem(280)};
  justify-content: space-between;
  .error-msg {
    width: ${pxToRem(280)};
    position: relative;
    left: -${pxToRem(60)};
    ::before {
      left: ${pxToRem(72)};
    }
  }
`;

const PhoneWrapper = styled.div`
  position: relative;
  width: ${pxToRem(220)};
`;

const AreaCode = styled.div`
  font-size: ${pxToRem(16)};
  font-weight: 500;
  height: ${pxToRem(44)};
  line-height: ${pxToRem(44)};
`;

interface ProfileEditInfoProps {
  keyName: string;
  title?: string;
  editing?: boolean;
  defaultValue?: string | number;
  onChange?: (name: string, value: any, error: boolean) => void;
  showError?: (value: any) => boolean | boolean;
  errorMsg?: string;
  type?: string;
  formValue: IProfileFormData;
  needToNumber?: boolean;
  min?: number;
  max?: number;
}

const nameInput: string[] = ['first_name', 'last_name', 'name_chinese'];

const ProfileEditInfo: FC<ProfileEditInfoProps> = ({
  keyName,
  title,
  editing,
  defaultValue,
  onChange,
  showError,
  errorMsg,
  type = 'input',
  formValue,
  needToNumber,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  const changed = useRef<boolean>(false);

  const checkError = useCallback(
    (v: any) =>
      typeof showError === 'function' ? showError?.(v) : !!showError,
    [showError]
  );

  const [hasError, setHasError] = useState(checkError(defaultValue));

  const [ageErrorMsg, setAgeErrorMsg] = useState(errorMsg);

  useEffect(
    () =>
      setHasError(
        type === 'mobileGroup'
          ? !(reg.hkTelephone.test(defaultValue) && defaultValue)
          : checkError(defaultValue)
      ),
    [defaultValue, checkError, type]
  );

  const handleChange = (name: string, value: any) => {
    changed.current = true;
    let error = checkError(value);
    if (name === 'mobile' || name === 'area_code') {
      const mobile = name === 'mobile' ? value : formValue.mobile;
      const isEmptyMobile = value === '';
      const areaCode = Number(
        name === 'area_code' ? value : formValue.area_code
      );
      if (areaCode === 852) {
        error = !(reg.hkTelephone.test(mobile) && mobile) && !isEmptyMobile;
      } else {
        error =
          !(
            (reg.mobile.test(mobile) || reg.telephone.test(mobile)) &&
            mobile
          ) && !isEmptyMobile;
      }
      if (name === 'area_code') {
        onChange?.('mobile', Number(mobile), error);
      }
    }
    onChange?.(name, needToNumber ? Number(value) : value, error);
    setHasError(error);
    if (name === 'age' && value !== '' && error) {
      setAgeErrorMsg('ErrorMsg.PleaseInputYourAgeBetween');
    }
  };

  const formItemEnum: { [key: string]: React.ReactElement } = {
    input: (
      <Input
        name={keyName || ''}
        width={280}
        onChange={handleChange}
        color={themeContext.color.grey_medium}
        hasError={hasError && changed.current}
        errorMsg={ageErrorMsg}
        maxLength={nameInput.includes(keyName) ? 20 : 50}
        defaultValue={defaultValue}
        type={keyName === 'age' ? 'phoneNumber' : ''}
        {...props}
      />
    ),
    mobileGroup: (
      <InputGroup>
        <AreaCode>+{formValue?.area_code}</AreaCode>
        <PhoneWrapper>
          <Input
            width={220}
            name="mobile"
            type="phoneNumber"
            hasError={hasError && changed.current}
            onChange={handleChange}
            color={themeContext.color.grey_medium}
            errorMsg={errorMsg}
            defaultValue={defaultValue}
            countryCode={formValue?.area_code}
          />
        </PhoneWrapper>
      </InputGroup>
    ),
  };

  return (
    <ProfileEditInfoWrapper>
      <Title>{title ? <FormattedMessage id={title} /> : 'Unknown'}</Title>
      {editing ? (
        formItemEnum[type]
      ) : (
        <ValueDiv>
          {type === 'mobileGroup'
            ? `+${formValue?.area_code} ${defaultValue || '-'}`
            : defaultValue || '-'}
        </ValueDiv>
      )}
    </ProfileEditInfoWrapper>
  );
};
export default ProfileEditInfo;
