import React, { FC, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { pxToRem, enlargeFontsize } from '@rjp/common/utils';
import startBottomImage from '@rjp/common/assets/images/start-bottom.png';
import { Input } from 'antd';
import { Button } from '@rjp/common/component';

const DigitNumber = 6;
const DigitGroupWidth = pxToRem(15 * (DigitNumber - 1) + 42 * DigitNumber);
const DigitWidth = pxToRem(42);
const CursorHeight = pxToRem(30);

const Wrapper = styled.div`
  margin-top: ${pxToRem(40)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 40)};
  display: flex;
  flex-direction: column;
  align-items: center;
  .code-input {
    width: ${DigitGroupWidth};
    height: ${DigitWidth};
    opacity: 0;
    z-index: -100;
    /* top: 100px; */
    position: absolute;
    font-size: ${(props) => enlargeFontsize(props.theme.language, 28)};
    color: ${(props) => props.theme.color.sunlife_blue_medium};
  }
  .CurrentDigitDiv::after {
    content: '';
    position: absolute;
    width: 1px;
    background: ${(props) => props.theme.color.sunlife_blue_medium};
    height: ${CursorHeight};
    top: 0;
    bottom: 0;
    right: ${-DigitWidth / 2};
    margin: auto 0;
    animation: cursorFlash 1.2s infinite;
    @keyframes cursorFlash {
      0% {
        opacity: 0;
      }
      49% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      99% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .CurrentDigitDiv2::after {
    right: 0;
  }
`;

const TitleDiv = styled.div`
  margin-top: ${pxToRem(107)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 28)};
  text-align: center;
  font-weight: medium;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const DigitDiv = styled.div`
  width: ${DigitWidth};
  height: ${DigitWidth};
  line-height: ${DigitWidth};
  border-bottom-color: #d7d7d7;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  text-align: center;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 30)};
  position: relative;
`;

const DigitDivGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: ${DigitGroupWidth};
  margin-top: ${DigitWidth};
  cursor: text;
`;

const ConfirmButton = styled(Button)`
  margin-top: ${pxToRem(87)};
`;

const BottomImage = styled.img`
  height: ${pxToRem(331)};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const VCode: FC = () => {
  const [currentValue, setCurrentValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<any>(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const changeCode = (event?: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value;
    if (!Number.isNaN(Number(value)) || value === '' || value === undefined) {
      setCurrentValue(value === undefined ? '' : value?.toString());
    }
  };

  const preventInputChange = (e: any) => {
    if (
      e.keyCode === 38 ||
      e.keyCode === 40 || // 上 下 (好像没用)
      e.keyCode === 37 ||
      e.keyCode === 39 // 左 右
    ) {
      e.preventDefault();
    }
  };

  const confirm = () => alert('Confirm');

  const currentFocusedDigitDivClassName = (index: number) => {
    if (!isFocused) {
      return '';
    }
    if (index === DigitNumber - 1 && currentValue.length === DigitNumber) {
      return 'CurrentDigitDiv CurrentDigitDiv2';
    }
    const curIndex = currentValue.length === 0 ? 0 : currentValue.length;
    return curIndex === index ? 'CurrentDigitDiv' : '';
  };

  return (
    <Wrapper>
      <TitleDiv>Verification Code</TitleDiv>
      <DigitDivGroup onClick={() => inputRef.current.focus()}>
        <Input
          className="code-input"
          inputMode="numeric"
          type="text"
          maxLength={DigitNumber}
          onChange={changeCode}
          ref={inputRef}
          value={currentValue}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={preventInputChange}
        />
        {Array.from(Array(DigitNumber).keys()).map((d) => (
          <DigitDiv className={currentFocusedDigitDivClassName(d)} key={d}>
            {currentValue.split('')[d] || ''}
          </DigitDiv>
        ))}
      </DigitDivGroup>
      <ConfirmButton
        onClick={confirm}
        type={
          currentValue.length !== DigitNumber ? 'disabled' : 'primary-yellow'
        }
        width={180}
      >
        Confirm
      </ConfirmButton>
      <BottomImage src={startBottomImage} />
    </Wrapper>
  );
};
export default VCode;
