import { forEach } from 'lodash';

export const RJP_LINK = 0;
export const RJP_VIEW = 1;
let trackingQueueFlag = true;
var trackingEventsQueue:{
  functionName: any;
  type: string;
  data: string;
}[] = [];

export function trackingFunc(functionName: any, type: string, data: string) {
  if (trackingQueueFlag) {
    try {
      var trackingInitialObject = setInterval(() => {
        if (Tracking.getInstance() && trackingQueueFlag) {
          clearInterval(trackingInitialObject);
          trackingQueueFlag = false;
          console.log("in inerval")
          trackingEventsQueue.forEach((event) => {
            console.log(trackingEventsQueue)
            trackingFunc(event.functionName, event.type, event.data);
          });
        }
      }, 10);
    } catch (event) {}
  }

  try {
    switch (functionName) {
      case RJP_LINK:
        Tracking.getInstance().rjpLink(type, data);
        break;
      case RJP_VIEW:
        Tracking.getInstance().rjpView(type, data);
        break;
    }
  } catch (error) {
    trackingEventsQueue.push({
      functionName: functionName,
      type: type,
      data: data,
    });
    console.log(trackingEventsQueue);
    console.log(error);
  }
}

export class Tracking {
  private static instance: Tracking;

  engine: any;

  constructor(trackingObject: any) {
    this.engine = trackingObject;
  }
  public static init(trackingObject: any) {
    if (!Tracking.instance) {
      Tracking.instance = new Tracking(trackingObject);
    }
  }

  public static setInstance(tracking: Tracking) {
    if (!Tracking.instance) {
      Tracking.instance = tracking;
    }
  }

  public static getInstance(): Tracking {
    return Tracking.instance;
  }

  public rjpLink = (type: any, data: any) => {
    if (this.engine) {
    }
    this.engine.link({
      ev_type: type,
      ev_action: 'clk',
      ev_title: 'retirement journey planner',
      ev_data_one: data,
    });
  };

  public rjpView = (type: any, data: any) => {
    if (this.engine) {
      this.engine.view({
        ev_type: type,
        ev_action: 'onpage_impr',
        ev_title: 'retirement journey planner',
        ev_data_one: data,
      });
    }
  };

}
