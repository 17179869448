import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Button,
} from '@rjp/common/component';
import { RouteComponentProps } from 'react-router-dom';
import { ReactComponent as CircularSvg } from '@rjp/common/assets/images/circular.svg';
import advisorForgotZh1 from '@rjp/common/assets/images/advisor-forgot-zh-1.png';
import advisorForgotEn1 from '@rjp/common/assets/images/advisor-forgot-en-1.png';
import advisorForgotZh2 from '@rjp/common/assets/images/advisor-forgot-zh-2.png';
import advisorForgotEn2 from '@rjp/common/assets/images/advisor-forgot-en-2.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../app';
// import clientForgot1 from '@rjp/common/assets/images/client-forgot-1.png';
// import clientForgot2 from '@rjp/common/assets/images/client-forgot-2.png';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`ForgotPassword.${id}`} />
);

const HeaderWrapper = styled.div`
  height: ${pxToRem(150)};
  background: ${(props) => props.theme.color.sunlife_blue_medium};
  padding-left: ${pxToRem(30)};
  overflow: hidden;
`;

const HeaderTitle = styled(CurFormattedMessage)`
  font-weight: 500;
  margin-top: ${pxToRem(20)};
  font-size: ${pxToRem(32)};
  height: ${pxToRem(44)};
  line-height: ${pxToRem(44)};
  color: ${(props) => props.theme.color.white};
`;

const HeaderDesc = styled(CurFormattedMessage)`
  height: ${pxToRem(66)};
  margin-top: ${pxToRem(19)};
  line-height: ${pxToRem(66)};
  font-size: ${pxToRem(18)};
  color: ${(props) => props.theme.color.white};
`;

const OtherForgotWrapper = styled.div``;

const StepWrapper = styled.div`
  display: flex;
  margin-left: ${pxToRem(61)};
  margin-top: ${pxToRem(61)};
`;

const StepItem = styled.div<{ index: number }>`
  position: relative;
  margin-right: ${pxToRem(120)};
`;

const StepTitle = styled(CurFormattedMessage)`
  height: ${pxToRem(66)};
  width: ${pxToRem(312)};
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  font-weight: 500;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const StepImage = styled.img`
  width: ${pxToRem(312)};
  border: 1px ${(props) => props.theme.color.grey_light} solid;
  margin-top: ${pxToRem(12)};
`;

const BottomTip = styled(CurFormattedMessage)`
  height: ${pxToRem(60)};
  width: ${pxToRem(747)};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(21)};
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  margin: ${pxToRem(28)} 0 ${pxToRem(15)} ${pxToRem(61)};
`;

const BottomBtnWrapper = styled.div`
  display: flex;
  width: ${pxToRem(360)};
  justify-content: space-between;
  margin-left: ${pxToRem(61)};
  margin-top: ${pxToRem(21)};
`;

const CurCircularSvg = styled(CircularSvg)`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  display: block;
`;

const SerialNumberWrapper = styled.div`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  position: absolute;
  top: ${pxToRem(2)};
  left: ${pxToRem(-31)};
`;

const SerialNumber = styled.div`
  font-size: ${pxToRem(18)};
  font-weight: 500;
  color: ${(props) => props.theme.color.white};
  position: absolute;
  top: 0;
  left: 0;
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const stepImagesEnum: { [k: string]: { [k: string]: string }[] } = {
  advisor: [
    {
      'zh-hant': advisorForgotZh1,
      en: advisorForgotEn1,
    },
    {
      'zh-hant': advisorForgotZh2,
      en: advisorForgotEn2,
    },
  ],
};

const OtherForgot: FC<RouteComponentProps<any>> = ({
  match,
  history,
}) => {
  const { role } = match.params;
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const goToSunLife = () => window.open(process.env.REACT_APP_SUNOFFICE_URL);

  return (
    <OtherForgotWrapper>
      <HeaderWrapper>
        <HeaderTitle id={`${role}.Title`} />
        <HeaderDesc id={`${role}.Desc`} />
      </HeaderWrapper>
      <StepWrapper>
        {stepImagesEnum[role].map((data, index: number) => (
          <StepItem key={data[language]} index={index + 1}>
            <SerialNumberWrapper>
              <CurCircularSvg />
              <SerialNumber>{index + 1}</SerialNumber>
            </SerialNumberWrapper>
            <StepTitle id={`${role}.Step${index + 1}`} />
            <StepImage src={data[language]} />
          </StepItem>
        ))}
      </StepWrapper>
      <BottomTip id={`${role}.Tip`} />
      <BottomBtnWrapper>
        <Button type="secondary" width={170} onClick={() => history.goBack()}>
          <CurFormattedMessage id={`${role}.Back`} />
        </Button>
        <Button type="primary-yellow" width={170} onClick={goToSunLife}>
          <CurFormattedMessage id={`${role}.GoToSunLife`} />
        </Button>
      </BottomBtnWrapper>
    </OtherForgotWrapper>
  );
};

export default OtherForgot;
