import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';

const Content = styled.div`
  font-size: ${pxToRem(16)};
`;

const prefix = 'PopModal.OnlyProductsSoldByAdvisorCanBeViewedModal.';

const OnlyProductsSoldByAdvisorCanBeViewedModal: FC<ModalProps> = ({
  visible = false,
  onClose,
}) => (
  <Modal
    width={466}
    visible={visible}
    onClose={onClose}
    title={`${prefix}Title`}
    textAlign="center"
  >
    <Content>
      <FormattedMessage id={`${prefix}Paragraph`} />
    </Content>
  </Modal>
);

export default OnlyProductsSoldByAdvisorCanBeViewedModal;
