import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { currencyFormat, enlargeFontsize, pxToRem } from '@rjp/common/utils';
import compoundPop from '@rjp/common/assets/images/cpmpound-pop.png';
import { Modal, ModalProps } from '@rjp/common/component';
import { CompoundUpItemConfig, CompoundBottomItemConfig } from './config';

interface ItemProps {
  width: number;
  marginLeft: number;
  marginTop?: number;
}

const StyleModal = styled((props) => <Modal {...props} />)`
  .ant-modal-content {
    height: ${pxToRem(580)};
  }
  .ant-modal-body {
    height: ${pxToRem(486)};
    padding: 0;
  }
`;

const ModalWrapper = styled.div`
  width: ${pxToRem(860)};
`;

const Content = styled.div`
  height: ${pxToRem(355)};
  background-color: ${(props) => props.theme.color.blue_light};
`;

const ContentTop = styled.div`
  height: ${pxToRem(200)};
  padding: ${pxToRem(57)} 0;
  padding-bottom: 0;
  text-align: center;
`;

const TitleDiv = styled.div`
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 20)};
  font-weight: bold;
  margin-bottom: ${pxToRem(34)};
`;

const Paragraph = styled.div`
  width: ${pxToRem(732)};
  margin: auto;
  line-height: 135%;
  font-size: ${pxToRem(18)};
  font-weight: 300;
`;

const ContentUp = styled.div`
  height: ${pxToRem(155)};
  background: url(${compoundPop}) no-repeat ${pxToRem(20)};
  background-size: ${pxToRem(808.55)} ${pxToRem(155)};
`;

const ContentUpItem = styled.div<ItemProps>`
  width: ${(props) => pxToRem(props.width)};
  height: ${(props) => pxToRem(props.width)};
  margin-left: ${(props) => pxToRem(props.marginLeft)};
  background-size: ${pxToRem(808.55)} ${pxToRem(155)};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(14)};
  display: inline-block;
  white-space: pre-wrap;
  text-align: center;
  vertical-align: bottom;

  &.equal-item {
    height: ${pxToRem(80)};
  }

  &.last-item {
    font-size: ${pxToRem(21)};
    font-weight: bold;
    line-height: ${pxToRem(21)};
    color: ${(props) => props.theme.color.white};
  }

  .item-desc {
    margin-top: ${(props) => pxToRem(props.marginTop || 0)};
    display: inline-block;
  }
`;

const ContentBottom = styled.div`
  height: ${pxToRem(131)};
`;

const ContentBottomItem = styled.div<ItemProps>`
  width: ${(props) => pxToRem(props.width)};
  margin-left: ${(props) => pxToRem(props.marginLeft)};
  margin-top: ${pxToRem(10)};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(14)};
  display: inline-block;
  white-space: pre-wrap;
  text-align: center;
  vertical-align: bottom;

  &.last-bottom-item {
    font-size: ${pxToRem(17)};
    font-weight: bold;
    line-height: ${pxToRem(21)};
    color: ${(props) => props.theme.color.sunlife_blue_light};
  }

  .item-bottom-money {
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
  }

  .item-desc {
    display: inline-block;
  }

  .item-bottom-bold {
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(11)};
    font-weight: 500;
    display: inline-block;
  }
`;

const Note = styled.div`
  margin-top: ${pxToRem(30)};
  margin-left: ${pxToRem(28)};
  font-size: ${pxToRem(12)};
`;

const moneyWrapper = (msg: string) => (
  <span className="item-bottom-money">{msg}</span>
);
const bold = (msg: string) => <span className="item-bottom-bold">{msg}</span>;

const prefix = 'PopModal.Compound.';
const CompoundPopModal: FC<ModalProps> = ({ visible = false, onClose }) => (
  <StyleModal width={860} visible={visible} onClose={onClose}>
    <ModalWrapper>
      <Content>
        <ContentTop>
          <TitleDiv>
            <FormattedMessage id={`${prefix}ThePowerOfCompounding`} />
          </TitleDiv>
          <Paragraph>
            <FormattedMessage id={`${prefix}Paragraph`} />
          </Paragraph>
        </ContentTop>
        <ContentUp>
          {CompoundUpItemConfig.map(
            ({ label, className, width, marginLeft, marginTop, money }) => (
              <ContentUpItem
                key={`${label || className}${width}`}
                width={width}
                marginLeft={marginLeft}
                marginTop={marginTop}
                className={className}
              >
                {label ? (
                  <div className="item-desc">
                    <FormattedMessage
                      id={`${prefix}${label}`}
                      values={{ value: currencyFormat(money || 0) }}
                    />
                  </div>
                ) : (
                  '='
                )}
              </ContentUpItem>
            )
          )}
        </ContentUp>
      </Content>
      <ContentBottom>
        {CompoundBottomItemConfig.map(
          ({ label, className, width, marginLeft, money, year }, index) => (
            <ContentBottomItem
              key={`${label}${index}` || className}
              width={width}
              marginLeft={marginLeft}
              className={className}
            >
              <div className="item-desc">
                <FormattedMessage
                  id={`${prefix}${label}`}
                  values={{
                    money: currencyFormat(money),
                    value: year,
                    bold,
                    moneyWrapper,
                  }}
                />
              </div>
            </ContentBottomItem>
          )
        )}
        <Note>
          <FormattedMessage id={`${prefix}AssumingInterestRate`} />
        </Note>
      </ContentBottom>
    </ModalWrapper>
  </StyleModal>
);

export default CompoundPopModal;
