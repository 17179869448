import React, { FC, useEffect, useState, useMemo } from 'react';
import {
  WorkSurfaceHeader,
  SignOutModel,
  WaitSignOutModal,
} from '@rjp/common/component';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import cookies from 'js-cookie';
import { toLogin } from '@rjp/common/utils';
import { logout } from '@rjp/main/src/features/auth/authSlice';
import {getIllustration, getVisionIllustration} from "@/api/illustration";
import {
  setShowContactAdvisorModal,
  setActiveContactAdvisorModalConfig,
} from '@rjp/main/src/features/contactAdvisor/contactAdvisorSlice';
import { getsvtoken } from '@rjp/main/src/api';
import {
  createRetirementProfile,
  updateRetirementProfile as updateRetirementProfileAPI,
} from '@rjp/main/src/api';
import { getProductsData } from '@rjp/main/src/features/productsData/productsSlice';
import {
  filteredIncludedQuoteSelector,
  filteredAddedQuotesSelector,
} from '@/features/rcData/rcDataSelector';
import { editSessionStatus } from '@/features/advisorEdit/advisorEditSlice';

import { AppDispatch, RootState } from '../../app';
import Profile from './profile';
import Home from './home';
import {
  setUserInfo,
  IUserInfoState,
  updateUserInfo,
} from '../../features/userInfo/userInfoSlice';
import {
  updateRetirementProfile,
  setRetirementGapSummary,
  setIncludedQuote,
  setAddedQuotes,
} from '../../features/rcData/rcDataSlice';
import {
  getMember,
  // getClient,
  getRetirementProfile,
  getRetirementGapCalculation,
  contactAdvisor,
  getAddedQuotes,
} from '../../api';

import { DoneRCModal, OverwriteRCResultModal } from './home/details/modal';
import { ContactAdvisorModal } from './home/modal';
import { trackingFunc, RJP_LINK } from '@/utils/tracking';

const WorkSurfaceWrapper = styled.div``;
let rp:any;
const caseId =
  cookies.get('caseId') ||
  new URLSearchParams(window.location.search).get('caseId');

const WorkSurface: FC<RouteComponentProps> = ({ history, location, match }) => {
  const RCPasteId = useSelector((state: RootState) => state.RCData.RCPasteId);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [showSignOutModal, setShowSignOutModal] = useState<boolean>(false);
  const [showWaitSignOut, setShowWaitSignOut] = useState<boolean>(false);
  const [contactAdvisorCheck, setContactAdvisorCheck] = useState<boolean>(
    false
  );
  const [memberShowQuotes, setMemberShowQuotes] = useState<boolean>(false);
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const { retirementProfile, rcRetirementProfile, addedQuotes } = useSelector(
    (state: RootState) => state.RCData,
    shallowEqual
  );
  const dispatch = useDispatch<AppDispatch>();
  const [clientInfo, setClientInfo] = useState<IUserInfoState>();
  const { id = '', consent_expiry_date, role = 'member' } = useSelector(
    (state: RootState) => state.userInfo,
    shallowEqual
  );
  const productsData = useSelector((state: RootState) => state.productsData);
  const filteredIncludedQuote = useSelector(filteredIncludedQuoteSelector);
  const filteredAddedQuotes = useSelector(filteredAddedQuotesSelector);

  const [showDoneRCModal, setShowDoneRCModal] = useState<boolean>(false);

  const [showOverwriteRCResult, setShowOverwriteRCResult] = useState<boolean>(
    false
  );

  const signOut = async () => {
    setShowSignOutModal(false);
    setShowWaitSignOut(true);
    await dispatch(logout());
    setShowWaitSignOut(false);
    if (role === 'advisor') {
      toLogin('advisor', history)();
    } else {
      history.replace('/');
    }
  };

  useEffect(() => {
    const fetchGetRGC = async () => {
      if (retirementProfile && retirementProfile?.gender) {
        const retirementGapData = await getRetirementGapCalculation({
          retirement_profile: {
            ...retirementProfile,
            quotes: filteredIncludedQuote,
          },
        });
        if (retirementGapData) {
          dispatch(
            setRetirementGapSummary(retirementGapData.retirement_gap_summary)
          );
        }
      }
    };
    fetchGetRGC();
  }, [dispatch, role, retirementProfile, filteredIncludedQuote]);

  rp = retirementProfile;

  const onClickContactAdvisor = async () => {
    if (consent_expiry_date && dayjs(consent_expiry_date).isValid()) {
      try {
        // use with consent_expiry_date should call contactAdvisor API again and decide the state.
        // error code should be returned for decision
        await contactAdvisor({ id, is_request_consent: contactAdvisorCheck });
      } catch (error) {
        if (error === 10017) {
          // 10017 means no advisor pick up the request yet, still pending
          dispatch(setActiveContactAdvisorModalConfig('pendingAssignmnet'));
        } else if (error === 10018) {
          // 10018 means advisor has picked up the request.
          dispatch(setActiveContactAdvisorModalConfig('extend'));
        }
      }
    } else {
      // user without consent_expiry_date should request advisor
      dispatch(setActiveContactAdvisorModalConfig('requestAdvisor'));
    }
    dispatch(setShowContactAdvisorModal(true));
  };

  const removeCaseId = () => cookies.remove('caseId');

  const createProfile = async () => {
    const curRetirementProfile = {
      ...rcRetirementProfile,
      other_investment: [],
      future_payments: [],
    };
    try {
      await createRetirementProfile({
        client_id: clientInfo?.id,
        language,
        ...curRetirementProfile,
      });
    } catch (error) {
      if (error === 10021) {
        try {
          await updateRetirementProfileAPI({
            id: clientInfo?.id ?? '',
            role,
            language,
            ...curRetirementProfile,
          });
          dispatch(updateUserInfo({ age: rcRetirementProfile?.age }));
        } catch (e) {
          console.error(e);
        }
      }
    }
    removeCaseId();
  };

  const overwriteRCResult = async () => {
    const curRetirementProfile = {
      ...rcRetirementProfile,
      other_investment: [],
      future_payments: [],
    };
    try {
      if (rcRetirementProfile) {
        await updateRetirementProfileAPI({
          id: clientInfo?.id ?? '',
          role,
          language,
          ...curRetirementProfile,
        });
        dispatch(updateRetirementProfile(curRetirementProfile));
        dispatch(updateUserInfo({ age: rcRetirementProfile?.age }));
      }
    } catch (err) {
      console.error(err);
    }
    setShowOverwriteRCResult(false);
    removeCaseId();
  };

  //const currentCustomerId = useMemo(() => clientInfo?.id ?? '', [clientInfo]);



  useEffect(() => {
    const fetchProfile = async () => {
      let profile: IUserInfoState | undefined;
      const paramsObject = new URLSearchParams(location.search);
      const create_profile = paramsObject.get('create_profile');

      try {
        if (role === 'member' && id) {
          const responseData = await getMember({ member_id: id, language });
          profile = responseData.member;
          // } else if (role === 'client') {
          //   const responseData = await getClient({ id: 'IDIDIDID', language });
          //   profile = responseData.client;
        } else if (role === 'advisor') {
          const member_id = paramsObject.get('member_id');
          if (member_id) {
            const responseData = await getMember({ member_id, language });
            profile = responseData.member;
          } else if (create_profile) {
            const createProfileData = sessionStorage.getItem('create_profile');
            if (createProfileData) {
              profile = JSON.parse(createProfileData);
            }
          }
        }
        if (profile?.id) {
          if (role !== 'advisor') {
            dispatch(
              setUserInfo({
                ...profile,
                role,
              })
            );
          }
          setClientInfo(profile);
          const responseData = await getRetirementProfile({
            id: profile.id,
            role,
          });
          const resultRetirementProfile: Record<string, any> = {
            ...responseData.retirement_profile,
            age: responseData.retirement_profile.age ?? profile?.age,
          };

          const rcResultRetirementProfile: Record<string, any> = {
            ...rcRetirementProfile,
            age: rcRetirementProfile?.age || profile?.age,
          };

          const isMember = role === 'member';
          // 如果后端没有profile 但是 有从rc带过来的profile 就为该用户创建一个profile， 只有member才有这个逻辑

          const isCreateProFile =
            rcResultRetirementProfile?.gender &&
            !resultRetirementProfile.gender &&
            isMember;

          dispatch(
            updateRetirementProfile(
              isCreateProFile
                ? {
                    ...rcResultRetirementProfile,
                    other_investment: [],
                    future_payments: [],
                  }
                : resultRetirementProfile
            )
          );

          if (isCreateProFile) createProfile();

          // 显示是否覆盖的弹框 只有member才弹这个框
          setShowOverwriteRCResult(
            responseData.retirement_profile.gender &&
              rcRetirementProfile?.gender &&
              isMember
          );

          setShowDoneRCModal(
            !responseData.retirement_profile.gender &&
              !caseId &&
              !RCPasteId &&
              isMember
          );

          // 登入之后拥有了 id，并且有 quote
          const addedQuotesResponseData = await getAddedQuotes({
            customer_id: profile.id,
          });
          setMemberShowQuotes(true);
          const dataToken = await getsvtoken({
            customer_id: profile.id,
          });
          // for each quote, send to illustration API
          for (const quote of addedQuotesResponseData.quotes) {
            if (quote.product_code === 'VICT') {
              try{
                const data = await getIllustration(quote.parameters, rp, dataToken.svToken);
                quote.illustration = data;
              } catch(error){
                console.log(error);
              }
            } else if (quote.product_code === "VISION") {
              try{
                const data = await getVisionIllustration(quote.parameters, rp, dataToken.svToken);
                quote.illustration = data;
              } catch(error){
                console.log(error);
              }
            }
          }
          dispatch(setAddedQuotes(addedQuotesResponseData.quotes));
        } else if (profile?.last_name && create_profile) {
          setClientInfo(profile);
        }
      } catch (error) {
        // TODO: Show error
        console.log(error);
      }
    };
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, role, rcRetirementProfile]);

  useEffect(() => {
    const quotesNeededToCallAPI = filteredAddedQuotes.filter(
      (quote) => !productsData[quote.product_code]?.[quote.id]
    );
    if (quotesNeededToCallAPI.length > 0) {
      dispatch(getProductsData(quotesNeededToCallAPI));
    }
  }, [dispatch, filteredAddedQuotes, productsData]);

  useEffect(() => {
    if (addedQuotes.length > 0 && isFirst) {
      dispatch(
        setIncludedQuote(
          addedQuotes.map((item) => ({ quote_id: item.id, is_included: false }))
        )
      );
      setIsFirst(false);
    }
  }, [addedQuotes, dispatch, isFirst]);

  const goToProfile = () => {
    if (location.pathname !== `${match.path}/profile`) {
      history.push(`${match.path}/profile`);
    }
  };

  const advisorEdit = useSelector((state: RootState) => state.advisorEdit);

  // convert expired time to undefined for easier logic flow
  const adjustedEditSessionExpiry = useMemo(
    () =>
      dayjs(advisorEdit[clientInfo?.id ?? '']).isAfter(dayjs())
        ? advisorEdit[clientInfo?.id ?? '']
        : undefined,
    [advisorEdit, clientInfo]
  );

  useEffect(() => {
    if (
      role === 'advisor' &&
      process.env.REACT_APP_IS_SUNLIFE_API &&
      !adjustedEditSessionExpiry
    ) {
      dispatch(editSessionStatus(clientInfo?.id ?? ''));
    }
  }, [adjustedEditSessionExpiry, dispatch, location.search, role, clientInfo]);

  return (
    <WorkSurfaceWrapper>
      <DoneRCModal
        onClose={() => setShowDoneRCModal(false)}
        visible={showDoneRCModal}
      />
      <OverwriteRCResultModal
        visible={showOverwriteRCResult}
        onClose={() => {
          removeCaseId();
          setShowOverwriteRCResult(false);
        }}
        onConfirm={overwriteRCResult}
      />
      <WorkSurfaceHeader
        match={match}
        history={history}
        onClickProfile={goToProfile}
        onClickSignOut={() => setShowSignOutModal(true)}
        onClickContactAdvisor={() => {onClickContactAdvisor();trackingFunc(RJP_LINK, 'other', 'contact advisor')}}
        inAdvisor={role === 'advisor'}
      />
      <SignOutModel
        visible={showSignOutModal}
        signOut={signOut}
        onClose={() => setShowSignOutModal(false)}
      />
      <ContactAdvisorModal
        contactAdvisorCheck={contactAdvisorCheck}
        setContactAdvisorCheck={setContactAdvisorCheck}
      />
      <WaitSignOutModal
        onClose={() => setShowWaitSignOut(false)}
        visible={showWaitSignOut}
      />
      <Switch>
        <Route path={`${match.path}/profile`} component={Profile} />
        <Route
          path={`${match.path}/gap`}
          render={(props) => (
            <Home
              clientInfo={clientInfo}
              memberShowQuotes={memberShowQuotes}
              {...props}
            />
          )}
        />
        <Route
          render={(routeProps) => (
            <Redirect
              to={{
                pathname: `${match.path}/gap`,
                search: routeProps.location.search,
              }}
            />
          )}
        />
      </Switch>
    </WorkSurfaceWrapper>
  );
};
export default WorkSurface;
