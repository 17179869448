import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/zh";
import "@formatjs/intl-numberformat/locale-data/en";

export const currencyFormat = (
  value: number,
  locale = "en",
  currency = "USD"
) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  }).format(value);

export const currencyNoPrefixAndDecimalFormat = (
  value: number,
  locale = "en"
) =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
  }).format(value);

export const currencyNoPrefixFormat = (value: number, locale = "en") =>
  new Intl.NumberFormat(locale).format(value);

export const currencyCompactFormat = (value: number) =>
  `$${new (Intl.NumberFormat as any)("en", {
    notation: "compact",
    compactDisplay: "short",
  }).format(value)}`;

export const numberDecimalsFormat = (value: number) =>
  Number(value.toString().match(/^\d+(?:\.\d{0,2})?/));

export const percentageFormat = (
  value: number,
  maximumFractionDigits = 1,
  useGrouping = false
) =>
  new Intl.NumberFormat("en", {
    style: "percent",
    maximumFractionDigits,
    useGrouping,
  }).format(value);

export const percentageSplitFormat = (value: number) =>
  new Intl.NumberFormat().format(value);

export const roundToHundredsOfDigits = (value: number) =>
  Math.round(value / 100) * 100;

export const roundUpDecimal = (value: number, dp = 0) =>
  Math.ceil(value * 10 ** dp) / 10 ** dp;

export const roundDownDecimal = (value: number, dp = 0) =>
  Math.floor(value * 10 ** dp) / 10 ** dp;

export const boundedValue = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);
