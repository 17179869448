import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { theme } from '@rjp/common/constants';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  CollapseCard,
} from '@rjp/common/component';
import { RootState } from '@rjp/main/src/app';
import { useSelector } from 'react-redux';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`WorkSurface.Home.RetirementStats.${id}`} />
);

const CurCollapseCard = styled(CollapseCard)`
  width: ${pxToRem(470)};
  border-radius: 0px 0px ${pxToRem(5)} ${pxToRem(5)};
  margin-bottom: ${pxToRem(20)};
  box-shadow: none;
  .title {
    font-weight: 500;
    font-size: ${pxToRem(18)};
    height: ${pxToRem(16)};
    line-height: ${pxToRem(16)};
  }
  .content {
    padding-top: ${pxToRem(27)};
    padding-bottom: ${pxToRem(4)};
  }
  svg {
    path {
      fill: ${(props) => props.theme.color.white};
    }
  }
`;

const DataList = styled.div`
  height: ${pxToRem(60)};
  padding: ${pxToRem(15)};
  padding-bottom: 0;
  display: flex;
`;

const DataItem = styled.div`
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.color.coolblue_medium};
  margin-right: ${pxToRem(17)};
  height: ${pxToRem(50)};
  &:last-child {
    border-right: none;
    margin-right: 0;
  }
`;

const DataItemName = styled(CurFormattedMessage)`
  font-size: ${pxToRem(16)};
`;

const Data = styled.div`
  font-weight: 500;
  font-size: ${pxToRem(24)};
  margin-top: ${pxToRem(-4)};
`;

const BottomTip = styled.div`
  font-size: ${pxToRem(16)};
  margin-top: ${pxToRem(15)};
  padding: ${pxToRem(15)};
  padding-bottom: 0;
  .bold {
    font-weight: 500;
    font-size: ${pxToRem(24)};
    margin-right: ${pxToRem(6)};
  }
`;

const RetirementStats: FC = () => {
  const retirementGapSummary = useSelector(
    (state: RootState) => state.RCData.retirementGapSummary
  );

  const retirementBaseData = useMemo(
    () => [
      { key: 'RetirementAge', value: retirementGapSummary.retirement_age },
      { key: 'LifeExpectancy', value: retirementGapSummary.life_expectancy },
      { key: 'RetirementYears', value: retirementGapSummary.retirement_years },
    ],
    [retirementGapSummary]
  );

  return (
    <CurCollapseCard
      backgroundColor={theme.color.coolblue}
      color={theme.color.white}
      defaultClose
      titleConfig={{
        title: 'WorkSurface.Home.RetirementStats.RetirementStats',
      }}
    >
      <DataList>
        {retirementBaseData.map((item) => (
          <DataItem key={item.key}>
            <DataItemName id={item.key} />
            <Data>{item.value}</Data>
          </DataItem>
        ))}
      </DataList>
      <BottomTip>
        <span className="bold">{retirementGapSummary.years_to_retirement}</span>
        <CurFormattedMessage id="YearsToYourRetirement" tagName="span" />
      </BottomTip>
    </CurCollapseCard>
  );
};

export default RetirementStats;
