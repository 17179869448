import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalProps, Label } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';
import { CheckBoxGroup } from '../modal/components';
import { RJP_LINK, trackingFunc } from '@/utils/tracking';

interface IAddSaveQuoteModalProps extends ModalProps {
  quotesData: Record<string, any>[];
  onAdd: (quotes_id: string[]) => void;
}

const StyleModal = styled((props) => <Modal {...props} />)`
  .ant-modal-body {
    padding: ${pxToRem(40)} ${pxToRem(78)} 0 ${pxToRem(100)};
  }
`;

const Top = styled((props) => <Label {...props} />)`
  height: ${pxToRem(50)};
  line-height: ${pxToRem(50)};
  margin-bottom: ${pxToRem(9)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Content = styled.div`
  font-size: ${pxToRem(16)};

  .group-wrapper {
    width: ${pxToRem(521)};
    min-height: ${pxToRem(302)};
    max-height: ${pxToRem(342)};
    margin-top: ${pxToRem(26)};
    padding: ${pxToRem(13)} ${pxToRem(20)} ${pxToRem(13)} ${pxToRem(32)};
    background: ${(props) => props.theme.color.cool_blue_light_20};
    overflow: auto;

    .checkbox-list {
      padding: ${pxToRem(10)} 0;
      line-height: ${pxToRem(20)};
      font-size: ${pxToRem(16)};

      svg {
        margin-right: ${pxToRem(10)};
      }
    }

    .select-all {
      width: 100%;
      font-size: ${pxToRem(14)};
      border-bottom: ${pxToRem(1)} solid
        ${(props) => props.theme.color.cool_blue_light};
      color: ${(props) => props.theme.color.coolblue};
    }
  }
`;

const SelectTheQuotes = styled.div`
  font-size: ${pxToRem(18)};
  font-weight: 500;
`;

const prefix = 'PopModal.AddSaveQuoteModal.';
const data = [
  {
    id: '02af950d-27c0-405e-885d-bf65bb574af4',
    product_code: 'COMM',
    product_status: 'Shelved',
    description: 'Vision - Amy Lu',
    parameters: {
      age: 35,
      gender: 'Male',
    },
    creation_date: '2020-07-29T16:39:57+08:00',
    product_last_update_date: '2020-07-29T16:39:57+08:00',
  },
  {
    id: '6348a211-693f-43da-8113-f93a1f6b4da3',
    product_code: 'VISION',
    product_status: 'Updated',
    description: 'Vision - Amy Lu',
    parameters: {
      age: 35,
      gender: 'Male',
    },
    creation_date: '2020-07-28T16:39:57+08:00',
    product_last_update_date: '2020-07-29T16:39:57+08:00',
  },
];

const AddSaveQuoteModal: FC<IAddSaveQuoteModalProps> = ({
  visible = false,
  onClose,
  quotesData,
  onAdd,
}) => {

  useEffect(() => {
    if (quotesData.length > 0) {
      console.log("quotesData => " + quotesData.length);
      trackingFunc(RJP_LINK, 'other', `sv proposals: ${quotesData.length}`);
    }
  }, [quotesData.length]);

  const [quotesId, setQuotesId] = useState<string[]>([]);
  const onClick = () => {
    onAdd(quotesId);
    setQuotesId([]);
    onClose?.();
  };
  const onChange = (value: string[]) => {
    setQuotesId(value);
  };

  const addType = useMemo(() => (quotesId.length ? 'primary' : 'disabled'), [
    quotesId,
  ]);

  return (
    <StyleModal
      width={718}
      visible={visible}
      onClose={onClose}
      footerConfig={[
        {
          message: `Common.Cancel`,
          width: 170,
          type: 'secondary',
          onClick: onClose,
        },
        { message: `${prefix}Add`, width: 170, type: addType, onClick },
      ]}
    >
      <Top fontSize={24}>
        <FormattedMessage id={`${prefix}AddSavedQuotes`} />
      </Top>
      <Content>
        <SelectTheQuotes>
          <FormattedMessage id={`${prefix}SelectTheQuotes`} />
        </SelectTheQuotes>
        { visible && <CheckBoxGroup
          options={quotesData.length ? quotesData : data}
          onChange={onChange}
          type="primary-blue"
        /> }
      </Content>
    </StyleModal>
  );
};

export default AddSaveQuoteModal;
