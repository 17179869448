import React, { FC } from "react";
import { Radio as AntRadio } from "antd";
import { RadioProps as AntRadioProps } from "antd/es/radio";
import { RadioButtonProps as AntRadioButtonProps } from "antd/es/radio/radioButton";
import styled, { css } from "styled-components";
import { FormattedMessage } from "react-intl";

import { enlargeFontsize, pxToRem } from "../../utils";
import ErrorMessage from "../errorMessage";

export interface RadioButtonProps extends AntRadioButtonProps {
  width?: number;
}

const StyledRadioButton = styled(AntRadio.Button)<RadioButtonProps>`
  text-align: center;
  ${(props) => props.width && `width: ${props.width}rem;`}
  user-select: none;

  &.ant-radio-button-wrapper-checked {
    font-weight: bold;
    color: ${(props) => props.theme.color.grey_dark};
  }

  &:not(.ant-radio-button-wrapper-disabled):hover {
    color: ${(props) => props.theme.color.grey_dark};
  }

  &.ant-radio-button-wrapper-disabled {
    border-color: ${(props) => props.theme.color.grey_medium};

    &::before {
      background-color: ${(props) => props.theme.color.grey_medium};
    }
  }
`;

type IRadio = FC<AntRadioProps> & {
  Group: typeof AntRadio.Group;
  Button: typeof StyledRadioButton;
};

const Radio: IRadio = (props) => <AntRadio {...props} />;

Radio.Button = StyledRadioButton;

Radio.Group = AntRadio.Group;

export default Radio;

export interface IRadioConfigProps {
  type: string;
  title: string;
  name: string;
  width: number;
  options: {
    value: string;
    label: string;
  }[];
  buttonType?: "primary" | "medium-blue";
}

export interface RadioWrapProps {
  config: any;
  onRadioButtonClick: any;
  value: string | number;
  prefix: string;
  errorMsg?: string;
  hasError?: boolean;
}

const RadioGroup = styled(Radio.Group)`
  display: flex;
`;

const mediumBlueMixin = css`
  &.ant-radio-button-wrapper,
  .ant-radio-button-wrapper:hover {
    color: ${(props) => props.theme.color.sunlife_blue_medium} !important;
    border-color: ${(props) => props.theme.color.sunlife_blue_medium};
  }
  &.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper-disabled:hover {
    color: ${(props) => props.theme.color.white} !important;
    background: ${(props) => props.theme.color.sunlife_blue_medium};
    border-color: ${(props) =>
      props.theme.color.sunlife_blue_medium} !important;
    font-weight: bold;
  }
  &.ant-radio-button-wrapper:not(:first-child)::before {
    background: ${(props) => props.theme.color.sunlife_blue_medium};
  }
`;

const RadioButton = styled(Radio.Button)<Pick<IRadioConfigProps, "buttonType">>`
  height: ${pxToRem(48)};
  line-height: ${pxToRem(48)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 16)};

  ${(props) => {
    if (props.buttonType === "medium-blue") {
      return mediumBlueMixin;
    }
  }}
`;

export const RadioGroupWrap: FC<RadioWrapProps> = ({
  config,
  onRadioButtonClick,
  value,
  prefix,
  errorMsg,
  hasError,
}) => (
  <>
    <RadioGroup value={value}>
      {config.options.map((item: any) => (
        <RadioButton
          key={item.value}
          onChange={(e: any) =>
            onRadioButtonClick?.(e.target.value, config.name)
          }
          width={config.width}
          value={item.value}
          disabled={item.disabled}
          buttonType={config.buttonType}
        >
          <FormattedMessage id={`${prefix}.${item.label}`} />
        </RadioButton>
      ))}
    </RadioGroup>
    {!!hasError && <ErrorMessage errorMsg={errorMsg} />}
  </>
);
