import React, { FC, useEffect, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { get } from "lodash";

import { currencyFormat, percentageFormat, pxToRem } from "@rjp/common/utils";
import {
  CollapseCard,
  ICurFormattedMessage,
  InfoButton,
  Label,
} from "@rjp/common/component";
import { CollapseCardProps } from "@rjp/common/component/collapseCard/CollapseCard";
import { ReactComponent as Edit } from "@rjp/common/assets/images/form-edit.svg";
import { ReactComponent as SolidPlus } from "@rjp/common/assets/images/solid-plus.svg";
import {
  fieldMap,
  otherValuesToTranslate,
  valuesToTranslate,
  otherValuesToTranslateMap,
  addFieldsTitle,
} from "@rjp/main/src/pages/workSurface/home/details/config";
import { IFormField, isInputNumber } from "./types";
import Fields, { ShowValueItemProps } from "./Fields";
import { trimAllReg } from "../../config";

const prefix = "Product.";
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

interface PersonalInformationItemProps extends CollapseCardProps {
  type?: string;
  subTitleConfig?: {
    title: string;
    name: string;
  };
  formData: any;
  itemConfig: any;
  setIsEdit: () => void;
  updateValue?: (
    value: number,
    name: string,
    cardName?: string,
    index?: number
  ) => void;
  setShowHelp?: (name: string, value: boolean) => void;
  errorTipValue?: number;
  prefix: string;
  onAdd?: (name: string) => void;
  addMsg?: string;
  portfolioTotal: number;
  fundsTotal: number;
  errorState?: ShowValueItemProps["errorState"];
  minMaxStep?: ShowValueItemProps["minMaxStep"];
  onRemove?: ShowValueItemProps["onRemove"];
  handleMaxMinErrorState?: ShowValueItemProps["handleErrorState"];
  handleErrorState?: ShowValueItemProps["handleErrorState"];
  onBlur?: ShowValueItemProps["onBlur"];
  activeTopError?: string;
}

interface AddComponentProps {
  isEdit: boolean;
  onAdd?: () => void;
  addMsg: string;
}

const ItemContent = styled.div`
  padding-bottom: 0;
  position: relative;

  svg {
    vertical-align: bottom;
  }

  .number-label,
  .number-inline {
    display: inline-block;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-input-number {
      width: ${pxToRem(50)};
    }
  }

  .number-inline {
    margin-left: ${pxToRem(8)};
    // margin-right: ${pxToRem(110)};
    margin-top: ${pxToRem(14)};
  }
  .input-input-number {
    margin-right: 0;
    position: absolute;
    right: ${pxToRem(15)};
  }
  .margin-top-number {
    margin-top: ${pxToRem(-88)};
  }
  .checkbox-input-number {
    position: absolute;
    right: ${pxToRem(15)};
    margin-top: ${pxToRem(-5)} !important;
    .ant-input-number {
      width: ${pxToRem(50)};

      .input__prefix {
        font-weight: 500;
      }
    }
    margin-top: 0;
    margin-right: 0;
  }
  .input-input {
    margin-left: 0;
    margin-right: ${pxToRem(28)};
  }

  .item-wrapper {
    margin-top: ${pxToRem(18)};

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${(props) => props.theme.color.coolblue_medium} !important;
    }

    .ant-select-arrow {
      svg {
        position: relative;
        top: ${pxToRem(-3)};
        path {
          fill: ${(props) => props.theme.color.sunlife_blue};
        }
      }
    }

    .check-box {
      width: ${pxToRem(343)};
      display: inline-flex;
    }

    .margin-top {
      line-height: ${pxToRem(21)};
      padding: ${pxToRem(6)} 0;
      white-space: pre-wrap;
    }
  }

  .no-of-children-input {
    margin-right: 0;
  }
`;

const ShowValueItemWrapper = styled.div<{ width?: number }>`
  &.number-item-wrapper {
    width: ${(props) => (props.width ? pxToRem(props.width) : "auto")};
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
  }

  &.current-age {
    margin-right: ${pxToRem(24)};
  }
`;

const EditSvg = styled(Edit)`
  width: ${pxToRem(20)};
  position: absolute;
  right: ${pxToRem(15)};
  top: ${pxToRem(18)};
`;

const StyledCollapseCard = styled((props) => <CollapseCard {...props} />)`
  margin: auto;
  margin-bottom: ${pxToRem(14)};

  .title {
    width: ${pxToRem(550)};

    .chevron-down {
      margin-top: ${pxToRem(10)};
    }
  }
`;

const ItalicSpan = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: ${pxToRem(14)};
`;

const SubTitle = styled.div<{ isEdit: boolean }>`
  margin: 0 ${pxToRem(15)};
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(57)};
  ${(props) =>
    !props.isEdit &&
    `border-bottom: 1px solid ${props.theme.color.cool_blue_light};`}
  display: flex;
  justify-content: space-between;

  .item-label-value {
    color: ${(props) => props.theme.color.coolblue};
    margin-right: ${pxToRem(30)};
  }

  .edit-item-value {
    color: ${(props) => props.theme.color.coolblue};
    margin-right: ${pxToRem(0)};
  }
`;

const FirstTimesClientView = styled.div`
  background: ${(props) => props.theme.color.cool_blue_light_20};
  padding: ${pxToRem(17)};
  font-size: ${pxToRem(16)};
  font-weight: 400;
  line-height: ${pxToRem(21)};

  .investment-style {
    margin-bottom: ${pxToRem(5)};
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(24)};
    color: ${(props) => props.theme.color.coolblue};
  }
`;

const Content = styled.div<{
  type?: string;
  haveLength: boolean;
  isEdit?: boolean;
}>`
  padding: ${(props) =>
    pxToRem(props.type !== "add" || !!props.isEdit ? 15 : 0)};
  background: ${(props) =>
    !!props.type && props.type === "add"
      ? props.theme.color.cool_blue_light_20
      : props.theme.color.white};
  ${(props) => !props.haveLength && "display: none;"}

  .investments-assets {
    height: ${pxToRem(54)};
    margin-left: ${pxToRem(15)};
    margin-right: ${pxToRem(15)};
    line-height: ${pxToRem(54)};
    border-bottom: 1px solid ${(props) => props.theme.color.cool_blue_light};
  }
`;

const Remove = styled.div`
  width: ${pxToRem(70)};
  margin-left: ${pxToRem(480)};
  text-align: right;
  margin-top: ${pxToRem(5)};
  font-size: ${pxToRem(14)};
  color: ${(props) => props.theme.color.coolblue};
  text-decoration: underline;
  text-align: right;
  display: inline-block;
  cursor: pointer;
  user-select: none;

  &.inline-remove {
    margin-left: 0;
  }
`;

const ItemWrapper = styled.div<{
  maxWidth?: number;
  itemConfigLength: number;
  type?: string;
  isFunds: boolean;
}>`
  width: ${(props) => pxToRem(props.maxWidth || 180)};
  margin: auto;
  margin-top: ${pxToRem(14)};
  line-height: ${pxToRem(34)};
  white-space: pre-wrap;
  display: ${(props) => (props.type !== "add" ? "inline-block" : "block")};

  .item-content {
    height: ${pxToRem(50)};
    margin-top: ${pxToRem(-14)};
    ${(props) =>
      props.itemConfigLength > 1 &&
      `border-bottom: 1px solid ${props.theme.color.cool_blue_light};`}

    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-content-label {
      width: ${pxToRem(450)};
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(26)};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${(props) => !props.isFunds && "font-weight: normal;"}
      ${(props) => props.isFunds && `color: ${props.theme.color.coolblue};`}
    }

    & .default {
      color: ${(props) => props.theme.color.font};
    }

    & .received-age {
      font-size: ${pxToRem(16)};
    }

    .item-label-value {
      font-size: ${pxToRem(20)};
      line-height: ${pxToRem(26)};
    }
  }

  .item-label {
    line-height: ${pxToRem(21)};
    padding: ${pxToRem(6)} 0;
  }

  .item-label-value {
    line-height: ${pxToRem(30)};
    font-size: ${pxToRem(24)};
    color: ${(props) => props.theme.color.coolblue};
  }
`;

const AddComponentWrapper = styled.div<{ isEdit: boolean }>`
  display: inline-block;
  height: ${pxToRem(54)};
  line-height: ${pxToRem(68)};
  padding: 0 ${pxToRem(15)};
  ${(props) =>
    !props.isEdit &&
    `border-top: 1px solid ${props.theme.color.cool_blue_light};`}
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  cursor: pointer;
  user-select: none;

  svg {
    width: ${pxToRem(24)};
    vertical-align: ${pxToRem(-6)};
    margin-right: ${pxToRem(10)};
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const bold = (msg: string) => <Bold>{msg}</Bold>;
const italic = (msg: string) => <ItalicSpan>{msg}</ItalicSpan>;

const ShowValueItem: FC<ShowValueItemProps> = ({
  type,
  name,
  config,
  formData,
  setShowHelp,
  prefix,
  itemConfigLength,
}) => {
  const curValue = useMemo(
    () =>
      get(
        formData,
        `${fieldMap[name || ""]}.${config.index || 0}.${
          config?.inputName || config.name
        }`
      ),
    [formData, name, config]
  );
  const curNameValue = useMemo(
    () =>
      get(
        formData,
        `${fieldMap[name || ""]}.${config.index || 0}.${config.name}`
      ),
    [formData, name, config]
  );
  const translateValue = (curName: string, value: string) => {
    if (valuesToTranslate.includes(curName)) {
      return value ? (
        <CurFormattedMessage id={value.replace(trimAllReg, "")} />
      ) : (
        "-"
      );
    }
    if (otherValuesToTranslate.includes(value)) {
      return <CurFormattedMessage id={otherValuesToTranslateMap[value]} />;
    }
    // @ts-ignore
    return value || value === 0 ? value : "-";
  };

  const currencyValue = (curName: string, value: any) => {
    if (curName === "monthly_cash_savings") {
      return (
        <>
          {percentageFormat(value.toFixed(2))}
          &nbsp;
          {currencyFormat(formData.monthly_income * value)}
        </>
      );
    }
    return currencyFormat(value);
  };

  return (
    <ItemWrapper
      maxWidth={config.maxWidth}
      itemConfigLength={itemConfigLength || 0}
      type={type}
      isFunds={name === "futurePayments"}
    >
      {!config.noLabel || !config.inVisible ? (
        <>
          <Label
            className={`item-label item-content-label ${
              config.maxWidth ? "margin-top" : ""
            } ${addFieldsTitle.includes(config.title) ? "default" : ""}`}
          >
            <FormattedMessage
              id={`${prefix}.${config.title}`}
              values={{
                info: (
                  <InfoButton
                    onClick={() => setShowHelp?.(config.name, true)}
                  />
                ),
                star: "",
                italic,
              }}
            />
          </Label>
          <Label className="item-label-value">
            {!!config.inputPrefix || !!config.prefix
              ? currencyValue(config.name, formData[config.name] || 0)
              : translateValue(
                  config.name,
                  get(formData, config.name) ||
                    (config.name === "no_of_children" ? 0 : "")
                )}
          </Label>
        </>
      ) : (
        config.title !== "NoLabel" && (
          <div className="item-content">
            <Label
              className={`item-label item-content-label
                  ${config.maxWidth ? " margin-top" : ""}
                  ${
                    config.title === "FundsToBeReceivedAtAge"
                      ? " received-age"
                      : ""
                  }`}
            >
              {config.title === "SunDiamondPolicy" ||
              config.title === "InvestmentDescription" ? (
                translateValue("", curValue)
              ) : (
                <FormattedMessage
                  id={`${prefix}.${config.title}`}
                  values={{
                    info: (
                      <InfoButton
                        onClick={() => setShowHelp?.(config.name, true)}
                      />
                    ),
                    star: "",
                    italic,
                  }}
                />
              )}
            </Label>
            <Label className="item-label-value">
              {config.prefix ||
              config.inputName === "payment_description" ||
              config.inputName === "investment_description"
                ? currencyFormat(curNameValue || 0)
                : curValue}
            </Label>
          </div>
        )
      )}
    </ItemWrapper>
  );
};

const AddComponent: FC<AddComponentProps> = ({ isEdit, onAdd, addMsg }) => (
  <AddComponentWrapper isEdit={isEdit} onClick={onAdd}>
    <SolidPlus />
    <FormattedMessage id={addMsg} />
  </AddComponentWrapper>
);

const titleMap = ["FundsToBeReceivedAtAge", "NoLabel"];

export const ShowValue: FC<PersonalInformationItemProps> = ({
  type,
  setIsEdit,
  formData,
  itemConfig,
  subTitleConfig,
  titleConfig,
  setShowHelp,
  onAdd,
  prefix,
  addMsg,
  portfolioTotal,
  fundsTotal,
}) => (
  <StyledCollapseCard
    width={583}
    titleConfig={{ ...titleConfig, onClick: setShowHelp }}
  >
    <ItemContent>
      {subTitleConfig && (
        <SubTitle
          isEdit={
            formData[fieldMap[titleConfig?.name || "otherInvestments"]]
              ?.length !== 0
          }
        >
          <FormattedMessage id={`${prefix}.${subTitleConfig.title}`} />
          <span className="item-label-value">
            {currencyFormat(
              subTitleConfig.title === "PortfolioTotal"
                ? portfolioTotal
                : fundsTotal
            )}
          </span>
        </SubTitle>
      )}
      <EditSvg onClick={setIsEdit} />
      {titleConfig?.name === "otherInvestments" &&
        (!formData?.other_investment?.length ||
          formData?.other_investment?.[0]?.investment_description === "") && (
          <FirstTimesClientView>
            <Label className="investment-style">
              <FormattedMessage id={`${prefix}.InvestmentStyle`} />
            </Label>
            <FormattedMessage
              id={`${prefix}.BasedOnTheInformationYouInput`}
              values={{ bold }}
            />
          </FirstTimesClientView>
        )}
      <Content
        type={type}
        haveLength={
          (type !== "add" && itemConfig.length !== 0) ||
          (type === "add" &&
            ((titleConfig?.name === "otherInvestments" &&
              formData?.other_investment?.[0]?.investment_description === "") ||
              !!formData[fieldMap[titleConfig?.name || "otherInvestments"]]
                ?.length))
        }
      >
        {subTitleConfig &&
          formData[fieldMap[titleConfig?.name || "otherInvestments"]]?.[0]
            ?.investment_description !== "" &&
          subTitleConfig.title === "PortfolioTotal" && (
            <div className="investments-assets">
              <FormattedMessage id={`${prefix}.InvestmentsAssets`} />
            </div>
          )}
        {itemConfig.map(
          (config: IFormField, index: number) =>
            (!titleMap.includes(config.title) ||
              (titleMap.includes(config.title) &&
                get(
                  formData,
                  `${fieldMap[titleConfig?.name || ""]}.${config.index || 0}.${
                    config.name
                  }`
                ))) && (
              <ShowValueItem
                key={`${titleConfig?.name || ""}${index}`}
                name={titleConfig?.name || ""}
                formData={formData}
                config={config}
                index={index}
                setShowHelp={setShowHelp}
                prefix={prefix}
                type={type}
                itemConfigLength={itemConfig.length}
              />
            )
        )}
      </Content>
      {!!type &&
        ((titleConfig?.name !== "otherInvestments" && type === "add") ||
          (titleConfig?.name === "otherInvestments" &&
            itemConfig.length < 20)) &&
        addMsg && (
          <AddComponent
            isEdit
            onAdd={() => onAdd?.(titleConfig?.name || "")}
            addMsg={addMsg}
          />
        )}
    </ItemContent>
  </StyledCollapseCard>
);

const EditItem: FC<ShowValueItemProps> = ({
  name,
  type,
  config,
  formData,
  setShowHelp,
  updateValue,
  errorTipValue,
  prefix,
  errorState,
  minMaxStep,
  onRemove,
  onBlur,
  handleErrorState,
  handleMaxMinErrorState,
  activeTopError,
}) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    if (
      activeTopError === config.name ||
      activeTopError === `${config?.inputName}${config.index}`
    ) {
      setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: /android/.test(navigator.userAgent.toLowerCase())
            ? "auto"
            : "smooth",
          block: "start",
        });
      }, 500);
    }
  }, [activeTopError, config]);

  return (
    <ShowValueItemWrapper
      ref={ref}
      className={`item-wrapper ${config?.className} ${
        isInputNumber(config) ? "number-item-wrapper" : ""
      }`}
      width={config.wrapperWidth}
    >
      {(!config.noLabel || !config.inVisible) && (
        <Label
          className={`margin-top ${
            isInputNumber(config) ? "number-label" : ""
          }`}
        >
          <FormattedMessage
            id={`${prefix}.${config.title}`}
            values={{
              info: (
                <InfoButton onClick={() => setShowHelp?.(config.name, true)} />
              ),
              star: config.required && config.canEdit ? "*" : "",
              italic,
            }}
          />
        </Label>
      )}
      <Fields
        name={name}
        type={type}
        formData={formData}
        config={config}
        setShowHelp={setShowHelp}
        prefix={prefix}
        errorTipValue={errorTipValue}
        updateValue={updateValue}
        errorState={errorState}
        minMaxStep={minMaxStep}
        onRemove={onRemove}
        onBlur={onBlur}
        index={config.index || 0}
        handleErrorState={handleErrorState}
        handleMaxMinErrorState={handleMaxMinErrorState}
      />
    </ShowValueItemWrapper>
  );
};

export const PersonalInformationItem: FC<PersonalInformationItemProps> = ({
  type,
  subTitleConfig,
  formData,
  itemConfig,
  updateValue,
  errorTipValue,
  titleConfig,
  setShowHelp,
  prefix,
  addMsg,
  onAdd,
  onRemove,
  errorState,
  minMaxStep,
  onBlur,
  portfolioTotal,
  fundsTotal,
  handleErrorState,
  handleMaxMinErrorState,
  activeTopError,
}) => {
  const openCard = useMemo(() => {
    let flag = false;
    itemConfig.forEach((item: any) => {
      if (
        item.name === activeTopError ||
        activeTopError === `${item?.inputName}${item.index}`
      ) {
        flag = true;
      }
    });
    return flag;
  }, [activeTopError, itemConfig]);

  return (
    <StyledCollapseCard
      width={583}
      openCard={openCard}
      titleConfig={{ ...titleConfig, onClick: setShowHelp }}
    >
      <ItemContent>
        {subTitleConfig && (
          <SubTitle isEdit>
            <FormattedMessage id={`${prefix}.${subTitleConfig.title}`} />
            <span className="item-label-value edit-item-value">
              {currencyFormat(
                subTitleConfig.title === "PortfolioTotal"
                  ? portfolioTotal
                  : fundsTotal
              )}
            </span>
          </SubTitle>
        )}
        <Content type={type} isEdit haveLength={itemConfig.length !== 0}>
          {itemConfig.map((config: IFormField, index: number) =>
            !config.canEdit ? (
              <ShowValueItem
                key={`${config.title + index}`}
                name={titleConfig?.name || ""}
                type={type}
                index={index}
                formData={formData}
                config={config}
                setShowHelp={setShowHelp}
                prefix={prefix}
              />
            ) : (
              <>
                {(formData?.total_mpf_savings <= 0 &&
                  config.name === "monthly_mpf_savings") ||
                (!formData?.monthly_income &&
                  config.name === "monthly_cash_savings") ? null : (
                  <EditItem
                    key={`${config.title + index}`}
                    name={titleConfig?.name || ""}
                    type={type}
                    index={index}
                    formData={formData}
                    config={config}
                    setShowHelp={setShowHelp}
                    prefix={prefix}
                    errorTipValue={errorTipValue}
                    updateValue={updateValue}
                    errorState={errorState}
                    minMaxStep={minMaxStep}
                    onRemove={onRemove}
                    onBlur={onBlur}
                    handleErrorState={handleErrorState}
                    handleMaxMinErrorState={handleMaxMinErrorState}
                    activeTopError={activeTopError}
                  />
                )}
                {!!type &&
                  type === "add" &&
                  index % 2 === 1 &&
                  config.name !== "other_investment_style" && (
                    <Remove
                      onClick={() =>
                        onRemove?.(config.name, titleConfig?.name, config.index)
                      }
                    >
                      <FormattedMessage id={`${prefix}.Remove`} />
                    </Remove>
                  )}
              </>
            )
          )}
        </Content>
        {!!type &&
          ((titleConfig?.name !== "otherInvestments" && type === "add") ||
            (titleConfig?.name === "otherInvestments" &&
              itemConfig.length < 20)) &&
          addMsg && (
            <AddComponent
              isEdit
              onAdd={() => onAdd?.(titleConfig?.name || "")}
              addMsg={addMsg}
            />
          )}
      </ItemContent>
    </StyledCollapseCard>
  );
};

export default PersonalInformationItem;
