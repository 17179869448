import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { pxToRem, enlargeFontsize } from '@rjp/common/utils';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as AdvisorShakeHands } from '@rjp/common/assets/images/advisorShakeHands.svg';
import contactHeaderSrc from '@rjp/common/assets/images/advisor-icon.png';

const TalkToAnAdvisorDiv = styled.div`
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0 ${pxToRem(5)} ${pxToRem(5)};
  padding: ${pxToRem(35)} ${pxToRem(0)} ${pxToRem(30)} ${pxToRem(44)};
  position: relative;
`;

const TitleDiv = styled.div`
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 28)};
  line-height: ${pxToRem(37)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  white-space: pre-wrap;
  margin-bottom: ${pxToRem(20)};
`;

const SubtitleDiv = styled.div`
  font-weight: normal;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 20)};
  line-height: normal;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  white-space: pre-wrap;
  margin-bottom: ${pxToRem(40)};
`;

const BottomDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: ${pxToRem(140)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-color: ${(props) => props.theme.color.grey_light};
  border-bottom-width: ${pxToRem(6)};
  border-bottom-style: solid;
`;

const ActionDiv = styled.div`
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  border-radius: ${pxToRem(6)};
  width: ${pxToRem(214)};
  height: ${pxToRem(46)};
  line-height: ${pxToRem(46)};
  position: relative;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  padding-right: ${pxToRem(13)};
  margin-top: ${pxToRem(60)};
  margin-left: ${pxToRem(75)};
  cursor: pointer;
  white-space: pre;
  padding-left: ${pxToRem(46)};
`;

const ContactHeader = styled.img`
  width: ${pxToRem(88)};
  position: absolute;
  left: ${pxToRem(-40)};
  top: ${pxToRem(-12)};
`;

interface ITalkToAnAdvisor {
  onTalkToAdvisor: () => void;
  currentState: string;
}

const TalkToAnAdvisor: FC<ITalkToAnAdvisor> = ({
  onTalkToAdvisor,
  currentState,
}) => {
  const Prefix = useMemo(() => `Common.${currentState}`, [currentState]);
  return (
    <TalkToAnAdvisorDiv>
      <TitleDiv>
        <FormattedMessage id={`${Prefix}.Title`} />
      </TitleDiv>
      <SubtitleDiv>
        <FormattedMessage id={`${Prefix}.Subtitle`} />
      </SubtitleDiv>
      <BottomDiv>
        <ActionDiv onClick={onTalkToAdvisor}>
          <ContactHeader src={contactHeaderSrc} />
          <FormattedMessage id={`${Prefix}.ActionTitle`} />
        </ActionDiv>
        <AdvisorShakeHands width={pxToRem(287)} />
      </BottomDiv>
    </TalkToAnAdvisorDiv>
  );
};

export default TalkToAnAdvisor;
