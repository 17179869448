import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { shallowEqual, useSelector } from "react-redux";
import { pxToRem, currencyFormat } from "@rjp/common/utils";
import Duotone from "@rjp/common/component/duotone";
import { FormattedMessage } from "react-intl";
import surplusIconSrc from "@rjp/common/assets/images/surplus-icon.png";
import { ReactComponent as RiseSvg } from "@rjp/common/assets/images/rise.svg";
import { ReactComponent as RetirementDataRisingArrow } from "@rjp/common/assets/images/retirement-data-rising-arrow.svg";
import { RootState } from "@rjp/main/src/app";
import { filteredIncludedQuoteSelector } from "@rjp/main/src/features/rcData/rcDataSelector";

const RetirementDataDiv = styled.div`
  width: ${pxToRem(440)};
  height: ${pxToRem(246)};
  background-color: rgba(255, 255, 255, 1);
  border-radius: ${pxToRem(5)};
  padding: ${pxToRem(16)};
  display: flex;
  justify-content: space-between;
`;

const SubDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${pxToRem(198)};
  .duotone {
    height: 100%;
  }
`;

const ColorfulDiv = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: ${pxToRem(63)};
  border-radius: ${pxToRem(5)};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-size: ${pxToRem(14)};
  padding: ${pxToRem(8)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: ${pxToRem(14)};
`;

const PercentagesWrapper = styled.div<{ hasRising: boolean }>`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin-left: ${pxToRem(10)};
  height: ${(props) => (props.hasRising ? pxToRem(110) : "auto")};
  position: relative;
`;

const PercentageItem = styled.div`
  font-size: ${pxToRem(28)};
  font-weight: bold;
  color: ${(props) => props.theme.color[props.color || "red_light"]};
  .small {
    font-size: ${pxToRem(14)};
  }
`;

const SurplusWrapper = styled.div`
  width: ${pxToRem(100)};
  height: ${pxToRem(126)};
  border: ${pxToRem(5)} ${(props) => props.theme.color.coolblue_medium} solid;
  border-radius: ${pxToRem(5)};
  background: ${(props) => props.theme.color.coolblue};
  position: relative;
  font-weight: bold;
  margin-left: ${pxToRem(15)};
  margin-top: ${pxToRem(12)};
`;

const SurplusTitle = styled.div`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  display: flex;
  align-items: center;
  text-align: center;
  width: ${pxToRem(75)};
  height: ${pxToRem(36)};
  margin: 0 auto;
  margin-top: ${pxToRem(18)};
  justify-content: center;
  color: ${(props) => props.theme.color.white};
`;

const SurplusIcon = styled.img`
  width: ${pxToRem(52)};
  top: ${pxToRem(-29)};
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
`;

const SurplusPercentage = styled.div`
  font-size: ${pxToRem(24)};
  text-align: center;
  height: ${pxToRem(26)};
  margin-top: ${pxToRem(3)};
  line-height: ${pxToRem(26)};
  color: ${(props) => props.theme.color.sunlife_yellow_medium};
`;

const Rise = styled.div`
  width: ${pxToRem(64)};
  height: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  border-radius: ${pxToRem(10)};
  background: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.sunlife_blue_light};
  margin: 0 auto;
  margin-top: ${pxToRem(5)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurRiseSvg = styled(RiseSvg)`
  fill: ${(props) => props.theme.color.sunlife_blue_light};
  width: ${pxToRem(13)};
  margin-right: ${pxToRem(3)};
`;

const RisingWrapper = styled.div`
  position: absolute;
  font-size: ${pxToRem(16)};
  color: ${(props) => props.theme.color.grey_dark};
  left: 0;
  height: ${pxToRem(24)};
  bottom: ${pxToRem(-24)};
  display: flex;
`;

const CurProductModalUpArrow = styled(RetirementDataRisingArrow)`
  width: ${pxToRem(20)};
  fill: ${(props) => props.theme.color.sunlife_blue_light};
  margin-bottom: ${pxToRem(2)};
`;

const RisingCount = styled.div`
  font-weight: bold;
  height: ${pxToRem(24)};
  font-size: ${pxToRem(28)};
  color: ${(props) => props.theme.color.sunlife_blue_light};
  line-height: ${pxToRem(20)};
`;

const RisingSmall = styled.span`
  font-size: ${pxToRem(18)};
`;

const leftData = [
  {
    title: "RetirementGap",
    value: "$0",
    backgroundColor: "#DA1F45",
    color: "#FFFFFF",
  },
  {
    title: "RetirementProjectedSavings",
    value: "$0",
    backgroundColor: "#FFCB05",
    color: "#003946",
  },
  {
    title: "RetirementGoal",
    value: "$0",
    backgroundColor: "#004C6C",
    color: "#FFFFFF",
  },
];

const rightData = {
  title: "ProjectedMonthlyRetirementAllowance",
  value: "$0",
  backgroundColor: "#C6D8D4",
  color: "#003946",
};

const RetirementData: FC<{ inflation?: string }> = ({ inflation }) => {
  const { retirementGapSummary } = useSelector(
    (state: RootState) => state.RCData,
    shallowEqual
  );
  const filteredIncludedQuote = useSelector(filteredIncludedQuoteSelector);
  const { quote_impact } = retirementGapSummary;

  const risingCount = useMemo(() => {
    const value = filteredIncludedQuote.reduce(
      (acc, { quote_id }) =>
        acc + (quote_impact?.find((q) => q.quote_id === quote_id)?.value ?? 0),
      0
    );
    return Math.round(value * 100);
  }, [quote_impact, filteredIncludedQuote]);

  const quoteImpactValue = useMemo(() => {
    if (Object.entries(retirementGapSummary).every(([, v]) => v === 0)) {
      return 0;
    }
    const value =
      quote_impact?.reduce((acc, quote) => acc + quote.value, 0) ?? 0;
    return Math.round(value * 100);
  }, [quote_impact, retirementGapSummary]);

  const contentData = useMemo(() => {
    const data = JSON.parse(
      JSON.stringify({
        leftData,
        rightData,
        chartData: [100, 0],
      })
    );

    if (retirementGapSummary.present_retirement_gap) {
      const period = inflation === "Future" ? "future" : "present";
      const retirementGap = retirementGapSummary[`${period}_retirement_gap`];
      const dataArr = [
        retirementGap > 0 ? retirementGap : 0,
        retirementGapSummary[`${period}_retirement_projected_savings`],
        retirementGapSummary[`${period}_retirement_goal`],
      ];

      dataArr.forEach((value: number, index: number) => {
        data.leftData[index].value = currencyFormat(Math.round(value));
      });

      const retirementShortfallPercentage =
        retirementGapSummary.retirement_shortfall_percentage;

      data.rightData.value = currencyFormat(
        Math.round(
          retirementGapSummary[`${period}_projected_monthly_retirement_income`]
        )
      );

      data.chartData = [
        Math.round(retirementShortfallPercentage * 100),
        Math.round((1 - retirementShortfallPercentage) * 100),
      ];
    }
    return data;
  }, [retirementGapSummary, inflation]);

  return (
    <RetirementDataDiv>
      <SubDiv>
        {contentData.leftData.map((d) => (
          <ColorfulDiv
            backgroundColor={d.backgroundColor}
            color={d.color}
            key={d.title}
          >
            <div>
              <FormattedMessage id={`Common.Start.SideCard3.${d.title}`} />
            </div>
            <div style={{ fontSize: pxToRem(16), fontWeight: "bold" }}>
              {d.value}
            </div>
          </ColorfulDiv>
        ))}
      </SubDiv>
      <SubDiv>
        <div
          style={{
            display: "flex",
            height: pxToRem(140),
          }}
        >
          <Duotone
            value={[contentData.chartData[1], contentData.chartData[0]]}
            className="duotone"
          />
          {contentData.chartData[1] < 100 ? (
            <PercentagesWrapper hasRising={risingCount > 0}>
              <PercentageItem>
                <span>{contentData.chartData[0]}</span>
                <span className="small">%</span>
              </PercentageItem>
              <PercentageItem color="sunlife_blue_medium">
                <span>{contentData.chartData[1]}</span>
                <span className="small">%</span>
              </PercentageItem>
              {risingCount > 0 && (
                <RisingWrapper>
                  (
                  <CurProductModalUpArrow />
                  <RisingCount>
                    {risingCount}
                    <RisingSmall>%</RisingSmall>
                  </RisingCount>
                  )
                </RisingWrapper>
              )}
            </PercentagesWrapper>
          ) : (
            <SurplusWrapper>
              <SurplusIcon src={surplusIconSrc} />
              <SurplusTitle>
                <FormattedMessage id="Common.Start.SideCard3.YouHaveSurplus" />
              </SurplusTitle>
              <SurplusPercentage>{contentData.chartData[1]}%</SurplusPercentage>
              {!!quoteImpactValue && (
                <Rise>
                  <CurRiseSvg />
                  <span>{quoteImpactValue}%</span>
                </Rise>
              )}
            </SurplusWrapper>
          )}
        </div>
        <ColorfulDiv
          backgroundColor={contentData.rightData.backgroundColor}
          color={contentData.rightData.color}
        >
          <div>
            <FormattedMessage
              id={`Common.Start.SideCard3.${contentData.rightData.title}`}
            />
          </div>
          <div style={{ fontSize: pxToRem(16), fontWeight: "bold" }}>
            {contentData.rightData.value}
          </div>
        </ColorfulDiv>
      </SubDiv>
    </RetirementDataDiv>
  );
};

export default RetirementData;
