import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { pxToRem, enlargeFontsize, toLogin } from '@rjp/common/utils';
import { Button } from '@rjp/common/component';
import startBottomImage from '@rjp/common/assets/images/start-bottom.png';
import { memberVerification } from '@rjp/main/src/api';
import { RootState } from '@rjp/main/src/app';
import { trackingFunc, RJP_LINK, RJP_VIEW } from '@/utils/tracking';

interface VerifyProps extends Pick<RouteComponentProps, 'history'> {
  token: string;
}

const FormattedMsg: FC<{ id: string; values?: any }> = ({ id, values }) => (
  <FormattedMessage id={id} values={values} />
);

const AccountActivatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
`;

const TitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 24)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 31)};
  text-align: center;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  margin-top: ${pxToRem(90)};
  margin-bottom: ${pxToRem(65)};
`;

const SubTitleDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${(props) => enlargeFontsize(props.theme.language, 24)};
  text-align: center;
  margin-bottom: ${pxToRem(65)};
`;

const BottomImage = styled.img`
  height: ${pxToRem(331)};
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Verify: FC<VerifyProps> = ({ history, token }) => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const [errorCode, setErrorCode] = useState<string>('10002');

  const goRetirement = useCallback(() => {
    const retirementLanguage: { [key: string]: string } = {
      en: 'en',
      'zh-hant': 'zh-tw',
    };
    window.open(
      `${process.env.REACT_APP_RETIREMENT_CALCULATOR_URL}/${retirementLanguage[language]}`
    );
  }, [language]);

  const config: Record<string, any> = useMemo(
    () => ({
      10005: {
        title: 'Login.CreateAccount.VerificationExpired',
        subTitle: 'Login.CreateAccount.WeSuggestYouDo',
        buttonText: 'Login.CreateAccount.DoRetirementCalculator',
        buttonWidth: 271,
        onClose: goRetirement,
      },
      10006: {
        title: 'Login.CreateAccount.VerificationLinkExpiredInvalid',
        subTitle: 'Login.CreateAccount.WeSuggestYouDo',
        buttonText: 'Login.CreateAccount.DoRetirementCalculator',
        buttonWidth: 271,
        onClose: goRetirement,
      },
      10007: {
        title: 'Login.AccountActivated.Title',
        subTitle: 'Login.AccountActivated.SubTitle',
        buttonText: 'Login.AccountActivated.SignIn',
        buttonWidth: 204,
        onClose: toLogin('member', history, 'activation'),
      },
    }),
    [goRetirement, history]
  );

  const errorHelper = (error: string) => {
    setErrorCode(error.toString());
  };

  useEffect(() => {
   if (errorCode == '10007') {
    trackingFunc(RJP_VIEW, 'other', 'account activated');
   }
  }, [errorCode]);

  useEffect(() => {
    if (token) {
      const checkMemberVerification = async () => {
        try {
          await memberVerification({
            token,
            language,
          });
          setErrorCode('10007');
        } catch (error) {
          errorHelper(error);
        }
      };
      checkMemberVerification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return (
    <AccountActivatedWrapper>
      {errorCode !== '10002' ? (
        <>
          <TitleDiv>
            <FormattedMsg id={config[errorCode].title} />
          </TitleDiv>
          <SubTitleDiv>
            <FormattedMsg id={config[errorCode].subTitle} />
          </SubTitleDiv>
          <Button
            width={config[errorCode].buttonWidth}
            type="primary-yellow"
            onClick={config[errorCode].onClose}
          >
            <FormattedMsg id={config[errorCode].buttonText} />
          </Button>
          <BottomImage src={startBottomImage} />
        </>
      ) : (
        <></>
      )}
    </AccountActivatedWrapper>
  );
};

export default Verify;
