import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/zh';

import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  Modal,
  ModalProps,
} from '@rjp/common/component';
import { contactAdvisor, contactAdvisorExtendExpiry } from '@rjp/main/src/api';
import { RootState, AppDispatch } from '@rjp/main/src/app';
import { updateUserInfo } from '@rjp/main/src/features/userInfo/userInfoSlice';
import {
  setShowContactAdvisorModal,
  setActiveContactAdvisorModalConfig,
  setIsRequestedAdvisor,
} from '@rjp/main/src/features/contactAdvisor/contactAdvisorSlice';
import { pxToRem } from '@rjp/common/utils';
import {
  DoYouWishToExtend,
  LetOurAdvisorHelp,
  HelpWithRetirement,
} from './components';
import { reg } from '@rjp/common/config';
import { RJP_LINK, RJP_VIEW, trackingFunc } from '@/utils/tracking';

interface ContactAdvisorModalProps {
  contactAdvisorCheck: boolean;
  setContactAdvisorCheck: (value: boolean) => void;
}
const prefix = 'PopModal.ContactAdvisor.';
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const Content = styled(CurFormattedMessage)`
  font-size: ${pxToRem(24)};
  font-weight: 500;
  text-align: center;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const ContactAdvisorModal: FC<ContactAdvisorModalProps> = ({
  contactAdvisorCheck,
  setContactAdvisorCheck,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id = '', consent_expiry_date } = useSelector(
    (state: RootState) => state.userInfo,
    shallowEqual
  );
  const [showError, setShowError] = useState(false);
  const [errorCode, setErrorCode] = useState<string>('10002');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState<string>(
    'InvalidMobileNumber'
  );
  const [countdown, setCountdown] = useState(60);
  const [confirmLabel, setConfirmLabel] = useState('Confirm');
  var [counter, setCounter] = useState(0); // Incorrect phone number counter
  const [confirmDisable, setConfirmDisable] = useState<boolean>(true);
  // const [contactAdvisorCheck, setContactAdvisorCheck] = useState<boolean>(false);
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const [languageType, setLanguagetype] = useState('en');

  const {
    showContactAdvisorModal,
    activeContactAdvisorModalConfig,
  } = useSelector((state: RootState) => state.contactAdvisor, shallowEqual);

  let timer:NodeJS.Timeout;

  useEffect(() => setLanguagetype(language === 'en' ? 'en' : 'zh'), [language]);

  const onModalClose = () => {
    setCounter(0);
    setShowError(false);
    setPhoneError(false);
    dispatch(setShowContactAdvisorModal(false));
  };

  const onAssignAnAdvisorToMe = async () => {
    try {
      const responseData = await contactAdvisor({
        id,
        is_request_consent: contactAdvisorCheck,
      });
      dispatch(setActiveContactAdvisorModalConfig('successfulRequest'));
      
      // Tracking for next advisor click modal
      trackingFunc(RJP_VIEW, 'other', 'lead gen assignment');
      
      dispatch(setIsRequestedAdvisor(true));
      dispatch(
        updateUserInfo({
          consent_expiry_date: responseData.consent_expiry_date,
        })
      );
    } catch (error) {
      setErrorCode?.(error?.toString());
      setShowError?.(true);
    }
  };

  /* Created func for existing user */
  const assignToExisting = () => {
    trackingFunc(RJP_LINK, 'other', 'assign an advisor to me - existing member');
    onAssignAnAdvisorToMe();
  }
   /* Created func for non-existing user */
   const assignToNonExisting = () => {
    trackingFunc(RJP_LINK, 'other', 'assign an advisor to me');
    onAssignAnAdvisorToMe();
  }

  const onMobileChange = (value: string) => {
    const error = !(reg.hkTelephone.test(value) && value);
    setPhoneNumber(value);
    setPhoneError(false);
    setConfirmDisable(value ? error : false && countdown !== 60);
  };

  const onMobileBlur = (value: string) => {
    const error = !(reg.hkTelephone.test(value) && value);
    setPhoneError(value ? error : false);
    setConfirmDisable(value ? error : false && countdown !== 60);
    setPhoneNumber(value);
    if (error) setPhoneErrorMsg('InvalidMobileNumber');
  };

  const onContactMyAdvisor = async () => {
    trackingFunc(RJP_LINK, 'other', 'contact my advisor');
    dispatch(setActiveContactAdvisorModalConfig('helpWithRetirement'));
  };

  const onContactMyAdvisorConfirm = async () => {
    try {
      const responseData = await contactAdvisor({
        id,
        is_request_consent: contactAdvisorCheck,
        request_agent_mobile: phoneNumber,
      });
      // Tracking for click
      if (counter > 0) {
        trackingFunc(RJP_LINK, 'other', `advisor assigned - existing member:fail tries:${counter}`);
        setCounter(0);
      }
      trackingFunc(RJP_LINK, 'other', 'advisor assigned - existing member');

      dispatch(setActiveContactAdvisorModalConfig('confirmExtend'));
      // Tracking for next confirm modal
      trackingFunc(RJP_LINK, 'other', 'contacting member advisor');

      dispatch(setIsRequestedAdvisor(true));
      dispatch(
        updateUserInfo({
          consent_expiry_date: responseData.consent_expiry_date,
        })
      );
      
      
      

    } catch (error) {      
      setErrorCode?.(error.toString());
      setShowError?.(true);
      setPhoneError(true);
      if (error === 10022) {
        setCounter(counter+1);
        trackingFunc(RJP_LINK, 'other', `incorrect contact number - existing member`);
        setCountdown(60);
        timer = setInterval(() => {
          setCountdown((value) => value - 1);
        }, 1000);
        setConfirmLabel('ConfirmCountdown');
        setPhoneErrorMsg('TheNumberIsNotRegisteredToAnAdvisor');
        setConfirmDisable(true);
      } else if (error === 10019) {
        setCounter(counter+1);
        trackingFunc(RJP_LINK, 'other', `incorrect contact number - existing member`);
        setConfirmDisable(false);
        setPhoneErrorMsg('TheNumberIsNotRegisteredToAnAdvisor');
      } else if (error === 400) {
        setConfirmDisable(true);
      }
      
    }
  };

  useEffect(() => {
    if (countdown === 0 && timer) {
      window.clearInterval(timer);
      setConfirmLabel('Confirm');
      setCountdown(60);
    }
  }, [countdown]);

  useEffect(() => {
    // console.log('use effect',counter);
  }, [counter]);

  const onAssignAnAdvisorToMeClose = () => {
    setContactAdvisorCheck(false);
    onModalClose();
  };

  const onTriggerAdvisorEmail = (is_extend_expiry: boolean) => async () => {
    try {
      const responseData = await contactAdvisorExtendExpiry({
        id,
        is_extend_expiry,
      });
      if (responseData.consent_expiry_date) {
        dispatch(
          updateUserInfo({
            consent_expiry_date: responseData.consent_expiry_date,
          })
        );
      }
      dispatch(setActiveContactAdvisorModalConfig('confirmExtend'));
    } catch (error) {
      setErrorCode?.(error?.toString());
      setShowError?.(true);
    }
  };

  const hasExpired = useMemo(
    () => consent_expiry_date && !dayjs(consent_expiry_date).isAfter(dayjs()),
    [consent_expiry_date]
  );

  const consentWithTracking =  function(){
    contactAdvisorCheck = !contactAdvisorCheck;
    // console.log('Check advisor value ', contactAdvisorCheck);
    trackingFunc(RJP_LINK, 'other', 'consent checkbox')
    return setContactAdvisorCheck ? setContactAdvisorCheck(contactAdvisorCheck): () => {}
  }

  const modalConfig: Record<string, ModalProps> = {
    loading: {},
    requestAdvisor: {
      width: 718,
      title: `${prefix}LetOurAdvisorsHelpYou`,
      onClose: onAssignAnAdvisorToMeClose,
      children: (
        <LetOurAdvisorHelp
          setContactAdvisorCheck={() => {consentWithTracking()}}
          showError={showError}
          errorCode={errorCode}
          config={[
            {
              width: 242,
              type: 'primary',
              message: 'AssignAnAdvisorToMe',
              title: 'IDontHaveAnExistingAdvisor',
              onClick: assignToNonExisting,
            },
            {
              width: 242,
              type: 'primary',
              message: 'ContactMyAdvisor',
              title: 'IWantToWorkWithAnAdvisorIKnow',
              onClick: onContactMyAdvisor,
            },
          ]}
        />
      ),
      showFooterConfig: false,
    },
    helpWithRetirement: {
      width: 718,
      textAlign: 'left',
      onClose: onAssignAnAdvisorToMeClose,
      children: (
        <HelpWithRetirement
          phoneError={phoneError}
          showError={showError}
          errorCode={errorCode}
          onChange={onMobileChange}
          onBlur={onMobileBlur}
          errorMsg={phoneErrorMsg}
        />
      ),
      footerConfig: [
        {
          width: 226,
          type: 'secondary',
          message: `${prefix}AssignAnAdvisorToMe`,
          onClick: assignToExisting,
        },
        {
          width: 170,
          type:
            confirmDisable ||
            (phoneNumber !== '' ? !reg.hkTelephone.test(phoneNumber) : true) ||
            countdown !== 60
              ? 'disabled'
              : 'primary',
          message: `${prefix}${confirmLabel}`,
          values: { countdown },
          onClick: onContactMyAdvisorConfirm,
        },
      ],
    },
    successfulRequest: {
      width: 628,
      children: <Content id="SuccessfulRequest" />,
    },
    pendingAssignmnet: {
      width: 582,
      children: <Content id="PendingAssignmnet" />,
    },
    extend: {
      width: hasExpired ? 664 : 760,
      title: (
        <CurFormattedMessage
          id={`${
            hasExpired ? 'YourConsentToAdvisorHasExpired' : 'DoYouWishToExtend'
          }`}
          values={{
            date: dayjs(consent_expiry_date ?? undefined)
              .locale(languageType)
              .format(languageType === 'en' ? 'D MMMM, YYYY' : 'YYYY年MMMDD日'),
          }}
        />
      ),
      children: (
        <DoYouWishToExtend
          consent_expiry_date={consent_expiry_date ?? undefined}
          showError={showError}
          errorCode={errorCode}
          hasExpired={!!hasExpired}
        />
      ),
      footerConfig: [
        {
          width: 170,
          type: 'secondary',
          message: `${prefix}NotNow`,
          onClick: () =>
            dispatch(setActiveContactAdvisorModalConfig('contactRequest')),
        },
        {
          width: 170,
          type: 'primary',
          message: `${prefix}Yes`,
          onClick: onTriggerAdvisorEmail(true),
        },
      ],
    },
    contactRequest: {
      width: 576,
      children: <Content id="DoYouWishToContactYourAdvisor" />,
      footerConfig: [
        {
          width: 170,
          type: 'secondary',
          message: `${prefix}NotNow`,
          onClick: onModalClose,
        },
        {
          width: 170,
          type: 'primary',
          message: `${prefix}Yes`,
          onClick: onTriggerAdvisorEmail(false),
        },
      ],
    },
    confirmExtend: {
      width: 582,
      children: <Content id="ConfirmExtend" />,
    },
  };

  return (
    <Modal
      destroyOnClose
      onClose={onModalClose}
      visible={showContactAdvisorModal}
      {...modalConfig[activeContactAdvisorModalConfig]}
    />
  );
};

export default ContactAdvisorModal;
