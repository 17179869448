import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';

const StyleModal = styled((props) => <Modal {...props} />)`
  .ant-modal-body {
    margin: auto;
  }
`;

const Content = styled.div`
  margin-bottom: ${pxToRem(20)};
  padding: 0 ${pxToRem(30)};
  line-height: 135%;
  font-size: ${pxToRem(18)};
`;

const Note = styled.div`
  padding: 0 ${pxToRem(30)};
  min-height: ${pxToRem(40)};
  font-size: ${pxToRem(12)};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const bold = (msg: string) => <Bold>{msg}</Bold>;

const prefix = 'PopModal.ProjectedMonthlyRetirementAllowance.';

const ProjectedMonthlyRetirementAllowanceModal: FC<ModalProps> = ({
  visible = false,
  onClose,
}) => (
  <StyleModal
    width={860}
    visible={visible}
    onClose={onClose}
    title={`${prefix}WhatIsProjectedMonthlyRetirementAllowance`}
  >
    <Content>
      <FormattedMessage id={`${prefix}Paragraph`} values={{ bold }} />
    </Content>
    <Note>
      <FormattedMessage id={`${prefix}Note`} values={{ bold }} />
    </Note>
  </StyleModal>
);

export default ProjectedMonthlyRetirementAllowanceModal;
