import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { enlargeFontsize, pxToRem } from "@rjp/common/utils";

export interface ErrorMessageProps {
  errorMsg?: string;
  values?: Record<string, any>;
  direction?: "left" | "right";
  pixel?: number;
  fontSize?: number;
  onClick?: () => void;
}

const Medium = styled.span`
  font-weight: 500;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const ErrorMsg = styled.div<
  Pick<ErrorMessageProps, "direction" | "pixel" | "fontSize">
>`
  min-height: ${pxToRem(40)};
  padding: ${pxToRem(5)} ${pxToRem(10)};
  margin-top: ${pxToRem(12)};
  font-size: ${(props) =>
    enlargeFontsize(props.theme.language, props.fontSize || 14)};
  line-height: ${pxToRem(30)};
  font-weight: normal;
  color: ${(props) => props.theme.color.white};
  background: ${(props) => props.theme.color.extra_red_error};
  box-sizing: border-box;
  position: relative;

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: ${pxToRem(12)} solid transparent;
    border-right: ${pxToRem(12)} solid transparent;
    border-bottom: ${pxToRem(12)} solid
      ${(props) => props.theme.color.extra_red_error};
    top: ${pxToRem(-12)};
    ${(props) => {
      switch (props.direction) {
        case "left":
          return `left: ${pxToRem(16)};`;
        case "right":
          return `right: ${pxToRem(props.pixel || 16)};`;
        default:
          return `left: ${pxToRem(16)};`;
      }
    }};
  }
`;

const ErrorMessage: FC<ErrorMessageProps> = ({
  errorMsg,
  values,
  direction,
  pixel,
  fontSize,
  onClick,
}) =>
  errorMsg ? (
    <ErrorMsg
      className="error-msg"
      direction={direction}
      pixel={pixel}
      fontSize={fontSize}
    >
      <FormattedMessage
        id={errorMsg}
        values={{
          ...values,
          bold: (msg: string) => <Bold>{msg}</Bold>,
          medium: (msg: string) => <Medium>{msg}</Medium>,
          underline: (msg: string) => (
            <Underline onClick={onClick}>{msg}</Underline>
          ),
        }}
      />
    </ErrorMsg>
  ) : null;

export default ErrorMessage;
