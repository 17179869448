import { useCallback, useEffect, useState } from 'react';

const useLayoutSize = () => {
  const [layoutSize, setLayoutSize] = useState({
    layoutWidth: window.innerWidth,
    layoutHeight: window.innerHeight,
    isLandscape: window.matchMedia('(orientation: landscape)').matches,
  });

  const onresize = useCallback((value: any) => {
    setTimeout(() => {
      setLayoutSize({
        layoutWidth: value.target.innerWidth,
        layoutHeight: value.target.innerHeight,
        isLandscape: value.target.matchMedia('(orientation: landscape)')
          .matches,
      });
    }, 500);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onresize);
    return () => {
      window.removeEventListener('resize', onresize);
    };
  }, [onresize]);

  return layoutSize;
};

export default useLayoutSize;
