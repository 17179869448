import React, { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { pxToRem } from "@rjp/common/utils";
import { ReactComponent as ChevronDown } from "@rjp/common/assets/images/chevron-down.svg";
import { InfoButton } from "../button";

export interface CollapseCardProps {
  className?: string;
  width?: number;
  marginTop?: number;
  backgroundColor?: string;
  color?: string;
  defaultClose?: boolean;
  titleComponent?: JSX.Element;
  titleConfig?: {
    title: string;
    name?: string;
    onClick?: (name: string, value: any) => void;
  };
  openCard?: boolean;
}

const CollapsibleCardWrapper = styled.div<
  Omit<CollapseCardProps, "className" | "titleConfig">
>`
  width: ${(props) => pxToRem(props.width || 583)};
  padding: ${pxToRem(15)} 0;
  margin-top: ${(props) => pxToRem(props.marginTop || 0)};
  border-radius: ${pxToRem(5)};
  box-sizing: border-box;
  box-shadow: 0px 1px ${pxToRem(4)}
    ${(props) => props.theme.color.extra_dark_20};
  background: ${(props) => props.backgroundColor || props.theme.color.white};
  color: ${(props) =>
    props.backgroundColor ? props.color : props.theme.color.font};
  transition: all 0.5s;

  svg {
    cursor: pointer;
    path {
      fill: ${(props) =>
        props.backgroundColor
          ? props.theme.color.coolblue
          : props.theme.color.sunlife_yellow};
    }
  }
`;

const TitleDiv = styled.div<Pick<CollapseCardProps, "backgroundColor">>`
  font-size: ${pxToRem(20)};
  margin: 0 ${pxToRem(15)};
  line-height: ${pxToRem(34)};
  color: ${(props) =>
    props.backgroundColor ? props.color : props.theme.color.font};
  border-bottom: 1px solid
    ${(props) =>
      props.backgroundColor ? "none" : props.theme.color.sunlife_yellow};
  display: flex;
  justify-content: space-between;

  svg {
    margin-left: ${pxToRem(5)};
    vertical-align: text-top;
  }
`;

const SubTitle = styled.span`
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(34)};
  font-weight: normal;
  font-style: italic;
`;

const StyleChevronDown = styled(({ rotated, ...props }) => (
  <ChevronDown {...props} />
))`
  width: ${pxToRem(24)};
  height: ${pxToRem(14)};
  transform: rotate(${(props) => (props.rotated ? 0 : 180)}deg);
  transition: all 0.5s;
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  font-size: ${pxToRem(16)};
  box-sizing: border-box;
  text-align: left;

  &.collapsed {
    max-height: 0;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }

  &.expanded {
    max-height: ${pxToRem(2100)};
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }
`;

const CollapseCard: FC<CollapseCardProps> = ({
  backgroundColor,
  titleConfig,
  children,
  defaultClose,
  titleComponent,
  openCard,
  ...props
}) => {
  const [rotate, setRotate] = useState<boolean>(!!defaultClose);

  useEffect(() => {
    if (openCard) {
      setRotate(false);
    }
  }, [openCard]);

  return (
    <CollapsibleCardWrapper backgroundColor={backgroundColor} {...props}>
      <TitleDiv backgroundColor={backgroundColor} className="title">
        <div className="title-wrapper">
          {titleConfig ? (
            <FormattedMessage
              id={titleConfig.title}
              values={{
                italic: (msg: string) => <SubTitle>{msg}</SubTitle>,
                info: (
                  <InfoButton
                    onClick={() =>
                      titleConfig?.onClick?.(titleConfig?.name || "", true)
                    }
                  />
                ),
              }}
            />
          ) : (
            titleComponent || null
          )}
        </div>
        <StyleChevronDown
          className="chevron-down"
          rotated={rotate}
          onClick={() => setRotate(!rotate)}
        />
      </TitleDiv>
      <ContentWrapper className={rotate ? "collapsed" : "expanded"}>
        {children}
      </ContentWrapper>
    </CollapsibleCardWrapper>
  );
};

export default CollapseCard;
