import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TranslationState {
  language: string;
}

const initialState: TranslationState = {
  language: 'en',
};

const translation = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    changeLanguage(state, action: PayloadAction<string>) {
      const params = new URLSearchParams(window.location.search);
      params.set('locale', action.payload);
      window.history.replaceState({}, '', `?${params.toString()}`);
      state.language = action.payload;
    },
  },
});

export const { changeLanguage } = translation.actions;

export default translation.reducer;
