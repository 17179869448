import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedIn } from '@rjp/main/src/features/auth/authSlice';
import { tokenExchange } from '../../api';
import { setUserInfo } from '../../features/userInfo/userInfoSlice';
import { AppDispatch, RootState } from '../../app';

const Authorized: FC<RouteComponentProps> = ({ history, location }) => {
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (code) {
      const triggerTokenExchange = async () => {
        try {
          const responseData = await tokenExchange({
            authorization_code: code,
          });
          const { role } = responseData.metadata;
          dispatch(setLoggedIn());
          dispatch(setUserInfo(responseData.metadata));
          history.push({
            pathname: `/${role}/${role === 'advisor' ? 'search' : 'home'}`,
            search: `locale=${language || 'en'}`,
          });
        } catch {
          history.push('/start');
        }
      };
      triggerTokenExchange();
    } else {
      history.push('/start');
    }
  }, [history, code, dispatch, language]);
  return <div />;
};

export default Authorized;
