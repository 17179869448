import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@rjp/main/src/app';

const includedQuoteSelector = (state: RootState) => state.RCData.includedQuote;
const addedQuotesSelector = (state: RootState) => state.RCData.addedQuotes;
const retirementProfileSelector = (state: RootState) =>
  state.RCData.retirementProfile;

const SmokingStatusMapping: Record<string, string> = {
  Smoker: 'Smoker',
  'Non-smoker': 'Non Smoker',
};

export const filteredAddedQuotesSelector = createSelector(
  addedQuotesSelector,
  retirementProfileSelector,
  (addedQuotes, retirementProfile) =>{
    return addedQuotes.filter(
      ({ parameters: { Age, Sex, Class } }) =>
        Age === retirementProfile?.age &&
        Sex === retirementProfile?.gender &&
        SmokingStatusMapping[Class] === retirementProfile?.smoking_status
    )
  }
);

export const filteredIncludedQuoteSelector = createSelector(
  includedQuoteSelector,
  filteredAddedQuotesSelector,
  (includedQuote, filteredAddedQuotes) =>
    includedQuote.filter(({ quote_id }) =>
      filteredAddedQuotes.find(({ id }) => id === quote_id)
    )
);
