import React, { FC, useContext, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalProps, Label, Input } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';

interface ShareProfileModalProps extends ModalProps {
  email: string;
  onShare: (email: string) => void;
  isShared: boolean;
  countdown: number;
  code: string;
  validTime: string;
}

const Content = styled.div`
  font-size: ${pxToRem(16)};
  padding: ${pxToRem(30)} ${pxToRem(45)} ${pxToRem(0)};

  .code-wrapper {
    text-align: center;
  }
`;

const ShareProfile = styled((props) => <Label {...props} />)`
  min-height: ${pxToRem(50)};
  margin-bottom: ${pxToRem(8)};
  line-height: ${pxToRem(31)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const Description = styled((props) => <Label {...props} />)`
  margin: ${pxToRem(10)} 0 ${pxToRem(15)};
  line-height: ${pxToRem(24)};
`;

const EmailAddressWrapper = styled.div<{ height: number }>`
  min-height: ${(props) => pxToRem(props.height)};
  padding: ${pxToRem(11)} ${pxToRem(25)} ${pxToRem(30)} ${pxToRem(16)};
  background: ${(props) => props.theme.color.cool_blue_light_20};
  overflow-x: auto;

  .email-input {
    width: ${pxToRem(500)};
  }

  & .field-title {
    font-weight: normal;
    line-height: ${pxToRem(26)};
    margin-bottom: ${pxToRem(11)};
  }
  .email {
    line-height: ${pxToRem(28)};
  }
  .error-msg {
    width: ${pxToRem(480)};
  }
`;

const EmailSentDetailDesc = styled.div`
  font-size: ${pxToRem(18)};
  font-weight: 500;
`;

const EmailSentCode = styled.div`
  height: ${pxToRem(50)};
  margin: ${pxToRem(20)} 0;
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(32)};
  letter-spacing: ${pxToRem(15)};
  font-weight: bold;
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const prefix = 'PopModal.ShareProfile.';

const ShareProfileModal: FC<ShareProfileModalProps> = ({
  visible = false,
  email,
  onClose,
  onShare,
  isShared,
  countdown,
  code,
  validTime,
}) => {
  const themeContext = useContext(ThemeContext);

  const [error, setError] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(email);
  const buttonType = useMemo(
    () =>
      email !== '' && !error && (!isShared || (isShared && countdown === 0))
        ? 'primary'
        : 'disabled',
    [error, email, isShared, countdown]
  );

  const shareLabel = useMemo(
    () =>
      countdown !== 0 && isShared
        ? 'ReShareTime'
        : isShared && countdown === 0
        ? 'ReShare'
        : 'Share',
    [countdown, isShared]
  );

  return (
    <Modal
      width={718}
      visible={visible}
      onClose={onClose}
      footerConfig={[
        {
          message: `${prefix}Cancel`,
          width: 170,
          type: 'secondary',
          onClick: onClose,
        },
        {
          message: `${prefix}${shareLabel}`,
          width: 170,
          type: buttonType,
          onClick: () => onShare(currentEmail),
          values: { time: countdown },
        },
      ]}
    >
      <Content>
        <ShareProfile fontSize={24}>
          <FormattedMessage id={`${prefix}${!code ? 'Title' : 'Shared'}`} />
        </ShareProfile>
        <Description fontSize={18}>
          <FormattedMessage id={`${prefix}Paragraph`} />
        </Description>
        <EmailAddressWrapper height={!code ? 114 : 94}>
          {!code ? (
            <Input
              name="email"
              type="email"
              width={480}
              className="email-input"
              title={`${prefix}ShareProfileToThisEmailAddress`}
              hasError={error}
              onChange={(name, value) => setCurrentEmail(value)}
              value={currentEmail}
              setError={(name, state) => setError(state)}
              color={themeContext.color.coolblue_medium}
              errorMsg="ErrorMsg.InvalidEmailAddressFormat"
            />
          ) : (
            <>
              <div className="field-title">
                <FormattedMessage
                  id={`${prefix}ShareProfileToThisEmailAddress`}
                />
              </div>
              <div className="email">{currentEmail}</div>
            </>
          )}
        </EmailAddressWrapper>
        {!!code && (
          <div className="code-wrapper">
            <EmailSentCode>{code}</EmailSentCode>
            <EmailSentDetailDesc>
              <FormattedMessage
                id={`${prefix}ThisOneTimePassword`}
                values={{ validTime }}
              />
            </EmailSentDetailDesc>
          </div>
        )}
      </Content>
    </Modal>
  );
};

export default ShareProfileModal;
