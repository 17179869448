import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';
import { RootState } from '@rjp/main/src/app';

const Content = styled.div`
  font-size: ${pxToRem(16)};
`;

const Bold = styled.span`
  font-weight: 500;
`;

const prefix = 'PopModal.DoneRcModal.';

const DoneRCModal: FC<ModalProps> = ({ visible = false, onClose }) => {
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const onClick = () => {
    const retirementLanguage: { [key: string]: string } = {
      en: 'en',
      'zh-hant': 'zh-tw',
    };
    window.open(
      `${process.env.REACT_APP_RETIREMENT_CALCULATOR_URL}/${retirementLanguage[language]}`
    );
    onClose?.();
  };
  return (
    <Modal
      width={600}
      visible={visible}
      onClose={onClose}
      title={`${prefix}Title`}
      textAlign="center"
      footerConfig={[
        {
          message: `${prefix}Close`,
          width: 170,
          type: 'secondary',
          onClick: onClose,
        },
        { message: `${prefix}DoTheRetirementCalculator`, width: 305, onClick },
      ]}
    >
      <Content>
        <FormattedMessage
          id={`${prefix}Paragraph`}
          values={{ bold: (msg: string) => <Bold>{msg}</Bold> }}
        />
      </Content>
    </Modal>
  );
};

export default DoneRCModal;
