import { init } from "@elastic/apm-rum";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const {
  REACT_APP_APM_SERVICE_NAME,
  REACT_APP_APM_SERVICE_URL,
  REACT_APP_COMMIT_HASH,
  REACT_APP_DEVOPS_ENV,
  REACT_APP_SENTRY_DSN,
} = process.env;

export const initApm = () => {
  if (REACT_APP_APM_SERVICE_NAME && REACT_APP_APM_SERVICE_URL) {
    init({
      serviceName: REACT_APP_APM_SERVICE_NAME,
      serverUrl: REACT_APP_APM_SERVICE_URL,
      serviceVersion: REACT_APP_COMMIT_HASH,
      environment: REACT_APP_DEVOPS_ENV || "Development",
    });
  }
};

export const initSentry = () => {
  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: REACT_APP_DEVOPS_ENV || "Development",
      integrations: [new Integrations.BrowserTracing()],
      release: REACT_APP_COMMIT_HASH,
      tracesSampleRate: 1.0,
    });
  }
};
