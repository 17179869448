import React, { FC } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { pxToRem, enlargeFontsize } from "@rjp/common/utils";

export interface TabProps {
  active: string;
  tabArray?: string[];
  translationPrefix?: string;
  onChange: (value: string) => void;
}

interface TabItemProps {
  active: boolean;
}

const TabArr = ["Retirement Details", "Products", "Report"];

const TabWrap = styled.div`
  text-align: center;
  display: flex;
  width: 100%;
  height: ${pxToRem(50)};
  justify-content: space-between;
  border-bottom: 1px ${(props) => props.theme.color.sunlife_yellow_medium} solid;
  background: ${(props) => props.theme.color.white};
`;

const TabItem = styled.div<TabItemProps>`
  user-select: none;
  flex: 1;
  height: 100%;
  line-height: ${pxToRem(56)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 20)};
  font-weight: 500;
  color: ${(props) =>
    props.active
      ? props.theme.color.sunlife_blue
      : props.theme.color.sunlife_blue_medium};
  position: relative;
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  &::after {
    content: "";
    position: absolute;
    height: ${pxToRem(4)};
    transition: all 0.5s;
    background: ${(props) => props.theme.color.sunlife_yellow};
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: ${(props) => (props.active ? 1 : 0)};
  }
`;

const Tab: FC<TabProps> = ({
  tabArray = TabArr,
  active,
  onChange,
  translationPrefix,
}) => (
  <TabWrap>
    {tabArray.map((tab) => (
      <TabItem key={tab} active={active === tab} onClick={() => onChange(tab)}>
        {translationPrefix ? (
          <FormattedMessage id={`${translationPrefix}.${tab}`} />
        ) : (
          { tab }
        )}
      </TabItem>
    ))}
  </TabWrap>
);

export default Tab;
