export const commitmentConfig: Record<string, any> = {
  tabList: ['summary', 'riders'],
  summary: [
    { label: 'CurrentAge', name: 'Age' },
    { label: 'Gender', name: 'Sex' },
    { label: 'SmokingStatus', name: 'Class' },
    { label: 'Currency', name: 'Currency' },
    { label: 'BasicPlan', name: 'GSA' },
    { label: 'SavingBooster', name: 'SavingBooster' },
    { label: 'SumAssuredProtectionBooster', name: 'ProtectionBooster' },
    { label: 'AccumulationTerm', name: 'AccPeriod' },
    { label: 'PremiumPaymentTerm', name: 'PremTerm' },
    { label: 'PremiumPaymentMode', name: 'PremMode' },
    { label: 'SinglePremium', name: 'TotalPrem' },
  ],
  riders: {
    title: 'TheseAreTheAdditionalBenefits',
    list: ['SavingBooster', 'SumAssuredProtectionBooster'],
  },
};

export const victoryConfig: Record<string, any> = {
  tabList: ['summary'],
  summary: [
    { label: 'CurrentAge', name: 'Age' },
    { label: 'Gender', name: 'Sex' },
    { label: 'SmokingStatus', name: 'Class' },
    { label: 'Currency', name: 'Currency' },
    { label: 'NotionalAmount', name: 'NotionalAmount' },
    { label: 'PremiumPaymentTerm', name: 'PremTerm' },
    { label: 'PremiumPaymentMode', name: 'PremMode' },
    { label: 'ModalPremium', name: 'ModalPremium' },
  ],
};

export const visionConfig: Record<string, any> = {
  tabList: ['summary'],
  summary: [
    { label: 'CurrentAge', name: 'Age' },
    { label: 'Gender', name: 'Sex' },
    { label: 'SmokingStatus', name: 'Class' },
    { label: 'Currency', name: 'Currency' },
    { label: 'InitialGuaranteedSavingAmount', name: 'FaceAmount' },
    { label: 'PremiumPaymentTerm', name: 'PremTerm' },
    { label: 'PremiumPaymentMode', name: 'PremMode' },
    { label: 'ModalPremium', name: 'ModalPremium' },
    { label: 'MonthlyCouponPaymentOption', name: 'MonthlyCouponStrategy' },

  ],
};

export const foresightConfig: Record<string, any> = {
  tabList: ['summary', 'riders'],
  summary: [
    { label: 'CurrentAge', name: 'Age' },
    { label: 'RetirementAge', name: 'retirement_Age' },
    { label: 'Gender', name: 'Sex' },
    { label: 'SmokingStatus', name: 'Class' },
    { label: 'Currency', name: 'Currency' },
    { label: 'GuaranteedMonthlyAnnuityPayment', name: 'gmap' },
    { label: 'BenefitTerm', name: 'bt' },
    { label: 'PremiumPaymentTerm', name: 'PremTerm' },
    { label: 'PremiumPaymentMode', name: 'PremMode' },
    { label: 'InitialPaymentModePremium', name: 'TotalPrem' },
  ],
  riders: {
    title: 'TheseAreTheAdditionalBenefits',
    list: [
      'AccidentBenefit',
      'HospitalIncomeBenefit',
      'LivingMasterExtraBenefit',
      'LivingMasterExtraBenefitOnTerm',
      'MultiProtectionBenefitOnExtraBenefit',
      'MultiProtectionBenefitOnTerm',
      'SunHealthMedicalCareRider',
      'SunHealthMedicalPremierRider',
      'SunHealthMedicalEssentialRider',
      'OptionalFemaleBenefit',
      'OptionalSupplementaryBenefit',
      'OneYearTermBenefit',
      'FiveYearTermBenefit',
      'TotalDisabilityBenefit',
    ],
  },
};

export const lastPremiumField = ['SinglePremium', 'InitialPaymentModePremium'];

export const configs: Record<string, any> = {
  Commitment: commitmentConfig,
  Vision: visionConfig,
  Victory: victoryConfig,
  Foresight: foresightConfig,
};

export const needTranslation: Record<string, any> = [
  'Gender',
  'SmokingStatus',
  'PremiumPaymentMode',
];

export const needValueTranslation: Record<string, any> = [
  'Currency',
  'BenefitTerm',
  'AccumulationTerm',
  'PremiumPaymentTerm',
  'MonthlyCouponPaymentOption',
];

export const currencyValue: Record<string, any> = [
  'BasicPlan',
  'SavingBooster',
  'SumAssuredProtectionBooster',
  'SinglePremium',
  'TargetGuaranteedSavingAmount',
  'InitialPaymentModePremium',
  'GuaranteedMonthlyAnnuityPayment',
  'InitialGuaranteedSavingAmount',
  'ModalPremium',
  'NotionalAmount',
];
