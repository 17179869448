import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toLogin } from '@rjp/common/utils';

const ToAdvisorLogin: FC = () => {
  const history = useHistory();
  useEffect(() => toLogin('advisor', history)(), [history]);
  return <div />;
};

export default ToAdvisorLogin;
