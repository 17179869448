/**
 * Password must contain:
 • A minimum of eight (8) characters
 • One lowercase character (e.g. a-z)
 • One uppercase character (e.g. A-Z)
 • One number (e.g. 0-9)
 • One of the following characters: !@#$%^&*( )+ -
 */
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()+-])[A-Za-z\d!@#$%^&*()+-]{8,}$/;

/**
 * Telephone
 */
export const telephoneReg = /^([0-9]{3,4})?[0-9]{7,8}$/;

/**
 * HK Telephone
 */
export const HKTelephoneReg = /^[0-9]{8}$/;
// export const HKTelephoneReg = /^(5|6|8|9)\d{7}$/;

/**
 * Mobile
 */
export const mobileReg = /^[1][3-9]\d{9}$/;

/**
 * Email
 */
export const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;

/**
 * Name
 */
export const nameReg = /^[a-zA-Z]+([-\s]*)+[a-zA-Z\s]{0,20}$/;

/**
 * Trim all space
 */
export const trimAllReg = /\s/g;

/**
 * chinese name
 */
export const chineseNameReg = /^[\u4e00-\u9fa5]{2,4}$/;

/**
 * age
 */
export const ageReg = /^([1-9]\d?|100)$/;

export const reg: Record<string, any> = {
  password: passwordReg,
  telephone: telephoneReg,
  hkTelephone: HKTelephoneReg,
  mobile: mobileReg,
  email: emailReg,
  name: nameReg,
  trimAll: trimAllReg,
  chineseName: chineseNameReg,
  age: ageReg,
  // advisoremail: emailReg,
};

export const countDown = 60;
export const timeout = 60 * 60 * 1000 - countDown * 1000;
