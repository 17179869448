import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Swiper, { Navigation, Pagination } from 'swiper';

import { AppDispatch, RootState } from '@rjp/main/src/app';
import { pxToRem } from '@rjp/common/utils';
import {
  Button,
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
} from '@rjp/common/component';
import { ReactComponent as RefreshSvg } from '@rjp/common/assets/images/refresh.svg';
import { IUserInfoState } from '@rjp/main/src/features/userInfo/userInfoSlice';
import {
  setIncludedQuote,
  setAddedQuotes,
} from '@rjp/main/src/features/rcData/rcDataSlice';
import { getsvtoken, IQuote, rjpToSv } from '@rjp/main/src/api';

import { productsConfigs } from './config';
import { addQuotes, deleteQuotes, getDraftQuotes } from '../../../../api';
// eslint-disable-next-line import/no-cycle
import ProductsContainer from './ProductsContainer';
import { AddSaveQuoteModal } from '../modal';
import {getIllustration, getVisionIllustration} from "@/api/illustration";
import { trackingFunc, RJP_VIEW } from '@/utils/tracking';
import { config } from 'process';
import { labelMap } from '../config/config';
import dayjs from 'dayjs';

Swiper.use([Navigation, Pagination]);

const prefix = 'WorkSurface.Home.Products.';

const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

interface ClientAdvisorProductProps {
  clientInfo?: IUserInfoState;
}

const Title = styled.div`
  height: ${pxToRem(45)};
  margin: ${pxToRem(14)} 0 ${pxToRem(5)} ${pxToRem(23)};
  line-height: ${pxToRem(50)};
  font-size: ${pxToRem(16)};
`;

const GetQuote = styled.div`
  padding: ${pxToRem(20)} ${pxToRem(28)} 0 ${pxToRem(20)};
  display: flex;
  justify-content: space-between;
`;

const Refresh = styled.div`
  margin-top: ${pxToRem(20)};
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(21)};
  font-weight: 500;
  cursor: pointer;
  user-select: none;

  svg {
    width: ${pxToRem(17)};
    height: ${pxToRem(17)};
    margin-right: ${pxToRem(18)};
    vertical-align: middle;

    path {
      fill: ${(props) => props.theme.color.coolblue};
    }
  }

  .refresh {
    text-decoration: underline;
    color: ${(props) => props.theme.color.coolblue};
  }
`;

const SmokingStatusMapping: Record<string, string> = {
  Smoker: 'Smoker',
  'Non-smoker': 'Non Smoker',
};

const ClientAdvisorProduct: FC<ClientAdvisorProductProps> = ({
  clientInfo,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const role =
    useSelector((state: RootState) => state.userInfo.role) ?? 'member';
  const { retirementProfile, includedQuote, addedQuotes } = useSelector(
    (state: RootState) => state.RCData
  );

  const [addSaveQuoteVisible, setAddSaveQuoteVisible] = useState(false);
  const [draftQuotes, setDraftQuotes] = useState<IQuote[]>([]);

  const { age, gender, smoking_status } = retirementProfile ?? {};
  console.log({ age, gender, smoking_status })
  const filteredDraftQuotes = useMemo(
    () =>
      draftQuotes.filter(
        ({ parameters: { Age, Sex, Class } }) =>
          Age === age &&
          Sex === gender &&
          SmokingStatusMapping[Class] === smoking_status
      ),
    [draftQuotes, age, gender, smoking_status]
  );

  const currentCustomerId = useMemo(() => clientInfo?.id ?? '', [clientInfo]);

  const getQuote = async () => {
    const retirementLanguage: { [key: string]: string } = {
      en: 'en',
      'zh-hant': 'zh',
    };
    const urlObject = new URL(process.env.REACT_APP_SUNVISUAL_URL);
    urlObject.searchParams.set('age', retirementProfile?.age || '');
    urlObject.searchParams.set('gender', retirementProfile?.gender || '');
    urlObject.searchParams.set(
      'smoking_status',
      retirementProfile?.smoking_status || ''
    );
    urlObject.searchParams.set('member_id', currentCustomerId);
    urlObject.searchParams.set('from', 'advisor_app');
    urlObject.searchParams.set('locale', retirementLanguage[language]);
    if (process.env.REACT_APP_IS_SUNLIFE_API) {
      const { svToken, transactionId } = await getsvtoken({
        customer_id: currentCustomerId,
      });
      urlObject.searchParams.set('transaction_id', transactionId);
      rjpToSv(urlObject.toString(), svToken);
    } else {
      window.open(urlObject.toString());
    }
  };

  const onRefresh = useCallback(async () => {
    try {
      const responseData = await getDraftQuotes({
        customer_id: currentCustomerId,
      });
      const dataToken = await getsvtoken({
        customer_id: currentCustomerId,
      });
      // for each quote, send to illustration API
      for (const quote of responseData.quotes) {
        if (quote.product_code === 'VICT') {
          try{
            const data = await getIllustration(quote.parameters, retirementProfile, dataToken.svToken);
            quote.illustration = data;
          } catch(error){
            console.log(error);
          }
        } else if (quote.product_code === "VISION") {
          try{
            const data = await getVisionIllustration(quote.parameters, retirementProfile, dataToken.svToken);
            quote.illustration = data;
          } catch(error){
            console.log(error);
          }
        }
      }
      setDraftQuotes(responseData.quotes);
    } catch (error) {
      // TODO: Show error
      console.log(error);
    }
  }, [currentCustomerId]);

  useEffect(() => {
    if (filteredDraftQuotes.length > 0) {
      setAddSaveQuoteVisible(true);
    }
  }, [filteredDraftQuotes]);

  const updateQuotes = useCallback(
    async (newAddedQuotes: IQuote[]) => {
      const newIncludedQuote = newAddedQuotes.map(
        (quote) =>
          includedQuote.find((q) => q.quote_id === quote.id) ?? {
            quote_id: quote.id,
            is_included: false,
          }
      );
      dispatch(setIncludedQuote(newIncludedQuote));
      dispatch(setAddedQuotes(newAddedQuotes));
    },
    [dispatch, includedQuote]
  );

  const onAdd = async (quote_ids: string[]) => {
    try {
      await addQuotes({ quote_id: quote_ids });
      const selectedDraftQuotes = draftQuotes.filter(({ id }) =>
        quote_ids.includes(id)
      );
      const dataToken = await getsvtoken({
        customer_id: currentCustomerId,
      });
      for (const quote of selectedDraftQuotes) {
        /* Tracking each product */
        let quoteName = quote.description ? quote.description : labelMap[quote.product_code];
        let quoteDate = dayjs(quote.creation_date).format('DD/MM/YY  HH:mm');
        trackingFunc(RJP_VIEW, 'other', `linked product : ${quoteName} : ${quoteDate}`);
        /* Tracking End */
        if (quote.product_code === 'VICT') {
          try{
            const data = await getIllustration(quote.parameters, retirementProfile, dataToken.svToken);
            quote.illustration = data;
          } catch(error){
            console.log(error);
          }
        } else if (quote.product_code === "VISION") {
          try{
            const data = await getVisionIllustration(quote.parameters, retirementProfile, dataToken.svToken);
            quote.illustration = data;
          } catch(error){
            console.log(error);
          }
        }
      }
      const newAddedQuotes = [...addedQuotes, ...selectedDraftQuotes];
      await updateQuotes(newAddedQuotes);
    } catch (error) {
      // TODO: Show error
      console.log(error);
    }
  };

  const onDelete = async (delete_id: string) => {
    try {
      await deleteQuotes({ quote_ids: [delete_id] });
      const newAddedQuotes = addedQuotes.filter(
        (beforeAddQuote) => beforeAddQuote.id !== delete_id
      );
      await updateQuotes(newAddedQuotes);
    } catch (error) {
      // TODO: Show error
      console.log(error);
    }
  };

  return (
    <>
      <Title>
        <CurFormattedMessage id="SunLifeProducts" />
      </Title>
      {/* { */}
      {/*  role !== 'member' && ( */}
      {/*    <ProductsContainer */}
      {/*      productsData={[]} */}
      {/*      productConfig={productsConfigs[0]} */}
      {/*      isPurchasedProducts */}
      {/*    /> */}
      {/*  ) */}
      {/* } */}
      <ProductsContainer
        productConfig={productsConfigs[1]}
        isPurchasedProducts={false}
        onDelete={onDelete}
      />
      {role === 'advisor' && (
        <GetQuote>
          <Button width={166} onClick={() => {getQuote(); trackingFunc(RJP_VIEW, 'other', 'get quote - main');}}>
            <CurFormattedMessage id="GetQuote" />
          </Button>
          <Refresh onClick={onRefresh}>
            <RefreshSvg />
            <span className="refresh">
              <CurFormattedMessage id="Refresh" tagName="span" />
            </span>
          </Refresh>
        </GetQuote>
      )}
      <AddSaveQuoteModal
        visible={addSaveQuoteVisible}
        quotesData={filteredDraftQuotes}
        onClose={() => setAddSaveQuoteVisible(false)}
        onAdd={onAdd}
        afterClose={() => setDraftQuotes([])}
      />
    </>
  );
};

export default ClientAdvisorProduct;
