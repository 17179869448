const defaultValue = {
  Age: 32,
  Sex: 'Female',
  Class: 'Smoker',
  Currency: 'HKD',
  GSA: 1600000,
  SavingBooster: 233680,
  ProtectionBooster: 500000,
  AccPeriod: '10',
  PremTerm: 'SinglePay',
  PremMode: 'Semi-Annual',
  TotalPrem: 1408210,
  tgsa: 958,
  ipmp: 10000,
  retirement_age: 65,
  gmap: 504927,
  bt: 100,
};

const commitmentData = {
  product_code: 'COMM',
  policyNo: '1234567',
  product_status: 'Shelved',
  creation_date: '2020-09-22T09:55:45.143Z',
  product_last_update_date: '2020-10-07T09:38:00.982Z',
  modalPremium: 100000,
  ...defaultValue,
};

const visionData = {
  product_code: 'VISION',
  policyNo: '2345678',
  product_status: 'Shelved',
  creation_date: '2020-07-29T16:39:57+08:00',
  product_last_update_date: '2020-07-29T16:39:57+08:00',
  modalPremium: 250000,
  ...defaultValue,
};

const foresightData = {
  product_code: 'FORES',
  policyNo: '3456789',
  product_status: 'Updated',
  creation_date: '2020-09-22T09:55:45.143Z',
  product_last_update_date: '2020-10-07T09:38:00.982Z',
  modalPremium: 400000,
  ...defaultValue,
};

export const defaultDataValue: Record<string, any> = {
  Commitment: commitmentData,
  Vision: visionData,
  Foresight: foresightData,
};

export const productsConfigs = [
  {
    title: 'PurchasedProducts',
    noDataLabel: 'NoPurchasedProducts',
    swiperName: 'purchased-products',
  },
  {
    title: 'ProductQuotes',
    noDataLabel: 'NoProductsQuotes',
    swiperName: 'product-quotes',
  },
];

export const selectOption = [
  {
    label: 'WorkSurface.Home.Products.SelectOption.AllAdvisors',
    value: 'AllAdvisors',
  },
];

export const addQuotesProduct: Record<string, any> = {
  COMM: [],
};
