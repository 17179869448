import sunlifeCaller from './sunlifeCaller';
import { IRetirementGapSummary } from './type';
import { IGetRetirementGapCalculation } from './retirementgap.type';

const getRetirementGapCalculation = async (
  data: IGetRetirementGapCalculation
) =>
  sunlifeCaller<{ retirement_gap_summary: IRetirementGapSummary }>(
    {
      method: 'post',
      url: '/retirementgapcalculation',
      data: {
        ...data,
        retirement_profile: Object.fromEntries(
          Object.entries(data.retirement_profile).filter(
            ([, value]) => value !== '' && value !== undefined && value !== null
          )
        ),
      },
    },
    2
  );

export default getRetirementGapCalculation;
