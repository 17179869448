import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { Modal as AntModal } from "antd";
import { ModalProps as AntModalProps } from "antd/es/modal";

import { pxToRem, enlargeFontsize } from "@rjp/common/utils";
import { ReactComponent as CloseSvg } from "@rjp/common/assets/images/close-btn.svg";
import {
  DefaultFooter,
  ButtonsFooter,
  FooterButtonProps,
} from "./FooterLayout";

export interface ModalProps extends Omit<AntModalProps, "footer"> {
  // 是否完全定义, 默认否
  customize?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  footerOnClose?: () => void;
  closeText?: string;
  closeBtnWidth?: number;
  textAlign?: "center" | "left";
  // close按钮 | 多个按钮
  footerConfig?: undefined | FooterButtonProps[];
  showFooterConfig?: boolean;
}

const StyledModalDefaultCss = css`
  /* & .ant-modal-header {
    padding: ${pxToRem(16)};
    border-bottom: 0;

    & .ant-modal-title{
      font-size: ${(props) =>
    enlargeFontsize(props.theme.language, props.titleFontSize ?? 30)};
      font-weight: bold;
      line-height: ${pxToRem(20)};
      text-align: right;
    }
  } */

  & .ant-modal-content {
    background-color: white;
  }

  & .ant-modal-body {
    font-size: ${(props) => enlargeFontsize(props.theme.language, 14)};
    padding: ${pxToRem(45)} ${pxToRem(45)} ${pxToRem(10)};
  }

  & .ant-modal-footer {
    padding: ${pxToRem(24)};
    border-top: 0;
    display: flex;
    justify-content: center;

    button {
      box-shadow: none;
    }
  }
`;

const StyledModalCustomizeCss = css`
  & .ant-modal-content {
      background-color: white;
    }

    & .ant-modal-body {
      padding: ${pxToRem(0)};
    }
  }
`;

const StyledModal = styled(({ width, ...props }) => (
  <AntModal {...props} />
))<ModalProps>`
  &.ant-modal {
    width: ${(props) => pxToRem(props.width) || "auto"} !important;
  }

  ${(props) => {
    switch (props.customize) {
      case true:
        return StyledModalCustomizeCss;
      default:
        return StyledModalDefaultCss;
    }
  }};
`;

const TitleDiv = styled.div`
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 20)};
  font-weight: bold;
  text-align: center;
  margin-bottom: ${pxToRem(30)};
  white-space: pre-wrap;
`;

const StyledBtn = styled.div`
  & svg {
    cursor: pointer;
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    position: absolute;
    right: ${pxToRem(13)};
    top: ${pxToRem(14)};
    z-index: 1;

    path {
      fill: ${(props) => props.theme.color.grey_light_0};
    }
  }
`;

const Content = styled.div<Pick<ModalProps, "textAlign">>`
  text-align: ${(props) => props.textAlign || "left"};
  max-height: ${pxToRem(500)};
  overflow-y: auto;
  white-space: pre-wrap;
`;

const Modal: FC<ModalProps> = ({
  children,
  customize,
  onClose,
  closeText,
  closeBtnWidth,
  footerOnClose,
  footerConfig,
  showFooterConfig = true,
  title,
  textAlign,
  ...props
}) => {
  let footer: any = false;
  if (!customize && !!showFooterConfig) {
    footer =
      footerConfig === undefined ? (
        <DefaultFooter
          onClose={footerOnClose || onClose}
          closeText={closeText}
          width={closeBtnWidth}
        />
      ) : (
        <ButtonsFooter config={footerConfig} />
      );
  }

  return (
    <StyledModal
      customize={customize}
      footer={footer}
      closable={false}
      transitionName="zoom-up"
      {...props}
      centered
      title=""
    >
      {!!title && (
        <TitleDiv>
          {typeof title === "string" ? <FormattedMessage id={title} /> : title}
        </TitleDiv>
      )}
      {customize ? null : (
        <StyledBtn onClick={onClose}>
          <CloseSvg />
        </StyledBtn>
      )}
      <Content
        textAlign={textAlign}
        className="modal-content main-content-wrapper"
      >
        {children}
      </Content>
    </StyledModal>
  );
};

export default Modal;
