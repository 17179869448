import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { ThunkAPIConfig } from '@rjp/main/src/app/store';
import { productfactoryAPI } from '@rjp/main/src/api';
import { IQuote } from '@/api';

interface IProductQuoteData {
  [key: string]: any;
}

interface IProductsData {
  [product: string]: {
    [id: string]: IProductQuoteData;
  };
}

const initialState: IProductsData = {};

interface IProductQuoteResult {
  product_code: string;
  id: string;
  output: Record<string, any>;
}

export const getProductsData = createAsyncThunk<
  IProductsData,
  IQuote[],
  ThunkAPIConfig
>('productsData', async (quoteData, { rejectWithValue }) => {
  try {
    const responseArray = await Promise.allSettled(
      quoteData.map((quote) =>
        productfactoryAPI[quote.product_code]?.(quote.parameters)
      )
    );
    const outputsArray = responseArray.map((result) =>
      result.status === 'fulfilled' ? result.value : undefined
    );

    const newProductsData: IProductsData = {};
    quoteData.forEach(({ product_code, id }, index) => {
      const output = outputsArray[index];
      if (output) {
        set(newProductsData, [product_code, id], output);
      }
    });
    return newProductsData;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const productsData = createSlice({
  name: 'productsData',
  initialState,
  reducers: {
    setProductsData(state, action: PayloadAction<IProductsData>) {
      const newDataProductList = Object.keys(action.payload);
      if (Object.keys(newDataProductList).length > 0) {
        const existingProductList = Object.keys(state);
        const combinedProductList = [
          ...new Set([...existingProductList, ...newDataProductList]),
        ];
        const newState = Object.fromEntries(
          combinedProductList.map((product) => [
            product,
            action.payload[product]
              ? { ...(state[product] ?? {}), ...action.payload[product] }
              : state[product],
          ])
        );
        return newState;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProductsData.fulfilled,
      (state, action: PayloadAction<IProductsData>) => {
        const newDataProductList = Object.keys(action.payload);
        if (Object.keys(newDataProductList).length === 0) {
          return state;
        }
        const existingProductList = Object.keys(state);
        const combinedProductList = [
          ...new Set([...existingProductList, ...newDataProductList]),
        ];
        const newState = Object.fromEntries(
          combinedProductList.map((product) => [
            product,
            action.payload[product]
              ? { ...(state[product] ?? {}), ...action.payload[product] }
              : state[product],
          ])
        );
        return newState;
      }
    );
  },
});

export const { setProductsData } = productsData.actions;

export default productsData.reducer;
