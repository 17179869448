import { IFormField } from '@rjp/common/template/retirementDetailsItems';
import { IStepLogic } from '@rjp/common/utils';

export const personalInformation: IFormField[] = [
  {
    type: 'inputNumber',
    title: 'CurrentAge',
    name: 'age',
    width: 50,
    min: 18,
    max: 69,
    errorMsg: 'ErrorMsg.PleaseInputYourCurrentAge',
    required: true,
    canEdit: true,
    noLabel: false,
    wrapperWidth: 262,
    className: 'current-age',
  },
  {
    type: 'inputNumber',
    title: 'NoOfChildren',
    name: 'no_of_children',
    errorMsg: 'ErrorMsg.PleaseInputYourNoOfChildren',
    width: 50,
    max: 10,
    maxWidth: 360,
    canEdit: true,
    noLabel: false,
    wrapperWidth: 264,
  },
  {
    type: 'radioGroup',
    title: 'Gender',
    name: 'gender',
    errorMsg: 'ErrorMsg.PleaseSelectYourGender',
    width: 336,
    canEdit: true,
    required: true,
    noLabel: false,
    options: [
      {
        value: 'Male',
        label: 'Male',
      },
      {
        value: 'Female',
        label: 'Female',
      },
    ],
  },
  {
    type: 'radioGroup',
    title: 'MaritalStatus',
    name: 'marital_status',
    errorMsg: 'ErrorMsg.PleaseSelectYourMaritalStatusStatus',
    width: 336,
    canEdit: true,
    required: true,
    noLabel: false,
    options: [
      {
        value: 'Single',
        label: 'Single',
      },
      {
        value: 'Married',
        label: 'Married',
      },
    ],
  },
  {
    type: 'radioGroup',
    title: 'SmokingStatus',
    name: 'smoking_status',
    errorMsg: 'ErrorMsg.PleaseSelectYourSmokingStatus',
    width: 336,
    canEdit: true,
    required: true,
    noLabel: false,
    options: [
      {
        value: 'Smoker',
        label: 'Smoker',
      },
      {
        value: 'Non Smoker',
        label: 'NonSmoker',
      },
    ],
  },
];

export const incomeAndCashSavings: IFormField[] = [
  {
    type: 'slider',
    title: 'MonthlyIncome',
    name: 'monthly_income',
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    inputPrefix: '$',
    step: 1,
    min: 0,
    max: 300000,
    canEdit: true,
    required: true,
    hasInput: true,
    noLabel: false,
  },
  {
    type: 'slider',
    title: 'CashSavingsInTheBank',
    name: 'total_cash_savings',
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    inputPrefix: '$',
    step: 1,
    min: 0,
    max: 50000000,
    maxWidth: 548,
    canEdit: true,
    hasInput: true,
    noLabel: false,
  },
  {
    type: 'slider',
    title: 'OfMonthlyIncomeYouAreSaving',
    name: 'monthly_cash_savings', // 需计算
    step: 1,
    min: 0,
    max: 100,
    inputPrefix: '$',
    maxWidth: 548,
    canEdit: true,
    hasInput: false,
    toolTip: true,
    noLabel: false,
  },
];

export const retirementTarget: IFormField[] = [
  {
    type: 'inputNumber',
    title: 'RetirementAge',
    name: 'retirement_age',
    errorMsg: 'ErrorMsg.PleaseInputYourRetirementAge',
    width: 50,
    min: 40,
    max: 70,
    required: true,
    canEdit: true,
    noLabel: false,
    wrapperWidth: 550,
  },
  {
    type: 'radioGroup',
    title: 'ProjectedMonthlyRetirementAllowance',
    name: 'retirement_monthly_income_style',
    width: 336,
    maxWidth: 548,
    canEdit: true,
    required: true,
    noLabel: false,
    options: [
      {
        value: 'Modest',
        label: 'Modest',
      },
      {
        value: 'Comfortable',
        label: 'Comfortable',
      },
      {
        value: 'Relaxed',
        label: 'Relaxed',
      },
    ],
  },
  {
    type: 'slider',
    title: 'NoLabel',
    name: 'retirement_monthly_income', // 需计算
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    step: 1,
    inputPrefix: '$',
    min: 3000,
    max: 6000,
    maxWidth: 548,
    canEdit: true,
    hasInput: true,
    noLabel: true,
    inVisible: true,
  },
  {
    type: 'slider',
    title: 'PostRetirementInvestmentMix',
    name: 'post_retirement_investment_mix', // 需计算
    step: 1,
    min: 0,
    max: 4,
    maxWidth: 548,
    canEdit: true,
    hasInput: false,
    noLabel: true,
    required: true,
    toolTip: true,
  },
];

export const mPF: IFormField[] = [
  {
    type: 'slider',
    title: 'TotalMPFBalance',
    name: 'total_mpf_savings',
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    inputPrefix: '$',
    step: 1,
    min: 0,
    max: 50000000,
    canEdit: true,
    hasInput: true,
    noLabel: false,
  },
  {
    type: 'slider',
    title: 'MonthlyMPFVoluntaryContributions',
    name: 'monthly_mpf_savings',
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    inputPrefix: '$',
    step: 1,
    min: 0,
    max: 300000,
    maxWidth: 548,
    canEdit: true,
    hasInput: true,
    noLabel: false,
  },
  {
    type: 'slider',
    title: 'MPFInvestmentMix',
    name: 'mpf_investment_mix',
    step: 1,
    min: 0,
    max: 4,
    maxWidth: 548,
    canEdit: true,
    hasInput: false,
    noLabel: false,
  },
];

export const otherInvestments: IFormField[] = [
  {
    type: 'selectInputNumber',
    title: 'InvestmentDescription',
    inputName: 'investment_description',
    name: 'amount',
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    inputErrorMsg: 'ErrorMsg.PleaseInputTheName',
    width: 118,
    placeHolder: 'Name',
    inputWidth: 381,
    maxWidth: 553,
    min: 0,
    max: 50000000,
    index: 0,
    canEdit: true,
    noLabel: true,
    inVisible: true,
    isAddCard: true,
    option: [
      { label: 'Product.Stocks', value: 'Stocks' },
      { label: 'Product.GovernmentBonds', value: 'Government bonds' },
      { label: 'Product.CorporateBonds', value: 'Corporate bonds' },
      { label: 'Product.EquityMutualFunds', value: 'Equity mutual funds' },
      { label: 'Product.BondMutualFunds', value: 'Bond mutual funds' },
      {
        label: 'Product.Commodities',
        value: 'Commodities (e.g. gold, silver etc.)',
      },
      { label: 'Product.REIT', value: 'Real Estate Investment Trust (REIT)' },
      { label: 'Product.Currencies', value: 'Currencies' },
    ],
  },
  {
    type: 'slider',
    title: 'NoLabel',
    name: 'other_investment_style',
    inputPrefix: '$',
    prefix: 'Product.LowRisk',
    suffix: 'Product.HighRisk',
    step: 1,
    min: 0,
    max: 4,
    width: 180,
    canEdit: true,
    index: 0,
    noLabel: true,
    inVisible: true,
    isAddCard: true,
  },
];

export const riskFieldsMap = [
  'Conservative',
  'Moderate',
  'Balanced',
  'Growth',
  'Aggressive',
];

export const riskFieldsValue: Record<string, any> = {
  Conservative: 0,
  Moderate: 1,
  Balanced: 2,
  Growth: 3,
  Aggressive: 4,
};

export const sliderFieldsName = [
  'post_retirement_investment_mix',
  'mpf_investment_mix',
];
export const sliderFieldsMap: Record<string, string[]> = {
  post_retirement_investment_mix: [
    'Conservative',
    'Moderate',
    'Balanced',
    'Growth',
    'Aggressive',
  ],
  mpf_investment_mix: [
    'Conservative',
    'Moderate',
    'Balanced',
    'Growth',
    'Aggressive',
  ],
};
export const sliderFieldsValueMap: Record<string, any> = {
  post_retirement_investment_mix: {
    Conservative: 0,
    Moderate: 1,
    Balanced: 2,
    Growth: 3,
    Aggressive: 4,
  },
  mpf_investment_mix: {
    Conservative: 0,
    Moderate: 1,
    Balanced: 2,
    Growth: 3,
    Aggressive: 4,
  },
};

export const futurePayments: IFormField[] = [
  {
    type: 'inputInputNumber',
    title: 'SunDiamondPolicy',
    inputName: 'payment_description',
    name: 'amount',
    width: 118,
    placeHolder: 'AddDescriptionOfTheFunds',
    errorMsg: 'ErrorMsg.PleaseInputTheAmountBetween',
    inputErrorMsg: 'ErrorMsg.PleaseInputTheDescription',
    inputWidth: 381,
    min: 0,
    max: 50000000,
    maxWidth: 548,
    index: 0,
    canEdit: true,
    noLabel: true,
    inVisible: true,
    isAddCard: true,
  },
  {
    type: 'checkBox',
    title: 'FundsToBeReceivedAtAge',
    note: 'WillYouReceiveThisFund',
    name: 'is_after_retirement',
    checkBoxType: 'primary-blue',
    width: 24,
    maxWidth: 553,
    index: 0,
    canEdit: true,
    noLabel: true,
    inputName: 'age_of_receipt',
    min: 41,
    max: 120,
    inVisible: true,
    isAddCard: true,
  },
];

export const formConfig: Record<string, any>[] = [
  {
    name: 'personalInformation',
    config: personalInformation,
    titleConfig: {
      title: 'Product.PersonalInformation',
      name: 'personalInformation',
    },
  },
  {
    name: 'incomeAndCashSavings',
    config: incomeAndCashSavings,
    titleConfig: {
      title: 'Product.IncomeAndCashSavings',
      name: 'incomeAndCashSavings',
    },
  },
  {
    name: 'retirementTarget',
    config: retirementTarget,
    titleConfig: {
      title: 'Product.RetirementTarget',
      name: 'retirementTarget',
    },
  },
  {
    name: 'mPF',
    config: mPF,
    titleConfig: {
      title: 'Product.MPF',
      name: 'mPF',
    },
  },
];

export const addConfigs: Record<string, any> = {
  otherInvestments,
  futurePayments,
};

export const addCards: string[] = ['otherInvestments', 'futurePayments'];
export const addFieldsTitle: string[] = ['PortfolioTotal', 'FundsTotal'];

export const hasEmptyErrorFields = [
  'age',
  'gender',
  'marital_status',
  'smoking_status',
  'monthly_income',
  'retirement_age',
];

export const fieldMap: Record<string, any> = {
  otherInvestments: 'other_investment',
  futurePayments: 'future_payments',
};

export const needStepFields = [
  'monthly_income',
  'total_cash_savings',
  'retirement_monthly_income',
  'total_mpf_savings',
  'monthly_mpf_savings',
];

export const needCurrentHandleErrorFields = [
  'gender',
  'marital_status',
  'smoking_status',
];

export const smallStepFields = [
  'monthly_income',
  'retirement_monthly_income',
  // 'monthly_cash_savings',
];

export const otherValuesToTranslateMap: Record<string, string> = {
  Stocks: 'Stocks',
  'Government bonds': 'GovernmentBonds',
  'Corporate bonds': 'CorporateBonds',
  'Equity mutual funds': 'EquityMutualFunds',
  'Bond mutual funds': 'BondMutualFunds',
  'Commodities (e.g. gold, silver etc.)': 'Commodities',
  'Real Estate Investment Trust (REIT)': 'REIT',
  Currencies: 'Currencies',
};

export const otherValuesToTranslate = [
  'Stocks',
  'Government bonds',
  'Corporate bonds',
  'Equity mutual funds',
  'Bond mutual funds',
  'Commodities (e.g. gold, silver etc.)',
  'Real Estate Investment Trust (REIT)',
  'Currencies',
];

export const valuesToTranslate = [
  'gender',
  'marital_status',
  'smoking_status',
  'retirement_monthly_income_style',
  'post_retirement_investment_mix',
  'mpf_investment_mix',
];

export const smallStepLogic: IStepLogic = [
  {
    cuttingPoint: 5000,
    stepSize: 100,
  },
  {
    cuttingPoint: 10000,
    stepSize: 500,
  },
  {
    cuttingPoint: 100000,
    stepSize: 1000,
  },
  {
    cuttingPoint: 500000,
    stepSize: 10000,
  },
  {
    cuttingPoint: -1,
    stepSize: 50000,
  },
];

export const largeStepLogic: IStepLogic = [
  {
    cuttingPoint: 1000,
    stepSize: 50,
  },
  {
    cuttingPoint: 10000,
    stepSize: 500,
  },
  {
    cuttingPoint: 100000,
    stepSize: 5000,
  },
  {
    cuttingPoint: 1000000,
    stepSize: 50000,
  },
  {
    cuttingPoint: 10000000,
    stepSize: 500000,
  },
  {
    cuttingPoint: -1,
    stepSize: 1000000,
  },
];

export const maxMinErrorMsg: Record<string, any> = {
  age: 'ErrorMsg.PleaseInputYourCurrentAgeBetween',
  retirement_age: 'ErrorMsg.PleaseInputYourRetirementAgeBetween',
};

const getVal = (value: number) => ({
  one: {
    Modest: 0.65 * value < 6000 ? 3900 : 0.65 * value,
    Comfortable: 0.8 * value < 6000 ? 4800 : 0.8 * value,
    Relaxed: value || 6000,
  },
  two: {
    Modest: 0.65 * value,
    Comfortable: 0.8 * value,
    Relaxed: value,
  },
  three: {
    Modest: 0.6 * value,
    Comfortable: 0.7 * value,
    Relaxed: 0.9 * value,
  },
  four: {
    Modest: 0.55 * value,
    Comfortable: 0.65 * value,
    Relaxed: 0.85 * value,
  },
});

const getValueMaxMin = (value: number) => ({
  one: {
    modest: {
      min: 0.5 * value < 6000 ? 3000 : 6000,
      max: value ? (0.7 * value < 6000 ? 4200 : 0.7 * value) : 4200,
    },
    comfortable: {
      min: 0.7 * value < 6000 ? 4200 : 0.7 * value,
      max: value ? (0.8 * value < 6000 ? 4800 : 0.8 * value) : 4800,
    },
    relaxed: {
      min: 0.8 * value < 6000 ? 4800 : 0.8 * value,
      max: value < 6000 ? 6000 : value,
    },
  },
  two: {
    modest: { min: 6000, max: 0.7 * value },
    comfortable: { min: 0.7 * value, max: 0.8 * value },
    relaxed: { min: 0.8 * value, max: value },
  },
  three: {
    modest: { min: 6000, max: 0.7 * value },
    comfortable: { min: 0.7 * value, max: 0.8 * value },
    relaxed: { min: 0.8 * value, max: value },
  },
  four: {
    modest: { min: 6000, max: 0.6 * value },
    comfortable: { min: 0.6 * value, max: 0.7 * value },
    relaxed: { min: 0.7 * value, max: value },
  },
});

export const getRetirementMonthlyIncome = (
  monthlyIncome: number = 0,
  style: string
) => {
  const data: Record<string, any> = getVal(monthlyIncome);
  if (monthlyIncome < 11999) {
    return data.one[style];
  } else if (monthlyIncome >= 12000 && monthlyIncome < 17999) {
    return data.two[style];
  } else if (monthlyIncome >= 18000 && monthlyIncome < 39999) {
    return data.three[style];
  } else if (monthlyIncome >= 40000) {
    return data.four[style];
  }
};

interface styleData {
  modest: { min: number; max: number };
  comfortable: { min: number; max: number };
  relaxed: { min: number; max: number };
}

const getStyle = (value: number, data: styleData) => {
  const { modest, comfortable } = data;
  if (value >= modest.min && value < modest.max) {
    return 'Modest';
  } else if (value >= comfortable.min && value < comfortable.max) {
    return 'Comfortable';
  } else {
    return 'Relaxed';
  }
};

export const getRetirementMonthlyStyle = (
  monthlyIncome: number = 0,
  value: number
) => {
  const data: Record<string, any> = getValueMaxMin(monthlyIncome);
  if (monthlyIncome < 11999) {
    return getStyle(value, data.one);
  } else if (monthlyIncome >= 12000 && monthlyIncome < 17999) {
    return getStyle(value, data.two);
  } else if (monthlyIncome >= 18000 && monthlyIncome < 39999) {
    return getStyle(value, data.three);
  } else if (monthlyIncome >= 40000) {
    return getStyle(value, data.four);
  } else {
    return 'Modest';
  }
};

const getMinMax = (value: number) => ({
  one: {
    min: value ? (0.5 * value < 6000 ? 3000 : 6000) : 3000,
    max: value && value >= 6000 ? value : 6000,
  },
  two: {
    min: 6000,
    max: value || 6000,
  },
  three: {
    min: 6000,
    max: value || 6000,
  },
  four: {
    min: 6000,
    max: value || 6000,
  },
});

export const getRetirementMonthlyMinMax = (monthlyIncome: number = 0) => {
  const data: Record<string, any> = getMinMax(monthlyIncome);
  if (monthlyIncome < 11999) {
    return data.one;
  } else if (monthlyIncome >= 12000 && monthlyIncome < 17999) {
    return data.two;
  } else if (monthlyIncome >= 18000 && monthlyIncome < 39999) {
    return data.three;
  } else if (monthlyIncome >= 40000) {
    return data.four;
  } else {
    return data.one;
  }
};
