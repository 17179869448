import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { enlargeFontsize, pxToRem } from "@rjp/common/utils";
import { Label } from "@rjp/common/component";

interface HeaderProps {
  height?: number;
  leftHeader?: {
    title: string;
    detail: string;
  };
  titleMarginBottom?: number;
}

const HeaderWrapper = styled.div<Pick<HeaderProps, "height">>`
  display: flex;
  width: 100%;
  height: ${(props) => pxToRem(props.height || 150)};
  background: ${(props) => props.theme.color.sunlife_blue_medium};
`;

const HeaderLeft = styled.div`
  width: ${pxToRem(670)};
  padding: ${pxToRem(20)} 0 0 ${pxToRem(30)};
  color: ${(props) => props.theme.color.white};
  box-sizing: border-box;
  text-align: left;
`;

const HeaderTitle = styled(({ marginBottom, ...props }) => (
  <Label {...props} />
))`
  margin-bottom: ${(props) => pxToRem(props.marginBottom || 30)};
  font-size: ${(props) => enlargeFontsize(props.theme.language, 32)};
  line-height: ${pxToRem(44)};
`;

const HeaderDetail = styled.div`
  font-size: ${(props) => enlargeFontsize(props.theme.language, 18)};
  line-height: ${pxToRem(23)};
`;

const Header: FC<HeaderProps> = ({
  height,
  leftHeader,
  children,
  titleMarginBottom,
}) => (
  <HeaderWrapper height={height}>
    {leftHeader && (
      <HeaderLeft>
        <HeaderTitle marginBottom={titleMarginBottom}>
          <FormattedMessage id={leftHeader.title} />
        </HeaderTitle>
        <HeaderDetail>
          <FormattedMessage id={leftHeader.detail} />
        </HeaderDetail>
      </HeaderLeft>
    )}
    {children}
  </HeaderWrapper>
);

export default Header;
