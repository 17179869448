/* eslint-disable import/prefer-default-export */
import sunlifeCaller from './sunlifeCaller';
import { IGetsvtoken, IGetsvtokenResponse } from './agent.type';

export const getsvtoken = async (data: IGetsvtoken) =>
  sunlifeCaller<IGetsvtokenResponse>({
    method: 'post',
    url: '/agents/getsvtoken',
    data,
    withCredentials: true,
    params: { version: 1 },
  });

export const rjpToSv = (url: string, svToken: string) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = url;
  form.target = '_blank';
  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = 'svToken';
  hiddenField.value = svToken;
  form.appendChild(hiddenField);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
