import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMember } from '../../api';

export interface IUserInfoState extends Partial<IMember> {
  role?: string;
}

const initialState: IUserInfoState = {
  id: '',
  role: undefined,
  first_name: '',
  last_name: '',
  name_chinese: '',
  email: '',
  area_code: '852',
  date_added: '',
  is_opt_in_direct_marketing: false,
  is_created_by_agent: false,
  is_verified: false,
};

const userInfo = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (_, action: PayloadAction<IUserInfoState>) => action.payload,
    updateUserInfo: (state, action: PayloadAction<IUserInfoState>) => ({
      ...state,
      ...action.payload,
    }),
    resetUserInfo: () => initialState,
  },
});

export const { updateUserInfo, setUserInfo, resetUserInfo } = userInfo.actions;

export default userInfo.reducer;
