import React, {
  FC,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl, IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '@rjp/common/constants';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import cookies from 'js-cookie';
import { Loading } from '@rjp/common/component';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { toLogin } from '@rjp/common/utils';
import { isLoggedInSelector } from '@rjp/main/src/features/auth/authSelector';
import { IdleTimeout, AbsoluteTimeout } from '@rjp/main/src/features/auth';
import { useLayoutSize } from '../hooks';
import { AppDispatch, RootState } from './store';
import { changeLanguage } from '../features/translation/translationSlice';
import { RCDataApiCall } from '../features/rcData/rcDataSlice';
import { showLoading, hideLoading } from '../features/loading/loadingSlice';
import { logout } from '../features/auth/authSlice';
import languagePackage from '../locale';
import Header from './Header';
import { authorizedRoutes, unauthorizedRoutes } from './routes';
import '../api/mock';
import { Tracking } from '@/utils/tracking';
import Maintenance from './maintenance';

/* Initializing UTAG for Tracking */
var timesRun = 0;
let settingTrackingInterval = setInterval(() => {
  timesRun += 1;
  if (timesRun >= 30) {
    clearInterval(settingTrackingInterval);
  }
  // console.log('timesRun = ' + timesRun);
  console.log(Tracking.getInstance());
  if (Tracking.getInstance()) {
    // console.log('found utag instance ', Tracking.getInstance());
    clearInterval(settingTrackingInterval);
  } else if ((window as any).utag) {
    // console.log('setting utag ', (window as any).utag);
    Tracking.init((window as any).utag);
  }
}, 1000);

const AppWrapper = styled.div<{ width: string; height: string }>`
  margin: 0 auto;
  min-width: 768px;
  min-height: 600px;
  overflow: auto;
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.theme.color.cool_blue_light_20};
`;

const BrowserTitle: FC<{ role?: string }> = ({ role }) => {
  const curLocation = useLocation();
  const intl = useIntl();
  const inAdvisor = useMemo(
    () => /advisor/.test(curLocation.pathname) || role === 'advisor',
    [curLocation, role]
  );
  return (
    <Helmet>
      <title>
        {intl.formatMessage({
          id: `Start.${inAdvisor ? 'AdvisorApp' : 'Header'}`,
        })}
      </title>
    </Helmet>
  );
};

const inMobile =
  navigator.userAgent.match(/(iPhone|iPad|Android|ios)/i) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const App: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [height, setHeight] = useState<string>('100vh');
  const [width, setWidth] = useState<string>('100%');
  const curLocation = useLocation();
  const { layoutWidth, layoutHeight, isLandscape } = useLayoutSize();

  useEffect(() => {
    const env = process.env.REACT_APP_UTAG_ENV;
    console.log(env);
    const src = `//tags.tiqcdn.com/utag/sunlife/slfhk-rjp/${env}/utag.js`;
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;
    const target = document.getElementsByTagName('script')[0];
    target.parentNode?.insertBefore(script, target);
    return () => {
      script.parentNode?.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const inIPad =
      navigator.userAgent.match(/(iPad)/i) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (inIPad) {
      document.querySelectorAll('meta').forEach((meta) => {
        const curMeta = meta as any;
        if (curMeta?.name === 'viewport') {
          curMeta.setAttribute(
            'content',
            'width=device-width, user-scalable=no, initial-scale=1.0, shrink-to-fit=no,maximum-scale=1.0, minimum-scale=1.0'
          );
        }
      });
      document.addEventListener('touchstart', (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      let lastTouchEnd = 0;
      document.addEventListener(
        'touchend',
        (event) => {
          const now = new Date().getTime();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
      document.addEventListener('gesturestart', (event) => {
        event.preventDefault();
      });
    }
  }, []);

  const autoViewScroll = useCallback(() => {
    setTimeout(() => {
      if (inMobile && isLandscape) {
        const { activeElement } = document;
        if (
          activeElement?.tagName === 'INPUT' ||
          activeElement?.tagName === 'input'
        ) {
          activeElement.scrollIntoView({
            behavior: /android/.test(navigator.userAgent.toLowerCase())
              ? 'auto'
              : 'smooth',
            block: 'center',
          });
        }
      }
    }, 500);
  }, [isLandscape]);

  useEffect(() => {
    const rootDom = document.documentElement;
    const rootDomStyle = rootDom.style;
    const proportion = 834 / 1194;

    let fontSize = 'calc(100vw / 11.94)';
    let curHeight = `${layoutHeight}px`;
    let curWidth = '100%';
    const minWidth = 768;
    const minHeight = 600;

    if (layoutWidth < minWidth) {
      fontSize = `${Math.floor(minWidth / 11.94)}px`;
      curWidth = `${minWidth}px`;
      curHeight = `${Math.floor(minWidth * proportion)}px`;
    } else if (layoutHeight < layoutWidth * proportion) {
      const widthNumber = Math.ceil(
        (layoutHeight > minHeight ? layoutHeight : minHeight) / proportion
      );
      fontSize = `${Math.floor(widthNumber / 11.94)}px`;
      curWidth = `${widthNumber}px`;
    } else if (!inMobile || (inMobile && !isLandscape)) {
      curHeight = `${layoutWidth * proportion}px`;
    }

    rootDomStyle.fontSize = fontSize;
    setWidth(curWidth);
    setHeight(curHeight);
    autoViewScroll();
  }, [layoutWidth, layoutHeight, isLandscape, autoViewScroll]);

  const history = useHistory();
  const language = useSelector(
    (state: RootState) => state.translation.language
  );
  const loadingCount = useSelector(
    (state: RootState) => state.loading.loadingCount
  );
  const role = useSelector((state: RootState) => state.userInfo.role);
  const isLoggedIn = useSelector(isLoggedInSelector);

  const isFetched = useRef<boolean>(false);

  const setRCPasteId = useCallback(
    (id: string) => {
      isFetched.current = true;
      dispatch(
        RCDataApiCall({
          isLoggedIn,
          caseId: id,
        })
      );
      if (/start/.test(curLocation.pathname)) {
        const searchString = window.location.search;
        history.replace({ pathname: '/member/create', search: searchString });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, history, isLoggedIn]
  );

  const showHeader = useMemo(
    () =>
      curLocation.pathname !== '/authorized' &&
      curLocation.pathname !== '/advisor/tologin',
    [curLocation]
  );

  useEffect(() => {
    dispatch(
      changeLanguage(
        new URLSearchParams(window.location.search).get('locale') ||
          language ||
          'en'
      )
    );
  }, [dispatch, language]);

  const setRCPasteIdFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const RCPasteId = params.get('caseId');

    if (RCPasteId) {
      cookies.set('caseId', RCPasteId, {
        path: '/',
        sameSite: 'None',
        secure: true,
      });
      params.delete('caseId');
      history.replace({
        pathname: curLocation.pathname,
        search: params.toString(),
      });
    }
  };

  useEffect(setRCPasteIdFromUrl, [curLocation, history]);

  useMemo(() => {
    axios.interceptors.request.use((config) => {
      dispatch(showLoading());
      return config;
    });
    axios.interceptors.response.use(
      (res) => {
        dispatch(hideLoading());
        return res;
      },
      async (error) => {
        if (error.response) {
          const { status } = error.response;
          if (status === 401 && error.response.config.url !== '/auth/logout') {
            // logout user if status code is 401
            await dispatch(logout());
            if (role === 'advisor') {
              toLogin('advisor', history)();
            } else history.replace('/');
          }
        }
        dispatch(hideLoading());
        return Promise.reject(error);
      }
    );
  }, [dispatch, history, role]);

  useEffect(() => {
    if (!isFetched.current) {
      let RCPasteId = cookies.get('caseId');
      if (RCPasteId) {
        setRCPasteId(RCPasteId);
      } else {
        const getRCPasteId = setInterval(() => {
          RCPasteId = cookies.get('caseId');
          if (RCPasteId) {
            setRCPasteId(RCPasteId);
            clearInterval(getRCPasteId);
          }
        }, 200);
      }
    }
  }, [setRCPasteId]);

  const appRoute = useMemo(() => {
    if (role && isLoggedIn) {
      return authorizedRoutes(role);
    }
    return unauthorizedRoutes;
  }, [isLoggedIn, role]);

  return (
    <IntlProvider locale={language} messages={languagePackage[language]}>
      <BrowserTitle role={role} />
      <ThemeProvider theme={{ language, ...theme }}>
        <CSSTransition
          in={loadingCount > 0}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <Loading />
        </CSSTransition>
        <AppWrapper height={height} width={width}>
          {showHeader && <Header />}
          <Switch>
            {appRoute.map((props) => (
              <Route key={String(props.path)} {...props} />
            ))}
          </Switch>
          <IdleTimeout />
          <AbsoluteTimeout />
        </AppWrapper>
      </ThemeProvider>
    </IntlProvider>

    // Added fallback for maintainance page. Remove comments from the below three lines when deploying for maintainance.
    // <Switch>
    //   <Route component={Maintenance} />
    // </Switch>
  );
};

export default App;
