import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { InfoModal } from '@rjp/common/component';

const prefix = 'PopModal.RequestPermission.';

interface IRequestPermission {
  visible?: boolean;
  onClose: () => void;
  onSend: () => void;
}

const CurInfoModal = styled(InfoModal)`
  .ant-modal-body {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
  }
`;

const RequestPermission: FC<IRequestPermission> = ({
  visible,
  onClose,
  onSend,
}) => (
  <CurInfoModal
    width={480}
    onClose={onClose}
    visible={visible}
    titleConfig={{
      formatMessage: {
        id: `${prefix}RequestPermission`,
      },
    }}
    contentConfig={{
      formatMessage: {
        id: `${prefix}YouAreAboutToRequest`,
      },
    }}
    footerConfig={[
      {
        width: 170,
        type: 'secondary',
        message: 'Common.Cancel',
        onClick: onClose,
      },
      {
        width: 170,
        type: 'primary',
        message: `${prefix}Send`,
        onClick: onSend,
      },
    ]}
  />
);

export default RequestPermission;
