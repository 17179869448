import React from "react";
import styled, { css } from "styled-components";
import { pxToRem } from "@rjp/common/utils";
import { FormattedMessage } from "react-intl";
import BikePng from "@rjp/common/assets/images/bike.png";
import WalkPng from "@rjp/common/assets/images/walk.png";
import RetirementData from "@rjp/common/component/retirementData";

const primary = css`
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  width: ${pxToRem(472)};
  height: ${pxToRem(580)};
  border-radius: ${pxToRem(5)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  padding: ${pxToRem(18)} ${pxToRem(17)} ${pxToRem(0)};
  background-image: url(${WalkPng});
  background-repeat: no-repeat;
  background-size: ${pxToRem(472 / 1.8)} ${pxToRem(263 / 1.8)};
  background-position: bottom 0px left ${pxToRem(0)};
`;

const TitleDiv = styled.div`
  font-size: ${pxToRem(24)};
  font-weight: medium;
  white-space: pre-wrap;
  line-height: ${pxToRem(34)};
  margin-bottom: ${pxToRem(8)};
`;

const DescDiv = styled.div`
  font-size: ${pxToRem(16)};
  white-space: pre-wrap;
  line-height: ${pxToRem(20)};
  margin-top: ${pxToRem(8)};
`;

const SideCard3 = styled(({ ...props }) => (
  <div {...props}>
    <TitleDiv>
      <FormattedMessage id="Common.Start.SideCard3.title" />
    </TitleDiv>
    <RetirementData />
    <DescDiv>
      <FormattedMessage id="Common.Start.SideCard3.desc" />
    </DescDiv>
  </div>
))`
  ${primary}
`;

export default SideCard3;
