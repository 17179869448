import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Modal, ModalProps } from '@rjp/common/component';
import { pxToRem } from '@rjp/common/utils';

interface ChangeProfileModalProps extends ModalProps {
  onYes: () => void;
}

const Content = styled.div`
  font-size: ${pxToRem(16)};
`;

const prefix = 'PopModal.ChangeProfile.';

const ChangeProfileModal: FC<ChangeProfileModalProps> = ({
  visible = false,
  onClose,
  onYes,
}) => {
  return (
    <Modal
      width={436}
      visible={visible}
      onClose={onClose}
      title={`${prefix}Title`}
      textAlign="center"
      footerConfig={[
        {
          message: `${prefix}Cancel`,
          width: 170,
          type: 'secondary',
          onClick: onClose,
        },
        { message: `${prefix}Yes`, width: 170, onClick: onYes },
      ]}
    >
      <Content>
        <FormattedMessage id={`${prefix}Paragraph`} />
      </Content>
    </Modal>
  );
};

export default ChangeProfileModal;
