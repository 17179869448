import React, { FC } from 'react';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import { InfoModal } from '@rjp/common/component';

const prefix = 'PopModal.ExpireInMinutes.';

interface IExpireInMinutes {
  visible?: boolean;
  onClose: () => void;
}

const CurInfoModal = styled(InfoModal)`
  .ant-modal-body {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
  }
`;

const ExpireInMinutes: FC<IExpireInMinutes> = ({ visible, onClose }) => (
  <CurInfoModal
    width={440}
    onClose={onClose}
    closeText={`${prefix}Continue`}
    visible={visible}
    titleConfig={{
      formatMessage: {
        id: `${prefix}ExpireInMinutes`,
      },
    }}
    contentConfig={{
      formatMessage: {
        id: `${prefix}YouCouldStillEdit`,
      },
    }}
  />
);

export default ExpireInMinutes;
