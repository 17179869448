import React, { FC, useState } from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { pxToRem } from '@rjp/common/utils';
import RetirementData from '@rjp/common/component/retirementData';
// import { useHistory } from 'react-router-dom';
import {
  CurFormattedMessage as FormattedMessage,
  ICurFormattedMessage,
  InfoButton,
  InfoModal,
} from '@rjp/common/component';
// import { RootState } from '@rjp/main/src/app';

const prefix = 'WorkSurface.Home.RetirementGap.';
const CurFormattedMessage: FC<ICurFormattedMessage> = ({ id, ...props }) => (
  <FormattedMessage {...props} id={`${prefix}${id}`} />
);

const CurInfoModal = styled(InfoModal)`
  .ant-modal-body {
    padding-left: ${pxToRem(32)};
    padding-right: ${pxToRem(32)};
  }
`;

const RetirementGapWrapper = styled.div`
  height: ${pxToRem(420)};
  width: ${pxToRem(470)};
  border-radius: ${pxToRem(5)};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.color.sunlife_yellow_medium};
  padding: 0 ${pxToRem(15)};
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${pxToRem(20)};
`;

const Title = styled(CurFormattedMessage)`
  font-weight: 500;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
  height: ${pxToRem(32)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
`;

// const UpdateInfo = styled(props => <CurFormattedMessage tagName="button" {...props} />)`
//   cursor: pointer;
//   background: ${props => props.theme.color.white};
//   height: ${pxToRem(32)};
//   width: ${pxToRem(134)};
//   line-height: ${pxToRem(32)};
//   text-align: center;
//   border: 1px solid ${props => props.theme.color.sunlife_blue_medium};
//   border-radius: ${pxToRem(4)};
//   font-size: ${pxToRem(14)};
//   color: ${props => props.theme.color.sunlife_blue_medium};
//   outline: none;
// `;

const InfoTip = styled(CurFormattedMessage)`
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  margin-top: ${pxToRem(9)};
  height: ${pxToRem(29)};
  line-height: ${pxToRem(21)};
  font-size: ${pxToRem(16)};
`;

const InflationWrapper = styled.div`
  display: flex;
  margin-top: ${pxToRem(13)};
  width: ${pxToRem(225)};
  align-items: center;
  justify-content: space-between;
`;

const SwitchWrapper = styled.div`
  display: flex;
  width: ${pxToRem(196)};
  height: ${pxToRem(28)};
  border: 1px solid ${(props) => props.theme.color.sunlife_blue_medium};
`;

const SwitchItem = styled(({ active, ...props }) => (
  <CurFormattedMessage {...props} />
))`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${pxToRem(14)};
  color: ${(props) =>
    props.theme.color[props.active ? 'white' : 'sunlife_blue_medium']};
  background: ${(props) =>
    props.theme.color[props.active ? 'sunlife_blue_medium' : 'white']};
  font-weight: ${({ active }) => (active ? '500' : 'normal')};
  transition: all 0.3s;
`;

const BottomTip = styled(CurFormattedMessage)`
  height: ${pxToRem(18)};
  margin-top: ${pxToRem(10)};
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  color: ${(props) => props.theme.color.sunlife_blue_medium};
  white-space: pre-wrap;
`;

const RetirementGap: FC = () => {
  const [inflation, setInflation] = useState<string>('Future');

  const [showProjectedInflation, setShowProjectedInflation] = useState<boolean>(
    false
  );

  // const history = useHistory();
  // const role = useSelector((state: RootState) => state.userInfo.role);
  // const goRetirementDetail = () => {
  //   const { search } = window.location;
  //   const locale = new URLSearchParams(search).get('locale') || sessionStorage.getItem('language') || 'en';
  //   history.push(`/${role}/home/gap/details?locale=${locale}${role === 'member' ? '&edit=true' : ''}`);
  // };

  return (
    <RetirementGapWrapper>
      <CurInfoModal
        titleConfig={{
          formatMessage: { id: `${prefix}ProjectedInflation` },
        }}
        contentConfig={{
          formatMessage: { id: `${prefix}FutureDollarValuesIllustrate` },
          style: { fontSize: pxToRem(14) },
        }}
        visible={showProjectedInflation}
        onClose={() => setShowProjectedInflation(false)}
      />
      <TitleWrapper>
        <Title id="YourRetirementGap" />
        {/* <UpdateInfo id="UpdateInfo" onClick={goRetirementDetail} /> */}
      </TitleWrapper>
      <InfoTip id="SeeHowYouAreGettingCloserTo" />
      <RetirementData inflation={inflation} />
      <InflationWrapper>
        <SwitchWrapper>
          {['Future', 'Todays'].map((item) => (
            <SwitchItem
              active={inflation === item}
              id={item}
              key={item}
              onClick={() => setInflation(item)}
            />
          ))}
        </SwitchWrapper>
        <InfoButton
          type="primary-medium-blue"
          width={20}
          onClick={() => setShowProjectedInflation(true)}
        />
      </InflationWrapper>
      <BottomTip
        id={
          inflation === 'Future'
            ? 'FutureDollarTakesIntoAccountThe'
            : 'TodayDollar'
        }
      />
    </RetirementGapWrapper>
  );
};

export default RetirementGap;
