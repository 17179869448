import { reg } from '@rjp/common/config';

const profileFormConfig = [
  {
    keyName: 'first_name',
    title: 'Profile.Profile.FirstName',
    errorMsg: 'ErrorMsg.InvalidName',
    showError: (value: any) => value !== '' && !reg.name.test(value),
    editable: true,
  },
  {
    keyName: 'last_name',
    title: 'Profile.Profile.LastName',
    errorMsg: 'ErrorMsg.InvalidName',
    showError: (value: any) => value !== '' && !reg.name.test(value),
    editable: true,
  },
  {
    keyName: 'name_chinese',
    title: 'Profile.Profile.ChineseName',
    errorMsg: 'ErrorMsg.InvalidName',
    showError: (value: any) => value !== '' && !reg.chineseName.test(value),
    editable: true,
  },
  {
    keyName: 'mobile',
    type: 'mobileGroup',
    needToNumber: true,
    title: 'Profile.Profile.Mobile',
    errorMsg: 'ErrorMsg.InvalidMobileNumber',
    showError: (value: any) => value !== '' && !reg.mobile.test(value),
    editable: true,
  },
  {
    keyName: 'email',
    title: 'Profile.Profile.Email',
    errorMsg: 'ErrorMsg.InvalidEmailAddressFormat',
    showError: (value: any) => value !== '' && !reg.email.test(value),
    editable: false,
  },
];

export default profileFormConfig;
